const iotmgtRouter = [
  {
    path: '/iotmgt/deviceManage/list',
    component: () => import('@/views/iotmgt/deviceManage/list'),
    name: 'iotmgtdeviceManagelist'
  },
  {
    path: '/iotmgt/deviceConfig/list',
    component: () => import('@/views/iotmgt/deviceConfig/list'),
    name: 'iotmgtdeviceConfiglist'
  },
  {
    path: '/iotmgt/inspectRecord/list',
    component: () => import('@/views/iotmgt/inspectRecord/list'),
    name: 'iotmgtinspectRecordlist'
  },
  {
    path: '/iotmgt/deviceConfig/version',
    component: () => import('@/views/iotmgt/deviceConfig/version'),
    name: 'iotmgtdeviceConfigVersionlist'
  },
]
export default iotmgtRouter;
