import $ from 'jquery';
import store from "@/store/index";
import { Message } from 'element-ui';

const envs = {
  prod: {
    sysServerUrl: 'https://iot.zzrunta.com/iot-server/',
    frontHome: '',
    frontLogin: '',
    useCommonLogin: false,
    showLog: false,
  },
  test: {
    sysServerUrl:"https://iot.zzrunta.com/iot-server/",
    frontHome: '',
    frontLogin: '',
    useCommonLogin: false,
    showLog: false,
  },
  dev: {
    sysServerUrl: 'http://localhost:9999/',
    frontHome: '',
    useCommonLogin: false,
    showLog: true,
  }
}
const current = envs.prod;
window.showLog = current.showLog;
export const sysServerUrl = current.sysServerUrl;
export const cmsServerUrl = sysServerUrl
export const frontHome = current.frontHome;
export const frontLogin = current.frontLogin;
export const useCommonLogin = current.useCommonLogin;

import axios from "axios";

/**
 * 数字格式化为n位，不足n位则前面补0
 * @param {*} n
 */
Number.prototype.paddingZero = function (n = 2) {
  let s = "";
  for (let i = 1; i < n; i++) {
    s += '0';
  }
  return (s + this).slice(-1 * n);
}

export function addTabs(path, title) {
  var name = path.replaceAll("/", "");
  store.commit("addKeepAliveCache", path);
  var submenu = {
    path: path,
    name: name,
    label: title,
  };
  store.commit("selectMenu", submenu);
}

// tab删除
export function removeTabs(path) {
  var oldTabList = store.state.tabList;
  // 删除tabList中的该对象
  for (let i = 0; i < oldTabList.length; i++) {
    var item = oldTabList[i];
    if (item.path === path) {
      oldTabList.splice(i, 1);
    }
  }
  // 删除keepAlive缓存
  store.commit("removeKeepAliveCache", path);
}

export function getMyDate(str) {
  var date = new Date(str)
  var Y = date.getFullYear() + '-'
  var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
  var D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
  var time = Y + M + D
  return time
}
export function projectDate() {
  store.commit("removeKeepAliveCache", 'cmsmgtmanuallist');
  store.commit("removeKeepAliveCache", 'cmsmgttasklist');
  store.commit("removeKeepAliveCache", 'cmsmgtauditlist');
  store.commit("removeKeepAliveCache", 'releasemgtissuelist');
  store.commit("removeKeepAliveCache", 'releasemgtpublishSetlist');
}
// 统计区域高度
export function statisticalSize() {
  setTimeout(() => {
    let tabHeight = 0;
    if($(".el-tabs__header").length != 0){
      tabHeight = $(".el-tabs__header").outerHeight(true);
    }
    let allHeight = $(".layoutContainer").outerHeight(true);
    let searchHeight = $(".secondFloat").outerHeight(true);
    let val = allHeight - searchHeight - tabHeight - 21;
    $(".statisticalArea").css({"height": val, "overflow": "auto"});
  }, 100);
}
export function statisticalHeight() {
  statisticalSize();
  window.addEventListener("resize", function() {
    statisticalSize();
  })
}
// 数列表内容高度
export function contentSize() {
  setTimeout(() => {
    let butHeight = $('.topButton').outerHeight(true) == null ? 0 : $('.topButton').outerHeight(true)
    let distance = $('.leftData').outerHeight(true) - butHeight - 5;
    $(".scrollClass").css('height', distance)
    window.addEventListener("resize", function () {
      let butHeight = $('.topButton').outerHeight(true) == null ? 0 : $('.topButton').outerHeight(true)
      let distance = $('.leftData').outerHeight(true) - butHeight - 5;
      $(".scrollClass").css('height', distance)
    })
  }, 100)
}

// 表格高度
export let tableMaxHeight = "";
export function tableHeight() {
  setTimeout(() => {
    let allHeight = $(".layoutContainer").height() == null ? 0 : $(".layoutContainer").height();
    if ($('.el-table') && $('.el-table').length != 0) {
      let tabHeight = $(".el-tabs__header").outerHeight(true) == null ? 0 : $(".el-tabs__header").outerHeight(true);
      let searchHeight = 0;
      if ($(".el-tabs") && $(".el-tabs").length != 0) {
        var idVal = $(".el-tabs__item.is-active").attr("aria-controls");
        searchHeight = $(`.el-tabs__content #${idVal} .secondFloat`).outerHeight(true) == null ? 0 : $(`.el-tabs__content #${idVal} .secondFloat`).outerHeight(true);
      } else {
        searchHeight = $(".secondFloat").outerHeight(true) == null ? 0 : $(".secondFloat").outerHeight(true);
      }
      let handleHeight = $(".tableHandle").outerHeight(true) == null ? 0 : $(".tableHandle").outerHeight(true);
      let pagHeight = $(".pagination-container").outerHeight(true) == null ? 0 : 33;
      var val = allHeight - tabHeight - searchHeight - handleHeight - pagHeight - 5;
      tableMaxHeight = val;
    }
    let searchArea = $(".secondFloat").outerHeight(true) == null ? 0 : $(".secondFloat").outerHeight(true);
    $(".tableDetail").css("height", allHeight - searchArea - 5);
  })
}
export let rightTableHeight = "";
export function rightHeight() {
  var allHeight = $(".fromRight").height();
  var title = $(".rightTitle").height();
  var styleVal = $(".pagination-container").css("display");
  let pagHeight = 0;
  if(styleVal == "block") {
    pagHeight = $(".pagination-container").outerHeight(true);
  }
  rightTableHeight = allHeight - title - pagHeight;
}
// 提示信息
export function handleAlert(type, text) {
  if (document.getElementsByClassName("el-message").length == 0) {
    return Message({
      type: type,
      message: text,
      duration: "1200"
    })
  }
}

// 目录展开状态
export let expandData= []
export function stateExpand(data){
  if(data[0].children.length > 0){
    for(var i = 0; i < data[0].children.length ; i++){
      if(data[0].children.length > 0){
        stateExpand(data[0].children)
      }
    }
  }else{
    expandData = data[0]
  }
}
export function expandEvents(data) {
  stateExpand(data)
  return expandData
}
export let treeData= []
export function stateTree(data){
  if(data[0].children !== null){
    for(var i = 0; i < data[0].children.length ; i++){
      if(data[0].children !== null){
        stateTree(data[0].children)
      }
    }
  }else{
    treeData = data[0]
  }
}
export function expandTree(data) {
  stateTree(data)
  return treeData
}
// 展开记录
// 数节点展开
export function nodeExpand(expandList, data){
  // 保存当前展开的节点
  let flag = false
  expandList.some(item => {
    if(item === data.id){
      //判断当前节点是否存在，存在不做处理
      flag = true
      return true
    }
  })
  if(!flag){
    // 不存在则存到数组里
    expandList.push(data.id)
  }
  return expandList
}
export function nodeCollapse(list,data){
  // 删除当前关闭的节点
  list.some((item,i) => {
    if(item === data.id){
      list.splice(i, 1)
    }
  })
  removeChildren(list,data)
  return list
}
// 删除树子节点
export function removeChildren(list,data){
  if(data.children!==null){
    data.children.forEach(function(item){
      const index = list.indexOf(item.id)
      if(index !== -1){
        list.splice(index,1)
      }
      removeChildren(list,item)
    })
  }
}


/** 计算文件md5值
 * @param file 文件
 * @param chunkSize 分片大小
 * @returns Promise
 */
import SparkMD5 from 'spark-md5'
export function getmd5(file, chunkSize) {
  return new Promise((resolve, reject) => {
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
      let chunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;
      let spark = new SparkMD5.ArrayBuffer();
      let fileReader = new FileReader();
      fileReader.onload = function(e) {
          spark.append(e.target.result);
          currentChunk++;
          if (currentChunk < chunks) {
              loadNext();
          } else {
              let md5 = spark.end();
              resolve(md5);

          }
      };
      fileReader.onerror = function(e) {
          reject(e);
      };
      function loadNext() {
          let start = currentChunk * chunkSize;
          let end = start + chunkSize;
          if (end > file.size){
              end = file.size;
          }
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }
      loadNext();
  });
}



// 语种数据
export function languageType() {
  // axios.get(`${sysServerUrl}sys/dict/query?dictType=languageType`).then(res => {
  //   if (res.data.code == 100) {
  //     sessionStorage.setItem('language', JSON.stringify(res.data.data))
  //   }
  // })
}

// 记录访问的菜单
export function accessRecordLog(menu) {
  // axios.get(`${sysServerUrl}sys/access/accessMenu?menu=` + menu).then(res => {

  // })
  // axios.post(`${sysServerUrl}sys/menu/accessMenu`, menu).then(res => {

  // })
}

export const quickEntryMap = new Map([
  ["在线反馈", "feedback.png"],
  ["技术通告", "technicalCircular.png"],
  ["系统公告", "systemNotice.png"],
  ["技术手册", "manualManage.png"],
  ["工具管理", "accessoryManage.png"],
  ["反馈评价", "SBOMIocn.png"],
  ["VR管理", "vehicleInfo.png"],
]);
