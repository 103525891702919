
import manualDetails from '@/views/material/manual/details.vue'
import commentstatistics from '@/views/material/comment/statistics.vue'
import maintainAdd from '@/views/material/maintain/addCase.vue'
import maintainDetail from '@/views/material/maintain/detailCase.vue'

const materialRouter = [
    {
        path: '/manualDetails/:id',
        name: 'manualDetails',
        component: manualDetails
    },
    {
        path: '/material/manual/list',
        component: () => import('@/views/material/manual/list'),
        name: 'materialmanuallist'
    },
    {
        path: '/commentstatistics',
        name: 'commentstatistics',
        component: commentstatistics
    },
    {
        path: '/material/comment/list',
        component: () => import('@/views/material/comment/list'),
        name: 'materialcommentlist'
    },
    {
        path: '/material/service/list',
        component: () => import('@/views/material/service/list'),
        name: 'materialservicelist'
    },
    {
        // 维修案例
        path: '/material/maintain/list',
        component: () => import('@/views/material/maintain/list'),
        name: 'materialmaintainlist'
    },
    {
        path: '/maintainAdd',
        name: 'maintainAdd',
        component: maintainAdd
    },
    {
        path: '/maintainDetail/:id',
        name: 'maintainDetail',
        component: maintainDetail
    },
    {
      path: '/material/feedback/list',
      component: () => import('@/views/material/feedback/list'),
      name: 'sysmgtfeedbacklist'
    },
    {
        path: '/material/translate/list',
        component: () => import('@/views/material/translate/list'),
        name: 'translatelist'
      }



]
export default materialRouter;