<template>
    <div v-loading="show" class="loadMessage"
      element-loading-spinner="el-icon-loading"
      element-loading-text="正在处理中..."
    >
    </div>
</template>
<script>
export default {
  name: "LoadingIndex",
  props: {
    show:Boolean
  }
}
</script>
