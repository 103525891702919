<template>
  <div class="layoutContainer">
        <el-form :model="temp" ref="temp" label-position="center" :rules="rules" >
          <el-row>
            <el-col :span="5">
              <el-form-item label="车系" prop="trainId" :label-width="formLabelWidth">
                <el-select v-model="temp.trainId" placeholder="请选择车系" @change="getTrainYearList" clearable filterable>
                  <el-option-group v-for="group in trainList" :key="group.id" :label="group.nameCh">
                    <el-option v-for="item in group.children" :key="item.id" :label="item.nameCh" :value="item.id"></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="年款" prop="trainYear" :label-width="formLabelWidth">
                <el-select id="year" v-model="temp.trainYear" clearable filterable @change="getModelList">
                  <el-option v-for="(item,index) in yearList" :key="index" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="车型" prop="modelId" :label-width="formLabelWidth">
                <el-select v-model="temp.modelId" clearable filterable>
                  <el-option v-for="(item,index) in modelList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="里程" prop="mileage" :label-width="formLabelWidth">
                <el-input v-model.trim="temp.mileage" placeholder="请输入里程(km)" oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="标题" prop="title" :label-width="formLabelWidth">
                <el-input v-model.trim="temp.title" placeholder="请输入案例标题" show-word-limit maxlength="100"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="故障现象" prop="phenomena" :label-width="formLabelWidth">
                <TinymceEditor v-if="pageFlag" ref="phenomenaEditor" :id="phenomenaEditor" :imgUploadUrl="uploadUrl" v-model="temp.phenomena"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="维修过程" prop="process" :label-width="formLabelWidth">
                <TinymceEditor v-if="pageFlag" ref="processEditor" :id="processEditor" :imgUploadUrl="uploadUrl" v-model="temp.process"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="维修总结" prop="summary" :label-width="formLabelWidth">
                <TinymceEditor v-if="pageFlag" ref="summaryEditor" :id="summaryEditor" :imgUploadUrl="uploadUrl" v-model="temp.summary"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="附件" :label-width="formLabelWidth">
                <el-upload
                class="upload-demo"
                style="max-width: 379px;"
                :action="accessoryUrl"
                :headers="importHeader"
                :on-success="handlesuccess"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
                multiple
                :limit="1"
                :file-list="imgList"
                accept=".jpg, .png, .jpeg, .mp4, .mp3, .xls, .xlsx, .doc, .ppt, .pptx, docx, .pdf, .zip"
              >
                <el-button  icon="el-icon-upload" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="width: 900px; margin: 0px;" v-if="isfinish">支持上传jpg, png, jpeg, mp4, mp3, xls, xlsx, doc,  docx, ppt, pptx, pdf, zip格式的文件</div>
              </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="submitArea">
            <el-button type="primary" @click="addClick('temp')">保存</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </div>
        </el-form>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, handleAlert } from '@/assets/js/common.js'
import TinymceEditor from  "@/components/TinymceEditor/TinymceEditor.vue"
import { caseTreeList, caseTrainYear, caseEdit, caseAdd, caseInfo } from '@/api/material.js'
import axios from 'axios'
export default {
  name: 'maintainAdd',
  components: { TinymceEditor },
  data () {
    return {
      init: {
        // 组件
        plugins: ' lists image colorpicker textcolor wordcount contextmenu autoresize',
        // 工具栏
        toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent  | undo redo | link unlink image  | removeformat ',
        branding: false,
        max_width: 800,
        min_height: 300
      },
      temp: {
        id: '',
        trainId:'',
        trainYear: '',
        modelId: '',
        mileage: '',
        title: '',
        phenomena: '',
        process: '',
        summary: '',
        path: '',
      },
      accessoryPath: '',
      formLabelWidth: '80px',
      uploadUrl: '', // 文件上传地址
      rules: {
        modelId: [{ required: true, message: '车型不能为空', tigger: ['blur', 'change'] }],
        title: [{ required: true, message: '标题不能为空', tigger: ['blur', 'change'] }],
        phenomena: [{ required: true, message: '现象不能为空', tigger: ['blur', 'change'] }],
        process: [{ required: true, message: '过程不能为空', tigger: ['blur', 'change'] }],
        summary: [{ required: true, message: '总结不能为空', tigger: ['blur', 'change'] }]
      },
      accessoryUrl: '',
      imgList: [],
      isFlag: true,
      isfinish: true,
      phenomenaEditor: 'phenomenaEditor',
      processEditor: 'processEditor',
      summaryEditor: 'summaryEditor',

      trainList: [],  // 车系
      yearList: [],  // 年款
      modelList: [],   // 车型
      yearTree: [],
      caseInfo: null,   // 案例信息

      pageFlag: false,
    }
  },
  watch: {

    // 利用watch方法检测路由变化
    $route: function(to, from) {

      if (to.path !== from.path) {

        this.getBrandTrainList()
        this.accessoryUrl = sysServerUrl + 'sys/upload/attach?flag=temp/maintain'
        this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=maintain/' + Date.now()
        // this.caseInfo = JSON.parse(sessionStorage.getItem("caseInfo"));
        // if (this.caseInfo != null) {
        //   // console.log("---");
        //   setTimeout(() => {
        //     this.getTemp()
        //   })

        // }else{
        //   this.resetTemp()
        // }
      }
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token };
    }
  },
  methods: {

    addClick (temp) {
      if (!this.temp.modelId || this.temp.modelId == '') {
        handleAlert('warning','请选择车型')
        return false
      }
      if (!this.temp.phenomena || this.temp.phenomena == '') {
        handleAlert('warning','请描述故障现象')
        return false
      }
      if (!this.temp.process || this.temp.process == '') {
        handleAlert('warning','请描述维修过程')
        return false
      }
      if (!this.temp.summary || this.temp.summary == '') {
        handleAlert('warning','请总结此次维修')
        return false
      }
      var params = new URLSearchParams()
      params.append('modelId', this.temp.modelId)
      params.append('title', this.temp.title)
      params.append('mileage', this.temp.mileage)
      params.append('phenomena', this.temp.phenomena)
      params.append('process', this.temp.process)
      params.append('summary', this.temp.summary)
      if (this.accessoryPath != this.temp.path) {
        params.append('path', this.temp.path)
      }

      if (this.caseInfo == null) {
         // 添加
         caseAdd(params).then(res => {
          if (res.data.code === 100) {
                handleAlert('success', '操作成功')
                this.pageFlag = false;
                sessionStorage.removeItem("caseInfo");
                this.$router.push('/material/maintain/list')
                setTimeout(() => {
                  addTabs(this.$route.path, "维修案例");
                })
              } else {
                handleAlert('error',res.data.msg)
              }
         }).catch(e => {
          console.log(e);
          handleAlert('error','系统开小差了...')
         })
      }else{
         // 编辑
         params.append('id', this.temp.id)
         caseEdit(params).then(res => {
          if (res.data.code === 100) {
            handleAlert('success', '操作成功')
            this.pageFlag = false;
            sessionStorage.removeItem("caseInfo");
            this.$router.push('/material/maintain/list')
            setTimeout(() => {
              addTabs(this.$route.path, "维修案例");
            })
          } else {
            handleAlert('error',res.data.msg)
          }
         }).catch(e => {
          console.log(e);
          handleAlert('error','系统开小差了...')
         })
      }
     },

    resetTemp(){
      this.temp= {
        id: '',
        trainId:'',
        trainYear: '',
        modelId: '',
        mileage: '',
        title: '',
        phenomena: '',
        process: '',
        summary: '',
        path: '',
      },
      this.imgList = [],
      this.isFlag = true,
      this.isfinish = true,
      this.$nextTick(function() {
        this.$refs.temp.clearValidate();
        if (this.$refs.phenomenaEditor) {
          this.$refs.phenomenaEditor.clear()
          this.$refs.processEditor.clear()
          this.$refs.summaryEditor.clear()
        }

      })
    },
    // 重置
    resetForm (temp) {
      this.resetTemp()
    },


     // ==== 上传附件 ===== //
     beforeAvatarUpload (file) {
      this.temp.newReplyAccessory = ''
      this.isfinish = true
      this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=temp/service/' + this.temp.id
      let suffix = ['jpg', 'png', 'jpeg', 'mp4', 'mp3', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'pdf','zip']
      var fileName = file.name.substring(file.name.lastIndexOf(".")+1).toLowerCase()
      const isLt2M = file.size / 1024 / 1024 < 100
      if (!suffix.includes(fileName)) {
        handleAlert('warning','上传模板只能是jpg, png, jpeg, mp4, mp3, xls, xlsx, doc, docx, ppt, pptx, pdf, zip格式!')
        this.isFlag = false;
        return false;
      }
      if (!isLt2M) {
        handleAlert('warning','上传模板大小不能超过 100MB!')
        this.isFlag = false;
        return false;
      }
      // return isLt2M
    },
    handlesuccess (file, fileList) {
      // console.log("---", file);
      this.isfinish = false
      this.imgList = []
      this.temp.path = file.data.fileUrl;
      var img = { name: file.data.fileName, url: sysServerUrl + 'sys/upload/display?filePath=' + file.data.fileUrl }
      this.imgList.push(img)

      this.isFlag = true;
    },
    handleRemove (file, fileList) {
      this.isfinish = true
      this.imgList=[]
      this.isFlag = true;
      this.temp.path = ''
    },
    handleExceed (files, fileList) {
      handleAlert('warning',`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      if (this.isFlag) {
        return this.$confirm(`确定移除选择文件？`, '删除',{type: 'warning'});
      }
    },


    // 获取品牌车系
    getBrandTrainList(){
      this.temp.trainYear = ''
      this.temp.modelId = ''
      this.yearList = []   // 年款
      this.yearTree = []
      this.modelList = []
      caseTreeList().then(res => {
        this.trainList = res.data.data
      }).catch(err => {
        handleAlert('error','系统开小差了...')
      })
    },

    // 查询 年款 - 车型
    getTrainYearList(trainId){
      this.temp.trainYear = ''
      this.temp.modelId = ''
      this.yearList = []   // 年款
      this.yearTree = []
      this.modelList = []
      if(!trainId){
        return false;
      }

      var params = {
        trainId: trainId
      }
      caseTrainYear(params).then(res => {
        this.yearTree = res.data.data.cmList
        let list = [];
        if(this.yearTree){
          for (let i = 0; i < this.yearTree.length; i++) {
            list.push(this.yearTree[i].year) ;
          }
        }
        this.yearList = list
      })
    },

    // 查询车型
    getModelList(year, flag){
      if(!flag){
        this.modelList = []
        this.temp.modelId = ''
      }
      if(!year){
        return false;
      }

      let list = [];
      for (let i = 0; i < this.yearTree.length; i++) {
        if(this.yearTree[i].year == year){
          list = this.yearTree[i].ychild
          break;
        }
      }

      this.modelList = list;
    },

    getTemp () {
      this.temp.id = this.caseInfo.id;
      this.temp.trainId = this.caseInfo.trainId;
      this.temp.trainYear = this.caseInfo.trainYear;
      this.temp.modelId = this.caseInfo.modelId;
      this.temp.mileage = this.caseInfo.mileage;
      this.temp.title = this.caseInfo.title;
      this.temp.phenomena = this.caseInfo.phenomena;
      this.temp.process = this.caseInfo.process;
      this.temp.summary = this.caseInfo.summary;
      this.temp.path = this.caseInfo.path;
      this.accessoryPath = this.caseInfo.path;
      this.imgList = []
      let path = this.caseInfo.path
      if (path && path.length>0) {
        this.isfinish = false;
        let fileName = path.substring(path.lastIndexOf("."))
        var img = { name: "附件" + fileName, url: this.caseInfo.path }
        this.imgList.push(img)
      }else{
        this.isfinish = true;
      }
      console.log('1', this.$refs.phenomenaEditor);
      setTimeout(() => {
        console.log('2',this.$refs.phenomenaEditor);
        this.$refs.phenomenaEditor.setValue(this.caseInfo.phenomena)
        this.$refs.processEditor.setValue(this.caseInfo.process)
        this.$refs.summaryEditor.setValue(this.caseInfo.summary)
      })

      this.yearList = []   // 年款
      this.modelList = []
      var params = {
        trainId: this.caseInfo.trainId
      }
      caseTrainYear(params).then(res => {
        this.yearTree = res.data.data.cmList
        let list = [];
        if(this.yearTree){
          for (let i = 0; i < this.yearTree.length; i++) {
            list.push(this.yearTree[i].year) ;
          }
        }
        this.yearList = list
        this.getModelList(this.caseInfo.trainYear, true)
      })

    }

  },
  mounted () {
    this.getBrandTrainList()
    this.accessoryUrl = sysServerUrl + 'sys/upload/attach?flag=temp/maintain'
    this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=maintain/' + Date.now()
    // this.caseInfo = JSON.parse(sessionStorage.getItem("caseInfo"));
    // if (this.caseInfo != null) {
    //   this.getTemp()
    // }else{
    //   this.resetTemp()
    // }
  },
  activated(){
    console.log("页面初始化");
    this.pageFlag = true
    this.caseInfo = JSON.parse(sessionStorage.getItem("caseInfo"));
    if (this.caseInfo != null) {
      this.getTemp()
    }else{
      this.resetTemp()
    }

  },
  deactivated(){
    console.log("页面销毁");
    this.pageFlag = false;
    sessionStorage.removeItem("caseInfo");
  },
}
</script>
<style>
  .tox-pop--top {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
</style>
