import { post,  get, down, download } from "../plugins/request";
import { sysServerUrl, cmsServerUrl } from '../assets/js/common'

// ========== 发布管理 ========== //
// 项目发布
export const releaseBasicList = (params) => get(cmsServerUrl + 'cms/manual/releaseBasic/list', params); // 发布列表-分页查询
export const releaseBasicAdd = (params) => post(cmsServerUrl + 'cms/manual/releaseBasic/add', params) ; // 发布列表-新增
export const releaseBasicDel = (params) => post(cmsServerUrl + 'cms/manual/releaseBasic/del/'+ params); // 发布列表-删除
export const topicTypeData = (params) => get(cmsServerUrl + 'cms/manual/releaseDirectory/getTopicType', params); // 发布目录-获取主题类型
export const releaseBasicSet = (params) => post(cmsServerUrl + 'cms/manual/releaseBasic/addCover', params); // 发布列表-设置封面
export const manualTypeData = (params) => get(cmsServerUrl + 'cms/manual/releaseBasic/project/manual/' + params);


// 项目发布-设置
export const configData = (params) => get(cmsServerUrl + 'cms/manual/release/config/list', params); // 样式管理-设置-内容数据
export const configParse = (params) => get(cmsServerUrl + 'cms/manual/parse/list/all', params); // 样式管理-设置-手册解析规则
export const configCondition = (params) => get(cmsServerUrl + 'cms/manual/release/config/precondition', params); // 样式管理-设置-前提条件
export const configAdd = (params) => post(cmsServerUrl + 'cms/manual/release/config/add', params); // 样式管理-设置-增加
export const configEdit = (params) => post(cmsServerUrl + 'cms/manual/release/config/edit', params); // 样式管理-设置-编辑
export const configDel = (params) => get(cmsServerUrl + 'cms/manual/release/config/del/' + params); // 样式管理-设置-删除
// 项目发布-目录
export const releaseCatalog = (params) => post(cmsServerUrl + 'cms/manual/releaseDirectory/getDirectoryTree', params); // 项目发布 - 目录
export const topicCatalogData = (params) => get(cmsServerUrl + 'cms/manual/releaseDirectory/getManualDirectory', params); //发布目录-获取手册目录
export const importCatalogData = (params) => post(cmsServerUrl + 'cms/manual/releaseDirectory/toDevDirectory/'+ params); // 发布目录-获取手册目录
export const importExcel = (params, uploadProgress) => post(cmsServerUrl + 'cms/manual/releaseDirectory/importOutline', params, uploadProgress); // 发布目录-导入目录(excel)
export const exportExcel = (params) => download(cmsServerUrl + 'cms/manual/releaseDirectory/download', params); // 发布目录-导出excel
export const previewURL = (params) => get(cmsServerUrl + 'cms/manual/topic/getPreviewPath/'+ params); //获取html
export const importData = (params) => post(cmsServerUrl + 'cms/manual/releaseDirectory/insertDirectory',params); //发布目录-导入手册目录到发布目录
export const releaseCatalogUpdate = (params) => post(cmsServerUrl + 'cms/manual/releaseDirectory/update', params); // 发布目录-修改
export const releaseCatalogAdd = (params) => get(cmsServerUrl + 'cms/manual/releaseDirectory/insert', params); // 发布目录-新增
export const releaseCatalogDel = (params) => post(cmsServerUrl + 'cms/manual/releaseDirectory/del/' + params); // 发布目录-删除
export const manualBasicType = (params) => get(cmsServerUrl + 'cms/manual/releaseDirectory/getManualList', params); //发布目录-关联Topic-获取手册类型
export const associationDefine = (params) => post(cmsServerUrl + 'cms/manual/releaseDirectory/association', params); // 发布目录-关联Topic-确定关联
export const topicSearchList = (params) => get(cmsServerUrl + 'cms/manual/releaseDirectory/search', params); // 发布目录-关联Topic-根据目录名称模糊搜索
export const struckThemeList = (params) => get(cmsServerUrl + 'cms/manual/releaseDirectory/getSonById', params); // 发布目录-批量修改主题-获取节点树
export const updateThemeType = (params) => post(cmsServerUrl + 'cms/manual/releaseDirectory/updateType', params); // 发布目录 - 批量修改主题 - 修改
export const alterDirectoryData = (params) => get(cmsServerUrl + 'cms/manual/releaseDirectory/alterDirectory', params); // 发布目录-获取目录变更信息
export const synchronizeDirs = (params) => get(cmsServerUrl + 'cms/manual/releaseDirectory/sync', params);// 发布目录-同步目录名称
// 项目发布-发布
export const releaseData = (params) => get(cmsServerUrl + 'cms/manual/release/list', params); // 样式管理-发布-内容数据
export const releaseUser = (params) => get(sysServerUrl + 'sys/user/list/all', params); // 样式管理-发布-用户列表
export const releaseConfig = (params) => get(cmsServerUrl + 'cms/manual/release/getReleaseConfig/' + params); // 样式管理-发布-发布配置列表
export const releaseAdd = (params) => post(cmsServerUrl + 'cms/manual/release/insert', params); // 样式管理-发布-增加
export const releaseEnable = (params) => post(cmsServerUrl + 'cms/manual/release/edit', params); // 样式管理-发布-启动 禁止
export const releaseDel = (params) => get(cmsServerUrl + 'cms/manual/release/del/' + params); // 样式管理-发布-删除
export const releaseDownload = (params) => download(cmsServerUrl + 'cms/manual/release/download', params); // 样式管理-发布-下载
export const releasePushData = (params) => get(cmsServerUrl + 'cms/manual/push/list', params); // 发布-推送-根据发布ID获取推送信息
export const releasePushAdd = (params) => post(cmsServerUrl + 'cms/manual/push/add', params); // 发布-推送-添加
export const releasePushDel = (params) => get(cmsServerUrl + 'cms/manual/push/delete/' + params); // 发布-推送-删除
export const pushUserList = (params) => get(cmsServerUrl + 'cms/manual/push/getPushUser', params); // 发布-推送-推送人员
export const releaseIsCan = (params) => get(cmsServerUrl + 'cms/manual/release/legitimacyCount', params); // 发布-能否发布


// 推送设置
export const pushSetData = (params) => get (cmsServerUrl + 'cms/manual/pushConfig/list', params); //推送设置-根据项目ID获取数据
export const pushSetUpdate = (params) => post(cmsServerUrl + 'cms/manual/pushConfig/update', params); // 推送设置- 修改
export const pushSetAdd = (params) => post(cmsServerUrl + 'cms/manual/pushConfig/add', params); // 推送设置- 添加
export const pushSetDel = (params) => get(cmsServerUrl + 'cms/manual/pushConfig/delete/' + params); // 推送设置-删除

// 样式管理
export const styleData = (params) => get(cmsServerUrl + 'cms/manual/style/list', params); // 样式管理-内容数据
export const styleAdd = (params) => post(cmsServerUrl + "cms/manual/style/add", params); // 样式管理-新增
export const styleEdit = (params) => post(cmsServerUrl + "cms/manual/style/edit", params); // 样式管理-编辑
export const styleDel = (params) => get(cmsServerUrl + 'cms/manual/style/del/' + params); // 样式管理-删除
export const styleDownload = (params) => down(cmsServerUrl + 'cms/manual/style/download' + params); // 样式管理-下载
export const dictTypeOutput = () => get(sysServerUrl + 'cms/manual/style/output/type'); 
export const dictTypePush = () => get(sysServerUrl + 'cms/manual/pushConfig/push/type');
// 解析规则
export const parseData = (params) => get(cmsServerUrl + 'cms/manual/parse/list', params); // 解析规则-内容数据
export const parseManualStyle = (params) => get(cmsServerUrl + 'cms/manual/style/list/all?useFlag=1', params); // 解析规则-手册样式
export const parseUser = (params) => get(sysServerUrl + 'sys/user/list/all', params); // 解析规则-用户列表
export const parseProject = (params) => get(cmsServerUrl + 'cms/project/list/all', params); // 解析规则-项目列表
export const parseAdd = (params) => post(cmsServerUrl + 'cms/manual/parse/add', params); // 解析规则-增加
export const parseCheck = (params) => get(cmsServerUrl + 'cms/manual/info' + params); // 解析规则-查看
export const parseEdit = (params) => post(cmsServerUrl + 'cms/manual/parse/edit', params); // 解析规则-编辑
export const parseDel = (params) => get(cmsServerUrl + 'cms/manual/parse/del/' + params); // 解析规则-删除

