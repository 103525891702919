<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
    background
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default () {
        return [10, 15, 20, 25, 30, 35, 40, 45, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get () {
        return this.page
      },
      set (val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get () {
        return this.limit
      },
      set (val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange (val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
    },
    handleCurrentChange (val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>
<style>
  /* 分页 */
  .pagination-container{
    border: 1px solid var(--table-color);
    border-top: none;
    background: #fff
  }
  .el-pagination{
    font-size: 12px;
    white-space: nowrap;
    padding: 3px 10px !important;
    color: #666 !important;
  }
  .el-pagination button, .el-pagination span:not([class*=suffix]) {
    font-size: 12px !important;
    height: 26px !important;
    line-height: 26px !important;
  }
  .el-pagination .el-pagination__jump .el-input__inner {
    padding: 0 3px !important
  }
  .el-pagination .el-pagination__jump .el-input .el-input__inner[type="number"]  {
    padding-right: 3px !important
  }
  .el-pagination__total,
  .el-pagination__sizes .el-input .el-input__inner{
    font-size: 12px !important;
  }

  .el-pagination__editor.el-input .el-input__inner,
  .el-pagination__editor,
  .el-input--mini .el-input__inner,
  .el-pager li {
    height: 26px !important;
    line-height: 26px !important;
  }
  .el-pagination .el-select .el-input{
    margin: 0 !important;
  }
  .el-pager li:hover,.btn-next .el-icon:hover,.btn-prev .el-icon:hover{
    color: var(--theme-color);
  }
  .el-pager li.active {
    color: var(--theme-color) ;
    cursor: default;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    margin: 0 5px !important;
    background-color: transparent !important;
    color: #666666 !important;
    min-width: 26px !important;
  }
  .el-pagination.is-background .el-pager li{
    border: 1px solid #e4e4e4;
    background-color: #fff !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--theme-color) !important;
    color: #fff !important;
    border: 1px solid var(--theme-color);
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--theme-color) !important;
  }
  .el-pagination.is-background .el-pager li.active:not(.disabled):hover {
    color: #fff !important;
  }
</style>
