import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login/login'
import home from '@/views/Home.vue'
import dashboard from '@/views/dashboard/workbench'
import systemBulletin from '@/views/dashboard/systemBulletin'
import bulletinDetail from '@/views/dashboard/bulletinDetail'
import latestNews from '@/views/dashboard/latestNews'
// import checkContent from '@/views/cmsmgt/manual/checkContent.vue'
import store from '../store/index'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
import sysmgtRouter from "./modules/sysmgt";
import cmsmgtRouter from "./modules/cmsmgt";
import carmgtRouter from "./modules/carmgt";
import icmsmgtRouter from "./modules/icmsmgt";
import maintenanceRouter from "./modules/maintenance";
import materialRouter from "./modules/material";
import statisticsRouter from "./modules/statistics";
import releaseRouter from "./modules/release";
import releasemgtRouter from "./modules/releasemgt"
import iotmgtRouter from "./modules/iotmgt"
import {handleAlert, accessRecordLog, useCommonLogin, frontLogin} from "@/assets/js/common.js"

const childOne = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard
  },
  {
    path: '/systemBulletin',
    name: 'systemBulletin',
    component: systemBulletin
  },
  {
    path: '/bulletinDetail/:id',
    name: 'bulletinDetail',
    component: bulletinDetail
  },
  {
    path: "/latestNews",
    name: "latestNews",
    component: latestNews,
  },
]
const childList = childOne.concat(sysmgtRouter).concat(cmsmgtRouter)
  .concat(carmgtRouter).concat(icmsmgtRouter).concat(maintenanceRouter)
  .concat(materialRouter).concat(statisticsRouter).concat(releaseRouter)
  .concat(releasemgtRouter).concat(iotmgtRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    redirect: '/dashboard',
    children: childList,
  },
  // {
  //   path: '/checkContent/:id/:manualVersion',
  //   name: 'checkContent',
  //   component: checkContent
  // }
]
// if(sessionStorage.getItem('store')) {
//   var token = JSON.parse(sessionStorage.getItem('store'))["token"];
//   store.commit("set_token", token);
// }
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (useCommonLogin) {
      location.href = frontLogin;
    } else {
      next()
    }
  } else {
    if (sessionStorage.token) {
      next()
      accessRecord(to, from)
    } else {
      store.commit("del_token");
      // store.commit("clearVUEX");
      // sessionStorage.clear();
      // handleAlert("error", "请登录")
      if (useCommonLogin) {
        location.href = frontLogin;
      } else {
        next({name: 'login'});
      }
    }
  }

})
export default router

function accessRecord(to, fo) {
  let name = to.name
  let oldName = fo.name
  var params = new URLSearchParams()
  params.append('menu', to.path)
  switch (name) {
    case 'materialmanuallist':
      if (oldName != 'manualDetails') {
        accessRecordLog(params)
      }
      break;
    case 'sysmgtnoticelist':
      if (oldName != 'addNotice' && oldName != 'noticeDetail') {
        accessRecordLog(params)
      }
      break;
    case 'cmsmgtbulletinlist':
      if (oldName != 'addBulletin' && oldName != 'bulletinDetails') {
        accessRecordLog(params)
      }
      break;
    default:
      accessRecordLog(params)
      break;
  }
}


