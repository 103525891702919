<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
          <el-form-item label="业务类型" prop="bizType">
           <el-select v-model="formInline.bizType" clearable filterable>
            <el-option value="1" label="安装"></el-option>
            <el-option value="2" label="更新"></el-option>
           </el-select>
        </el-form-item>
        <el-form-item label="版本号" prop="versionNumber">
          <el-input v-model.trim="formInline.versionNumber" placeholder="请输入版本号"></el-input>
        </el-form-item>
          <el-form-item label="版本类型" prop="versionType">
          <el-select v-model="formInline.versionType" clearable filterable>
            <el-option v-for="(item,index) in appVersionTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <div class="tableHandle" v-if="hasPerm('menuAsimss1A11B_101')">
        <el-button type="text" icon="el-icon-plus"  @click="addHandle()">新增</el-button>
      </div>
      <el-table
        style="width:100%"
        border
        stripe
        ref="table"
        highlight-current-row
        :max-height="maximumHeight"
        :data="resultList"
        :header-cell-style="{
          'text-align':'center',
          'background-color': 'var(--other-color)',
        }"
        @header-dragend="changeColWidth"
      >
        <el-table-column label="业务类型" prop="bizType" min-width="100" align="center">
           <template slot-scope="{row}">
            <span v-if="row.bizType === 1" class="successColor">安装包</span>
            <span v-if="row.bizType === 2" class="errorColor">更新包</span>
          </template>
        </el-table-column>
        <el-table-column label="版本号" prop="versionNumber" min-width="150" align="center"></el-table-column>
        <el-table-column label="版本类型" prop="versionTypeName" min-width="100"></el-table-column>
        <el-table-column label="更新模式" prop="updateMode" min-width="100" align="center">
            <template slot-scope="{row}">
            <span v-if="row.updateMode === 1" class="successColor">可取消</span>
            <span v-if="row.updateMode === 2" class="errorColor">强制更新</span>
          </template>
        </el-table-column>
        <el-table-column label="最小版本" prop="minVersion" min-width="150" align="center"></el-table-column>
        <el-table-column label="状态" prop="inUse" width="100" align="center">
          <template slot-scope="{row}">
            <span v-if="row.inUse === 1" >草稿</span>
            <span v-if="row.inUse === 2" class="successColor">发布</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="updatedTime" width="180" :formatter="dateFormat" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="{row}">
            <el-button type="text" v-if="hasPerm('menuAsimss1A11B_101')" size="small" @click="openVersionDescForm(row)">版本描述</el-button>
            <el-button type="text" v-if="hasPerm('menuAsimss1A11B_103') && row.inUse == 1" size="small" @click="handleEdit(row)">编辑</el-button>
            <el-button type="text" v-if="row.inUse == 1" size="small" @click="releaseVersion(row)">发布</el-button>
            <el-button type="text" v-if="hasPerm('menuAsimss1A11B_102') && row.inUse == 1" size="small" @click="handleDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible"  :close-on-click-modal="false">
        <el-form ref='dataForm' :model="dataForm" :rules="fromrules" label-position="center">
          <el-form-item label="业务类型" prop="bizType"  :label-width="formLabelWidth">
            <el-radio-group v-model="dataForm.bizType"   @change="bizTypeChange">
              <el-radio :label="1">安装包</el-radio>
              <el-radio :label="2">更新包</el-radio>
            </el-radio-group>
          </el-form-item>
         <el-form-item label="版本号" prop="versionNumber" :label-width="formLabelWidth">
            <el-input v-model.trim="dataForm.versionNumber" show-word-limit maxlength="30" placeholder="请输入名称"></el-input>
          </el-form-item>
         <el-form-item label="版本类型" prop="versionType"  :label-width="formLabelWidth">
          <el-select v-model="dataForm.versionType"  filterable @change="versionTypeChange">
            <el-option v-for="(item,index) in appVersionTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="更新模式" prop="updateMode" :label-width="formLabelWidth">
             <el-radio-group v-model="dataForm.updateMode">
              <el-radio :label="2">强制更新</el-radio>
              <el-radio :label="1">可取消</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="最小版本" prop="minVersion"  :label-width="formLabelWidth"  v-show="showMinVersionArea">
          <el-select v-model="dataForm.minVersion" clearable filterable>
            <el-option v-for="(item,index) in installerVersionList" :key="index" :label="item.versionNumber" :value="item.versionNumber"></el-option>
          </el-select>
        </el-form-item>
          <el-form-item :label="bizTypeName" prop="filePath" :label-width="formLabelWidth">
              <!-- <div class="upload-area">

              </div> -->

                <el-upload
                  class="upload-demo inline-block margin-left-10 margin-right-10"
                  ref="elUpload"
                  action="#"
                  :show-file-list="false"
                  multiple
                  :limit="1"
                  :before-upload="onBeforeUpload"
                >
                  <!-- before-upload：上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。 -->
                  <el-button size="min" icon="el-icon-upload" type="primary" style="" v-show="showUploadBtn">批量上传</el-button>
                  <el-progress style="width:350px;margin-top:50px;margin-left:10px"  v-show="showUploadProcess" color="green" type="line"  :text-inside="true" :stroke-width="20" :percentage="percentage"></el-progress>
                </el-upload>
                 <el-upload
                  ref="elUploadResult"
                  action="#"
                  :show-file-list="true"
                  :file-list="fileList"
                  :limit="1"
                  :before-remove="handleBeforeRemove"
                  :on-remove="handleRemove"
                >
               </el-upload>
          </el-form-item>
          <el-form-item class="submitArea">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('dataForm') : editClick('dataForm')">
              立即提交
            </el-button>
            <el-button @click="resetForm('dataForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="descDlgFormVisible" :close-on-click-modal="false" customClass="descdlg-area">
          <!-- 版本描述 -->
        <el-form>
          <div class="tableHandle">
            <el-button type="text" icon="el-icon-plus" v-if="hasPerm('menuAsimss1A11B_101')" @click="addDescHandle">新增</el-button>
          </div>
          <el-table
            style="width:1000px"
            border
            stripe
            ref="table"
            highlight-current-row
            :data="versionDescList"
            :header-cell-style="{
              'text-align': 'center',
              'background-color': 'var(--other-color)',
            }"
            @header-dragend="changeColWidth"
          >
            <el-table-column label="序号" width="50px" align="center">
              <template slot-scope="scope">
                   {{ scope.$index+1}}
              </template>
            </el-table-column>
            <el-table-column label="版本描述" >
              <template slot-scope="{row}">
                <el-input type="textarea" v-model.trim="row.content" :rows="5" @blur="saveDescHandle(row)" show-word-limit maxlength="1000" style="margin-top:2px;margin-top:2px;padding:0 5px 0 0" placeholder="请输入版本描述"></el-input>
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" width="100">
              <template slot-scope="scope">
                <!-- <el-button type="text" size="small" @click="handelSaveConfig(row)">保存</el-button> 上移 下移 删除 mini medium-->
                <el-button type="text" size="medium" icon="el-icon-top" title="上移" v-if="scope.$index != 0 " @click="moveDescHandle(scope.row,2)"></el-button>
                <el-button type="text" size="medium" icon="el-icon-bottom" title="下移"  v-if="scope.$index != versionDescList.length-1" @click="moveDescHandle(scope.row,1)"></el-button>
                <el-button type="text" size="medium" icon="el-icon-delete" title="删除"  v-if="hasPerm('menuAsimss2A2B_102')" @click="delDescHandle(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, handleAlert, tableHeight, tableMaxHeight } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import {  procSplitFile, checkUploadProgress,appVersionData, appVersionInstaller, appVersionAdd,appVersionEdit, appVersionDel, appVersionUpload,dictTypeQueryData, appVersionRelease } from '@/api/sysmgt.js'
import {  appVersionDescData,appVersionDescAdd,appVersionDescEdit, appVersionDescDel,appVersionDescSort, appVersionDelFiile } from '@/api/sysmgt.js'
import axios from 'axios'
import SparkMD5 from 'spark-md5'

/** 计算文件md5值
 * @param file 文件
 * @param chunkSize 分片大小
 * @returns Promise
 */
function getmd5(file, chunkSize) {
    return new Promise((resolve, reject) => {
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        let chunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let spark = new SparkMD5.ArrayBuffer();
        let fileReader = new FileReader();
        fileReader.onload = function(e) {
            spark.append(e.target.result);
            currentChunk++;
            if (currentChunk < chunks) {
                loadNext();
            } else {
                let md5 = spark.end();
                resolve(md5);
                //  console.log(md5);
            }
        };
        fileReader.onerror = function(e) {
            reject(e);
        };
        function loadNext() {
            let start = currentChunk * chunkSize;
            let end = start + chunkSize;
            if (end > file.size){
                end = file.size;
            }
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        loadNext();
    });
}
export default {
  name: 'sysmgtAppVersion',
  components: { Pagination },
  data () {
    return {
      formInline: {
        bizType: '',
        versionNumber: '',
        versionType: ''
      },
      dataForm: {
        id: '',
        appId: '',
        bizType: 0,
        versionNumber: '',
        versionType: '',
        versionTypeName: '',
        minVersion: '',
        updateMode: 2,
        filePath: '',
        updatedTime: ''
      },
      appId: '',
      appType: '',
      installerVersionList: [],
      appVersionTypeList: [],
      versionId: '',
      versionNumber: '',
      versionType: '',
      versionDescList: [],
      dialogFormVisible: false,
      descDlgFormVisible:false,
      formLabelWidth: '100px',
      dialogStatus: '',
      textMap: {
        edit: '编辑应用版本',
        add: '新增应用版本',
        desc: '版本描述'
      },
      showMinVersionArea: false,
      bizTypeName: "上传数据包",
      resultList: [],
      pagesize: 20,
      currentPage: 1,
      total: 0,
      maximumHeight: 0,
      fromrules: {
        bizType: [{ required: true, message: '业务类型不能为空', trigger: ['blur', 'change'] }],
        versionNumber: [{ required: true, message: '版本号不能为空', trigger: ['blur', 'change'] }],
        versionType: [{ required: true, message: '版本类型不能为空', trigger: ['blur', 'change'] }],
        updateMode: [{ required: true, message: '更新模式不能为空', trigger: ['blur', 'change'] }]
      },
      fileList: [],
      //切片文件
      fileShard:{},
      //当前文件
      curFile:{},
        //文件分割的开始位置
      start: 0,
      //文件分割的结束位置
      end: 0,
      //文件大小
      fileSize: 0,
      fileKey: '',
      fileShardIndex: 0,
      fileShardTotal: 0,
      fileShardSize: 0,
      switchC: false,
      percentage: 0,
      showUploadBtn:false,
      showUploadProcess:false,
      flagType: '',
    }
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "appVersion") {
        _this.appId = to.params.id
        _this.appType = to.params.type
        _this.initialState();
      }
    }
  },
  methods: {
    // 监听表格列宽变化
    changeColWidth(){
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      })
    },
    // 数据
    dataList () {
      this.$loading.show();
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        appId: this.appId,
        appType: this.formInline.appType,
        bizType: this.formInline.bizType,
        versionNumber: this.formInline.versionNumber,
        versionType: this.formInline.versionType
      }
      appVersionData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
        this.tableHeightArea()
        this.$loading.hide();
      })
    },
    //安装包列表
    getInstallerVersionList(appId,versionType,versionNumber){
     var params = new URLSearchParams()
        params.append('appId', appId)
        params.append('versionType', versionType)
        params.append('versionNumber', versionNumber)
        appVersionInstaller(params).then(res=>{
        this.installerVersionList = res.data.data
      })
    },

     //版本类型列表
    getAppVersionTypeList () {
      dictTypeQueryData("versionType").then(res => {
        if(res.data.code == 100){
          this.appVersionTypeList = res.data.data
	      }
	    })
    },
    bizTypeChange(val){
      let that= this
      that.showMinVersionArea=(val==1)?false:true
      if(!that.showMinVersionArea){
        that.bizTypeName="上传安装包"
      }else{
         that.bizTypeName="上传更新包"
      }
    },
    versionTypeChange(val){
       if(val!=null&&val.length>0){
          this.getInstallerVersionList(this.appId,val,this.versionNumber)
       }
    },
    handleBeforeRemove(){
     if(this.fileList!=null&&this.fileList.length>0){
        var filePath= this.fileList[0].filePath;
          var _this=this
                this.$confirm('确认删除文件？', '删除',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 var param = new URLSearchParams()
                    param.append('filePath', filePath)
                    appVersionDelFiile(param).then(res => {
                     _this.fileList=[];
                     return true;
                  })
              }).catch((error)=>{
                 return false;
              })


      }
      return false;

    },
    handleRemove(){
      this.fileList=[];
      // _this.$refs.elUploadResult.clearFiles()
    },
    // 搜索
    onSubmit () {
      this.currentPage=1
      this.dataList()
    },
    // 重置
    resetForm (dataForm) {
      this.resetTemp()
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.currentPage = 1
      this.dataList()
    },
    resetTemp () {
      this.dataForm = {
        id: '',
        appId: '',
        bizType: 0,
        versionNumber: '',
        versionTypeName: '',
        minVersion: '',
        updateMode: 2,
        filePath: '',
        updatedTime: ''
      }
      this.$nextTick(function() {
        this.$refs.dataForm.clearValidate();
      })
    },

     // 文件上传之前
    onBeforeUpload(file){
        let text=""
        // 获取文件后缀
        var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();
        // 文件后缀是否是 zip
        const zipExt = fileExt === 'zip'
        const exeExt = fileExt === 'exe'
        // 文件大小不能超过1G
        const isLimit = file.size / 1024 / 1024 < 1024
        if(!zipExt && !exeExt) {
        text="上传文件只能是 zip或exe 格式!";
        this.$message.error(text)
        return false;
        }
        if (!isLimit) {
        text="上传文件大小不能超过 1GB!";
        this.$message.error(text)
        return false;
        }
        this.fileShardSize = 1*1024 * 1024; //每片文件大小

        //点击后隐藏上传按钮 ，防止重复点击
        //$("#fileUpload").css('visibility','hidden');
        this.showUploadBtn=false
        this.showUploadProcess=true
        this.percentage=1
        var _this=this
        getmd5(file,_this.fileShardSize).then(e =>{
                _this.switchC=false;
                _this.fileShardIndex=1;//分片索引
                _this.curFile=file;
                _this.fileKey=e;
                _this.fileSize=file.size;
                _this.fileShardTotal=Math.ceil(file.size/_this.fileShardSize);//分片总数
                var fileFullName=file.name;
                _this.fileSuffix = fileFullName.substr(fileFullName.lastIndexOf('.') + 1);
                _this.fileName = fileFullName.substr(0, fileFullName.lastIndexOf('.'));

                //上传参数
                var params =  new FormData()
                params.append('fileName', _this.fileName)
                params.append('fileShardTotal', _this.fileShardTotal)
                params.append('fileKey', _this.fileKey)
                params.append('fileSuffix', _this.fileSuffix)
                params.append('fileShardSize', _this.fileShardSize)
                params.append('fileSize', _this.fileSize)
                params.append('fileFlag', _this.flagType)

                _this.updateProgress(file,params)


            })


    },
    // 批量上传
    uploadFile (formData) {
      var _this=this
      // 上传
      procSplitFile(formData).then(res => {
        if(res.data.code==200){
          //上传分片完成
          if(res.data.shardIndex<_this.fileShardTotal){
            _this.fileShardIndex=_this.fileShardIndex+1;
            _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
            _this.end =Math.min(_this.curFile.size,_this.start+_this.fileShardSize);
            _this.fileSize=_this.curFile.size;
            var params =  new FormData()
            params.append('fileName', _this.fileName)
            params.append('fileShardTotal', _this.fileShardTotal)
            params.append('fileKey', _this.fileKey)
            params.append('fileSuffix', _this.fileSuffix)
            params.append('fileShardSize', _this.fileShardSize)
            params.append('fileSize', _this.fileSize)
            params.append('fileFlag', _this.flagType)
            params.append('fileShardIndex', _this.fileShardIndex)
            var fileShardtem=_this.curFile.slice(_this.start,_this.end);//从文件中获取当前分片数据
            let fileReader = new FileReader();
            //异步读取本地文件分片数据并转化为base64字符串
            fileReader.readAsDataURL(fileShardtem);
            //本地异步读取成功后，进行上传
            fileReader.onload = function (e) {
              let  base64str = e.target.result;
              params.append('base64', base64str)
              _this.uploadFile(params)
            }
            let perentNum=Math.ceil(this.fileShardIndex * 100 / this.fileShardTotal)
            if(perentNum>100){
              this.percentage=100
            }else{
              this.percentage=perentNum
            }
          }
        }else if(res.data.code==100){
          var fileId= res.data.id
          //上传完成
          _this.percentage=100
          _this.switchC=true

          _this.finishUpload(fileId)
        }
        console.log(this.percentage)
      }).catch((error)=>{
        if(error.response){
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        }else{
          console.log(error.message)
        }
      })

    },
    updateProgress(file,params){
      var _this= this
      var param = new URLSearchParams()
      param.append('shardKey', _this.fileKey)
      // 批量上传
      checkUploadProgress(param).then(res => {
        if(res.data.code==200){
          //新文件
          _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
          _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
          _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        }else if(res.data.code==220){
          _this.fileShardIndex=res.data.ShardIndex;
          //有上传未完成的
          _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
          _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
          _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        }else if (res.data.code==240){
          //急速上传
          var fileId= res.data.id
          _this.percentage=100
          _this.switchC=true
          console.log(this.percentage)
          // this.$message({
          //   message: '极速上传成功',
          //   type: 'success'
          // })
          _this.finishUpload(fileId)
          return false;
        }
        //读取base64str
        let fileReader = new FileReader();
        //异步读取本地文件分片并转化为base64字符串
        fileReader.readAsDataURL(_this.fileShard);
        //本地异步读取成功，进行上传
        fileReader.onload=function (e){
          let  base64str=e.target.result;
          params.append('base64', base64str)
          params.append('fileShardIndex', _this.fileShardIndex)
          if(_this.switchC==false){
              _this.uploadFile(params)
          }
        }
      }).catch((error)=>{
        this.$message.error('上传错误')
      })

    },
    finishUpload(fileId){
         var _this=this
           //进行保存提醒
          _this.uploadMsg = _this.$message({
                              duration:0,
                              message: "请稍等，正在保存...",
                              type: "warning"
                            });
          appVersionUpload(fileId).then(res => {
            if(res.data.code==100){
                let uploadFileName= res.data.data.fileName
                let uploadFilePath= res.data.data.filePath
                let uploadFileMD5 = res.data.data.md5Value
                _this.fileList = []
                if(uploadFileName != null && uploadFileName.length > 0){
                  var fileObj = { name: uploadFileName , filePath:uploadFilePath, md5: uploadFileMD5}
                  _this.fileList.push(fileObj)
                }
                //关闭消息提醒
                _this.uploadMsg.close()

                //上传完成提示
                _this.$message({
                  duration:2000,
                  message: '上传已完成',
                  type: 'success'
                })
              _this.showUploadProcess=false
              _this.showUploadBtn=true
            }
          })

    },
    getDescList(){
      var params = new URLSearchParams()
      params.append('versionId', this.versionId)
      appVersionDescData(params).then(res => {
          this.versionDescList = res.data.data
      })
    },
    //打开版本描述
    openVersionDescForm (row) {
      this.versionDescList=[]
      this.dialogStatus = 'desc'
      this.descDlgFormVisible = true
      this.versionId=row.id;
      this.getDescList();
    },
    addDescHandle () {
      var sortVal = ''
      this.versionDescList
      if (this.versionDescList.length !== 0) {
        sortVal = Number(this.versionDescList[this.versionDescList.length - 1].sort) + Number(1)
        if(sortVal>9999){
          sortVal=9999
        }
      } else {
        sortVal = 1
      }

      var params = new URLSearchParams()
      params.append('versionId', this.versionId)
      appVersionDescData(params).then(res => {
          this.versionDescList = res.data.data
          this.versionDescList.push({
          id: 0,
          content: '',
          sort: sortVal
        })
      })


    },
    moveDescHandle (row,type) {
      var params = new URLSearchParams()
      params.append('id', row.id)
      params.append('type', type)
      appVersionDescSort(params).then(res => {
        if (res.data.code === 100) {
              this.getDescList()
            }
        })


    },
    delDescHandle (row) {
      this.$confirm('确认删除此描述内容？', '删除描述',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var params = row.id
        appVersionDescDel(params).then(res => {
          if (res.data.code === 100) {
            handleAlert('success','删除成功')
            this.getDescList()
          }else{
            handleAlert('error',res.data.msg)
          }
        })
      }).catch((error)=>{})
    },
    // 保存当前描述信息
    saveDescHandle (row) {
      let curContent= row.content
      if(curContent==null||curContent.length==0){
        handleAlert('error','请输入描述内容')
        return;
      }


      var params = new URLSearchParams()
      if (row.id === undefined) {
          row.id = 0
      }

      params.append('id', row.id)
      params.append('versionId', this.versionId)
      params.append('content', curContent)
      params.append('sort', row.sort)
      if(row.id==0){
       appVersionDescAdd(params).then(res => {
          if (res.data.code === 100) {
            handleAlert('success','保存成功')
            this.getDescList()
          }else{
            handleAlert('error',res.data.msg)
          }
        })
      }else{
        appVersionDescEdit(params).then(res => {
          if (res.data.code === 100) {
            handleAlert('success','保存成功')
            this.getDescList()
          }else{
            handleAlert('error',res.data.msg)
          }
        })
      }

    },
    // 新增
    addHandle () {
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
      this.resetTemp()
      this.versionId= ''
      this.versionType= ''
      this.versionNumber=''
     this.getInstallerVersionList(this.appId,this.versionType,this.versionNumber)
      this.showUploadBtn=true
      this.fileList=[];
      this.percentage=0;
    },
    addClick (dataForm) {
      var filePath="";
      var fileMD5="";
      var submitFlag=true;
      if(this.fileList!=null&&this.fileList.length>0){
         filePath= this.fileList[0].filePath;
         fileMD5 = this.fileList[0].md5;
      }
      if(this.appId==null || typeof(this.appId) == "undefined" || this.appId.trim().length==0){
            submitFlag=false;
            handleAlert('error','应用标识Id无效')
            return false;
        }

      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          if(filePath==""){
            submitFlag=false;
             handleAlert('error','请'+this.bizTypeName)
             return false;
          }

          if(submitFlag){
              var params = new URLSearchParams()
                params.append('appId', this.appId)
                params.append('bizType', this.dataForm.bizType)
                params.append('versionNumber', this.dataForm.versionNumber)
                params.append('versionType', this.dataForm.versionType)
                params.append('updateMode', this.dataForm.updateMode)
                params.append('minVersion', this.dataForm.minVersion)
                params.append('filePath', filePath)
                params.append("md5Value", fileMD5)
                appVersionAdd(params).then(res => {
                  if(res.data.code === 100){
                    handleAlert('success',res.data.msg)
                    this.dataList()
                    this.dialogFormVisible = false
                  }else{
                    handleAlert('error',res.data.msg)
                  }
                })
          }


        } else {
          handleAlert('error','请完善版本信息')
        }
      })
    },
    // 编辑
    handleEdit (row) {
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
      this.resetTemp()
      this.dataForm = Object.assign({}, row)
      this.versionId= row.versionId
      this.versionType= row.versionType
      this.versionNumber=row.versionNumber
      let curFilePath =row.filePath
      let curFileMd5=row.md5Value

      //加载附件
      this.fileList=[]
      if(curFilePath!=null&&curFilePath.length>0){
          let curFileName= curFilePath.substring(curFilePath.lastIndexOf("\\")+1)
          if(curFileName != null && curFileName.length > 0){
            var fileObj = { name: curFileName , filePath:curFilePath, md5: curFileMd5}
            this.fileList.push(fileObj)
          }
      }
      if(row.bizType==2){
        this.showMinVersionArea=true;
        this.bizTypeName="上传更新包"
        this.getInstallerVersionList(this.appId,this.versionType,this.versionNumber)
      }else{
        this.showMinVersionArea=false;
         this.bizTypeName="上传安装包"
      }

      this.showUploadBtn=true
      this.percentage=0;
    },
    editClick (dataForm) {
      var filePath="";
      var fileMD5 = "";
      var submitFlag=true;
      if(this.fileList!=null&&this.fileList.length>0){
         filePath= this.fileList[0].filePath;
         fileMD5 = this.fileList[0].md5;
      }
      if(this.appId==null || typeof(this.appId) == "undefined" || this.appId.trim().length==0){
            submitFlag=false;
            handleAlert('error','应用标识Id无效')
            return false;
        }

      this.$refs[dataForm].validate((valid) => {
        if (valid) {
          if(filePath==""){
            submitFlag=false;
             handleAlert('error','请'+this.bizTypeName)
             return false;
          }
          if(submitFlag){
                this.$loading.show();
                var params = new URLSearchParams()
                params.append('id', this.dataForm.id)
                params.append('appId', this.appId)
                params.append('bizType', this.dataForm.bizType)
                params.append('versionNumber', this.dataForm.versionNumber)
                params.append('versionType', this.dataForm.versionType)
                params.append('updateMode', this.dataForm.updateMode)
                params.append('minVersion', this.dataForm.minVersion)
                params.append('filePath', filePath)
                params.append("md5Value", fileMD5)
                appVersionEdit(params).then(res => {
                  if(res.data.code === 100){
                    handleAlert('success',res.data.msg)
                    this.dataList()
                    this.dialogFormVisible = false
                  }else {
                    if(res.data.code === 404){
                      handleAlert('error','系统出现异常，更新失败')
                    }else{
                      handleAlert('error',res.data.msg)
                    }
                  }
                  this.$loading.hide();
                })
          }

        } else {
          handleAlert('error','请完善版本信息')
        }
      })
    },
    // 发布
    releaseVersion(row){
      var params = new URLSearchParams()
      params.append('id', row.id)
      params.append('inUse', 2)
      appVersionRelease(params).then(res => {
        if (res.data.code == 100) {
          handleAlert('success', "成功")
          this.dataList()
        }else {
          if(res.data.code === 404){
            handleAlert('error','系统开小差，请稍后再试')
          }else{
            handleAlert('error', "系统开小差，请稍后再试")
          }
        }
      })
    },
    // 删除
    handleDelete (row) {
      this.currentPage=1

      this.$confirm('确定刪除 应用版本【' + row.versionNumber + '】及其描述相关信息吗?', '删除应用版本', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.appVersionDelClick(row.id);
        }).catch((error) => {})
    },
    appVersionDelClick(id) {
      appVersionDel(id).then(res => {
        if(res.data.code === 100){
          handleAlert('success','删除成功!')
          if(this.total > 1){
              if(this.resultList!=null&&this.resultList.length == 1){
                this.currentPage =this.currentPage-1
              }
            }
          this.dataList()
          this.getUserappVersionInfo()
        }else{
          handleAlert("error", res.data.msg)
        }
      })
    },
    dateFormat(row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        const H = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        const Min = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
        const S = date.getSeconds() < 10 ? '0' + date.getSeconds() + '' : date.getSeconds()
        return Y + M + D + H + Min + S
      }
    },
    maximumArea() {
      setTimeout(() => {
        tableHeight()
        setTimeout(() => {
          this.maximumHeight = tableMaxHeight;
        }, 50)
      },50)
    },
    tableHeightArea() {
      var _this = this;
      _this.maximumArea();
      window.addEventListener("resize", function () {
        _this.maximumArea();
      });
    },
    initialState() {
      var _this = this;
      if(_this.appId){
        sessionStorage.setItem("appIdKey", _this.appId)
      }else{
        _this.appId =sessionStorage.getItem("appIdKey")
      }
      if(_this.appType){
        sessionStorage.setItem("appTypeKey",this.appType)
      }else{
        _this.appType =sessionStorage.getItem("appTypeKey")
      }
      _this.flagType='appVersion\\' + _this.appType + "\\" + _this.appId
      _this.dataList()
      _this.getAppVersionTypeList()
    },
  },
  mounted () {
    var _this = this;
    _this.appId = _this.$route.params.id
    _this.appType=  _this.$route.params.type
    _this.initialState();
  }
}
</script>

<style>
  .upload-area{
    text-align: left;
    width: 400px;
    height: 180px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #dedede;
  }

.el-progress-bar__outer {
    height: 15px !important;
}

.descdlg-area{
    width:  800px;
    height: 600px;
}

</style>
