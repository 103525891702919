<template>
  <div class="asideContent">
    <el-aside width="collapse">
      <el-menu
        background-color="var(--aside-bgColor)"
        :collapse="isCollapse"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :default-active="selectTabPath"
        :collapse-transition="true"
        router
      >
        <el-menu-item index="/dashboard" @click="selectMenu({'url': '/dashboard','name': '首页'})">
          <img src="../../assets/image/homeIcon.png" alt="">
          <!-- <svg-icon icon-class="home"></svg-icon> -->
          <span slot="title">{{$t('catalogue.oneTitle')}}</span>
        </el-menu-item>
        <el-submenu :index="num+'2'" v-for="(item, num) of newList" :key='num'>
          <template slot="title">
            <img v-if="item.icon !== ''" :src="imgSrc + item.icon" alt=""/>
            <!-- <svg-icon v-if="item.icon == ''" icon-class="commonIcon"></svg-icon> -->
            <img v-if="item.icon == ''" src="../../assets/image/commonIcon.png" alt="">
            <span>{{item.name}}</span>
          </template>
          <el-menu-item-group v-for="(itm, idx) of item.children" :key="idx">
            <el-menu-item class="groupName" :index="itm.url"  @click="selectMenu(itm)">
              <img v-if="itm.icon !== ''" :src="imgSrc + itm.icon" alt="">
              <!-- <svg-icon v-if="itm.icon == ''" icon-class="commonIcon"></svg-icon> -->
              <img v-if="itm.icon == ''" src="../../assets/image/commonIcon.png" alt="">
              <span>{{itm.name}}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
      <div class="asideBottom">
        <svg-icon icon-class="openIcon" class="openIcon" @click="toggleClick()"></svg-icon>
        <svg-icon icon-class="flodIcon" class="flodIcon" @click="toggleClick()"></svg-icon>
        <img src="../../assets/image/baseMap.png" alt="">
      </div>
    </el-aside>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { sysServerUrl, addTabs } from '@/assets/js/common.js'
import { catalog } from '@/api/sysmgt.js'
import $ from 'jquery'
export default {
  name: 'index',
  computed: {
    ...mapState({ // 从 state 中的到的计算属性
      activeName: state => state.activeName, // 已选中菜单
      selectTabPath: state => state.selectTabPath, // 已选中菜单
    })
  },
  data () {
    return {
      imgSrc: sysServerUrl + "sys/upload/display?filePath=",
      isCollapse:false,
      isIconActive:false,
      newList: [],
      color: 'var(--theme-color)',
      menuVisible: false,
    }
  },
  methods: {
    // 点击菜单
    selectMenu(item) {
      if (item.url == "/dashboard") {
        this.$router.push('/dashboard')
      } else {
        this.$router.push(item.url);
        setTimeout(() => {
          addTabs(item.url, item.name);
        });
      }
    },
    // 左侧目录显示隐藏
    toggleClick(){
      this.isCollapse = !this.isCollapse
      this.isIconActive = !this.isIconActive
      if(this.isCollapse == true){
        setTimeout(function(){
          $(".asideBottom img:last-child").hide()
          $(".el-aside .asideBottom .flodIcon").show();
          $(".el-aside .asideBottom .openIcon").hide();
        },0)
      }else{
        setTimeout(function(){
          $(".asideBottom img:last-child").show()
          $(".el-aside .asideBottom .flodIcon").hide();
          $(".el-aside .asideBottom .openIcon").show();
        },300)
      }
    },
    collapse () {
      this.collapsed = !this.collapsed
    },
    // 字体颜色
    // changeThemeColor (val) {
    //   this.color = val
    //   document.getElementById('app').style.setProperty('--themeColor', val)
    // },
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      document.removeEventListener('click', this.foo) // 关掉监听，
    },
    // 目录信息
    catalogueList () {
      var _this = this
      catalog().then(res =>{
        _this.newList = res.data.data
      })
    },
    // 获取内容区域
    heightSize() {
    },
    areaSize() {
      var _this = this;
      _this.heightSize();
      window.addEventListener("resize", function() {
        _this.heightSize();
      })
    },
  },
  mounted () {
    var _this = this
    _this.catalogueList()
  },
}
</script>
<style>
  /* 左侧目录 */
  .asideContent {
    height: 100%;
  }
  .el-aside {
    position: relative;
    background-color: var(--aside-bgColor);
    color: rgba(0, 0, 0, 0.8);
    height: 100%;
    text-align: center;
    overflow: hidden !important;
  }
  .el-menu-vertical-demo.el-menu{
    height: calc(100vh - 95px);
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .el-aside .asideBottom{
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0px;
    background: var(--subMenu-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
  }
  .el-aside .asideBottom img:first-child {
    cursor: pointer;
  }
  .el-aside .asideBottom img,
  .el-aside .asideBottom svg {
    width: 22px;
    height: 22px;
    color: var(--text-color) !important;
  }
  .el-aside .asideBottom .flodIcon {
    display: none;
  }
  .el-aside .asideBottom img:last-child{
    opacity: 0.6;
    height: 100%;
    width: auto;
  }
  .el-menu--collapse {
    width: 50px !important
  }
  /* body */
  .el-container.is-vertical{
    width: 100%;
    height: 100%;
  }
  /* 目录 */
  .el-menu{
    border: none !important;
    text-align: left;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
  }
  .el-submenu {
    border-top: 1px solid var(--line-color);
  }
  /* 点击后的目录列表 */
  .el-submenu.is-opened .el-submenu__title,.el-submenu.is-opened .el-submenu__title i{
    color: var(--text-color) !important;
    font-weight: bold;
  }
  .el-submenu .el-menu-item.is-active,.el-menu-item.is-active,.el-menu-item,
  .el-submenu .el-menu-item.is-active:hover, .el-menu-item.is-active:hover {
    color: var(--text-color) !important;
    background: var(--subMenu-bgColor) !important;
  }
  .el-submenu.is-opened .el-submenu__title svg,
  .el-submenu .el-menu-item.is-active svg,.el-menu-item.is-active svg,.el-menu-item svg,
  .el-submenu .el-menu-item.is-active:hover svg, .el-menu-item.is-active:hover svg {
    color: var(--text-color) !important;
  }
  /* 目录列表 */
  .el-submenu__title,.el-menu-item{
    color: var(--aside-color) !important;
    background: var(--subMenu-color) !important;
    border-right: 1px solid var(--subMenu-color) !important;
  }
  .el-menu-item>img,
  .el-submenu__title img,
  .el-submenu .el-menu-item img,
  .el-menu-item svg,
  .el-submenu__title svg,
  .el-submenu .el-menu-item svg{
    margin-right: 8px !important;
    width: 22px !important;
    height: 22px !important;
    opacity: 0.8;
    color: var(--aside-color) !important;
    vertical-align: middle !important;
  }
  .blackTheme > .el-menu--vertical .el-menu-item-group .el-menu-item img,
  .blackTheme .el-aside .el-menu-item img,
  .blackTheme .el-aside .el-submenu__title img,
  .blackTheme .el-aside .el-submenu .el-menu-item img,
  .blackTheme .el-aside .el-menu-item svg,
  .blackTheme .el-aside .el-submenu__title svg,
  .blackTheme .el-aside .el-submenu .el-menu-item svg{
    filter: invert(100%);
  }
  .el-menu-item.is-active >img,
  .el-menu-item.groupName.is-active > img,
  .el-submenu.is-opened .el-submenu__title > img{
    opacity: 1;
  }
  .el-submenu__icon-arrow {
    font-size: 15px !important;
    right: 16px !important;
  }
  .el-submenu__title i{
    color:var(--aside-color) !important
  }
  .el-menu--vertical .el-menu-item-group .el-menu-item:hover,
  .el-submenu div:hover,
  .el-submenu__title:hover,
  .el-submenu__title i:hover{
    color:var(--text-color) !important;
    background: var(--line-color) !important;
  }
  .el-menu.el-menu--inline{
    background: var(--aside-bgColor) !important
  }
  .el-menu-item-group__title{
    background: var(--aside-bgColor) !important;
    color: var(--aside-color) !important;
    text-align: left;
    padding: 0px 0 0px 20px !important;
  }
  .el-submenu .el-menu-item{
    padding:0px 18px 0 40px !important;
    background: var(--aside-bgColor) !important;
    color: var(--aside-color) !important;
    border-left: 3px solid transparent;
    box-sizing: border-box;
  }
  .el-submenu .el-menu-item:hover{
    border-left: 3px solid var(--theme-color) !important;
    background: var(--line-color) !important;
  }
  .el-color-dropdown__btns .el-button--text{
    display: none;
  }
  @media screen and (max-width: 1600px) and (min-width: 1440px) {
    .el-aside .asideBottom{
      height: 36px;
    }
    .el-aside .asideBottom img,
    .el-aside .asideBottom svg{
      width: 22px;
      height: 22px;
    }
    .el-aside .asideBottom img:last-child {
      width: auto;
      height: 100%;
    }
  }
  @media screen and (max-width: 1366px) and (min-width: 1280px) {
    .el-aside .asideBottom{
      height: 32px;
    }
    .el-aside .asideBottom img,
    .el-aside .asideBottom svg {
      width: 20px;
      height: 20px;
    }
    .el-aside .asideBottom img:last-child {
      width: auto;
      height: 100%;
    }
  }
  @media screen and (max-width: 1024px) {
    .el-aside .asideBottom{
      height: 32px;
    }
    .el-aside .asideBottom img,
    .el-aside .asideBottom svg {
      width: 20px;
      height: 20px;
    }
    .el-aside .asideBottom img:last-child {
      width: auto;
      height: 100%;
    }
  }
</style>
