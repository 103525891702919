import { post, get, down, del } from "../plugins/request";
import { sysServerUrl, cmsServerUrl } from '../assets/js/common'

import axios from 'axios'

import store from '@/store/index';
//必须使用此种方式,否则拦截器会在header上加token
export const requestTokenRefresh = (params) =>  axios.create()({// 刷新token
    url: sysServerUrl + 'sys/user/refresh',
    method: 'post',
    data: params
  })

// 验证码
export const captCha = (params) => get(sysServerUrl + 'sys/captcha/get', params);
// 登录
export const onLogin = (params) => post(sysServerUrl + 'sys/user/login', params);
// 第一次登录修改密码
export const initialPsw = (params) => post(sysServerUrl + 'sys/user/initialPsw', params);
// 退出登录
export const logout = (params) => post(sysServerUrl + 'sys/user/logout', params);

// 当前用户信息
export const getUserInfo = (params) => get(sysServerUrl + 'sys/user/getCurrentInfo', params);
// 当前用户信息
export const currentUserInfo = (params) => get(sysServerUrl + 'sys/user/getCurrentInfo', params);
// 目录列表
export const catalog = (params) => get(sysServerUrl + 'sys/user/current/menutreee', params);
// 用户修改密码
export const userDefinedPwd = (params) => post(sysServerUrl + 'sys/user/updatePsw', params);
// 用户修改主题
export const updatedTheme = (params) => post(sysServerUrl + "sys/user/updatedTheme", params);
// 用户修改基本信息
export const userDefinedInfo = (params) => post(sysServerUrl + "sys/user/updateInfo", params);



// 我的主页-查询发布的系统公告
export const systemBulletinList = (params) => get(sysServerUrl + 'sys/notice/getNoticeByRelease', params);
// 我的主页-查询系统公告明细
export const systemBulletinInfo = (params) => get(sysServerUrl + 'sys/notice/info/' + params);
// 我的主页-修改公告的观看次数
export const systemBulletinRead = (params) => post(sysServerUrl + 'sys/notice/editRead/' + params);
// 我的主页-获取主页信息
export const getHomeData = (params) => get('', params);
// 我的主页-访问记录
export const getRecordsData = (params) => get('', params);


// 系统管理-文件上传
export const importAttach = (params) => post(sysServerUrl + 'sys/upload/attach', params);
// 系统管理-文件管理-批量上传
export const procSplitFile = (params) => post(sysServerUrl + "sys/upload/procFile", params);
// 系统管理-文件管理-批量上传分片
export const checkUploadProgress = (params) => post(sysServerUrl + "sys/upload/checkProgress", params);
// 系统管理-文件管理-获取文件上传的信息
export const getFileInfo = (param) => post(sysServerUrl + "sys/upload/getFileInfo", param);
// 系统管理-文件管理-删除文件上传的信息
export const delFileInfo= (param) => post(sysServerUrl + "sys/upload/delFileInfo", param);
// 系统管理-文件下载
export const downFile= (param) => down(param);



// ============ 我的主页 =========== //
// 近一周访问量
export const accessOneWeek = () => get(sysServerUrl + "statistics/access/access");
// 快捷入口-查询添加和未添加的菜单
export const quickEntryMenuData = (params) => get(sysServerUrl + 'sys/quickEntry/api/quickEntryList');
// 快捷入口-修改快捷入口
export const quickEntryEdit = (params) => post(sysServerUrl + 'sys/quickEntry/api/edit', params);
// 汇总消息
export const messageData = (params) => get(sysServerUrl + 'front/message/background', params); // 汇总消息



// ========== 系统管理 ========== //

// 数据字典-分页查询 内容数据
export const dictData = (params) => get(sysServerUrl + 'sys/dict/list', params);
// 数据字典-类型数据
export const dictTypeList = (params) => get(sysServerUrl + 'sys/dict/list/type', params);
// 数据字典-新增
export const dictAdd = (params) => post(sysServerUrl + 'sys/dict/add', params);
// 数据字典-编辑
export const dictEdit = (params) => post(sysServerUrl + 'sys/dict/edit', params);
// 数据字典-删除
export const dictDel = (params) => post(sysServerUrl + 'sys/dict/del/' + params);
// 根据类型获取
export const dictTypeQueryData = (params) => get(sysServerUrl + 'sys/dict/query?dictType='+ params);


// 用户管理-分页查询 内容数据
export const userDataList = (params) => get(sysServerUrl + 'sys/user/findList', params);
// 用户管理-全部的角色信息（用户能看到的）
export const userRoleData = (params) => get(sysServerUrl + 'sys/role/list/all', params);
// 用户管理-增加
export const userAdd = (params) => post(sysServerUrl + 'sys/user/add', params);
// 用户管理-编辑
export const userEdit = (params) => post(sysServerUrl + 'sys/user/edit', params);
// 用户管理-删除
export const userDel = (params) => post(sysServerUrl + 'sys/user/del/' + params);
// 用户管理-密码重置
export const userPasswordReset = (params) => get(sysServerUrl + 'sys/user/initPsw' + params);
// 用户管理-根据ID查询用户信息
export const assignRole = (params) => get(sysServerUrl + 'sys/user/info' + params);
// 用户管理-分配角色(分配)
export const updateAssignRole = (params) => post(sysServerUrl + 'sys/user/assignRole', params);
// 用户管理-获取国家
export const userCountryData = () => get(sysServerUrl + 'sys/region/api/getCountry');
// 用户管理-获取全部的国家信息包含大洲
export const getCountryAll = (params) => post(sysServerUrl + 'sys/region/api/country/all');
// 用户管理-获取用户的国家
export const getUserCountry = (params) => get(sysServerUrl + 'sys/user/getUserCountry/' + params);
// 用户管理-修改授权国家
export const updatedCountry = (params) => post(sysServerUrl + 'sys/user/updatedCountry', params);

// 用户管理-模板下载
export const downTemplate = () => down(sysServerUrl + 'static/excel/用户导入模板.xlsx');
// 用户管理-批量添加用户-文件上传
export const batchAdd = (params) => post(sysServerUrl + 'sys/user/batchImport', params);

// 用户管理-导出用户
export const userDown = (params) => down(sysServerUrl + 'sys/user/batch?' + params);

export const userAtlasCountry = (params) => post(sysServerUrl + 'sys/user/atlasCountry');


export const userEmpowerCountry = (params) => get(sysServerUrl + 'sys/region/api/empowerCountry');




//经销商管理
// 经销商管理-内容数据
export const dealerData = (params) => get(sysServerUrl + 'sys/dealer/list', params);
// 经销商管理-添加
export const dealerAdd = (params) => post(sysServerUrl + 'sys/dealer/add', params);
// 经销商管理-修改
export const dealerEdit = (params) => post(sysServerUrl + 'sys/dealer/edit', params);
// 经销商管理-删除
export const dealerDel = (params) => post(sysServerUrl + 'sys/dealer/del/' + params);
// 经销商管理-批量添加子账户
export const dealerBatchSubAccount = (params) => post(sysServerUrl + 'sys/dealer/subAccount', params);
// 经销商管理-根据ID查询用户信息
export const getUserInfoId = (params) => get(sysServerUrl + 'sys/user/info', params);
// 经销商管理-根据国家查询国家下的信息
export const getCountryTree = (params) => post(sysServerUrl + 'sys/region/api/getCountryTree', params);


// 经销商管理-树结构单个节点子节点数据获取
export const dealerChildData = (params) => get(sysServerUrl + 'sys/dealer/child/list', params);
// 经销商-模板下载
export const downDealerTemplate = (params) => down(sysServerUrl + 'static/excel/服务店导入模板.xlsx');
// 经销商-导出
export const dealerBatchDown = (params) => down(sysServerUrl + 'sys/dealer/batchExport?' + params);
// 经销商-导入
export const dealerBatchImport = (params) => post(sysServerUrl + 'sys/dealer/batchImport', params);

export const getUserTrainByUserId = (params) => post(sysServerUrl + 'sys/user/getUserTrainByUserId', params);

export const getAll = (params) => post(sysServerUrl + 'sys/dealer/all', params);
export const authorize = (params) => post(sysServerUrl + 'sys/dealer/authorize', params);


// 租户管理
export const tenantData = (params) => get(sysServerUrl + 'sys/tenant/list', params); //租户管理-内容数据
export const tenantSearch = (params) => get(sysServerUrl + 'sys/tenant/list', params);//租户管理-搜索
export const tenantList = (params) => get(sysServerUrl + 'sys/tenant/list/all', params);// 租户管理-列表
export const tenantDetail = (params) => get(sysServerUrl + 'sys/user/info', params);//租户管理-查看
export const tenantAdd = (params) => post(sysServerUrl + 'sys/tenant/add', params); //租户管理-增加
export const tenantEdit = (params) => post(sysServerUrl + 'sys/tenant/edit', params);//租户管理-编辑
export const tenantDel = (params) => get(sysServerUrl + 'sys/tenant/del/' + params);//租户管理-删除
export const getTenantList = () => get(sysServerUrl + 'sys/tenant/getTenantIdNameList');//租户管理-删除


//角色管理-内容数据
export const roleData = (params) => get(sysServerUrl + 'sys/role/list', params);
//角色管理-增加
export const roleAdd = (params) => post(sysServerUrl + 'sys/role/add', params);
//角色管理-编辑
export const roleEdit = (params) => post(sysServerUrl + 'sys/role/edit', params);
//角色管理-删除
export const roleDel = (params) => get(sysServerUrl + 'sys/role/del/' + params);



// 菜单管理-内容数据
export const menuData = (params) => get(sysServerUrl + 'sys/menu/getMenuTree', params);
// 菜单管理-分配按钮列表
export const menuAssignList = (params) => get(sysServerUrl + 'sys/permission/list/all', params);
// 菜单管理-增加
export const menuAdd = (params) => post(sysServerUrl + 'sys/menu/add', params);
// 菜单管理-编辑
export const menuEdit = (params) => post(sysServerUrl + 'sys/menu/edit', params);
// 菜单管理-查看
export const menuCheck = (params) => get(sysServerUrl + 'sys/menu/info' + params);
// 菜单管理-删除
export const menuDel = (params) => get(sysServerUrl + 'sys/menu/del' + params);
// 菜单管理-分配按钮信息
export const menuAssignInfo = (params) => get(sysServerUrl + 'sys/permission/list/bymenu' + params);
// 菜单管理-分配按钮提交
export const menuAssignUpdate = (params) => post(sysServerUrl + 'sys/menu/assign', params);

// 菜单管理-权限码列表
export const permissionList = (params) => get(sysServerUrl + 'sys/permission/list/all', params);
// 菜单管理-添加权限码
export const operatePermission = (params) => post(sysServerUrl + 'sys/permission/add', params);
// 菜单管理-删除权限码
export const delPermission = (params) => post(sysServerUrl + 'sys/permission/del', params);




//权限管理-内容数据
export const oauthData = (params) => get(sysServerUrl + 'sys/permission/list', params);
//权限管理-角色列表
export const oauthRoleList = (params) => get(sysServerUrl + 'sys/role/list/all', params);
//权限管理-用户列表
export const oauthUserList = (params) => get(sysServerUrl + 'sys/user/findList/all', params);
//权限管理-提交
export const oauthSubmit = (params) => post(sysServerUrl + 'sys/permission/assign', params);
//权限管理-刷新用户
export const userRefresh = (params) => post(sysServerUrl + 'sys/permission/list/user', params);
//权限管理-刷新角色
export const roleRefresh = (params) => post(sysServerUrl + 'sys/permission/list/role', params);

/**
 * 获取当前用户所属租户的厂商logo
 * @returns {AxiosPromise}
 */
export const getLogoPath = (params) => get(sysServerUrl + 'sys/tenant/getLogoPath', params);





// 部门管理
export const departmentData = (params) => get(sysServerUrl + 'sys/dept/getTree', params); //部门管理-查询树
export const departmentType = (params) => get(sysServerUrl + 'sys/dept/getDept', params); // 部门管理-查询所以部门
export const departmentTeam = (params) => get(sysServerUrl + 'sys/dept/getGruop/' + params); // 部门管理-查询部门下的小组
export const departmentAdd = (params) => post(sysServerUrl + 'sys/dept/add', params); // 部门管理-添加
export const departmentEdit = (params) => post(sysServerUrl + 'sys/dept/update', params); // 部门管理-修改
export const departmentDel = (params) => del(sysServerUrl + 'sys/dept/' + params); // 部门管理-删除



// 系统公告
//系统公告-公告类型
export const noticeTypeList = (params) => get(sysServerUrl + 'sys/notice/type', params);
//系统公告-内容数据
export const noticeData = (params) => get(sysServerUrl + 'sys/notice/list', params);
// 系统公告-状态
export const noticeStateEdit = (params) => post(sysServerUrl + 'sys/notice/statusEdit', params);
// 系统公告-新增
export const noticeAdd = (params) => post(sysServerUrl + 'sys/notice/add', params);
// 系统公告-编辑
export const noticeEdit = (params) => post(sysServerUrl + 'sys/notice/edit', params);
// 系统公告-删除
export const noticeDel = (params) => post(sysServerUrl + 'sys/notice/del/' + params);
// 系统公告-明细
export const noticeInfo = (params) => post(sysServerUrl + 'sys/notice/getNoticeById/' + params);

// 获取对象
export const noticTarget = (params) => get(sysServerUrl + "sys/notice/getTarget", params);
// 获取对象
export const noticEmpower = (params) => post(sysServerUrl + "sys/notice/empower/" + params);



// ========== 车型管理 ========== //
// 车系管理-内容数据
export const trainData = (params) => get(sysServerUrl + 'sys/car/train/list', params);
// 车系管理-节点类型
export const trainTypeDate = (params) => get(sysServerUrl + 'sys/car/train/type', params);
// 车系管理-获取当前信息
export const getTrainInfo = (params) => post(sysServerUrl + 'sys/car/train/find' + params);
// 车系管理-增加节点
export const trainAdd = (params) => post(sysServerUrl + 'sys/car/train/addNode', params);
// 车系管理-修改
export const trainUpdate = (params) => post(sysServerUrl + 'sys/car/train/update', params);
// 车系管理-删除
export const trainDel = (params) => post(sysServerUrl + 'sys/car/train/del/' + params);
// 车系管理-能源类型：新能源、燃油车
export const trainCarTypeList = (params) => get(sysServerUrl + 'sys/car/train/energy/type', params);

export const importTrain = (params) => post(sysServerUrl + 'sys/car/train/importTrain', params); // 批量上传

export const trainTemplate = () => down(sysServerUrl + 'static/excel/车型模板.xlsx'); // 车型管理-车型模板下载


// 车型管理
// 车型管理-内容数据
export const modelData = (params) => get(sysServerUrl + 'sys/car/model/findTree', params);
// 车型管理-车型数据
export const modelTrainList = (params) => get(sysServerUrl + 'sys/car/train/list', params);
// 车型管理-增加
export const modelAdd = (params) => post(sysServerUrl + 'sys/car/model/add', params);
// 车型管理-编辑
export const modelEdit = (params) => post(sysServerUrl + 'sys/car/model/edit', params);
// 车型管理-删除
export const modelDel = (params) => post(sysServerUrl + 'sys/car/model/del/' + params);


export const modelImport = (params) => post(sysServerUrl + 'sys/car/model/batchImport', params); // 车型管理-内容数据

export const modelYearDel = (params) => post(sysServerUrl + 'sys/car/model/delYear', params);
export const modelConfigData = (params) => get(sysServerUrl + 'sys/car/model/cfglist' + params); // 车型管理-车型配置
export const configBatchDel = (params) => get(sysServerUrl + 'sys/car/model/deleteAllCfg' + params); //车型管理-删除全部配置代码
export const modelConfigDel = (params) => get(sysServerUrl + 'sys/car/model/deleteConfig' + params); //车型管理-删除当前行配置代码
export const configSave = (params) => post(sysServerUrl + 'sys/car/model/saveConfig', params); //车型管理-车型配置保存
export const modelTemplate = (params) => down(sysServerUrl + 'static/excel/车型导入模板.xlsx' + params); // 车型管理-车型模板下载
export const configTemplate = (params) => down(sysServerUrl + 'static/excel/车型配置表.xlsx' + params); // 车型管理-配置模板下载
export const editUseFlag = (params) => post(sysServerUrl + 'sys/car/model/editUseFlag', params);
export const modelEditYear = (params) => post(sysServerUrl + 'sys/car/model/editYear', params);  // 修改年款





//日志相关


export const requestLogList = (params) => post(sysServerUrl + 'sys/log/list', params);


export const requestLogModularList = (params) => get(sysServerUrl + 'sys/log/getLogModularList', params);

export const requestLogStackInfo = (params) => get(sysServerUrl + 'sys/log/getLogStack', params);



// ========= 区域管理 ====== //
// 获取结构树
export const regionData = (params) => get(sysServerUrl + 'sys/region/api/findTree', params);
// 获取类型
export const regionType = (params) => get(sysServerUrl + 'sys/region/api/typeList', params);
// 添加
export const regionAdd = (params) => get(sysServerUrl + 'sys/region/api/add', params);
// 修改
export const regionEdit = (params) => get(sysServerUrl + 'sys/region/api/edit', params);
// 删除
export const regionDel = (params) => get(sysServerUrl + 'sys/region/api/del/' + params);
// 导入
export const regionImportInfo = (params) => post(sysServerUrl + 'sys/region/api/batchImport', params);
// 明细模板下载
export const regionTemplate = (params) => down(sysServerUrl + 'static/excel/' + params);



export const appAccountData = (params) => post(sysServerUrl + 'sys/user/getAppAccountList', params);  // 用户管理-应用账号列表

//应用管理
export const appBasicData = (params) => get(sysServerUrl + 'sys/app/basic/list', params); //应用管理-列表
export const appBasicAdd = (params) => post(sysServerUrl + 'sys/app/basic/add', params); //应用管理-增加
export const appBasicEdit = (params) => post(sysServerUrl + 'sys/app/basic/edit', params);//应用管理-编辑
export const appBasicDel = (params) => get(sysServerUrl + 'sys/app/basic/del/' + params);//应用管理-删除

//应用版本管理
export const appVersionData = (params) => get(sysServerUrl + 'sys/app/version/list', params); //应用版本管理-列表
export const appVersionQueryAll = (params) => get(sysServerUrl + 'sys/app/version/list', params); //应用版本管理-全部列表
export const appVersionInstaller = (params) => get(sysServerUrl + 'sys/app/version/getInstallerList', params); //应用版本管理-全部列表
export const appVersionAdd = (params) => post(sysServerUrl + 'sys/app/version/add', params); //应用版本管理-增加
export const appVersionEdit = (params) => post(sysServerUrl + 'sys/app/version/edit', params);//应用版本管理-编辑
export const appVersionDel = (params) => get(sysServerUrl + 'sys/app/version/del/' + params);//应用版本管理-删除
export const appVersionUpload = (params) => get(sysServerUrl + 'sys/app/version/finishUpload?fileId=' + params);//应用版本管理-完成上传
export const appVersionDelFiile = (params) => post(sysServerUrl + 'sys/app/version/delUploadFile', params);//应用版本管理-删除上传文件
export const appVersionRelease = (params) => post(sysServerUrl + 'sys/app/version/release', params);

export const appVersionDescData = (params) => post(sysServerUrl + 'sys/app/version/detail/list/all', params); //应用版本描述管理-列表
export const appVersionDescAdd = (params) => post(sysServerUrl + 'sys/app/version/detail/add', params); //应用版本描述管理-增加
export const appVersionDescEdit = (params) => post(sysServerUrl + 'sys/app/version/detail/edit', params);//应用版本描述管理-编辑
export const appVersionDescDel = (params) => get(sysServerUrl + 'sys/app/version/detail/del/' + params);//应用版本描述管理-删除
export const appVersionDescSort = (params) => post(sysServerUrl + 'sys/app/version/detail/sort' , params);//应用版本描述管理-排序

