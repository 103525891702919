<template>
  <div class="layoutContainer">
    <div class="infoDetail releaseContainer">
      <el-row>
        <el-col :span="5" class="leftData">
          <div>
            <div class="topButton" v-if="hasPerm('menuAsimssCMS4A2B_107') || hasPerm('menuAsimssCMS4A2B_101') || hasPerm('menuAsimssCMS4A2B_102') || hasPerm('menuAsimssCMS4A2B_103')">
              <el-dropdown>
                <el-button v-if="hasPerm('menuAsimssCMS4A2B_107')" type="text" size="small" style="font-size: 14px;">
                  <i class="el-icon-upload" style="margin-right: 5px;"></i>导入导出
                </el-button>
                <el-dropdown-menu slot="dropdown" style="margin-top: -3px">
                  <el-dropdown-item v-if="hasPerm('menuAsimssCMS4A2B_107') && isComposite != '1'" @click.native="importCatalogClick()">导入开发目录</el-dropdown-item>
                  <el-dropdown-item >
                    <el-upload
                      class="upload-demo inline-block margin-left-10 margin-right-10"
                      ref="melUpload"
                      action="#"
                      :show-file-list="false"
                      multiple
                      :before-upload="onBeforeUpload"
                      :http-request="uploadModel"
                      accept="xls、xlsx"
                    >
                      <span v-if="hasPerm('menuAsimssCMS4A2B_107')">导入excel</span>
                    </el-upload>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="hasPerm('menuAsimssCMS4A2B_107')" @click.native="exportExcelClick()">导出excel</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="text" v-if="hasPerm('menuAsimssCMS4A2B_101')" icon="el-icon-plus" @click="handelAdd()">新增</el-button>
              <el-button type="text" v-if="hasPerm('menuAsimssCMS4A2B_102')" icon="el-icon-delete" @click="handelDel()">删除</el-button>
              <el-button type="text" v-if="hasPerm('menuAsimssCMS4A2B_103') && isComposite !== 1" icon="el-icon-connection" @click="handelRelation()">关联Topic</el-button>
              <!-- <el-button type="text" v-if="hasPerm('menuAsimssCMS4A2B_103') &&  typeThemeStatus == 'true'" icon="el-icon-coin" @click="handelTopic()">批量维护主题类型</el-button> -->
              <el-button type="text" v-if="hasPerm('menuAsimssCMS4A2B_103')" icon="el-icon-document-copy" @click="handelSync()">目录同步</el-button>
            </div>
            <el-progress v-show="progressState"  class="imgProgress" :percentage="progressPercent" />
            <div class="scrollClass">
              <el-scrollbar>
                <el-tree ref="tree"
                  :data="listdata"
                  node-key="id"
                  :props="otherProps"
                  highlight-current
                  :default-expanded-keys="treeExpandIdList"
                  @node-click="handleNodeClick"
                >
                </el-tree>
              </el-scrollbar>
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="leftData issueCenter">
          <div class="issueDetail">
            <div class="topButton">
              <span style="height:41px;line-height:41px;margin-left: 10px;">详情内容</span>
            </div>
            <div class="releaseDetail">
              <div v-if="detailPageUrl == ''" style="margin:20px;text-align:center">{{detailPageTip}}</div>
              <iframe v-if="detailPageUrl != ''" :src="detailPageUrl" frameborder="0" width="100%" height="100%"></iframe>
            </div>
          </div>
        </el-col>
        <el-col :span="7" class="fromRight issueInfo">
          <div class="foldArea">
            <div class="flodTitle">
              <div>{{editTitle}}</div>
              <div class="foldBut" @click="foldClick()">
                <i class="el-icon-d-arrow-right"></i>
              </div>
            </div>
            <el-form ref="form" :model="form" :rules="newForm">
              <el-form-item label="目录编码" prop="code" :label-width="formLabelWidth">
                <el-input v-model.trim='form.code' placeholder="请输入目录编码" show-word-limit maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="中文名称" prop="cnName" :label-width="formLabelWidth">
                <el-input v-model.trim="form.cnName" placeholder="请输入中文名称" show-word-limit maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="Topic节点" prop="isLeaf" :label-width="formLabelWidth">
                <el-radio-group  v-model="form.isLeaf" :disabled="butType!=='addNodeBut'">
                  <el-radio :label="1" @change="topicNodeClick(1)">是</el-radio>
                  <el-radio :label="0" @change="topicNodeClick(0)">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 章节类型作用于 pdf 每个章节 -->
            <el-form-item v-if="this.outputType == 'pdf'" label="目录类型" prop="chapterType" :label-width="formLabelWidth">
                <el-select v-model="form.chapterType" clearable filterable :disabled="manualTypeId != form.pid">
                  <el-option v-for="(item, index) in chapterTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
                </el-select>
            </el-form-item>
              <el-form-item v-if="this.topicTypeStatus == 'true' && this.outputType != 'pdf'" label="主题类型" prop="topicType" :label-width="formLabelWidth">
                <el-select v-model="form.topicType" clearable filterable>
                  <el-option v-for="(item, index) in topicTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item  v-if="this.filterAttrStatus == 'true'" label="筛选属性" :label-width="formLabelWidth">
                <el-input v-model.trim="form.topicFilter" placeholder="请输入筛选属性" show-word-limit maxlength="100"></el-input>
              </el-form-item>
              <el-form-item v-if="form.isLeaf == '1' && filterAttrStatus == 'true'" label="适用配置" :label-width="formLabelWidth">
                <el-input v-model.trim="form.config" placeholder="请输入适用配置" show-word-limit maxlength="100"></el-input>
              </el-form-item>
              <el-form-item  v-if="this.outputType == 'pdf'" label="附加信息" :label-width="formLabelWidth">
                <el-button type="info" @click="additionalClick()">添加信息</el-button>
              </el-form-item>
              <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
                <el-input type="number" min="1" oninput="value=value.replace(/[^\d]/g, '')" v-model.trim="form.sort"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
                <el-input v-model.trim="form.remark" placeholder="请输入备注" show-word-limit maxlength="100"></el-input>
              </el-form-item>
              <el-form-item class="butArea">
                <el-button v-show="butType===''" type="primary" @click="updateNode()">保存</el-button>
                <el-button v-show="butType==='addNodeBut'" type="primary" @click="nodeConfirm('form')">确定</el-button>
                <el-button v-show="butType==='addNodeBut'" @click="resetForm('form')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="openArea" @click="openClick()">
            <div class="openTitle">
              <div class="openBut" >
                <i class="el-icon-d-arrow-left"></i>
              </div>
              <div v-for="index of editTitle.length" :key="index">{{editTitle[index-1]}}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-dialog :width="dialogStatus == 'struckTheme' || dialogStatus == 'synchronizeDirs' ?  '' : '995px !important'"
        :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <!-- 导入开发目录 -->
        <div v-if="this.dialogStatus == 'importCatalog'" style="height:100%">
          <el-row>
            <el-col :span="8" class="leftData dialogCatalog">
              <div>
                <div class="topButton">
                  <el-checkbox style="margin-right: 10px;" v-model="checkAll" @change="checkAllClick">全选</el-checkbox>
                  <el-button type="text" icon="el-icon-finished" @click="determine()">确定</el-button>
                </div>
                <div class="scrollClass">
                  <el-scrollbar>
                    <el-tree ref="dataTree"
                      :data="catalogList"
                      node-key="id"
                      :default-expanded-keys="dataTreeExpandIdList"
                      :default-checked-keys="defaultCheckList"
                      :props="defaultProps"
                      show-checkbox
                      highlight-current
                      :render-content="renderContent"
                      @node-click="importNodeClick"
                      @check="(click, checked)=>{checkAllChange(click, checked)}"
                    ></el-tree>
                  </el-scrollbar>
                </div>
              </div>
            </el-col>
            <el-col :span="16" class="fromRight dialogCenter">
              <div class="formTitle">{{pageTitle}}</div>
              <div v-if="nodePageUrl == ''" style="margin:20px;text-align:center">{{nodePageTip}}</div>
              <iframe v-if="nodePageUrl != ''" id="iframe" :src="nodePageUrl" frameborder="0" width="100%" height="calc(100% - 60px)"></iframe>
            </el-col>
          </el-row>
        </div>
        <!-- 关联Topic -->
        <div v-if="this.dialogStatus == 'relatedTopic'" style="height:100%">
          <el-row>
            <el-col :span="8" class="leftData dialogCatalog">
              <div>
                <div class="topButton">
                  <el-select v-model="topicManualtype" style="width: 145px;" clearable filterable @change="seletChange">
                    <el-option v-for="(item, index) in topicManualList" :key="index" :label="item.cnName" :value="item.manualType"></el-option>
                  </el-select>
                  <el-button type="text" icon="el-icon-finished" @click="defineRelated">确定</el-button>
                </div>
                <div class="scrollClass">
                  <el-scrollbar>
                    <el-tree ref="topicTree"
                      :data="catalogList"
                      node-key="id"
                      :default-expanded-keys="dataTreeExpandIdList"
                      :props="defaultProps"
                      show-checkbox
                      highlight-current
                      :check-strictly="true"
                      :render-content="renderContent"
                      @node-click="importNodeClick"
                      @check-change="checkChange"
                    >
                    </el-tree>
                  </el-scrollbar>
                </div>
              </div>
            </el-col>
            <el-col :span="16" class="fromRight dialogCenter">
              <div class="formTitle">{{pageTitle}}</div>
              <div v-if="nodePageUrl == ''" style="margin:20px;text-align:center">{{nodePageTip}}</div>
              <iframe v-if="nodePageUrl != ''" id="iframe" :src="nodePageUrl" frameborder="0" width="100%" height="calc(100% - 60px)"></iframe>
            </el-col>
          </el-row>
        </div>
        <!-- 维护主题类型 -->
        <div class="themeDetail" v-if="this.dialogStatus == 'struckTheme'">
          <div class="themeTypeTtile">
            <div>
              <span>主题类型</span>
              <el-select v-model="batchTopicType"  clearable filterable>
                <el-option v-for="(item, index) in topicTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </div>
            <el-button type="primary" @click="typeOnSubmit">确定</el-button>
          </div>
          <div class="struckThemeDetail">
            <el-checkbox style="margin-right: 10px;" v-model="themeCheck" @change="handelCheckAll()">全选</el-checkbox>
            <div class="scrollClass">
              <el-scrollbar>
                <el-tree ref="themeTree"
                  :data="catalogList"
                  node-key="id"
                  :props="otherProps"
                  show-checkbox
                  default-expand-all
                  :expand-on-click-node="false"
                  highlight-current
                  @check="(click, checked)=>{themeCheckChange(click, checked)}"
                ></el-tree>
              </el-scrollbar>
            </div>
          </div>
        </div>
        <!-- 目录同步 -->
        <div class="syncDetail" v-if="this.dialogStatus == 'synchronizeDirs'"  style="height:100%">
          <el-button type="primary" style="margin-bottom:10px;padding:11px 13px;" @click="batchSyncClick()">批量目录同步</el-button>
          <el-table style="width:100%; margin-bottom:10px;overflow-x: hidden;overflow-y: auto;max-height: calc(100% - 55px);"
            border :data="alterDirectoryList"  @selection-change="handleSelectionChange" :header-cell-style="{'text-align':'center'}">
            <el-table-column type="selection" width="45" align="center"></el-table-column>
            <el-table-column label="现名称" prop="oldName" min-width="150">
              <template slot-scope="{row}">
                <span :title="row.oldName">{{row.oldName}}</span>
              </template>
            </el-table-column>
            <el-table-column label="新名称" prop="newName" min-width="150">
              <template slot-scope="{row}">
                <span :title="row.newName">{{row.newName}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="{row}">
                <el-button type="text"  size="small" @click="syncDirsHandel(row)">【同步】</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>

      <el-dialog width="500px !important" title="添加附加信息" :visible.sync="dialogAdditionalVisible" >
        <!-- 动态增加项目 -->
        <!-- 不止一个项目，用div包裹起来 -->
        <el-form  :model="formAdditional" >
            <div v-for="(item, index) in formAdditional.dynamicItem" :key="index">
              <el-row>
                <el-col :span="11">
                  <el-form-item label="属性名" :prop="'dynamicItem.' + index + '.key'">
                      <el-input v-model.trim="item.key"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="属性值" :prop="'dynamicItem.' + index + '.value'" >
                      <el-input v-model.trim="item.value"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1">
                  <el-form-item>
                      <i class="el-icon-delete" @click="deleteItem(item, index)"></i>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <el-form-item class="submitArea">
              <el-button type="primary" @click="confirmAdditional()">确定</el-button>
              <el-button @click="addDomain()">新增</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { contentSize, handleAlert, expandEvents } from '@/assets/js/common.js'
import {
  releaseCatalog,
  topicCatalogData,
  importCatalogData,
  importExcel,
  exportExcel,
  topicTypeData,
  previewURL,
  importData,
  releaseCatalogUpdate,
  releaseCatalogAdd,
  releaseCatalogDel,
  manualBasicType,
  associationDefine,
  struckThemeList,
  updateThemeType,
  alterDirectoryData,
  synchronizeDirs
} from '@/api/releasemgt.js'
import { catalogTypeList } from '@/api/cmsmgt.js'
import $ from "jquery"
export default {
  name: "releaseCatalog",
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: '85px',
      // 数
      otherProps: {
        children: 'children',
        label: 'cnName'
      },
      defaultProps: {
        children: 'children',
        label: 'cnName',
        disabled:function(val){
          if(val.auditStatus === 1 ){
            return false
          }else{
            return true
          }
        }
      },
      // 弹框
      dialogStatus: '',
      textMap: {
        importCatalog: '导入开发目录',
        importExcel: '导入excel',
        relatedTopic: '关联Topic',
        struckTheme: '维护主题类型',
        synchronizeDirs: '目录同步',
      },
      dataTreeExpandIdList:[],
      treeExpandIdList:[],
      detailPageUrl: '',// 页面url
      detailPageTip:'',
      nodePageUrl:"", //弹框页面url
      nodePageTip:'',
      listdata: [],
      butType: "",
      editTitle: "当前目录节点信息",
      // 当前信息
      form: {
        id: 0,
        pid: 0,
        type: '',
        code: '',
        cnName: '',
        isLeaf: '',
        sort: 1,
        remark: '',
        topicFilter:'',
        topicType: '', // 主题类型
        length: 0,
        additionInfoList:[],
        chapterType: '',
        config: '',
      },
      isComposite:'',
      chapterTypeList: [],
      topicTypeList: [], // 主题类型
      catalogueLevel:"", //层级
      manualParam: [], // 参数
      topicTypeStatus:"false",// 主题
      filterAttrStatus:"false",// 筛选
      relatedTopicId:'', // 关联topic确定
      topicManualtype:'',
      topicManualList:"",// topic手册类型
      batchTopicType:"",
      newForm:{
        code: [{ required: true, message: '目录编码不能为空', trigger: ['blur', 'change'] }],
        cnName: [{ required: true, message: '中文名称不能为空', trigger: ['blur', 'change'] }],
        isLeaf: [{ required: true, message: '请选择Topic节点', trigger: ['blur', 'change'] }],
      },
      // 导入导出
      progressState:false,
      progressPercent: 0,
      catalogList:[],
      checkAll:"",
      defaultCheckList:[], // 默认选中
      allowCheckAll:[], //全选
      themeCheck: true,
      themeCheckAll:[],
      pageTitle:"",
      typeName:"",
      pageStatus: true,
      topicTypeVal:"",
      typeThemeStatus:false,
      alterDirectoryList:[],
      syncList:[],
      manualTypeId: 0,

      outputType: '',  // 输出类型
      dialogAdditionalVisible: false,  // 附加信息的弹窗
      formAdditional: {
        dynamicItem: []
      }
    }
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "releaseCatalog") {
        _this.isComposite = to.params.isComposite
        _this.dataList()
        _this.handelTopicType()
        contentSize();
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      this.typeName = ""
      this.treeExpandIdList = []
      var params = new URLSearchParams()
      params.append('basicId', this.$route.params.basicId)
      releaseCatalog(params).then(res => {
        this.listdata = res.data.data
        this.manualTypeId = res.data.data[0].id
        this.manualParam = [this.listdata[0].manualVersion, this.listdata[0].manualId, this.listdata[0].cnName]
        if(this.pageStatus == true){
          this.expandStatus(this.listdata)
          if (this.$route.params.outputType == 'pdf' || this.manualParam[2] == '电路图册' || this.$route.params.isComposite == '1') {
            this.typeThemeStatus = 'true'
          }
        }
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(this.form.id);
        });

      })
    },
    // 详情内容
    previewDetail(type,id,leafStatus){
      var directoryId
      if(type == 'node'){
        directoryId = id + '/1'
      }else{
        directoryId = id + '/0'
      }
      if(leafStatus == 1){
        previewURL(directoryId).then(res => {
          if(res.data.code == '100'){
            if(res.data.data != null){
              if(type == 'node'){
                this.detailPageUrl = res.data.data
                this.detailPageTip = ''
              }else{
                this.nodePageUrl = res.data.data
                this.nodePageTip = ''
              }
            } else{
              if(type == 'node'){
                this.detailPageUrl = ''
                this.detailPageTip = '暂无详情页面'
              }else{
                this.nodePageUrl = ''
                this.nodePageTip = '暂无详情页面'
              }
            }
          } else {
            if(type == 'node'){
              this.detailPageUrl = ''
              this.detailPageTip = res.data.msg
            }else{
              this.nodePageUrl = ''
              this.nodePageTip = res.data.msg
            }
          }
        })
      }
    },
    formClear(){
      this.form= {
        id: 0,
        pid: 0,
        type: '',
        code: '',
        cnName: '',
        isLeaf: '',
        sort: 1,
        remark: '',
        topicFilter:'',
        topicType: '', // 主题类型
        length: 0,
        chapterType:''

      },
      this.$refs.form.resetFields()
      this.topicTypeStatus = 'false'
      this.filterAttrStatus = 'false'
    },
    // 数据详情
    handleNodeClick(data){
      this.typeName = ""
      this.topicTypeList= []
      this.previewDetail('node', data.id, data.isLeaf)
      this.topicNodeClick(data.isLeaf)
      this.assignment(data)
    },
    assignment(data){
      let list = []
      if (data.additionInfoList) {
        let obj = JSON.parse(data.additionInfoList)
        Object.keys(obj).forEach(key=>{
          let param = {
            key:key,
            value:obj[key]
          }
          list.push(param);
        })

      }
      this.butType = ''
      this.editTitle = "当前目录节点信息"
      this.form.id = data.id
      this.form.pid = data.pid
      this.form.type = data.type
      this.form.code = data.code
      this.form.cnName = data.cnName
      this.catalogueLevel = data.level
      this.form.isLeaf = data.isLeaf
      this.form.sort = data.sort
      this.form.remark = data.remark
      this.topicTypeVal = data.topicType
      this.form.topicFilter = data.topicFilter
      this.form.chapterType = data.chapterType ? data.chapterType : ""
      this.form.config = data.config ? data.config : ""
      this.form.additionInfoList = list
      if (data.children != null) {
        this.form.length = data.children.length
      }
    },
    // 目录展开状态
    expandStatus(data){
      if (data.length > 0) {
        if (this.typeName == "importNode") {
          var importExpand = expandEvents(data)
          this.dataTreeExpandIdList.push(importExpand.id)
          this.importNodeClick(importExpand)
          if( this.dialogStatus == 'importCatalog'){
            this.$nextTick(() => {
              this.$refs.dataTree.setCurrentKey(importExpand.id);
            });
          }else if(this.dialogStatus == "relatedTopic"){
            this.$nextTick(() => {
              this.$refs.topicTree.setCurrentKey(importExpand.id);
            });
          }
        } else {
          var nodeExpand = expandEvents(data)
          this.treeExpandIdList.push(nodeExpand.id)
          this.handleNodeClick(nodeExpand)
          this.$nextTick(() => {
            if(this.$refs.tree != undefined) {
              this.$refs.tree.setCurrentKey(nodeExpand.id);
            }
          });
        }
      }
    },
    // 主题类型
    topicTypeDetail(){
      this.form.topicType = ''
      this.form.topicFilter = ''
      var params = {
        outputType: this.$route.params.outputType,
        isFlag: this.$route.params.isComposite,
        manualType: this.$route.params.manualType
      }
      topicTypeData(params).then(res => {
        this.topicTypeList = res.data.data
        if(this.topicTypeVal == 'null'){
          this.topicTypeVal = ""
        }
        this.form.topicType = this.topicTypeVal
      })
    },
    topicNodeClick(isLeaf){
      this.outputType = this.$route.params.outputType;
      if (isLeaf == 1) {
        if (this.$route.params.outputType == 'pdf' || this.manualParam[2] == '电路图册' || this.$route.params.isComposite == '1') {
          this.topicTypeStatus = 'true'
        }
        if (this.$route.params.isComposite == '1') {
          this.filterAttrStatus = 'true'
        }
      } else {
        this.topicTypeStatus = 'false'
        this.filterAttrStatus = 'false'
      }
      this.topicTypeDetail()
    },
    // 导入开发目录
    // 数据
    statusMask(){
      this.nodePageUrl=""
      this.pageTitle=""
      this.dialogFormVisible = true
      this.typeName="importNode"
    },
    importCatalogClick(){
      this.catalogList = []
      this.dialogStatus = 'importCatalog'
      this.statusMask()
      importCatalogData(this.$route.params.basicId).then(res => {
        this.catalogList = res.data.data
        this.checkForList(this.catalogList)
        this.expandStatus(this.catalogList)
      })
      setTimeout(() => {
        this.allowCheckAll=[]
        this.checkAll = false
        this.$refs.dataTree.setCheckedKeys([]);
      })
    },
    // 导入目录选择状态
    checkState(item){
      if(item.relevancyStatus === true){
        this.defaultCheckList.push(item.id)
      }
      if (item.auditStatus === 1) {
        this.allowCheckAll.push(item.id)
      }
      if(this.dialogStatus == 'struckTheme'){
        this.themeCheckAll.push(item.id)
      }
    },
    recursion(data){
      for(var i = 0 ; i < data.length ; i++){
        if(data[i].children.length > 0){
          this.checkState(data[i])
          this.recursion(data[i].children)
        }else{
          this.checkState(data[i])
        }
      }
    },
    checkForList(data){
      if(data.length == 0){
        return
      }
      this.recursion(data)
    },
    // 导入目录文字颜色
    renderContent(h, { node, data, store }) {
      let isLeaf = data.children.length;
      let vStatus= data.auditStatus == null ? 0:data.auditStatus;
      let status = data.relevancyStatus
      let style=''
      if(isLeaf > 0){
        style='black-category'
  		}else{
        if(vStatus==2){
	  	    style='red-category'
	  	  }else if(vStatus==1){
          style='green-category'
	  	  }else if(vStatus==3){
          style='orange-category'
	  	  }else{
          style='blue-category'
        }
      }
      return(<span class={style} style="font-size:15px;cursor:pointer">{node.label}</span>)
    },
    importNodeClick(data){
      this.pageTitle = data.cnName
      if(this.dialogStatus == 'importCatalog' || this.dialogStatus == "relatedTopic"){
        this.previewDetail('importNode', data.id, data.isLeaf)
      }
    },
    // 导入目录全选
    checkAllClick(){
      if(this.checkAll) {
        this.$refs.dataTree.setCheckedKeys(this.allowCheckAll)
      } else {
        this.$refs.dataTree.setCheckedKeys([]);
      }
    },
    // 导入目录反选
    checkAllChange(click, checked){
      var checkList = []
      var length = checked.checkedNodes.length
      if(length > 0){
        for(var i = 0; i < length; i++){
          if(checked.checkedNodes[i].children.length == 0){
            checkList.push(checked.checkedNodes[i])
            if(checkList.length == this.allowCheckAll.length){
              this.checkAll=true
            } else {
              this.checkAll=false
            }
          }
        }
      }else{
        this.checkAll=false
        // this.allowCheckAll=[]
      }
    },
    // 导入目录确定
    determine(){
      if(this.$refs.dataTree.getCheckedKeys().length == 0){
        handleAlert('error','导入目录节点不能为空')
      }else{
        this.pageStatus = true
        var params = new URLSearchParams()
        params.append('basicId', this.$route.params.basicId)
        params.append('manualId', this.catalogList[0].manualId)
        params.append('manualVersion', this.catalogList[0].manualVersion)
        params.append('manualDirectoryIds', this.$refs.dataTree.getCheckedKeys())
        importData(params).then(res => {
          if(res.data.code == 100){
            handleAlert('success',res.data.msg)
            this.dataList()
            this. dialogFormVisible = false
            this.allowCheckAll = []
          } else {
            handleAlert('error',res.data.msg)
          }
        })
      }
    },
    // 导入excel
    // 附件上传
    onBeforeUpload(file) {
      var _this =this
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();
      const docExt = fileExt === 'xls'
      const docxExt = fileExt === 'xlsx'
      const isLimit = file.size / 1024 / 1024 < 50
      if(!docExt && !docxExt) {
        handleAlert('warning',"上传的文件只能是 xls、xlsx格式!")
        return false;
      }
      if (!isLimit) {
        handleAlert('warning',"上传的文件大小不能超过 50MB!")
        return false;
      }
      return true;
    },
    //进度条
    initialState(){
      setTimeout(() => {
        this.progressPercent = 0
        this.progressState = false
      },100)
    },
    // 上传
    uploadModel (param){
      var _this = this
      var handelProgress
      this.pageStatus = true
      var formData = new FormData();
      formData.append('file', param.file);
      formData.append('basicId', _this.$route.params.basicId)
      const uploadProgress = progressEvent => {
        _this.progressState = true
        handelProgress = setInterval(function(){
          if(_this.progressPercent > 96){
            return
          }
          _this.progressPercent += 1
        },120)
      }
      importExcel(formData, uploadProgress).then(res => {
        if (res.data.code == '100') {
          _this.progressPercent = 100
          handleAlert('success','导入成功')
          _this.dataList()
        }else{
          handleAlert('error',res.data.msg)
        }
        _this.initialState()
      }).catch(function(error){
        handleAlert('error','上传出现异常，稍后重试')
        _this.initialState()
      })
      clearInterval(handelProgress)
    },
    // 导出excel
    exportExcelClick(){
      var params = new URLSearchParams()
      params.append('basicId', this.$route.params.basicId)
      exportExcel(params).then(res => {
        if (!res.data) {
          return
        }
        // console.log(res.data.type);
        let name = decodeURI(res.headers["content-type"].split("charset=")[1]);
        // var name = decodeURI(res.data.type);
        var blob = new Blob([res.data]);
        var url = window.URL.createObjectURL(blob);
        var aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = url;
        aLink.setAttribute("download", name);
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink); //下载完成移除元素
        window.URL.revokeObjectURL(url); //释放掉blob对象
      })
    },
    // 新增
    handelAdd(){
      if(this.form.id == 0){
        handleAlert('error', '请选择目录节点')
        return false
      }
      if(this.form.isLeaf == 1){
        handleAlert('error', `父节目录【${this.form.cnName}】是TOPIC节点`)
        return false
      }

      let chapterTypeTemp = this.form.chapterType
      this.pageStatus = false
      this.editTitle = "添加节点信息"
      this.butType='addNodeBut'
      this.catalogueLevel = this.catalogueLevel + 1
      let sort = this.form.length
      this.$refs.form.resetFields()
      this.topicTypeStatus = 'false'
      this.filterAttrStatus = 'false'
      if(this.form.pid == '0'){
        this.form.pid = this.manualTypeId
        this.form.chapterType = ''
      } else {
        this.form.pid = this.form.id
        this.form.chapterType = chapterTypeTemp
      }
      this.form.sort = sort + 1;
      this.form.additionInfoList = []

    },
    // 新增确定
    nodeConfirm(form){
      this.$refs[form].validate((valid) => {
        if(valid){
          const map =new Map()
          this.form.additionInfoList.forEach(function (item) {
            map.set(item.key, item.value);
          })
          let jsonStr =  JSON.stringify(Object.fromEntries(map))
          this.$loading.show();
          var params = new URLSearchParams()
          params.append('pid', this.form.pid)
          params.append('basicId', this.$route.params.basicId)
          params.append('manualId',this.manualParam[1])
          params.append('manualVersion', this.manualParam[0])
          params.append('code', this.form.code)
          params.append('type', "category")
          params.append('cnName', this.form.cnName)
          params.append('level', this.catalogueLevel)
          params.append('isLeaf', this.form.isLeaf)
          params.append('chapterType', this.form.chapterType)
          params.append('topicType', this.form.topicType)
          params.append('topicFilter', this.form.topicFilter)
          params.append('config', this.form.config)
          params.append('additionInfoList', jsonStr)
          params.append('remark', this.form.remark)
          params.append('sort', this.form.sort)
          releaseCatalogAdd(params).then(res => {
            if (res.data.code === 100) {
              handleAlert('success',res.data.msg)
              this.dataList()
              this.treeExpandIdList.push(this.form.id)
              this.butType=''
              this.editTitle = "当前目录节点信息"
              this.formClear()
            } else {
              handleAlert('error',res.data.msg)
            }
            this.$loading.hide();
          })
        }else{
          handleAlert('error','请完善信息');
          this.$loading.hide();
        }
      })

    },
    // 重置
    resetForm(){
      this.formClear()
    },
    // 删除
    handelDel(){
      if(this.form.pid == 0) {
        handleAlert('error','目录根节点无法删除')
        return
      }
      if(this.form.length > 0){
        handleAlert('error','有子节点无法删除')
        return
      }
      var pid = this.form.pid
      var id = this.form.id
      let nodeName= this.form.cnName
      this.$confirm('确定删除【'+ nodeName + '】的目录节点信息?', '删除目录节点', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.pageStatus = false
        releaseCatalogDel(id).then(res => {
          if(res.data.code==100){
            handleAlert('success','删除成功')
            this.dataList()
            this.treeExpandIdList.push(pid)
            this.formClear()
          }else{
            handleAlert('error',res.data.msg)
          }
        })
      }).catch((error)=>{})
    },
    // 关联Topic
    // 关联topic手册类型
    handelTopicType(){
      var params = {
        basicId: this.$route.params.basicId
      }
      manualBasicType(params).then(res => {
        if(res.data.code == '100'){
          this.topicManualList = res.data.data
        }
      })
    },
    topicCatalog(){
      this.catalogList = []
      if(this.topicManualList.length > 0){
        this.topicManualList.forEach((item) => {
          if(item.manualType == this.topicManualtype){
            var params = {
              manualId: item.id,
              manualVersion: item.manualVersion
            }
            topicCatalogData(params).then(res => {
              if(res.data.data.length > 0){
                this.catalogList = res.data.data[0].children
                this.expandStatus(res.data.data[0].children)
              }

            })
            return
          }
        })
      }else{
        this.topicManualtype = ''
      }
    },
    // 确定关联
    checkChange(data,checked){
      if(checked){
        this.relatedTopicId = data.id
        this.$refs.topicTree.setCheckedKeys([data.id])
      }
    },
    defineRelated(){
      if(this.relatedTopicId == ''){
        handleAlert('error','请选择目录节点')
        return
      }
      var params = new URLSearchParams();
      params.append("directoryId", this.relatedTopicId);
      params.append("id", this.form.id);
      associationDefine(params).then(res => {
        if(res.data.code == '100'){
          handleAlert('success','成功关联topic')
          this.dataList()
          this.dialogFormVisible = false
          this.relatedTopicId = ''
          this.$refs.topicTree.setCheckedKeys([])
        } else {
          handleAlert('error', res.data.msg)
        }
      })
    },
    // 数据
    handelRelation(){
      this.dialogStatus = "relatedTopic"
      this.statusMask()
      this.topicManualList.forEach((item) => {
        if(item.manualType == this.$route.params.manualType){
          this.topicManualtype = item.manualType
        }else{
          this.topicManualtype = this.topicManualList[0].manualType
        }
      })
      this.topicCatalog()
      setTimeout(()=> {
        this.relatedTopicId = ''
        this.$refs.topicTree.setCheckedKeys([])
      })
    },
    seletChange(val){
      this.topicManualtype = val
      this.topicCatalog()
    },
    // 维护主题类型
    struckThemeData(){
      this.catalogList = []
      this.themeCheck = true
      const loading = this.$loading({
        lock:true,
        text:'数据加载中,请稍等...',
        spinner:'el-icon-loading',
        background: 'rgba(0,0,0,0.5)'
      })
      var param = {
        id: this.form.id,
        basicId: this.$route.params.basicId
      }
      struckThemeList(param).then(res => {
        if(res.data.code == '100'){
          this.themeCheck = true
          this.catalogList = res.data.data
          loading.close();
          setTimeout(() => {
            this.dialogStatus = "struckTheme"
            this.dialogFormVisible=true
            this.checkForList(this.catalogList)
            setTimeout(() => {
              this.$refs.themeTree.setCheckedKeys(this.themeCheckAll)
            })
            this.themeArea()
          })
        }
      })
    },
    themeArea(){
      setTimeout(() => {
        var allHeight = $('.releaseContainer .el-dialog__body').outerHeight(true)
        var title = $('.themeTypeTtile').outerHeight(true)
        $('.struckThemeDetail').css('height', allHeight - title - 27)
        window.addEventListener('resize', function(){
          var allHeight = $('.releaseContainer .el-dialog__body').outerHeight(true)
          var title = $('.themeTypeTtile').outerHeight(true)
          $('.struckThemeDetail').css('height', allHeight - title - 27)
        })
      })
    },
    handelTopic(){
      if(this.form.length == '0'){
        handleAlert('error','目录无子节点不可批量维护')
        return
      }
      this.themeCheckAll = []
      this.struckThemeData()
      $('.struckThemeDetail').removeAttr("style")
    },
    // 主题类型全选
    handelCheckAll(){
      if(this.themeCheck) {
        this.$refs.themeTree.setCheckedKeys(this.themeCheckAll)
      } else {
        this.$refs.themeTree.setCheckedKeys([]);
      }
    },
    // 主题类型反选
    themeCheckChange(click, checked){
      if(checked.checkedNodes.length == this.themeCheckAll.length){
        this.themeCheck=true
      } else {
        this.themeCheck=false
      }
    },
    // 确定
    typeOnSubmit(){
      this.pageStatus = false
      var params = new URLSearchParams();
      params.append("basicId", this.$route.params.basicId);
      params.append("ids", this.$refs.themeTree.getCheckedKeys());
      params.append("topicType", this.batchTopicType);
      updateThemeType(params).then(res => {
        if(res.data.code == '100'){
          handleAlert('success','修改成功')
          this.dataList()
          this.dialogFormVisible = false
          this.themeCheckAll = []
          this.batchTopicType = ''
          this.treeExpandIdList.push(this.form.id)
        } else {
          handleAlert('error', res.data.msg)
        }
      })
    },

    // 添加附加信息
    additionalClick(){
      // 打开弹窗
      this.formAdditional.dynamicItem = []
      let _this = this
      this.form.additionInfoList.forEach(function (item) {
        _this.formAdditional.dynamicItem.push(item)
      })
      this.dialogAdditionalVisible = true
      if(this.formAdditional.dynamicItem.length <= 0){
        this.addDomain()
      }

    },
    addDomain () {
      this.formAdditional.dynamicItem.push({
        key: '',
        value: ''
      })
    },
    deleteItem (item, index) {
      this.formAdditional.dynamicItem.splice(index, 1)
    },
    confirmAdditional(){
      // 遍历获取所有有效的键值对

      let list = []
      let len = this.formAdditional.dynamicItem.length;
      let b = false;
      for (let i = 0; i < len; i++) {
        let item = this.formAdditional.dynamicItem[i];
        if (item.key.length>0 && item.value.length>0) {
          let param = {
            key: item.key,
            value: item.value
          }
          list.push(param)
        }else{
          b = true;
        }
      }
      if (b) {
        handleAlert('warning',"提交时不会保存空属性")
      }
      this.form.additionInfoList = list
      this.dialogAdditionalVisible = false
    },
    // 当前节点信息修改
    updateNode(){
      this.pageStatus = false
      const map =new Map()
      this.form.additionInfoList.forEach(function (item) {
        map.set(item.key, item.value);
      })
      let jsonStr =  JSON.stringify(Object.fromEntries(map))
      this.$loading.show();
      var params = new URLSearchParams()
      params.append('id', this.form.id)
      params.append('code', this.form.code)
      params.append('cnName', this.form.cnName)
      params.append('remark', this.form.remark)
      params.append('isLeaf', this.form.isLeaf)
      params.append('sort', this.form.sort)
      params.append('chapterType', this.form.chapterType)
      params.append('topicType', this.form.topicType)
      params.append('topicFilter', this.form.topicFilter)
      params.append('config', this.form.config)
      params.append('additionInfoList', jsonStr)
      // 确认修改
      releaseCatalogUpdate(params).then(res => {
        if (res.data.code === 100) {
          handleAlert('success',res.data.msg)
          this.dataList()
          this.treeExpandIdList.push(this.form.id)
        } else {
          handleAlert('error',res.data.msg)
        }
        this.$loading.hide();
      })
    },
    // 目录同步
    handelSync(){
      this.dialogStatus = "synchronizeDirs"
      this.dialogFormVisible=true
      this.dirsChangeData()
    },
    dirsChangeData(){
      var param = {
        basicId: this.$route.params.basicId
      }
      alterDirectoryData(param).then(res => {
        if(res.data.code == '100'){
          this.alterDirectoryList = res.data.data
        }
      })
    },
    // 同步操作
    syncDirsHandel(row){
      var param  = {
        ids: row.id
      }
        this.$confirm('确定目录同步?', '目录同步', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        synchronizeDirs(param).then(res => {
          if(res.data.code == '100'){
            handleAlert('success', '目录同步成功')
            this.dialogFormVisible = false
            this.dataList()
          }else{
            handleAlert('error', res.data.msg)
          }
        })
      }).catch((error)=>{})
    },
    handleSelectionChange(val){
      this.syncList = val
    },
    // 批量同步
    batchSyncClick(){
      var list = []
      this.syncList.forEach(function (item) {
        list.push(item.id)
      })
      if(list.length == 0){
        handleAlert('error','请选择需要同步的目录')
        return
      }
      var param  = {
        ids: list.join(',')
      }
      this.$confirm('确定目录同步?', '目录同步', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        synchronizeDirs(param).then(res => {
          if(res.data.code == '100'){
            handleAlert('success', '目录同步成功')
            this.dialogFormVisible = false
            this.dataList()
          }else{
            handleAlert('error', res.data.msg)
          }
        })
      }).catch((error)=>{})
    },
    foldClick(){
      $(".foldArea").hide();
      $(".openArea").show();
      $(".issueCenter").css("width", "calc(79% - 30px)")
      $(".issueInfo").css({"width": "30px","border-left": "2px solid #1890ff"})
    },
    openClick(){
      $(".foldArea").show();
      $(".openArea").hide();
      $(".issueCenter,.issueInfo").removeAttr("style")
    },
    // 目录类型
    getChapterTypeList(){
      catalogTypeList('2').then(res => {
        this.chapterTypeList = res.data.data
      }).catch(e => {
        this.chapterTypeList = []
      })
    },
  },
  mounted() {
    var _this = this;
    _this.isComposite = this.$route.params.isComposite
    _this.getChapterTypeList()
    _this.dataList()
    _this.handelTopicType()
    contentSize();
  },
}
</script>
<style>
.releaseContainer .el-dialog{
  height: 78%;
}
.el-dialog[aria-label="维护主题类型"]{
  height: auto;
  max-height: 78%;
}
.dialogCatalog>div>div:last-child{
  height:calc(100% - 50px) !important
}
.dialogCenter>div:last-child{
  height:calc(100% - 90px) !important
}
.releaseContainer .struckThemeDetail{
  overflow-y: auto
}
.infoDetail  .dialogCatalog .el-scrollbar {
  height: calc(100% - 10px) !important;
}
.infoDetail .issueCenter {
  display: flex;
}
.infoDetail .issueCenter .issueDetail {
  flex: 1;
}
.issueInfo .flodTitle .foldBut,
.issueInfo .openTitle .openBut{
  cursor: pointer;
}
.issueInfo .flodTitle .foldBut .el-icon-d-arrow-right:before,
.issueInfo .openTitle .openBut .el-icon-d-arrow-left:before{
  font-weight: bold;
  color: var(--theme-color);
}
.dialogCenter #iframe{
  height: calc(100% - 48px);
}
.infoDetail .issueInfo .foldArea{
  height: 100%;
}
.infoDetail .issueInfo .foldArea .flodTitle {
  background: #fafafa;
  border-bottom: 1px solid #cfd5de;
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.issueInfo .openArea{
  display: none;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
.issueInfo .openBut{
  margin-bottom: 10px;
}
.flodTitle div{
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dialogCatalog .el-select{
  vertical-align: middle !important;
}
.dialogCatalog .el-dialog .el-select {
  margin-top: -3px !important;
}
.themeTypeTtile>div{
  display: inline-block;
  margin-bottom: 15px;
}
.themeTypeTtile>div>span{
  display: inline-block;
  height: 35px;
  line-height: 35px;
  margin-right: 8px;
}
.el-dialog .themeTypeTtile> div .el-select{
  width: 210px !important;
  margin-right:20px;
}
.themeTypeTtile .el-button{
  padding: 9px 15px
}

</style>
