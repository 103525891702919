<template>
  <el-container>
    <el-header>
      <common-header></common-header>
    </el-header>
    <el-container>
      <sidebar></sidebar>
      <el-container>
        <el-main id="mainContainer">
          <tabNav></tabNav>
          <div class="centerArea">
            <keep-alive :include="catchComponents">
              <router-view></router-view>
            </keep-alive>
          </div>
        </el-main>
        <reminder></reminder>
        <!-- <el-footer>
          <div class="footerArea"> -->
            <!-- <p>Copyright © 2015.鑫源汽车官方网站 All rights reserved.</p>
            <p>渝ICP备13005065号-1   渝ICP备13005065号-2</p>
            <p>
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010202001019"
              >
                <img src="../assets/image/headerIcon/beian.png" alt="" />
                渝公网安备 50010202001019号
              </a>
            </p> -->
          <!-- </div>
        </el-footer> -->
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
import commonHeader from "@/components/header/header.vue";
import tabNav from "@/components/navTab/navTabs.vue";
import sidebar from "@/components/Sidebar/index.vue";
import reminder from "@/components/infoReminder/reminder.vue";
import {mapState} from 'vuex';
import $ from 'jquery';
import {onLogin, currentUserInfo, captCha, getUserInfo} from '@/api/sysmgt.js'
import { handleAlert, languageType, contentSize } from '@/assets/js/common.js'
import {initWebSocket} from '@/assets/js/real_message.js'
export default {
  name: "home",
  components: {
    commonHeader,
    tabNav,
    sidebar,
    reminder
  },
  computed: {
    ...mapState({
      catchComponents: (state) => state.catchComponents,
    }),
  },
  created() {
    if (sessionStorage.getItem("store")) {
      var selectPath = JSON.parse(sessionStorage.getItem('store'))["selectTabPath"];
      if(selectPath != undefined) {
        this.$store.state.selectTabPath = '/dashboard'
      }
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  methods: {
    // 高度
    heightArea() {
      setTimeout(() => {
        var allHeight = $("#app").outerHeight(true);
        var headerHeight = $(".headerContent").outerHeight(true);
        var mainVal = allHeight - headerHeight;
        $(".el-main").css("hieght", mainVal);
        var distance = mainVal - $('.tabContent').outerHeight(true)
        $('.centerArea').css('height', distance);
        if($('.leftData').length !== 0){
          contentSize()
        }
      }, 50);
    },
    areaSize() {
      var _this = this
      _this.heightArea()
      window.addEventListener('resize', function () {
        _this.heightArea()
      })
    },
    getUserDetail() {
      currentUserInfo().then(res => {
        if (res.data.code == 100) {
          var list = res.data.data;
          this.$store.commit('SET_ROLES', list.roleList)
          this.$store.commit('SET_PERMS', list.permissionList)
          if (document.getElementById("theme") == null) {
            let link = document.createElement("link");
            link.type = "text/css";
            link.id = "theme";
            link.rel = "stylesheet";
            if (
              list.theme == "black" ||
              list.theme == undefined ||
              list.theme == null ||
              list.theme == ""
            ) {
              link.href = "./static/theme/themeBlack.css";
              $("body").addClass("blackTheme");
            } else if (list.theme == "blue") {
              link.href = "./static/theme/themeBlue.css";
              $("body").removeClass("blackTheme");
            } else if (list.theme == "cyan") {
              link.href = "./static/theme/themeCyan.css";
              $("body").removeClass("blackTheme");
            } else if (list.theme == "green") {
              link.href = "./static/theme/defaultTheme.css";
              $("body").removeClass("blackTheme");
            }else{
              link.href = "./static/theme/themeBlack.css";
              $("body").removeClass("blackTheme");
            }
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          languageType()
        }
      }).catch(err => {
        if (sessionStorage.token) {
          currentUserInfo().then(res => {
            if (res.data.code == 100) {
              var list = res.data.data;
              this.$store.commit('SET_ROLES', list.roleList)
              this.$store.commit('SET_PERMS', list.permissionList)
            }
          })
        } else {
          handleAlert('error', '系统开小差...')
        }
      })
    },
  },
  mounted() {
    this.areaSize()
    this.getUserDetail();
  }
}
</script>
<style>
.el-main {
  overflow: hidden !important;
  padding: 0 !important;
  background: rgb(235,237,241);
}
.centerArea {
  overflow: hidden;
}

/* 底部导航 */
#app .el-footer {
  height: 40px !important;
  box-sizing: border-box;
  line-height: 40px !important;
  color: #000;
  font-size: 13px;
  border-top: 2px solid #eee;
}
.footerArea {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerArea p {
  margin: 0 5px;
  white-space: nowrap;
}
.footerArea p:last-child {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.footerArea p a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
}
.footerArea p img {
  width: 15px;
  margin-right: 10px;
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  #app .el-footer {
    height: 36px !important;
    line-height: 36px !important;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1280px) {
  #app .el-footer {
    height: 32px !important;
    line-height: 32px !important;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
  #app .el-footer {
    height: 32px !important;
    line-height: 32px !important;
  }
}

@media screen and (max-width: 1024px) {
  #app .el-footer {
    height: 32px !important;
    line-height: 32px !important;
  }
}
</style>
