import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // 引入element语言包
const cn = {
  login: {
    title: '智能车联云平台',
    userName: '用户名:',
    password: '密码:',
    code: '验证码:',
    userTip: '用户名不能为空',
    passTip: '密码不能为空',
    codeTip: '验证码不能为空',
    userInput: '请输入用户名',
    passInput: '请输入密码',
    codeInput: '请输入验证码',
    button: '确定'
  },
  nav: {
    signOut: '退出',
    data: '基本资料',
    change: '修改密码'
  },
  catalogue: {
    oneTitle: '首页'
  },
  dashboard: {
    layTitle: '个人页',
    subTitle: '工作台',
    oneGreet: '早上好！',
    twoGreet: '中文好！',
    threeGreet: '下午好！',
    fourGreet: '晚上好！',
    tipTitle: '新的一天要努力工作哦~',
    myProject: '我的项目',
    Incomplete: '未完成的项目',
    defer: '已延期的项目',
    pTitle: '进行中的项目',
    rTitle: '全部项目',
    dTitle: '动态'
  },
  foot: {
    name: ''
  },
  ...zhLocale
}
export default cn
