import { render, staticRenderFns } from "./bulletinDetail.vue?vue&type=template&id=4b8b481a"
import script from "./bulletinDetail.vue?vue&type=script&lang=js"
export * from "./bulletinDetail.vue?vue&type=script&lang=js"
import style0 from "./bulletinDetail.vue?vue&type=style&index=0&id=4b8b481a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports