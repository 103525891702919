import release from '@/views/releasemgt/issue/release.vue'
import releaseCatalog from '@/views/releasemgt/issue/releaseCatalog.vue'
import pushList from '@/views/releasemgt/issue/pushList.vue'
const releasemgtRouter = [
  {
    path: '/release/:id/:projectId/:outputType',
    name: 'release',
    component: release
  },
  {
    path: '/releaseCatalog/:basicId/:projectId/:manualType/:outputType/:isComposite',
    name: "releaseCatalog",
    component: releaseCatalog
  },
  {
    path: '/pushList/:id/:projectId/:manualId/:manualVersion',
    name: 'pushList',
    component: pushList
  },
  {
    path: '/releasemgt/issue/list',
    component: () => import('@/views/releasemgt/issue/list'),
    name: 'releasemgtissuelist'
  },
  {
    path: '/releasemgt/publishSet/list',
    component: () => import('@/views/releasemgt/publishSet/list'),
    name: 'releasemgtpublishSetlist'
  },
  {
    path: '/releasemgt/pushSet/list',
    component: () => import('@/views/releasemgt/pushSet/list'),
    name: 'releasemgtpushSetlist'
  },
  {
    path: '/releasemgt/style/list',
    component: () => import('@/views/releasemgt/style/list'),
    name: 'releasemgtstylelist'
  },
  {
    path: '/releasemgt/parse/list',
    component: () => import('@/views/releasemgt/parse/list'),
    name: 'releasemgtparselist'
  }
]
export default releasemgtRouter;
