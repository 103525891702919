import enLocale from 'element-ui/lib/locale/lang/en' // 引入element语言包
const en = {
  login: {
    title: 'Intelligent Vehicle Connected Cloud Platform',
    userName: 'UseName:',
    password: 'Password:',
    code: 'Captcha:',
    userTip: 'User name cannot be empty',
    passTip: 'Password cannot be empty',
    codeTip: 'Verification code cannot be empty',
    userInput: 'Please enter your username',
    passInput: 'Please enter your password',
    codeInput: 'Please enter your authentication code',
    button: 'Cancel'
  },
  nav: {
    signOut: 'Sign out',
    data: 'Base information',
    change: 'Change password'
  },
  catalogue: {
    oneTitle: 'Home Page'
  },
  dashboard: {
    layTitle: 'Personal page',
    subTitle: 'Workbench',
    oneGreet: 'Good morning!',
    twoGreet: 'Good noon!',
    threeGreet: 'Good afternoon!',
    fourGreet: 'Good evening!',
    tipTitle: 'Work hard in the new day~',
    myProject: 'My projects',
    Incomplete: 'Unfinished projects',
    defer: 'defer projects',
    pTitle: 'Ongoing projects',
    rTitle: 'All items',
    dTitle: 'Dynamic'
  },
  foot: {
    name: '渝公网安备 50009802001190号 ICP备 09002790号-2'
  },
  ...enLocale
}
export default en
