
// =========== 手册管理 ============
import { post,  get, down, download,getByArrayBufferRespose,formPost } from "../plugins/request";
import { sysServerUrl, cmsServerUrl } from '../assets/js/common'
import { param } from "jquery";




// =========== 评分管理 ============
// 界面评价-分页查询
export const commentData = (params) => post(cmsServerUrl + 'manual/comment/findPage', params); 
// 界面评价-修改状态
export const commentStatus = (params) => post(cmsServerUrl + 'manual/comment/handle', params);
// 界面评价-删除记录
export const commentDel = (params) => post(cmsServerUrl + 'manual/comment/del', params);
// 界面评价-获取详情
export const commentInfo = (params) => post(cmsServerUrl + 'manual/comment/info/' + params);
// 界面评价-获取模块
export const commentModule = (params) => get(cmsServerUrl + 'release/api/type');
// 界面评价-获取手册
export const commentManual = (params) => get(cmsServerUrl + 'front/message/manual');




// =============== 反馈管理
// 在线反馈-分页查询
export const feedbackData = (params) => get(sysServerUrl + 'sys/epc/feedback/list', params);
// 在线反馈-获取详情
export const feedbackInfo = (params) => get(sysServerUrl + 'sys/epc/feedback/editPage', params);
// 在线反馈-删除
export const feedbackDel = (params) => post(sysServerUrl + 'sys/epc/feedback/del', params);
// 在线反馈-回复
export const feedbackReply = (params) => post(sysServerUrl + 'sys/epc/feedback/reply', params);
// 在线反馈-问题类型
export const feedbackTypeList = (params) => get(sysServerUrl + 'sys/epc/feedback/getFeedbackType'); 

export const feedbackTreeList = (params) => get(sysServerUrl + 'sys/car/train/getBrandTrainList', params);

export const feedbackLangeList = (params) => get(sysServerUrl + 'sys/dict/query?dictType=language', params); 
export const feedbackTranslate = (params) => post(sysServerUrl + 'sys/epc/feedback/translate', params);
// 在线反馈-结束反馈
export const feedbackEnd = (params) => post(sysServerUrl + 'sys/epc/feedback/end/' + params);   








// =========== 翻译管理 ===========

export const requestTranslateList = (params) => get(sysServerUrl + 'translateMgt/listTranslate', params);   //条件查询翻译列表

export const requestlangList = (params) => get(sysServerUrl + 'translateMgt/langList', params);   //获取所有语言列表


export const requestTranslateBusinessList = (params) => get(sysServerUrl + 'translateMgt/translateBusinessList', params);   //获取所有业务类型列表


export const requestExportUnTranslate = (params) => get(sysServerUrl + 'translateMgt/exportUnTranslate', params);   //获取所有业务类型列表

export const requestImportTranslate = (params) => formPost(sysServerUrl + 'translateMgt/importTranslate', params);   //获取所有业务类型列表



export const requestProcessList = (params) => get(sysServerUrl + 'translateMgt/processList', params);   //获取处理进展列表


export const requestDeleteProcessRecord = (params) => get(sysServerUrl + 'translateMgt/deleteProcessRecord', params);   //请求删除处理进展



export const requestDownLoadFile = (params) => getByArrayBufferRespose(sysServerUrl + 'translateMgt/downloadFile', params);   //请求下载文件


export const requestAddNew = (params) => post(sysServerUrl + 'translateMgt/addNew', params);   //新增一个翻译

export const requestEditTranslate = (params) => post(sysServerUrl + 'translateMgt/editTranslate', params);   //编辑某个翻译

export const requestDeleteOne = (params) => get(sysServerUrl + 'translateMgt/delTranslate', params);   //删除某个翻译