import addNotice from '@/views/sysmgt/notice/addNotice'
import noticeDetail from '@/views/sysmgt/notice/noticeDetail'
import appVersion from '@/views/sysmgt/app/versionList'


const sysmgtRouter = [
  {
    path: '/sysmgt/dict/list',
    component: () => import('@/views/sysmgt/dict/list'),
    name: 'sysmgtdictlist'
  },
  {
    path: '/sysmgt/user/list',
    component: () => import('@/views/sysmgt/user/list'),
    name: "sysmgtuserlist"
  },
  {
    path: '/sysmgt/tenant/list',
    component: () => import('@/views/sysmgt/tenant/list'),
    name: "sysmgttenantlist"
  },
  {
    path: '/sysmgt/dealer/list',
    component: () => import('@/views/sysmgt/dealer/list'),
    name: "sysmgtdealerlist"
  },
  {
    path: '/sysmgt/role/list',
    component: () => import('@/views/sysmgt/role/list'),
    name: 'sysmgtrolelist'
  },
  {
    path: '/sysmgt/menu/list',
    component: () => import('@/views/sysmgt/menu/list'),
    name: 'sysmgtmenulist'
  },
  {
    path: '/sysmgt/oauth/list',
    component: () => import('@/views/sysmgt/oauth/list'),
    name: 'sysmgtoauthlist'
  },
  {
    path: '/sysmgt/department/list',
    component: () => import('@/views/sysmgt/department/list'),
    name:'sysmgtdepartmentlist'
  },
  {
    path: '/sysmgt/notice/list',
    component: () => import('@/views/sysmgt/notice/list'),
    name: 'sysmgtnoticelist'
  },
  {
    path: '/addNotice/:id',
    component:addNotice,
    name: 'addNotice'
  },
  {
    path: '/noticeDetail/:id',
    component:noticeDetail,
    name: 'noticeDetail'
  },
  {
    path: '/sysLogMgt/list',
    component:()=>import('@/views/sysmgt/log/list'),
    name: 'sysLogMgtList'
  },
  {
    path: '/sysmgt/region/list',
    component:()=>import('@/views/sysmgt/region/list'),
    name: 'sys_region_list'
  },
  {
    path: '/sysmgt/app/list',
    component: () => import('@/views/sysmgt/app/list'),
    name: 'sysmgtappBasiclist'
  },
  {
    path: '/appVersion',
    component:appVersion,
    name: 'appVersion'
  }
]
export default sysmgtRouter;
