<template>
  <div class="layoutContainer">
    <div class="noticeContent">
      <!-- 标题 -->
      <div class="title">{{result.title}}</div>
      <!-- 通告信息 -->
      <div class="datum">
        <!-- <span>状态：{{ result.status }}</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
        <span>车系：{{ result.trainName }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>年款：{{ result.trainYear }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>车型：{{ result.modelName }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span v-if="result.mileage">里程：{{ result.mileage }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>创建时间：{{ result.createdTime | conversion("yyyy-MM-dd") }}</span>
      </div>
      <div>&nbsp;</div>
      <!-- 现象 -->
      <div>
        <div style="font-size: 16px; font-weight: bold;">故障现象</div>
        <div v-html="result.phenomena"></div>
      </div>
      <div>&nbsp;</div>
      <!-- 过程 -->
      <div >
        <div style="font-size: 16px; font-weight: bold;">维修过程</div>
        <div v-html="result.process"></div>
      </div>
      <div>&nbsp;</div>
      <!-- 总结 -->
      <div >
        <div style="font-size: 16px; font-weight: bold;">维修总结</div>
        <div v-html="result.summary"></div>
      </div>
      <!-- 附件 -->
      <div>&nbsp;</div>
      <div >
        <el-button type="primary" icon="el-icon-download" v-if="result.path && result.path.length>0" @click="lookClick(result.path)">查看附件</el-button>
      </div>
    </div>

  </div>
</template>
<script>
import { caseInfo } from '@/api/material.js'
import axios from 'axios'
export default {
  name: 'maintainDetail',
  data () {
    return {
      id: '',
      result: {},
    }
  },
  watch: {

    // 利用watch方法检测路由变化
    $route: function(to, from) {
      if (to.path !== from.path) {
      this.getInfo();
      }
    }
  },
  methods: {
    // 获取明细
    getInfo(){
      if (!this.$route.query.id) {
        return false
      }
      caseInfo(this.$route.query.id).then(res=>{
        this.result = res.data.data
        // console.log("-=-=-", this.result);
      })
    },
   // 查看附件
   lookClick(path){
      if (path.length<=0) {
        handleAlert('warning','没有附件可以查看')
        return false
      }
      window.open(path, '_blank');

    },
  },
  mounted () {
    this.id = this.$route.query.id
    this.getInfo();
  }
}
</script>
