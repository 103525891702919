import axios from 'axios'
import qs from "qs";

export function post(url, parameter, onUploadProgress) {
  return axios({
    url: url,
    method: 'post',
    data: parameter,
    onUploadProgress
  })
}

export function get(url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter
  })
}

export function getWithArray(url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter,
    paramsSerializer: params => {
      return qs.stringify(params, {indices: false})
    }
  })
}
export function del(url, parameter) {
  return axios({
    url: url,
    method: 'delete',
    params: parameter
  })
}

export function put(url, parameter) {
  return axios({
    url: url,
    method: 'put',
    data: parameter
  })
}

export function down(url, parameter) {
  return axios({
    url: url,
    responseType: 'blob',
    method: 'get',
    data: parameter
  })
}

export function download(url, parameter) {
  return axios({
    url: url,
    responseType: 'blob',
    method: 'post',
    data: parameter
  })
}

/**
 * 支持字节数组响应的请求
 * @param {*} url
 * @param {*} parameter
 * @returns
 */
export function getByArrayBufferRespose(url, parameter) {
  return axios({
    url: url,
    method: 'get',
    responseType: 'arraybuffer',
    params: parameter
  })
}

export function formPost(url, parameter, onUploadProgress) {
  return axios({
    url: url,
    method: 'post',
    headers: {"content-type": "multipart/form-data; charset=utf-8;"},
    data: parameter,
    onUploadProgress
  })
}
