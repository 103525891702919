<template>
    <div class="layoutContainer">
      <div class="secondFloat">
        <el-form :inline="true" label-width="65px" class="demo-form-inline">

          <el-form-item label="手册类型" prop="manual">
            <el-select v-model="manual" placeholder="请选择手册类型" clearable filterable>
              <el-option v-for="item in manualList" :key="item.id" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="日期范围">
                  <el-date-picker :clearable="false" :editable="false" prop="start" align="center" value-format="yyyy-MM-dd" type="date" placeholder="开始日期" v-model="valueDate.start" :picker-options="startTime"></el-date-picker>
                  <span class="line">至</span>
                  <el-date-picker :clearable="false" :editable="false" prop="end" align="center" value-format="yyyy-MM-dd" type="date" placeholder="结束日期" v-model="valueDate.end" :picker-options="endTime"></el-date-picker>
              </el-form-item>
          <el-form-item >
                <el-button type="primary" @click="onSubmitSecond" icon="el-icon-search">搜索</el-button>
                <el-button plain @click="resetFirst()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>


      <div class="tableDetail">
        <div class="tableHandle">
          <span>国家:</span><span>&nbsp;&nbsp;{{ access.country }}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span>服务店名称:</span><span>&nbsp;&nbsp;{{ access.realName }}</span>
        </div>
        <!-- 分页显示列表 -->
        <!-- <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" @selection-change="handleSelectionChange"> -->
        <el-table
          style="width:100%"
          border
          stripe
          highlight-current-row
          :max-height="maximumHeight"
          :data="resultList"
          :header-cell-style="{
            'text-align': 'center',
            'background-color': 'var(--other-color)',
          }"
        >
          <el-table-column label="序号" type="index" width="60"  align="center"></el-table-column>
          <el-table-column label="手册类型" prop="manualName" min-width="60" show-overflow-tooltip>
            <template slot-scope="{row}">
                <span>{{ getManualName(row.manualType) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="分类" prop="targetName" min-width="150" show-overflow-tooltip></el-table-column>
          <el-table-column label="访问次数" prop="count" align="center" width="150"  show-overflow-tooltip></el-table-column>
          <el-table-column label="日期(天)" prop="day" align="center" width="150" >
            <template slot-scope="{row}">
                <span>{{ dateFormat( new Date(row.day)) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>

      </div>
    </div>
  </template>
  <script>

  import {  handleAlert, tableHeight, tableMaxHeight, getMyDate } from '@/assets/js/common.js'
  import Pagination from '@/components/Pagination'
  import { serviceDetail, commentManual } from '@/api/statistics.js'
  import axios from 'axios'

  export default {
    name: 'statistics_service_detail',
    // components: { Pagination ,SelectTree },
    components: { Pagination },
    data () {
      return {

        // 时间段
        valueDate: {
                  start: '',
                  end: '',
              },
        flag: '1',
        start: '',
        end: '',


        // 手册
        manualList: [],
        manual: "",

        access: {
          realName: '',
          country: '',
          start:'',
          end: ''
        },

        // 当前页
        currentPage: 1,
        // 每页显示的条数
        pagesize: 15,
        // 总条数
        total: 0,
        // 数据集合
        resultList: [],

        formLabelWidth: '100px',
        maximumHeight: 0,

        // true: 国内；false: 海外
        empowerChina: false,
        startTime: {
                disabledDate: (time) => {
                return this.valueDate.end != null ? time.getTime() > new Date(this.valueDate.end) : false //只能选结束日期之前的日期
                //返回---结束时间是否有值？   可选时间小于结束时间   ：  任意时间都可选
                }
            },
            endTime: {
                disabledDate: (time) => {
                return this.valueDate.start != null ? time.getTime() < new Date(this.valueDate.start) : false //只能选开始时间之后的日期
                //返回---开始时间是否有值？   可选时间大于开始时间   ：  任意时间都可选
                }
            },
      }
    },
    methods: {
        // 获取手册
        getManualList(){
            this.manualList = []
            commentManual().then(res => {
                this.manualList = res.data.data
            }).catch(e => {
                this.manualList = []
            })
        },

      // 重置
      resetFirst(){
          this.currentPage = 1;
          this.valueDate = {
                  start: '',
                  end: ''
              };
              this.manual = ''
          this.onSubmitSecond()
      },
      // 搜索
      onSubmitSecond(){
        this.currentPage = 1;
        this.dataList()
      },


      // 分页查询数据
      dataList () {
        this.$loading.show();
        var params = new URLSearchParams();
        params.append('page', this.currentPage);
        params.append('limit', this.pagesize);
        params.append('start', this.valueDate.start ? getMyDate(this.valueDate.start) : '')
        params.append('end', this.valueDate.end ? getMyDate(this.valueDate.end) : '')
        params.append('realName', this.access.realName)
        params.append('manualType', this.manual)
        serviceDetail(params).then(res => {
          this.total = res.data.total    // 总条数
          this.resultList = res.data.data   // 数据
          this.tableHeightArea();
          this.$loading.hide();
        })
      },


      getManualName(type){
        let name = ""
        for (let i = 0; i < this.manualList.length; i++) {
            const element = this.manualList[i];
            if (element.code == type) {
                name = element.name;
                break
            }
        }
        return name;
      },

      // 搜索
      keyDown(e) {
        if (e.keyCode === 13) {
          this.onSubmitSecond()
        }
      },

      maximumArea() {
        setTimeout(() => {
          tableHeight()
          setTimeout(() => {
            this.maximumHeight = tableMaxHeight;
          }, 50)
        }, 50)
      },
      tableHeightArea() {
        var _this = this;
        _this.maximumArea();
        window.addEventListener("resize", function () {
          _this.maximumArea();
        });
      },
      dateFormat (date) {
              const Y = date.getFullYear() + '-'
              const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
              const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
              return Y + M + D
          },

        getCache(){
            let obj = JSON.parse(sessionStorage.getItem('access'))
            this.access.country = obj.country
            this.access.realName = obj.realName
        },

    },
    mounted () {
      // 获取缓存
      this.getCache()
      // 获取手册
      this.getManualList()

      // 获取明细
     this.onSubmitSecond()


      this.resetFirst()
      window.addEventListener('keydown', this.keyDown);
    },
    destroyed() {
      window.removeEventListener('keydown', this.keyDown, false);
    },
    watch: {
        $route(to) {
            if (to.name == 'statistics_service_detail') {
                this.reset()
            }
        }
    },

  }
  </script>
  <style>
  .fileImg {
    width: 100%;
    height: 100%;
  }

  .image{
  /*设置图片宽度和浏览器宽度一致*/
    width:100%;
    height:inherit;
  }

  .el-tooltip__popper {
      max-width: 800px;
  }

  /* 设置输入框的长度 */
  /* .el-dialog .el-input {
      width: 100% !important;
  } */

  .el-progress-bar__innerText {
    color: #fff;
  }

  #improt_info{
    margin: 0px;
  }

  .el-message-box__message {
      overflow-x: auto;
      max-height: 500px;
    }
  </style>
