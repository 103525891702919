<template>
  <div class="layoutContainer">
    <div class="bulletinDetail">
      <div class="title">{{bulletinDetail.title}}</div>
      <div class="subTitle">
        <span>发布时间：{{bulletinDetail.time | conversion("yyyy-MM-dd HH:mm")}}</span>
        <span>发布人：{{bulletinDetail.username}}</span>
      </div>
      <div class="content">
        <div v-html="bulletinDetail.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { systemBulletinInfo, systemBulletinRead } from '@/api/sysmgt.js'
export default {
  name:"bulletinDetail",
  data(){
    return{
      bulletinId:"",
      bulletinDetail:[],
    }
  },
  methods:{
    bulletinInfo(){
      systemBulletinInfo(this.bulletinId).then(res =>{
        this.bulletinDetail = res.data.data
        // 2023-09-05 修改公告已读
        systemBulletinRead(this.bulletinId).then(res =>{})
      })
    }
  },
  mounted(){
    this.bulletinId = this.$route.params.id
    this.bulletinInfo()
  }
}
</script>
<style >
  .bulletinDetail .title {
    text-align: center;
    font-size: 20px;
    font-weight:bold;
    line-height: 1.6;
    word-break: break-all;
  }
  .bulletinDetail .subTitle {
    text-align: center;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    color: #666;
    margin: 5px 0;
  }
  .bulletinDetail .subTitle span{
    margin:  0 10px;
  }
  .bulletinDetail .content{
    color:#333;
    font-size: 15px;
    line-height: 1.6;
  }

</style>
