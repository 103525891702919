<template>
  <div class="layoutContainer">
    <div class="tableDetail">
      <el-button type="primary" v-if="false" icon="el-icon-plus" @click="newAdd">新增</el-button>
      <el-table
        style="width:100%"
        border
        stripe
        ref="table"
        highlight-current-row
        :max-height="maximumHeight"
        :data="resultList"
        :header-cell-style="{
          'text-align':'center',
          'background-color': 'var(--other-color)',
        }"
        @header-dragend="changeColWidth"
      >
        <el-table-column label="版本号" prop="version" min-width="150"></el-table-column>
        <el-table-column label="是否默认版本" prop="isLatest" min-width="80" align="center">
          <template slot-scope="{row}">
            <span v-if="row.isLatest === true" class="successColor">是</span>
            <span v-if="row.isLatest === false" class="errorColor">否</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" min-width="150"></el-table-column>
        <el-table-column label="创建时间" prop="createdTime" align="center" :formatter="dateFormat" min-width="100"></el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="{row}">
            <el-button type="text" v-if="hasPerm('menuAsimssCMS3A2B_111') && row.isLatest != 1" size="mini" @click="setDefault(row)">设置默认</el-button>
            <el-button type="text" v-if="hasPerm('menuAsimssCMS3A2B_103')" size="mini" @click="handelEdit(row)">编辑</el-button>
            <el-button type="text" v-if="hasPerm('menuAsimssCMS3A2B_102')" size="mini"  @click="handeDel(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form :model="temp" ref="temp" label-position="center" :rules="rules" :label-width="formLabelWidth">
          <el-form-item label="版本号" prop="version">
            <el-input v-model.trim="temp.version" placeholder="请输入版本号" disabled></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model.trim="temp.remark" placeholder="请输入备注" show-word-limit maxlength="100"></el-input>
          </el-form-item>
          <el-form-item class="submitArea">
            <el-button type="primary" @click="dialogStatus === 'add' ? addClick('temp') : editClick('temp')">立即提交</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 手册管理-手册目录-增加接口有问题(接口是编辑的接口)
import { cmsServerUrl, handleAlert, tableHeight, tableMaxHeight } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { versionData, versionAdd, versionDefault, versionDel } from '@/api/cmsmgt.js';
import axios from 'axios'
export default {
  name: 'version',
  components: { Pagination },
  data () {
    return {
      manualId: '',
      resultList: [],
      temp: {
        id: '',
        mId: '',
        version: '',
        remark: ''
      },
      textMap: {
        edit: '编辑',
        add: '新增版本'
      },
      dialogFormVisible: false,
      dialogStatus: '',
      maximumHeight: 0,
      formLabelWidth: '100px',
      pagesize: 20,
      currentPage: 1,
      total: 0,
      rules: {
        version: [{ required: true, message: '请输入版本号', trigger: ['blur', 'change'] }],
        remark: [{ required: true, message: '请输入备注', trigger: ['blur', 'change'] }]
      }
    }
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "version") {
        _this.manualId = to.params.id;
        _this.dataList();
      }
    }
  },
  methods: {
    // 监听表格列宽变化
    changeColWidth(){
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      })
    },
    dataList () {
      this.$loading.show();
      var params = '?manualId=' + this.manualId;
      versionData(params).then(res => {
        this.resultList = res.data.data;
        this.total = res.data.total;
        this.tableHeightArea();
        this.$loading.hide();
      });
    },
    resetTemp () {
      this.temp = {
        version: '',
        remark: ''
      }
      this.$nextTick(function() {
        this.$refs.temp.clearValidate();
      })
    },
    // 新增
    newAdd () {
      this.resetTemp()
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          if (this.temp.id === undefined) {
            params.append('id', '0')
          } else {
            params.append('id', '')
          }
          if (this.manualId === undefined) {
            params.append('manualId', '')
          } else {
            params.append('manualId', this.manualId)
          }
          params.append('version', this.temp.version)
          params.append('remark', this.temp.remark)
          versionAdd(params).then(res =>{
            if (res.data.code === 100) {
              handleAlert('success','新增成功!')
              this.dataList()
              this.dialogFormVisible = false
            } else {
              handleAlert('error','提交失败，请重试')
            }
          })
        } else {
          handleAlert('error','请完善信息')
        }
      })
    },
    // 编辑
    handelEdit (row) {
      this.resetTemp()
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
      this.temp.version = row.version
      this.temp.remark = row.remark
    },
    editClick (temp) {
      this.$refs[temp].validate((valid) => {
        if(valid){
          this.$loading.show();
          var params = new URLSearchParams()
          if (this.temp.mId === undefined) {
            params.append('manualId', '')
          } else {
            params.append('manualId', this.temp.mId)
          }
          params.append('id', this.temp.id)
          params.append('version', this.temp.version)
          params.append('remark', this.temp.remark)
          versionAdd(params).then(res => {
            if (res.data.code === 100) {
              handleAlert('success','保存成功')
              this.dataList()
              this.dialogFormVisible = false
            } else {
              handleAlert('error','提交失败，请重试')
            }
            this.$loading.hide();
          })
        } else {
          handleAlert('error','请完善信息');
          this.$loading.hide();
        }
      })
    },
    // 重置
    resetForm (temp) {
      if (this.$refs[temp].resetFields() !== undefined) {
        this.$refs[temp].resetFields()
      }
    },
    // 默认版本
    setDefault (row) {
      this.$confirm('确定设置 【' + row.version + '】版本为默认版本吗?', '设置版本', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var params = new URLSearchParams()
        params.append('id', row.id)
        params.append('manualId', row.manualId)
        versionDefault(params).then(res => {
          if (res.data.code === 100) {
            handleAlert('success','设置默认版本成功')
            this.dataList()
          } else {
            handleAlert('error','设置默认版本版本失败')
          }
        })
      }).catch((error)=>{})
    },
    // 删除
    handeDel (row) {
      this.$confirm('确定删除【' + row.version + '】的版本信息?', '删除版本', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        versionDel(row.id).then(res => {
          if (res.data.code === 100) {
            handleAlert('success','删除成功')
            if(this.total > 1){
              if(this.resultList!=null&&this.resultList.length == 1){
                this.currentPage =this.currentPage-1
              }
            }
            this.dataList()
          }else{
            handleAlert('error','删除失败')
          }
        })
      }).catch((error)=>{})
    },
    // 时间转换
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    },
     maximumArea() {
      setTimeout(() => {
        tableHeight()
        setTimeout(() => {
          this.maximumHeight = tableMaxHeight;
        }, 50)
      },50)
    },
    tableHeightArea() {
      var _this = this;
      _this.maximumArea();
      window.addEventListener("resize", function () {
        _this.maximumArea();
      });
    }
  },
  mounted () {
    this.manualId = this.$route.params.id
    this.dataList()
  }
}
</script>
