<template>
  <div class="layoutContainer">
    <div class="noticeContent">
      <div class="datum-manual" v-if="result == null">
        <span>暂无数据</span>
      </div>
      <!-- 名称 -->
      <div class="title-manual" v-if="result != null">{{result.name}}</div>
      <!-- 信息 -->
      <div class="datum-manual" v-if="result != null">
        <!-- <span>车系：{{result.trainName}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>年款：{{result.modelYear}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>类型：{{result.typeName}}</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
        <!-- <span>语种：{{result.languageName}}</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
        <span>更新人员：{{result.username}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>更新时间：{{result.updatedTime | conversion("yyyy-MM-dd HH:mm:ss")}}</span>
        <!-- <span>状态：{{getType(this.result.status)}}</span><br> -->
      </div>
      <!-- 描述 -->
      <div class="subhead-manual" v-if="result != null">{{(result.remark == "" || result.remark == null) ? "": result.remark}}</div>
      <!-- 内容 -->
      <div class="content-manual" v-if="result != null">
        <iframe
          id="ifram"
          :src="url"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, handleAlert } from '@/assets/js/common.js'
import { manualInfo } from '@/api/material.js'
import axios from 'axios'

export default {
  name: 'bulletinDetails',
  data () {
    return {
      id: '',
      result: {
        id: '',  // ID
        name: '',  // 名称
        manualName: '',  // 类型名称
        type: '',  // 手册类型
        typeName: '',
        language: '',   // 语种
        languageName: '',  // 语种名称
        modelYear: '',  // 年款
        trainId: '',  // 车系ID
        trainName: '',
        status: '', // 状态
        path: '', // 手册路径
        remark: '',  // 手册说明
      },
      url:'',
      statusList: [
        { name: '草稿', code: 1 },
        { name: '发布', code: 2 },
      ],
    }
  },
  methods: {
    // 获取通告明细
    getInfo(){
      manualInfo(this.id).then(res=>{
        if(res.data.code == 100){
          this.result = res.data.data
          this.url = "static/pdf/web/viewer.html?file=" + this.result.path;
          this.contentSize();
        }else{
          // handleAlert('error','系统开小差了...')
          this.result = null
        }
      }).catch((error)=>{
        this.result = null
        // handleAlert('error','系统开小差了...')
      })
    },
    getType(type){
      for (let i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].code == type) {
          return this.statusList[i].name;
        }
      }
      return "未知";
    },
    contentHeight() {
      setTimeout(() => {
        var allHeight = $(".noticeContent").height();
        var title = $(".title-manual").outerHeight(true);
        var datum = $(".datum-manual").outerHeight(true);
        var val = allHeight - title - datum - 15;
        $(".content-manual").css("height", val)
      })
    },
    contentSize(){
      var _this = this;
      _this.contentHeight();
      window.addEventListener("resize", function () {
        _this.contentHeight();
      });
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.getInfo();
  }
}
</script>

<style>
  .iframDiv{
    height: 100%;
    line-height: 1.6;
  }
  .title {
    text-align: center;
    font-size: 20px;
    font-weight:bold;
    line-height: 1.6;
    word-break: break-all;
  }
   .datum {
    text-align: center;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    color: #666;
    margin: 5px 0;
  }
   .subhead{
    font-size: 16px;
    line-height: 1.6;
    word-break: break-all;
  }
   .content{
    font-size: 15px;
    line-height: 1.6;
    color: #333;
    height: 700px;
  }
  .noticeContent {
    height: 100%;
    overflow-y: auto;
  }
  .datum-manual{
    text-align: center;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    color: #666;
    margin: 5px 0;
  }

  .content-manual {
    font-size: 15px;
    line-height: 1.6;
    color: #333;
  }
  .subhead-manual {
    font-size: 16px;
    line-height: 1.6;
    word-break: break-all;
  }
  .title-manual {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    word-break: break-all;
  }

</style>
