import { post,  get, down, download } from "../plugins/request";
import { sysServerUrl, cmsServerUrl } from '../assets/js/common'



// 获取手册
export const commentManual = (params) => get(cmsServerUrl + 'front/message/manual');

export const getCarTrainModelList = (params) => post(cmsServerUrl + 'sys/car/model/getTrainModelList', params); 
// 查询 产品类别-车系
export const getCarTrainList = (params) => get(sysServerUrl + 'sys/car/train/tree/list', params);  
// 查询 问题分类
export const getFeedbackList = (params) => get(sysServerUrl + 'sys/epc/feedback/getFeedbackType', params);  


// ========== 分类访问次数
export const visits = (params) => post(cmsServerUrl + 'statistics/evaluate/assembly', params);


// ========== 在线反馈统计 - 反馈分类
export const feedbackClass = (params) => post(cmsServerUrl + 'statistics/feedback/category', params); 

// ========== 在线反馈统计 - 反馈数量
export const feedbackNum = (params) => post(cmsServerUrl + 'statistics/feedback/quantity', params); 


// ========== 反馈评价统计
export const commentProportion = (params) => post(cmsServerUrl + 'statistics/evaluate/commentProportion', params); 

// ========== 服务店访问量
// 查询访问量
export const serviceNumber = (params) => post(cmsServerUrl + 'statistics/access/visits', params); 
// 查询明细
export const serviceDetail = (params) => post(cmsServerUrl + 'statistics/access/detail', params);
// 下载 
export const serviceDown = (params) => download(cmsServerUrl + 'statistics/access/export/visits', params);