const releaseRouter = [
    {
      path: '/release/manual/list',
      component: () => import('@/views/release/manual/list'),
      name: 'release_manual_list'
    },
    {
        path: '/release/tool/list',
        component: () => import('@/views/release/tool/list'),
        name: 'release_tool_list'
    },
    {
    path: '/release/vr/list',
    component: () => import('@/views/release/vr/list'),
    name: 'release_vr_list'
    }
  ]
  export default releaseRouter;