<template>
  <div class="layoutContainer excelContainer">
    <div class="tableDetail">
      <div class="tableHandle">
        <el-button type="text" :disabled="buttonState == false ? false : true" icon="revoke-icon" title="撤销" @click="revokeClick()"></el-button>
        <el-button type="text" :disabled="buttonState == false ? false : true" icon="recovery-icon" title="恢复" @click="recoveryClick()"></el-button>
        <el-button type="text" :disabled="buttonState == false ? false : true" icon="shear-icon" title="剪切" @click="shearClick()"></el-button>
        <el-button type="text" :disabled="buttonState == false ? false : true" icon="copy-icon" title="复制" @click="copyClick()"></el-button>
        <el-button type="text" :disabled="buttonState == false ? false : true" icon="paste-icon" title="粘贴" @click="pasteClick()"></el-button>
        <el-divider direction="vertical"></el-divider>
        <el-dropdown>
          <el-button type="text"><i class="el-icon-upload"></i>导入导出<i class="el-icon-arrow-down el-icon--right"></i></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>导入默认目录</el-dropdown-item>
            <el-dropdown-item>
              <el-upload
                class="upload-demo inline-block"
                ref="batchUpload"
                action="#"
                :show-file-list="false"
                multiple
                :before-upload="onBeforeUpload"
                :http-request="uploadFile"
                accept="xls、xlsx"
              >
                <span>导入excel</span>
              </el-upload>
            </el-dropdown-item>
            <el-dropdown-item>导出excel</el-dropdown-item>
            <el-dropdown-item>导入数据包</el-dropdown-item>
            <el-dropdown-item>导出数据包</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-table
        style="width: 100%"
        border
        ref="table"
        highlight-current-row
        :max-height="maximumHeight"
        :data="tableList"
        default-expand-all
        row-key="id"
        @header-dragend="changeColWidth"
        :header-cell-style="{
          'text-align': 'center',
          'background-color': 'var(--other-color)'
        }"
        :tree-props="{children:'children', hasChildren: 'hasChildren'}"
        :row-style="rowStyle"
      >
        <el-table-column type="index" width="50" align="center"></el-table-column>
        <el-table-column label="大纲管理" min-width="240" prop="name"></el-table-column>
        <el-table-column label="零件编码" min-width="220" prop="partCode">
          <template slot-scope="scope">
            <div v-if="scope.row.partCode == undefined" :class="'noEdit ' + scope.$index + '_3'">
              <i class="noEdit-icon"></i>
            </div>
            <div v-else class="rowEditShow" @click="cellClick(scope, 'partCode')">
              <el-input
                v-if="
                rowEditIndex == scope.$index &&
                colimnEditIndex == scope.column.id"
                ref="tableRowInputRef"
                v-model="scope.row.partCode"
                @blur="onInputTableBlur(scope)"
              >
              </el-input>
              <div v-else :class="'textShow ' + scope.$index + '_3 ' + scope.row.id + ' partCode'" @contextmenu.prevent="contextmenuClick(scope, $event, 'partCode')" @dblclick="dbClickCell(scope)">{{ scope.row.partCode }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="主题类型" min-width="220" prop="themeType">
          <template slot-scope="scope">
            <div v-if="scope.row.themeType == undefined" :class="'noEdit ' + scope.$index + '_4'">
              <i class="noEdit-icon"></i>
            </div>
            <div v-else class="rowEditShow" @click="cellClick(scope, 'themeType')">
              <el-input
                v-if="
                rowEditIndex == scope.$index &&
                colimnEditIndex == scope.column.id"
                ref="tableRowInputRef"
                v-model="scope.row.themeType"
                @blur="onInputTableBlur(scope)"
              >
              </el-input>
              <div v-else :class="'textShow ' + scope.$index + '_4 ' + scope.row.id + ' themeType'" @contextmenu.prevent="contextmenuClick(scope, $event, 'themeType')" @dblclick="dbClickCell(scope)">{{ scope.row.themeType }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="适用配置" min-width="220" prop="config">
          <template slot-scope="scope">
            <div v-if="scope.row.config == undefined" :class="'noEdit ' + scope.$index + '_5'">
              <i class="noEdit-icon"></i>
            </div>
            <div v-else class="rowEditShow" @click="cellClick(scope, 'config')">
              <el-input
                v-if="
                rowEditIndex == scope.$index &&
                colimnEditIndex == scope.column.id"
                ref="tableRowInputRef"
                v-model="scope.row.config"
                @blur="onInputTableBlur(scope)"
              >
              </el-input>
              <div v-else :class="'textShow ' + scope.$index + '_5 ' + scope.row.id + ' config'" @contextmenu.prevent="contextmenuClick(scope, $event, 'config')" @dblclick="dbClickCell(scope)">{{ scope.row.config }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="contextmenuContent">
      <div @click="shearClick()">
        <i class="shear-icon"></i>
        <span>剪切</span>
      </div>
      <div @click="copyClick()">
        <i class="copy-icon"></i>
        <span>复制</span>
      </div>
      <div @click="pasteClick()">
        <i class="paste-icon"></i>
        <span>粘贴</span>
      </div>
      <el-divider></el-divider>
      <div @click="clearClick()">
        <i class="clear-icon"></i>
        <span>清空单元格</span>
      </div>
    </div>
  </div>
</template>
<script>
import {handleAlert, tableHeight, tableMaxHeight, copyResult } from '@/assets/js/common.js'
// import
import axios from 'axios'
import { set } from 'vue'
export default {
  name: 'manageCatalog',
  data() {
    return {
      tableList: [],
      menuList: [
        {
          id: "01",
          name:"概述",
          children: [
            {
              id: "0101",
              name: "概述信息",
              children: [
                {
                  id: "010101",
                  name: "如何使用本手册",
                  children:[
                    {
                      id: "01010101",
                      name: "说明",
                      partCode:"2400200-DA020-A000000,1152110-BS-A000000",
                      themeType: "维修",
                      config: "豪华版、旗舰版、至尊版",
                      children: [],
                    },
                    {
                      id: "01010102",
                      name: "术语定义",
                      partCode:"5402312-JB010-A000000,BL540C,2309218-T1500-A727000",
                      themeType: "维修",
                      config: "豪华版、旗舰版、至尊版",
                      children: [],
                    },
                    {
                      id: "01010103",
                      name: "国际单位制",
                      partCode:"1709361-00000-A000T15,5402313-JB010-A000000",
                      themeType: "维修",
                      config: "豪华版、旗舰版、至尊版",
                      children: [],
                    },
                    {
                      id: "01010104",
                      name: "维修程序",
                      partCode:"5401071-BA300-A039000",
                      themeType: "维修",
                      config: "豪华版、旗舰版、至尊版",
                      children: [],
                    }
                  ]
                },
                {
                  id: "010102",
                  name: "识别信息",
                  children: [
                    {
                      id: "01010201",
                      name: "车辆识别号",
                      partCode:"",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010202",
                      name: "电机型号和编号",
                      partCode:"",
                      themeType: "",
                      config: "",
                      children: [],
                    }
                  ]
                },
                {
                  id: "010103",
                  name: "维修说明",
                  children:[
                    {
                      id: "01010301",
                      name: "操作提示",
                      partCode:"",
                      themeType: "",
                      config: "",
                      children: [],
                    }
                  ]
                },
                {
                  id: "010104",
                  name: "警告和注意事项",
                  children: [
                    {
                      id: "01010401",
                      name: "电动汽车维修安全须知",
                      partCode:"",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010402",
                      name: "电动车维修安全措施",
                      partCode:"",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010403",
                      name: "有关断开蓄电池的重要警告事项",
                      partCode:"",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010404",
                      name: "检查和维修高压电路的注意事项",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010405",
                      name: "检查和维修前舱的注意事项",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010406",
                      name: "检查和维修安全气囊系统注意事项",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010407",
                      name: "车辆举升和支撑位置注意事项",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010408",
                      name: "有关路试的重要警告事项",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    }
                  ]
                },
                {
                  id: "010105",
                  name: "电路故障维修",
                  children: [
                    {
                      id: "01010501",
                      name: "工作流程",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010502",
                      name: "保险丝的检查维修",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010503",
                      name: "如何处理间隙性故障",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010504",
                      name: "如何处理历史故障",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    }
                  ]
                },
                {
                  id: "010106",
                  name: "噪音、震动和不平顺(NVH)",
                  children: [
                    {
                      id: "01010601",
                      name: "说明与操作",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "01010602",
                      name: "诊断与测试",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: "02",
          name: "保养操作",
          children: [
            {
              id: "0201",
              name: "维护保养",
              children: [
                {
                  id: "020101",
                  name: "系统概述",
                  children:[
                    {
                      id: "02010101",
                      name: "警告和注意事项",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    }
                  ]
                },
                {
                  id: "020102",
                  name: "保养周期",
                  children:[
                    {
                      id: "02010201",
                      name: "首次保养",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010202",
                      name: "定期保养",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010203",
                      name: "恶劣驾驶条件内的补充保养",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    }
                  ]
                },
                {
                  id: "020103",
                  name: "检查",
                  children:[
                    {
                      id: "02010301",
                      name: "检查车门限位器",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010302",
                      name: "检查电器系统工作状态",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010303",
                      name: "检查轮胎",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010304",
                      name: "检查安全带",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010305",
                      name: "检查蓄电池",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010306",
                      name: "检查安全气囊",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010307",
                      name: "检查冷却液",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010308",
                      name: "检查风窗玻璃洗涤液",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010309",
                      name: "检查制动液",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010310",
                      name: "检查喇叭",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010311",
                      name: "检查雨刮片",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010312",
                      name: "检查差减齿轮油",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010313",
                      name: "检查制动摩擦片",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010314",
                      name: "检查制动盘",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010315",
                      name: "检查制动踏板",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010316",
                      name: "检查驻车制动",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010317",
                      name: "检查底盘",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010318",
                      name: "检查三电系统",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010319",
                      name: "检查灯光",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    },
                    {
                      id: "02010320",
                      name: "检查防爆透气阀",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children: [],
                    }
                  ]
                },
                {
                  id: "020104",
                  name: "更换和调整",
                  children:[
                    {
                      id: "02010401",
                      name: "制动液",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children:[],
                    },
                    {
                      id: "02010402",
                      name: "差减齿轮油",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children:[],
                    },
                    {
                      id: "02010403",
                      name: "空调滤芯总成",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children:[],
                    },
                    {
                      id: "02010404",
                      name: "电池温控系统冷却液",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children:[],
                    },
                    {
                      id: "02010405",
                      name: "电机冷却系统冷却液",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children:[],
                    },
                    {
                      id: "02010406",
                      name: "暖风系统冷却液",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children:[],
                    },
                    {
                      id: "02010407",
                      name: "调整前组合灯",
                      partCode: "",
                      themeType: "",
                      config: "",
                      children:[],
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      buttonState: false,
      maximumHeight: 0, // 最大高度
      copyVal: "",
      rowEditIndex: "",
      colimnEditIndex: "",
      cellId: "",
      rowIndex: "",
      colIndex: "",
      cellType: "",
      cellState: false,
      upload:[],
    }
  },
  watch: {
    $route(to) {
      if (to.name == "excelDetail") {
        this.menuData();
      }
    }
  },
  methods: {
    // 表格样式
    rowStyle({ row, rowIndex }) {
      let styleJson = {
        "background": "var(--cell-bgColor)",
      }
      if(row.children.length == 0 || row.children == null) {
        return styleJson;
      } else {
        return {};
      }
    },
    // 监听表格列宽变化
    changeColWidth() {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      })
    },
    // 数据内容
    menuData() {
      this.tableList = this.menuList
      this.areaSize();
    },
    // 撤销
    revokeClick(){

    },
    // 恢复
    recoveryClick(){

    },
    upladeDAta(num, content) {

    },
    // 表格双击编辑
    dbClickCell(scope){
      this.rowEditIndex = scope.$index
      this.colimnEditIndex = scope.column.id
      this.$nextTick(() => {
        this.$refs.tableRowInputRef.focus();
      });
      this.buttonState = true
    },
    onInputTableBlur(scope) {
      this.rowEditIndex = ""
      this.colimnEditIndex = ""
      this.buttonState = false
    },
    // 右键点击
    contextmenuClick(scope, event, type) {
      this.cellsContent(scope, type)
      var allHeight = $(".el-container").height();
      var allWidth = $(".el-container").width();
      var menuHeight = $(".contextmenuContent").height();
      var menuWidth = $(".contextmenuContent").width();
      $(".contextmenuContent").show()
      if(event.clientY + menuHeight > allHeight) {
        $(".contextmenuContent").css('top', event.clientY - menuHeight)
      } else {
        $(".contextmenuContent").css('top', event.clientY)
      }
      if(event.clientX + menuWidth > allWidth) {
        $(".contextmenuContent").css('left', event.clientX - menuWidth)
      } else {
        $(".contextmenuContent").css('left', event.clientX)
      }
    },
    // 剪切
    shearClick() {
      var id = this.rowIndex + "_" + this.colIndex + "." + this.cellId + "." + this.cellType;
      $(".textShow." + id).html("");
      this.copyClick();
    },
    // 复制
    copyClick(){
      var input = document.createElement('input')
      input.value = this.copyVal
      document.body.appendChild(input)
      input.select()
      document.execCommand("copy")
      setTimeout(() =>{
        input.remove()
      },100)
    },
    // 粘贴
    pasteClick(){
      Notification.requestPermission().then(function(permission){
        if(permission == "granted"){
          this.pasteOperate();
          // copyResult(window.bodyHtml, currentRow, currentCow)
        }else{
          alert("剪切板被禁用，请先开启浏览器的剪切板使用权限。")
          // this.$confirm('剪切板被禁用，请先开启浏览器的剪切板使用权限。', "粘贴提示", {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   //   window.cellStatus = true;
          //   //   let currentRow = this.rowIndex; // 当前行数
          //   //   let currentCow = this.colIndex; // 当前列数
          //   //  copyResult(window.bodyHtml, currentRow,currentCow)
          // }).catch((error)=>{
          //   // window.cellStatus = false;
          // })
        }
      })
            // navigator.permissions.query({
            //   name: 'clipboard-read'
            // }).then(permissionStatus => {
            //   console.log(permissionStatus.state)
            //   If()
            //   // this.$confirm('剪切板被禁用，请先开启浏览器的剪切板使用权限。', "粘贴提示", {
            //   //   confirmButtonText: '确定',
            //   //   cancelButtonText: '取消',
            //   //   type: 'warning'
            //   // }).then(() => {
            //   // //   window.cellStatus = true;
            //   // //   let currentRow = this.rowIndex; // 当前行数
            //   // //   let currentCow = this.colIndex; // 当前列数
            //   // //  copyResult(window.bodyHtml, currentRow,currentCow)
            //   // }).catch((error)=>{
            //   //   // window.cellStatus = false;
            //   // })
            // })
      // var input = document.createElement('input')
      // input.className="copyinput"
      // input.value = this.copyVal
      // document.body.appendChild(input)
      // input.select()
      // document.execCommand("paste")
      // var id = this.rowIndex + "_" + this.colIndex + "." + this.cellId + "." + this.cellType;
      // $(".textShow." + id).html("65+");
    },
    // 点击单元格
    cellClick(scope, type) {
      this.cellsContent(scope, type)
    },
    // 单元格信息
    cellsContent(scope, type) {
      this.copyVal = "";
      this.cellId = scope.row.id;
      this.rowIndex = scope.$index;
       if(type == "partCode") {
        this.copyVal = scope.row.partCode;
        this.colIndex = 3;
        this.cellType = "partCode";
      }
      if(type == "themeType") {
        this.copyVal = scope.row.themeType;
        this.colIndex = 4;
        this.cellType = "themeType";
      }
      if(type == "config"){
        this.copyVal = scope.row.config;
        this.colIndex = 5;
        this.cellType = "config";
      }
    },
    // ctrl + c
    copyControl() {
      this.copyClick();
    },
    // ctrl + v
    pasteControls(){
      console.log(this.copyVal)
      if(this.copyVal !== ''){
        window.cellStatus = false;
        this.pasteOperate();
        this.$confirm('确定是否覆盖原有数据信息?', "复制提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.cellStatus = true;
          let currentRow = this.rowIndex; // 当前行数
          let currentCow = this.colIndex; // 当前列数
         copyResult(window.bodyHtml, currentRow,currentCow)
        }).catch((error)=>{
          window.cellStatus = false;
        })
      } else {
        window.cellStatus = true;
        this.pasteOperate();
      }
    },
    pasteOperate() {
      let currentRow = this.rowIndex; // 当前行数
      let currentCow = this.colIndex; // 当前列数
      let copyRowNum = ""; //行数
      let copyColNum = ""; // 列数
      var items = (event.clipboardData || window.clipboardData).items;
      var len = items.length;
      let div = document.createElement('html')
      for (let i = 0; i < len; i++) {
        if(items[i].type.indexOf("html") !== -1) {
          items[i].getAsString(function(str) {
            // 获取复制的值
            let body = document.createElement('body')
            body.innerHTML = str.replace("\u200c", "").replace("\u200b", "")
            div.appendChild(body)
            let newBody = document.createElement('body')
            let nodes = div.getElementsByTagName('body')[0].children
            for (let j = 0; j < nodes.length; j++) {
              if(nodes[j].nodeName == "TABLE"){
                newBody.appendChild(nodes[j]);
                j--
              }
            }
            window.bodyHtml = newBody;
            if(window.cellStatus == true){
              copyResult(window.bodyHtml, currentRow, currentCow)
            }
            // if(newBody.getElementsByTagName('table') !== undefined){
            //   copyRowNum = newBody.getElementsByTagName('tr').length
            //   copyColNum = newBody.getElementsByTagName('tr')[0].getElementsByTagName('td').length
            //   // 行列复制
            //   setTimeout(() => {
            //     for (var row = 0; row < copyRowNum; row++) {
            //       for(var col = 0 ; col < copyColNum; col++) {
            //         var text = newBody.getElementsByTagName('tr')[row].getElementsByTagName('td')[col].innerHTML
            //         var rowCol = (currentRow + row) + "_" + (currentCow + col)
            //         // if($(".textShow." + rowCol).text() !== ''){
            //         //   this.$confirm('确定是否覆盖原有数据信息?', "复制提示", {
            //         //     confirmButtonText: '确定',
            //         //     cancelButtonText: '取消',
            //         //     type: 'warning'
            //         //   }).then(() => {
            //         //     console.log(text)
            //         //     //  aa(window.aa, this.rowIndex, this.colIndex)
            //         //   }).catch((error)=>{})
            //         // }else{
            //           $(".textShow." + rowCol).html(text);
            //         // }
            //         // console.log($(".textShow." + rowCol).text())
            //         // $(".textShow." + rowCol).html(text);
            //       }
            //     }
            //   }, 10)
            // }
          })
        }
      }
    },
    // 清空
    clearClick() {
      var id = this.rowIndex + "_" + this.colIndex + "." + this.cellId + "." + this.cellType;
      $(".textShow." + id).html("");
    },
    // 文件上传之前
    onBeforeUpload(file) {
      var fileExt = file.name.substring(file.name.lastIndexOf(".")+1).toLowerCase();
      const docExt = fileExt === 'xls'
      const docxExt = fileExt === 'xlsx'
      const isLimit = file.size / 1024 / 1024 < 1024
      if(!docExt && !docxExt) {
        handleAlert('warning', "上传的文件只能是 xls、xlsx格式!")
        return false;
      }
      if (!isLimit) {
        handleAlert('warning', "上传文件大小不能超过 1GB!")
        return false;
      }
    },
    initialState() {
      this.percentage = 0
      this.showUploadProcess= false
    },
    uploadFile(file) {
    },
    widthSize() {
      setTimeout(() => {
        tableHeight()
        this.maximumHeight = tableMaxHeight;
      }, 20)
    },
    areaSize() {
      var _this = this;
      _this.widthSize();
      window.addEventListener("resize", function() {
        _this.widthSize();
      });
    },
  },
  mounted() {
    var _this = this;
    _this.menuData();
    window.addEventListener("copy", this.copyControl);
    window.addEventListener("paste", this.pasteControls);
    document.oncontextmenu = function (e) {
      return false
    }
    document.onclick = function (e) {
      $(".contextmenuContent").hide()
    }
    document.onmousewheel = function(e) {
      $(".contextmenuContent").hide()
    }
    window.cellStatus = false;
    // document.addEventListener('copy', (event) => {
    // })
    // document.addEventListener('paste', (event) => {
    //   // console.log(event)
    //   // console.log(event.clipboardData || window.clipboardData)
    // })
  },
}
</script>
<style>
  .excelContainer .tableHandle i {
    filter: grayscale(100%) brightness(0);
  }
  .excelContainer .tableHandle .el-dropdown i {
    margin: 0 6px
  }
  .excelContainer .tableHandle .el-button.is-disabled {
    opacity: 0.3;
  }
  .excelContainer .el-table__expand-icon .el-icon-arrow-right::before,
  .excelContainer .el-table__expand-icon .el-icon-arrow-right::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url("../../../assets/image/Icon/expandIcon.png") no-repeat center;
    background-size: 100% 100%;
    margin-right: 3px;
    vertical-align: middle;
  }
  .excelContainer .el-table__expand-icon .el-icon-arrow-right::after {
    width: 15px;
    height: 15px;
    background: url("../../../assets/image/Icon/treeFold.png") no-repeat center;
    background-size: 100% 100%;
    margin-right: 0px;
    /* vertical-align: middle; */
  }
  .excelContainer .el-table__expand-icon--expanded {
    transform: none !important;
  }
  .excelContainer .el-table__expand-icon.el-table__expand-icon--expanded .el-icon-arrow-right::before,
  .excelContainer .el-table__expand-icon.el-table__expand-icon--expanded .el-icon-arrow-right::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url("../../../assets/image/Icon/collapseIcon.png") no-repeat center;
    background-size: 100% 100%;
    margin-right: 3px;
    vertical-align: middle;
  }
  .excelContainer .el-table__expand-icon.el-table__expand-icon--expanded .el-icon-arrow-right::after {
    width: 15px;
    height: 15px;
    background: url("../../../assets/image/Icon/treeOpen.png") no-repeat center;
    background-size: 100% 100%;
    margin-right: 0px;
  }
  .excelContainer  .el-table__placeholder {
    margin-left: 20px;
  }
  .excelContainer .el-table__placeholder::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url("../../../assets/image/Icon/chapterIcon.png") no-repeat center;
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -3px;
  }
  .excelContainer .el-table .el-table__cell {
    padding: 0;
  }
  .excelContainer .el-table .cell {
    height: 30px;
    line-height: 30px;
  }
  .excelContainer .rowEditShow  {
    height: 100%;
    width: calc(100% + 10px);
    margin-left: -5px;
    cursor: pointer;
  }
  .excelContainer .rowEditShow > div {
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .excelContainer .rowEditShow .textShow {
    padding: 0 5px;
  }
  .excelContainer .rowEditShow .el-input .el-input__inner{
    border-radius: 0;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    border: 1px solid transparent;
  }
  /* 右键菜单 */
  .excelContainer .contextmenuContent {
    position: absolute;
    display: none;
    background: #fff;
    border-radius: 3px;
    margin: 2px 0;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    z-index: 10;
  }
  .excelContainer .contextmenuContent > div {
    padding: 5px 15px;
    margin: 3px 0;
    cursor: pointer;
    font-size: 14px;
  }
  .excelContainer .contextmenuContent .el-divider--horizontal {
    padding: 0;
  }
  .excelContainer .contextmenuContent > div i {
    width: 20px;
    margin: 0 3px;
    color: #000;
    filter: grayscale(100%) brightness(0);
  }
  .excelContainer .contextmenuContent > div:hover {
    background: var(--hover-color);
    color: var(--theme-color);
    filter: grayscale(0%) brightness(1);
  }
  .excelContainer .contextmenuContent > div:hover i {
    color: var(--theme-color);
    filter: grayscale(0%) brightness(1);
  }
  @media screen and (max-width: 1366px) and (min-width: 1280px) {
    .excelContainer .el-table .cell,
    .excelContainer .rowEditShow .el-input .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 1024px) {
    .excelContainer .el-table .cell,
    .excelContainer .rowEditShow .el-input .el-input__inner {
      height: 26px;
      line-height: 26px;
    }
  }
  @media screen and (max-width: 1024px) {
    .excelContainer .el-table .cell,
    .excelContainer .rowEditShow .el-input .el-input__inner {
      height: 25px;
      line-height: 25px;
    }
  }
</style>
