<template>
  <div class="layoutContainer manualImage">
    <div class="tableDetail">
      <div class="tableHandle"  style="display: flex;justify-content: space-between;">
        <el-upload
          class="upload-demo inline-block"
          ref="batchUpload"
          action="#"
          :show-file-list="false"
          multiple
          :before-upload="onBeforeBatchUpload"
          :http-request="uploadZipFile"
          accept="zip"
        >
          <el-button type="text" v-if="hasPerm('menuAsimssCMS3A2B_107')" icon="bulkImport-icon">批量导入</el-button>
          <el-progress v-show="progressState"  class="imgProgress" :percentage="progressPercent" />
        </el-upload>

        <div class="rightSearch">
          <el-input placeholder="请输入图片名称" v-model.trim="inputVal">
            <el-button slot="append" @click="searchClick()"  icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </div>
      <el-table
        style="width:100%"
        border
        stripe
        ref="table"
        highlight-current-row
        :max-height="maximumHeight"
        :data="resultList"
        :header-cell-style="{
          'text-align':'center',
          'background-color': 'var(--other-color)',
        }"
        @header-dragend="changeColWidth"
      >
        <el-table-column label="图片" prop="path" width="200" align="center">
          <template slot-scope="{row}">
            <img v-if="row.type != 'svg'" :src="url + 'cms/manual/image/display?filePath=' + row.path + '/' + row.name" @click="bigImg(row.path, row.name, row.type)" style="width:50px;margin:2px 0">
            <img v-else :src="url + 'cms/manual/image/display?filePath=' + getImgPath(row.path,row.name)" @click="bigImg(row.path, row.name, row.type)" style="width:60px">
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" min-width="100"></el-table-column>
        <el-table-column label="类型" prop="type" width="80" align="center"></el-table-column>
        <!-- <el-table-column label="批注" prop="notes" min-width="150">
          <template inline-template slot-scope="scope">
            <el-input v-model.trim="scope.row.notes" type="textarea" placeholder="请输入批注信息" rows="2"
              @change="updateNotes(scope.$index,scope.row,$event)">
            </el-input>
          </template>
        </el-table-column> -->
        <el-table-column label="上传人" prop="uploaderName" width="140" align="center"></el-table-column>
        <el-table-column label="上传时间" prop="createdTime" width="140" :formatter="dateFormat"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="{row}">
            <el-button type="text" v-if="hasPerm('menuAsimssCMS3A2B_103')" size="small" @click="showImageEditDlg(row)">编辑</el-button>
            <el-button type="text" v-if="hasPerm('menuAsimssCMS3A2B_102')" size="small" @click="Delete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form :model="imageInfo" ref='imageInfo' label-position="center" :rules="fromTemp" :label-width="formLabelWidth">
          <el-form-item label="图片名称" prop="name">
            <el-input v-model="imageInfo.name" aria-required="true" readonly></el-input>
          </el-form-item>
          <!-- <el-form-item label="图片批注" prop="notes" :label-width="formLabelWidth">
            <el-input type="textarea" placeholder="请输入图片批注" style="width: 85% !important;" rows="3" v-model.trim="imageInfo.notes" ></el-input>
          </el-form-item> -->
          <el-form-item label="图片" prop="path">
            <el-upload
              class="upload-demo"
              style="max-width: 379px;"
              :action="uploadUrl"
              :headers="importHeader"
              :on-success="handleOnSuccess"
              :on-remove="handleOnRemove"
              :before-remove="beforeOnRemove"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleOnExceed"
              multiple
              :limit="1"
              :file-list="imgList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg, .svg, .SVG"
              list-type="picture"
            >
              <el-button size="min" icon="el-icon-upload" type="primary">选择图片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item class="submitArea">
            <el-button type="primary" @click="updateImageInfo">
              立即提交
            </el-button>
            <el-button @click="dialogFormVisible = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="大图" :visible.sync="dialogBigImageVisible" center>
        <div v-if="isSvg" class="fileImg">
          <div id="mysvg" style="height:100%"></div>
        </div>
        <div v-else style="width: 100%;position: relative;overflow: hidden;text-align: center;border: 1px solid #f1f2f3;">
          <el-button size='mini' @click="toBIgChange" icon="el-icon-zoom-in"
            style="position: absolute;top: 2px ;left: 2px;z-index: 999;"></el-button>
          <el-button size='mini' @click="toSmallChange" icon="el-icon-zoom-out"
            style="position: absolute;top: 2px ;left: 40px;z-index: 999;"></el-button>
          <img id="img" :src="url + 'cms/manual/image/display?filePath=' + path + '/' + name"
            alt="" @mousedown.prevent="dropImage" @mousewheel.prevent='gunlun' :style="{transform:'scale('+multiples+')'}">
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, handleAlert, tableHeight, tableMaxHeight } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { detailData, detailImport, detailUpdate, detailNotes, detailDelete } from '@/api/cmsmgt.js'
import axios from 'axios'
import { loadSvg } from '@/plugins/mysvg.js'
export default {
  name: 'imgDetail',
  components: { Pagination },
  data () {
    return {
      progressState:false,
      progressPercent: 0,
      formInline: {
        manualVersion: '',
        queryimgName: '',
        queryNotes: '',
        begintime: null,
        endtime: null,
        queryUploader: ''
      },
      inputVal:"",
      imageInfo: {
        id: '',
        name: '',
        notes: '',
        path: ''
      },
      url: '',
      name: '',
      path: '',
      type: '',
      isSvg: false,
      fileList: [],
      resultList: [],
      formLabelWidth: '100px',
      dialogFormVisible: false,
      dialogBigImageVisible: false,
      dialogStatus: '',
      textMap: {
        import: '批量导入',
        edit: '编辑明细'
      },
      maximumHeight: 0,
      manualId: '',
      manualVersion: '',
      pagesize: 20,
      currentPage: 1,
      total: 0,
      uploadUrl: '',
      imgList: [],
      isFlag: true,
      multiples: 1,
      odiv: {
        style :{
          left: 0,
          top: 0,
        }
      },
      fromTemp: {
        name: [{ required: true, message: '图片名称不能为空', trigger: ['blur', 'change'] }],
        path: [{ required: true, message: '图片不能为空', trigger: ['blur', 'change'] }]
      },
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token }
    }
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "manualImage") {
        _this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=manualImage'
        _this.url = cmsServerUrl
        _this.manualId = to.params.id
        _this.manualVersion = to.params.manualVersion
        _this.formInline.manualVersion = _this.manualVersion
        _this.dataList()
      }
    }
  },
  methods: {
    // 监听表格列宽变化
    changeColWidth(){
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      })
    },
    // 显示数据
    dataList () {
      this.$loading.show();
      var param = {
        page: this.currentPage,
        limit: this.pagesize,
        manualId: this.$route.params.id,
        manualVersion: this.$route.params.manualVersion,
        notes: this.inputVal
      }
      detailData(param).then(res => {
        this.total = res.data.total;
        this.resultList = res.data.data;
        this.$loading.hide();
        this.tableHeightArea();
      });
    },
    // 批量导入 -- 文件上传前的钩子
    onBeforeBatchUpload(file,fileList) {
      var fileExt = file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();
      const docExt = fileExt === 'zip'
      const isLimit = file.size / 1024 / 1024 < 500
      if(!docExt) {
        handleAlert('warning',"上传文件只能是 zip 格式!")
        return false;
      }
      if (!isLimit) {
        handleAlert('warning',"上传文件大小不能超过 500MB!")
        return false;
      }
      return true;
    },
    initialState(){
      setTimeout(() => {
        this.progressPercent = 0
        this.progressState = false
      },100)
    },
    // 批量导入上传
    uploadZipFile (file) {
      var _this = this
      var handelProgress;
      _this.$loading.show();
			var formData = new FormData();
			formData.append('file', file.file);
      formData.append('manualId', _this.manualId);
      formData.append('manualVersion', _this.manualVersion);
      const uploadProgress = progressEvent => {
        _this.progressState = true;
        handelProgress = setInterval(function(){
          if(_this.progressPercent > 96){
            return;
          }
          _this.progressPercent += 1;
        },120)
      }
      detailImport(formData,uploadProgress).then(res => {
        if (res.data.code === 100) {
          _this.progressPercent = 100;
          handleAlert('success','导入成功');
          _this.dataList();
        }else{
          handleAlert('error','导入失败');
        }
        _this.initialState();
        _this.$loading.hide();
      }).catch(function(error){
        handleAlert('error','导入失败');
        _this.initialState();
        _this.$loading.hide();
      })
      clearInterval(handelProgress);
    },
    // 搜索
    searchClick(){
      this.currentPage = 1
      if(this.inputVal == ""){
        handleAlert("error", "搜索内容不能为空")
      }else {
        this.dataList()
      }
    },
    // 编辑
    showImageEditDlg(row){
      this.imageInfo.id=row.id
      this.imageInfo.name=row.name
      this.imageInfo.notes=row.notes
      this.imageInfo.path= row.path
      this.imgList=[]
      if (row.type == "svg") {
        var img = {url: sysServerUrl + 'cms/manual/image/display?filePath=' + this.getImgPath(this.imageInfo.path, this.imageInfo.name )}
      }else{
        var img = {url: sysServerUrl + 'cms/manual/image/display?filePath=' + this.imageInfo.path + "/" +  this.imageInfo.name}
      }
      this.imgList.push(img)
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
      this.$nextTick(function() {
        this.$refs.imageInfo.clearValidate();
      })
    },
    // 确认编辑图片信息
    updateImageInfo(){
      if(this.imgList.length==0){
        handleAlert('error','图片不能为空，请选择图片')
        return  false
      }
      var params = new URLSearchParams()
      params.append('id', this.imageInfo.id)  // 图片ID
      params.append('name', this.imageInfo.name)   // 图片名称
      params.append('path',this.imageInfo.path)    // 图片路径
      if (this.imageInfo.notes!=null) {
        params.append('notes', this.imageInfo.notes)   // 图片批注
      }
      detailUpdate(params).then(res=>{
        if(res.data.code==100){
          handleAlert('success','修改图片成功')
          this.dataList()
          this.dialogFormVisible=false
        }
      }).catch(function(error){
        handleAlert('error','修改图片失败')
      })

    },
    // 修改批注，焦点失去事件
    updateNotes(index,row,value){
      var params = new URLSearchParams()
      params.append('id', row.id)
      params.append('manualId', this.manualId)
      params.append('manualVersion',this.manualVersion)
      params.append('notes',value)
      detailNotes(params).then(res => {
        if(res.data.code==100){
          handleAlert('success','修改批注成功')
        }
      })
    },
    handleOnSuccess (res, obj) {
      this.imageInfo.path = res.data.fileUrl
      this.imgList=[]
      var img = {url:  sysServerUrl + 'sys/upload/display?filePath=' +  this.imageInfo.path }
      this.imgList.push(img)
      this.isFlag = true;
    },
    // 文件移除前的钩子
    beforeOnRemove(file, fileList) {
      if (this.isFlag) {
        return this.$confirm(`确定移除选择文件？`, '删除',{type: 'warning'});
      }
    },
    // 文件移除时的钩子
    handleOnRemove(file,fileList){
      this.imgList = []
      this.isFlag = true;
    },
    // 文件上传前的钩子
    beforeAvatarUpload (file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".")+1).toLowerCase();
      const extension = suffix === 'png'
      const extension2 = suffix === 'jpg'
      const extension3 = suffix === 'jpeg'
      const extension4 = suffix === 'gif'
      const extension5 = suffix === 'svg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!extension && !extension2 && !extension3 && !extension4 && !extension5) {
        handleAlert('warning','上传图片只能是 png、jpg、jpeg、gif、svg格式!')
        this.isFlag = false;
        return false;
      }
      if (!isLt2M) {
        handleAlert('warning','上传图片大小不能超过 2MB!')
        this.isFlag = false;
        return false;
      }
      const rawName = this.imageInfo.name
      const rawSuffix = rawName.substring(rawName.lastIndexOf(".")+1).toLowerCase();
      if (suffix != rawSuffix) {
        handleAlert('warning','本图片只能上传 '+ rawSuffix + " 格式的图片！")
        this.isFlag = false;
        return false;
      }
    },
    handleOnExceed (files, fileList) {
      handleAlert('warning',`当前限制选择1张图片，本次选择了 ${files.length} 张图片，共选择了 ${files.length + fileList.length} 张图片`)
    },
    // 导入
    headelImport () {
      this.dialogStatus = 'import'
      this.dialogFormVisible = true
    },
    // 删除
    Delete (row) {
      let imgId= row.id
      if(imgId==0){
        handleAlert('error','请选中需要删除的图片')
        return false;
      }
      this.$confirm('确定删除【'+ row.name + '】的图片信息?', '删除图片', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
      }).then(() => {
        detailDelete(imgId).then(res => {
          if(res.data.code==100){
            handleAlert('success','删除成功')
            if(this.total > 1){
              if(this.resultList!=null&&this.resultList.length == 1){
                this.currentPage =this.currentPage-1
              }
            }
            this.dataList()
          }else{
            handleAlert('error',res.data.msg)
          }
        })
      }).catch((error) => {})
    },
    // 大图
    bigImg(path, imgName, type) {
      this.type = type;
      if (type != 'svg') {
        this.isSvg= false;
        this.name = imgName; // 图片名称
        this.path = path;
        $(".hasSVG").attr("style","width: 100%;height: 0;");

      }else{
        this.isSvg = true;
        loadSvg()
        $(".hasSVG").attr("style","width: 100%;height: 100%;");
        let svgPath = this.url + 'cms/manual/image/display?filePath=' + path + '/' + imgName;
        setTimeout(function(){
          loadSvg(svgPath)
        },0);

      }
      this.dialogBigImageVisible = true;
    },
    // 滚轮滑动
    gunlun(e) {
      let direction = e.deltaY > 0 ? 'down' : 'up'
      if (direction === 'up') {
        // 滑轮向上滚动
        this.toBIgChange()
      } else {
        // 滑轮向下滚动
        this.toSmallChange()
      }
    },
    // 放大
    toBIgChange() {
      if (this.multiples >= 2.5) {
        return;
      }
      this.multiples += 0.25;
    },
    // 缩小
    toSmallChange() {
      if (this.multiples <= 0.5) {
        return;
      }
      this.multiples -= 0.25;
    },
    // 拖拽
    dropImage(e) {
      if (e === null) {
        return
      }
      this.odiv = e.target;        //获取目标元素
      //算出鼠标相对元素的位置
      let disX = e.clientX - this.odiv.offsetLeft;
      let disY = e.clientY - this.odiv.offsetTop;
      document.onmousemove = (e) => {       //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        //绑定元素位置到positionX和positionY上面
        this.positionX = top;
        this.positionY = left;

        //移动当前元素
        this.odiv.style.left = left + 'px';
        this.odiv.style.top = top + 'px';
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },

    getImgPath(path, name){
      path = path.replace("source/graphics", "conversion/svgImg/");
      name = name.substring(0,name.lastIndexOf(".")) + ".png"
      return path + name;
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    },
    maximumArea() {
      setTimeout(() => {
        tableHeight()
        setTimeout(() => {
          this.maximumHeight = tableMaxHeight;
        }, 50)
      },50)
    },
    tableHeightArea() {
      var _this = this;
      _this.maximumArea();
      window.addEventListener("resize", function () {
        _this.maximumArea();
      });
    }
  },
  mounted () {
    this.uploadUrl=sysServerUrl + 'sys/upload/attach?flag=manualImage'
    this.url = cmsServerUrl
    this.manualId = this.$route.params.id
    this.manualVersion = this.$route.params.manualVersion
    this.formInline.manualVersion = this.manualVersion
    this.dataList()
  },
}
</script>
<style>
  .imgProgress{
    width: 500px;
    height: 20px;
    line-height: 20px !important;
    margin-top: 8px;
    position: absolute !important;
    top: 23%;
    /* background: #edf2fc; */
    /* border: 1px solid #409eff; */
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999
  }
  .imgProgress .el-progress-bar__outer{
    height: 10px !important;
  }
  .tableHandle .el-input__inner{
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border-radius: 0;
    width: 220px;
    border-right: none;
    outline: none;
    border: 1px solid #c0c4cc;
    border-right: none;
  }
  .tableHandle .el-input__inner:focus {
    border: 1px solid #c0c4cc;
    border-right: none;
  }
  .tableHandle .el-input-group__append{
    background: #fff;
    border: 1px solid #c0c4cc;
    border-left: none;
    box-sizing: border-box;
    border-radius: 0;
    padding: 0;
    font-size: 16px;
  }
  .tableHandle .rightSearch .el-button {
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
  }
  .el-upload-list__item {
   transition: none !important;
  }
  .fileImg {
    width: 100%;
    height: 100%;
  }
  .manualImage img {
    width: 100%;
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
  .manualImage .el-textarea__inner{
    resize: none !important;
    color: #333 !important;
    line-height: 1.3 !important;
    font-size: 12px !important;
    padding: 2px 5px !important;
    margin: 2px 0;
  }
</style>
