<template>
  <div class="headerContent" v-cloak>
    <div class="navHeader">
      <div class="logo">
        <img :src="logoPath">
        <!-- <img @click="logoClick()" src="../../assets/image/xy_logo.png" alt=""> -->
      </div>
      <div class="navIcon">
        <div @click="frontPage()" v-if="false">
          前台首页
        </div>
        <!-- 帮助手册 -->
        <div v-if="false">
          <img src="../../assets/image/headerIcon/help.png" alt="">
        </div>
        <!-- 系统公告 -->
        <div @click="noticeClick()" v-if="false">
          <el-tooltip
            class="item"
            effect="light"
            content="系统公告"
            placement="bottom"
          >
            <el-badge
              :hidden="unreadNum != 0 ? false : true"
              :value="unreadNum"
              :max="99"
              class="item"
              style="width: 100%; height: 100%"
            >
              <img src="../../assets/image/headerIcon/noticeIcon.png" alt="">
            </el-badge>
          </el-tooltip>
        </div>

        <!-- <div v-if="unreadNum != 0" @click="noticeClick()">
          <el-badge :value="unreadNum" :max="100" class="item">
            <img src="../../assets/image/headerIcon/noticeIcon.png" alt="">
          </el-badge>
        </div>
        <div v-if="unreadNum == 0">
          <img @click="noticeClick()" src="../../assets/image/headerIcon/noticeIcon.png" alt="">
        </div> -->
        <!-- 主题 -->
        <div v-if="false">
          <el-tooltip
            class="item"
            effect="light"
            content="主题切换"
            placement="bottom"
          >
            <img
              src="../../assets/image/headerIcon/theme.png"
              @click="themeClick()"
              alt=""
            />
          </el-tooltip>
        </div>

        <div class="dropdrow" v-cloak>
          <el-dropdown style="cursor: pointer;">
            <span class="el-dropdown-link">
              <img v-if="userInfo.headimgurl == null" src="../../assets/image/defaultAvatar.png" alt="">
              <img v-if="userInfo.headimgurl !== null" :src="userInfo.headimgurl" alt="">
              {{userInfo.realName}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="basicInfo()">{{$t('nav.data')}}</el-dropdown-item>
              <el-dropdown-item @click.native="modifyCipher()">{{$t('nav.change')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div @click="signOut()" style="cursor: pointer;">
          {{$t('nav.signOut')}}
        </div>
      </div>
    </div>
    <el-dialog v-dialogDrag :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form v-if="dialogStatus === 'info'" :label-width="formLabelWidth" ref="infoData" :model="infoData" :rules="formRules">
        <el-form-item label="账号名" prop="username">
          <el-input type="text" placeholder="请输入账号名" v-model.trim="infoData.username" disabled=""></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="realName">
          <el-input type="text" placeholder="请输入名称" v-model.trim="infoData.realName" disabled></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="infoData.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input type="tel" placeholder="请输入联系人" v-model.trim="infoData.contacts"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input type="tel" placeholder="请输入联系电话" maxlength="11" v-model.trim="infoData.mobile"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input type="text" placeholder="请输入地址" v-model.trim="infoData.address"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input type="email" placeholder="请输入邮箱" v-model.trim="infoData.email"></el-input>
        </el-form-item>
        <div class="submitArea">
          <el-button type="primary" @click="infoSubmit()">
            提交修改
          </el-button>
        </div>
      </el-form>
      <!-- 修改密码 -->
      <el-form class="updatePswArea" :label-width="formLabelWidth" v-if="dialogStatus === 'password'" :rules="formPwd" ref="modify" :model="modify" :validate-on-rule-change="false">
        <el-form-item label="旧密码" prop="oldPassword" class="oldPassword">
          <el-input type="password" v-model.trim="modify.oldPassword" placeholder="请输入旧密码"></el-input>
          <img
            class="seeArea"
            @click="noSeeClick('oldPassword')"
            src="../../assets/image/sightIocn.png"
          />
          <img
            class="noSeeArea"
            @click="seeClick('oldPassword')"
            src="../../assets/image/noSeeIcon.png"
          />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword" class="newPassword">
          <el-input type="password" v-model.trim="modify.newPassword" placeholder="请输入新密码"></el-input>
          <img
            class="seeArea"
            @click="noSeeClick('newPassword')"
            src="../../assets/image/sightIocn.png"
          />
          <img
            class="noSeeArea"
            @click="seeClick('newPassword')"
            src="../../assets/image/noSeeIcon.png"
          />
        </el-form-item>
        <el-form-item label="确定密码" prop="confirmPassword" class="repeatPwd">
          <el-input type="password" v-model.trim="modify.confirmPassword" placeholder="请输入确定密码"></el-input>
          <img
            class="seeArea"
            @click="noSeeClick('repeatPwd')"
            src="../../assets/image/sightIocn.png"
          />
          <img
            class="noSeeArea"
            @click="seeClick('repeatPwd')"
            src="../../assets/image/noSeeIcon.png"
          />
        </el-form-item>
        <div class="submitArea">
          <el-button type="primary" @click="cipherSubmit('modify')">
            提交修改
          </el-button>
          <el-button plain @click="resetPwd('modify')">
            重置
          </el-button>
        </div>
      </el-form>
      <!-- 切换主题 -->
      <div v-if="dialogStatus == 'themeSwitch'">
        <div class="themeSwitchArea">
          <div class="themeType">
            <div
              v-for="(item, index) of themeList"
              :key="index"
              :style="
                item.color == themeColorVal
                  ? 'border: 2px solid var(--theme-color)'
                  : ''
              "
            >
              <img
                @click="themeColor(item.color)"
                :src="require('../../assets/image/headerIcon/' + item.img)"
                alt=""
              />
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="submitArea">
            <el-button type="primary" class="butStyle" @click="themeSubmit()">
              确定
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-dialogDrag title="选择国家" :visible.sync="dialogCountryFormVisible">
      <el-form ref="alloter" :label-width="formLabelWidth" :validate-on-rule-change="false">
      <el-form-item label="选择国家">
        <el-select v-model="countryCode" placeholder="请选择" filterable clearable>
          <el-option
            v-for="item in countryList"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
        <div class="submitArea">
          <el-button type="primary" @click="submitCountry()">
            跳转
          </el-button>
          <el-button @click="resetCode()">
            取消
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { sysServerUrl, handleAlert,addTabs, readState, frontHome } from '@/assets/js/common.js'
import {
  getUserInfo,
  userDefinedInfo,
  updatedTheme,
  userDefinedPwd,
  messageData,
  logout,
  userAtlasCountry,
  getLogoPath
} from '@/api/sysmgt.js'
import {closeRealMessage} from '@/assets/js/real_message.js'
export default {
  name: "home",
  data() {
    return {
      logoPath:"",
      imgSrc: sysServerUrl + "sys/upload/display?filePath=",
      userInfo: [],
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        info: '基本资料',
        password: '修改密码',
        themeSwitch: '主题切换',
      },
      unreadNum:"",
      // 基本资料
      infoData: {
        username:'',
        realName: '',
        userType: '',
        sex: '',
        email: '',
        telephone: '',
        address:'',
        mobile: '',  // 手机号
        contacts: '', // 联系人
        theme: '',
      },
      // 修改密码
      modify: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formLabelWidth: '100px',
      themeColorVal: "",

      dialogCountryFormVisible: false,
      countryList: [],
      countryCode: '',

    }
  },
  computed: {
    themeList() {
      var list = [
        {
          img: "themeBlack.png",
          color: "rgb(35, 134, 238)",
          name: "经典黑",
        },
        {
          img: "themeBlue.png",
          color: "rgb(35, 134, 238)",
          name: "简约蓝",
        },
        {
          img: "themeCyan.png",
          color: "rgb(26, 172, 228)",
          name: "典雅青",
        },
        {
          img: "themeGreen.png",
          color: "rgb(119, 153, 47)",
          name: "清新绿",
        },
      ];
      return list;
    },
    formRules() {
      return {
        email: [
          {
            type: "email",
            required: false,
            message: "请输入正确的邮箱",
            trigger: ["blur", "change"],
          },
        ],
        mobile: [
          {
            required: false,
            pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
            message: "请输入正确的电话号码",
            trigger: ["blur", "change"],
          },
        ],
      };
    },
    formPwd() {
      return {
        oldPassword: [
          { required: true, message: '旧密码不能为空', trigger: ['blur', 'change'] }
        ],
        newPassword: [
          { required: true, message: '新密码不能为空', trigger: ['blur', 'change'] },
          {
            pattern: /^(?=.*\d+)(?=.*[A-Za-z]+)[\w]{6,16}$/,
            required: true,
            message: "新密码为6~16位字母和数字组合",
            trigger: ["blur", "change"],
          },
        ],
        confirmPassword: [
          { required: true, message: '确定密码不能为空', trigger: ['blur', 'change'] }
        ]
      }
    },
  },
  methods: {
    // 登录
    logoClick () {
      this.$router.push('/index');
    },
    // 退出登录
    signOut () {
      logout().then(res => {
        this.$store.commit('del_token');
        this.$router.push("/");
      }).catch(e => {
        this.$store.commit('del_token');
      })
      var linkLength = document.getElementsByTagName("link");
      for (var i = 0; i < linkLength.length; i++) {
        if (linkLength[i].getAttribute("id") == "theme") {
          linkLength[i].parentNode.removeChild(linkLength[i]);
        }
      }
      closeRealMessage();
    },
    // 未读信息
    unreadDetail(){
      var _this = this
      messageData().then(res => {
        _this.unreadNum = res.data.data.notice;
      })
    },
    noticeClick(){
      this.$router.push({ name: "systemBulletin" });
      addTabs(this.$route.path, "系统公告");
    },
    // 密码不可见
    noSeeClick(text) {
      $("." + text + " .seeArea").hide();
      $("." + text + " .noSeeArea").show();
      $("." + text + " .el-input .el-input__inner").attr("type", "password");
    },
    // 密码可见
    seeClick(text) {
      $("." + text + " .seeArea").show();
      $("." + text + " .noSeeArea").hide();
      $("." + text + " .el-input .el-input__inner").attr("type", "text");
    },
    // 切换主题
    themeClick() {
      this.dialogStatus = "themeSwitch";
     this.userData();
      this.dialogFormVisible = true;
    },
    themeColor(val) {
      this.themeColorVal = val;
    },
    themeSubmit() {
      this.$loading.show();
      var params = new FormData();
      var themeType = "";
      if (this.themeColorVal == "rgb(119, 153, 47)") {
        themeType = "green";
      } else if (this.themeColorVal == "rgb(35, 134, 238)") {
        themeType = "blue";
      } else if (this.themeColorVal == "rgb(26, 172, 228)") {
        themeType = "cyan";
      } else if (this.themeColorVal == "rgb(35, 134, 238)") {
        themeType = "black";
      }
      params.append("theme", themeType);
      updatedTheme(params).then((res) => {
        if (res.data.code == 100) {
          this.dialogFormVisible = false;
          if (themeType == "green") {
            document.getElementById("theme").href =
              "./static/theme/defaultTheme.css";
            $("body").removeClass("blackTheme");
          } else if (themeType == "blue") {
            document.getElementById("theme").href =
              "./static/theme/themeBlue.css";
            $("body").removeClass("blackTheme");
          } else if (themeType == "cyan") {
            document.getElementById("theme").href =
              "./static/theme/themeCyan.css";
            $("body").removeClass("blackTheme");
          } else if (themeType == "black") {
            document.getElementById("theme").href =
              "./static/theme/themeBlack.css";
            $("body").addClass("blackTheme");
          }
        } else {
          handleAlert('error',res.data.msg)
        }
        this.$loading.hide();
      });
    },
    // 用户信息
    userData () {
      getUserInfo().then(res => {
        if (res.data.code == 100) {
          this.userInfo = res.data.data;
          this.infoData = Object.assign({}, this.userInfo);
          if (
            this.infoData.theme == "green" ||
            this.infoData.theme == undefined ||
            this.infoData.theme == null ||
            this.infoData.theme == ""
          ) {
            this.themeColorVal = "rgb(119, 153, 47)";
          } else if (this.infoData.theme == "blue") {
            this.themeColorVal = "rgb(35, 134, 238)";
          } else if (this.infoData.theme == "cyan") {
            this.themeColorVal = "rgb(26, 172, 228)";
          } else if(this.infoData.theme == "black"){
            this.themeColorVal = "rgb(35, 134, 238)";
          }
          getLogoPath().then(res=>{
            if (res.data.code == 100) {
              this.logoPath = sysServerUrl  +"sys/upload/display?filePath="+ res.data.data;
            }
          })
        }
      })
    },
    // 基本资料
    basicInfoReset() {
      this.infoData.username = "";
      this.infoData.realName = "";
      this.infoData.userType = "";
      this.infoData.sex = "";
      this.infoData.email = "";
      this.infoData.telephone = "";
      this.infoData.address = "";
      this.infoData.mobile = "";
      this.infoData.contacts = "";
      this.$nextTick(() => {
        this.$refs.infoData.clearValidate();
      });
    },
    basicInfo () {
      this.dialogStatus = 'info'
      this.basicInfoReset()
      this.userData()
      this.dialogFormVisible = true
    },
    // 确认修改基本信息
    infoSubmit () {
      this.$loading.show();
      var param = new URLSearchParams();
      param.append("username", this.infoData.username)
      param.append("realName", this.infoData.realName)
      param.append("sex", this.infoData.sex)
      param.append("email", this.infoData.email)
      param.append("contacts", this.infoData.contacts)
      param.append("mobile", this.infoData.mobile)
      param.append("address", this.infoData.address)
      userDefinedInfo(param).then(res => {
        if (res.data.code === 100) {
          handleAlert('success',"修改成功！")
          this.userData();
          this.dialogFormVisible = false;
        }else{
          handleAlert('error',"修改失败！")
        }
        this.$loading.hide();
      })
    },
    // 修改密码
    modifyCipher(){
      this.dialogStatus = 'password';
      this.resetPwd();
      this.dialogFormVisible = true;
    },
    // 自定义修改密码
    cipherSubmit (modify) {
      this.$refs[modify].validate((valid) => {
        if (valid) {
          this.$loading.show();
          var param = new URLSearchParams();
          param.append("oldPwd", this.modify.oldPassword)
          param.append("newPwd", this.modify.newPassword)
          param.append("confirmPwd", this.modify.confirmPassword)
          userDefinedPwd(param).then(res => {
            if (res.data.code === 100) {
              handleAlert('success',res.data.msg)
              this.dialogFormVisible = false;
            }else{
              handleAlert('error',res.data.msg)
            }
            this.$loading.hide();
          })
        } else {
          handleAlert('error',"请填写完整信息")
          this.$loading.hide();
        }
      })
    },
    resetPwd () {
      $(".seeArea").hide();
      $(".noSeeArea").show();
      $(".updatePswArea .el-input .el-input__inner").attr("type", "password");
      this.modify.oldPassword = "";
      this.modify.newPassword = "";
      this.modify.confirmPassword = "";
      this.$nextTick(() => {
        this.$refs.modify.resetFields();
      });
    },
    // 跳转前台
    frontPage() {
      userAtlasCountry().then(res => {
        this.countryList = res.data.data
        if (this.countryList.length == 1) {
          this.countryCode = this.countryList[0].code
          let url = frontHome + "?country=" + this.countryCode
          window.open(url);
        }else{
          this.countryCode = this.countryList[0].code
          this.dialogCountryFormVisible = true;
        }
      })
    },
    // 取消
    resetCode(){
      this.dialogCountryFormVisible = false;
      this.countryList = []
      this.countryCode = ''
    },
    // 跳转前台提交
    submitCountry(){
      this.dialogCountryFormVisible = false;
      let url = frontHome + "?country=" + this.countryCode
      window.open(url);
    }
  },
  mounted() {
    this.userData()
    // 未读信息
    this.unreadDetail();
    window.noticeUnread = () => {
      setTimeout(() => {
        this.unreadDetail();
      }, 100)
    }
  },
}
</script>
<style>
  /* 顶部导航 */
  .el-header{
    height: 55px !important;
    padding: 0 20px  0 0 !important;
    background: var(--header-color);
    width: 100%;
  }
  .headerContent {
    width: 100%;
    height: 100%;
  }
  .el-header .navHeader {
    width: 100%;
    height: 100%;
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
  }
  /* logo */
  .el-header .navHeader .logo{
    height: 55px;
    padding:0 20px;
    display: flex;
    align-items: center;
  }
  .el-header .navHeader .logo img{
    height: 23px;
  }
  /* 导航按钮 */
  .el-header .navHeader .navIcon>div {
    display: inline-block;
    margin-left: 35px;
    vertical-align: middle;
    cursor: pointer;
  }
  .navIcon .el-badge,
  .navIcon .el-tooltip {
    margin-top: 6px;
  }
  .el-tooltip__popper.is-light {
    border: 1px solid var(--theme-color) !important;
    padding: 6px 8px !important;
  }
  .el-tooltip__popper .popper__arrow {
    top: 100%;
    border-top-color: var(--theme-color)!important;
    border-bottom-color: var(--theme-color)!important;
  }
  .el-header .navHeader .el-dropdown span{
    color: #fff ;
  }
  .navIcon .el-badge__content {
    height: 16px;
    line-height: 16px;
  }
  /* 下拉框 */
  .dropdrow{
    font-size: 15px;
    color: #333;
  }
  .dropdrow img{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .el-header .el-dropdown span{
    display: inline-block;
    height: 100%;
  }
  .el-dropdown {
    color: #333 !important;
    font-size: 15px !important;
  }
  .headerContent .themeType {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 15px;
  }
  .headerContent .themeType > div {
    width: 25%;
    border: 2px solid transparent;
    min-width: 215px;
    margin: 15px 10px;
  }
  .headerContent .themeType > div > div {
    text-align: center;
    padding-bottom: 8px;
    font-size: 16px;
  }
  .headerContent .themeType img {
    padding: 15px;
    width: calc(100% - 30px);
    cursor: pointer;
  }
  .submitArea,
  .el-dialog .submitArea,
  .headerContent .el-dialog .submitArea {
    text-align: center;
    margin: 15px 0 !important;
  }
  .submitArea .el-form-item__content{
    margin: 0 !important;
  }
  .headerContent .el-dialog .updatePswArea .el-input .el-input__inner {
    padding: 0 30px 0 10px;
  }
  .headerContent .el-dialog .updatePswArea img {
    cursor: pointer;
    width: 20px;
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translate(0, -50%);
  }
  .headerContent .el-dialog .updatePswArea .seeArea {
    display: none;
  }
  @media screen and (max-width: 1600px) and (min-width: 1440px) {
    /* 顶部导航 */
    .el-header{
      height: 50px !important;
    }
    /* logo */
    .el-header .navHeader .logo{
      height: 50px;
    }
    /* 导航按钮 */
    .el-header .navHeader {
      font-size: 16px;
    }
    .el-header .navHeader .navIcon>div {
      margin-left: 35px;
    }
    .navIcon .el-badge {
      margin-top: 6px;
    }
    .navIcon .el-badge img {
      width: 21px;
      height: 21px;
    }
    .navIcon .el-badge__content {
      height: 15px;
      line-height: 15px;
    }
    /* 下拉框 */
    .dropdrow{
      font-size: 16px;
    }
    .dropdrow img{
      width: 28px;
      height: 28px;
    }
    .el-dropdown {
      font-size: 16px !important;
    }
    .headerContent .themeType > div > div {
      font-size: 15px;
    }
    .headerContent .el-dialog .updatePswArea img {
      width: 19px;
    }
  }
  @media screen and (max-width: 1440px) and (min-width: 1366px) {
    /* 顶部导航 */
    .el-header{
      height: 50px !important;
    }
    /* logo */
    .el-header .navHeader .logo{
      height: 50px;
    }
    .headerContent .el-dialog .updatePswArea img {
      width: 19px;
    }
  }
  @media screen and (max-width: 1366px) and (min-width: 1280px) {
    /* 顶部导航 */
    .el-header{
      height: 40px !important;
    }
    /* logo */
    .el-header .navHeader .logo{
      height: 40px;
    }
    /* 导航按钮 */
    .el-header .navHeader {
      font-size: 15px;
    }
    .el-header .navHeader .navIcon>div {
      margin-left: 25px;
    }
    .navIcon .el-badge {
      margin-top: 6px;
    }
    .navIcon .el-badge img {
      width: 18px;
      height: 18px;
    }
    .navIcon .el-badge__content {
      height: 15px;
      line-height: 15px;
    }
    /* 下拉框 */
    .dropdrow{
      font-size: 15px;
    }
    .dropdrow img{
      width: 25px;
      height: 25px;
    }
    .el-dropdown {
      font-size: 15px !important;
    }
    .headerContent .themeType img {
      padding: 8px;
      width: calc(100% - 16px);
    }
    .headerContent .themeType > div > div {
      font-size: 14px;
    }
    .headerContent .el-dialog .updatePswArea img {
      width: 16px;
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 1024px) {
    /* 顶部导航 */
    .el-header{
      height: 40px !important;
    }
    /* logo */
    .el-header .navHeader .logo{
      height: 40px;
    }
    /* 导航按钮 */
    .el-header .navHeader {
      font-size: 14px;
    }
    .el-header .navHeader .navIcon>div {
      margin-left: 25px;
    }
    .navIcon .el-badge {
      margin-top: 6px;
    }
    .navIcon .el-badge img {
      width: 18px;
      height: 18px;
    }
    .navIcon .el-badge__content {
      height: 14px;
      line-height: 14px;
    }
    /* 下拉框 */
    .dropdrow{
      font-size: 14px;
    }
    .dropdrow img{
      width: 23px;
      height: 23px;
    }
    .el-dropdown {
      font-size: 14px !important;
    }
    .headerContent .themeType img {
      padding: 8px;
      width: calc(100% - 16px);
    }
    .headerContent .themeType > div > div {
      font-size: 13px;
    }
    .headerContent .el-dialog .updatePswArea img {
      width: 15px;
    }
  }
  @media screen and (max-width: 1024px) {
    /* 顶部导航 */
    .el-header{
      height: 40px !important;
    }
    /* logo */
    .el-header .navHeader .logo{
      height: 40px;
    }
    /* 导航按钮 */
    .el-header .navHeader {
      font-size: 14px;
    }
    .el-header .navHeader .navIcon>div {
      margin-left: 25px;
    }
    .navIcon .el-badge {
      margin-top: 6px;
    }
    .navIcon .el-badge img {
      width: 18px;
      height: 18px;
    }
    .navIcon .el-badge__content {
      height: 14px;
      line-height: 14px;
    }
    /* 下拉框 */
    .dropdrow{
      font-size: 14px;
    }
    .dropdrow img{
      width: 25px;
      height: 25px;
    }
    .el-dropdown {
      font-size: 14px !important;
    }
    .headerContent .themeType img {
      padding: 8px;
      width: calc(100% - 16px);
    }
    .headerContent .themeType > div > div {
      font-size: 13px;
    }
    .headerContent .el-dialog .updatePswArea img {
      width: 15px;
    }
  }
</style>
