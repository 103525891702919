<template>
  <div class="tabContent">
    <div class="tabCenter">
      <div v-for="(tab, index) in tabList" :key="index" v-show="selectTabPath == tab.path">
        <div v-if='tab.label.indexOf(">") == -1'>
          {{tab.label}}
        </div>
        <div v-if='tab.label.indexOf(">") !== -1'>
          <div v-if='tab.label.toString().split(">").length-1 == 2'>
            <span class="twoText" @click='pathClick(tab.label.split(">")[2])'>
              {{ tab.label.split(">")[0] }}
            </span>
            <span>
              {{ tab.label.split(">")[1] }}
            </span>
          </div>
          <div v-if='tab.label.toString().split(">").length-1 == 3'>
            <div v-if='tab.label.indexOf("&") == -1'>
              <span>
                {{ tab.label.split(">")[0] }}
              </span>
              <span class="twoText" @click='pathClick(tab.label.split(">")[3])'>
                {{ tab.label.split(">")[1] }}
              </span>
              <span>
                {{ tab.label.split(">")[2] }}
              </span>
            </div>
            <div v-if='tab.label.indexOf("&") !== -1'>
               <span>
                {{ tab.label.split(">")[0] }}
              </span>
              <span class="twoText" @click='pathClick(tab.label.split("&")[1])'>
                {{ tab.label.split(">")[1].split("&")[0] }}
              </span>
              <span class="twoText" @click='pathClick(tab.label.split(">")[3])'>
                {{ tab.label.split(">")[1].split("&")[2] }}
              </span>
              <span>
                {{ tab.label.split(">")[2] }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="tabCenter">
      <div class="leftIcon" @click="leftList">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="homeArea">
        <el-tag
          size="medium"
          v-for="(tab, index) in firstTab"
          :key="tab.label"
          @close="handleClose(tab, index)"
          @click.native="changeMenu(tab)"
          :closable="tab.path !== '/dashboard'"
          :effect="selectTabPath === tab.path ? 'dark' : 'plain'"
        >
          <span>{{ tab.label }}</span>
        </el-tag>
      </div>
      <div class="centerTab">
        <div class="tabs-switch-page">
          <el-tag
            size="medium"
            v-for="(tab, index) in tabList"
            :key="tab.name"
            @close="handleClose(tab, index)"
            @click="changeMenu(tab)"
            :closable="tab.path !== '/dashboard'"
            :effect="selectTabPath === tab.path ? 'dark' : 'plain'"
          >
            <span v-if="tab.label.length <= 15">
              {{ tab.label }}
            </span>
            <span :title="tab.label" v-else>
              {{ tab.label.substr(0, 15) }}...
            </span>
          </el-tag>
        </div>
      </div>
      <div class="rightIcon" @click="tabsRight">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="tabs-close-box">
        <el-dropdown @command="tabsCloseItem" :show-timeout="100">
          <i class="el-icon-close"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="other" style="white-space: nowrap">
              关闭其他
            </el-dropdown-item>
            <el-dropdown-item command="all" style="white-space: nowrap">
              关闭所有
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex';
import $ from 'jquery';
export default {
  computed: {
    ...mapState({ // 从 state 中的到的计算属性
      selectTabPath: state => state.selectTabPath, // 已选中菜单
      // firstTab: (state) => state.firstTab, // tags菜单列表
      tabList: state => state.tabList,  // tags菜单列表
    })
  },
  data() {
    return {
    }
  },
  watch: {
    $route(to) {
      this.tagNavStart(to.path);
      // if (this.$store.state.tabList.length > 0) {
      //   this.setTabsPosition();
      // }
    }
  },
  methods: {
    pathClick(path){
      this.$router.push(path)
      if(path == '/systemBulletin'){
        addTabs(this.$route.path, "系统公告");
      }
    },
    tagNavStart(item) {
      this.$store.state.selectTabPath = item;
    },
    // // 关闭tab标签
    // handleClose(tab, index) {
    //   this.cloaseTabsPosition();
    //   // 历史选中菜单
    //   var oldActivePath = this.$store.state.selectTabPath;
    //   var pathVal = "";
    //   // 历史已选中菜单列表
    //   var oldTabList = this.$store.state.tabList;
    //   // 计算标签个数
    //   let length = oldTabList.length - 1;
    //   // 删除tabList中的该对象
    //   for (let i = 0; i < oldTabList.length; i++) {
    //     var item = oldTabList[i];
    //     if (item.path === tab.path) {
    //       oldTabList.splice(i, 1);
    //     }
    //   }
    //   // 删除keepAlive缓存
    //   this.$store.commit("removeKeepAliveCache", tab.path);
    //   // 如果关闭的标签不是当前路由的话，就不跳转
    //   if (tab.path !== oldActivePath) {
    //     return;
    //   }
    //   // 如果length为1，必然只剩下首页标签，此时关闭后，更新到首页
    //   if (length === 0) {
    //     // 同时存储菜单
    //     this.$store.commit("closeTab", {
    //       selectTabPath: "/dashboard",
    //       tabList: oldTabList,
    //     });
    //     this.$router.push("/dashboard");
    //     // tab页向左跳转
    //     // 不再向下执行
    //     return;
    //   }
    //   // 关闭的标签是最右边的话，往左边跳转一个
    //   if (index === length) {
    //     // 同时更新路径
    //     oldActivePath = oldTabList[index - 1].path;
    //     pathVal = oldTabList[index - 1].path;
    //     // 同时存储菜单
    //     this.$store.commit("closeTab", {
    //       selectTabPath: oldActivePath,
    //       tabList: oldTabList,
    //     });
    //     this.$router.push(oldTabList[index - 1].path);
    //   } else {
    //     // 同时更新路径
    //     oldActivePath = oldTabList[index].name;
    //     pathVal = oldTabList[index].path;
    //     // 同时存储菜单
    //     this.$store.commit("closeTab", {
    //       selectTabPath: oldActivePath,
    //       tabList: oldTabList,
    //     });
    //     // tab页向右跳转
    //     this.$router.push(oldTabList[index].path);
    //   }
    // },
    // // 标签样式
    // tagNavStart(item) {
    //   if (item.path === "/dashboard") {
    //     this.$store.state.selectTabPath = "/dashboard";
    //   } else {
    //     this.$store.state.selectTabPath = item.path;
    //   }
    // },
    // // 点击标签跳转路由
    // changeMenu(item) {
    //   // 历史选中菜单
    //   var oldActivePath = this.$store.state.selectTabPath;
    //   // 首先判断点击的是否是自己，如果是自己则return
    //   if (oldActivePath === item.path) {
    //     return;
    //   }
    //   // 不是自己，存储菜单
    //   this.$store.commit("changeMenu", item.path);
    //   this.$router.push(item.path);
    // },
    // // 左移动
    // leftList() {
    //   let tboxWidth = $(".centerTab").outerWidth(true); //外边盒子距离长度
    //   let ulWidth = $(".tabs-switch-page")[0].offsetWidth; //tab 栏宽度
    //   let ul = document.getElementsByClassName("tabs-switch-page")[0]; //tabs外边盒子长度
    //   let value = ulWidth - tboxWidth;
    //   let left = parseInt(ul.style.marginLeft.replace("px", ""));
    //   if (tboxWidth > ulWidth) {
    //     ul.style.marginLeft = 0 + "px";
    //   }
    //   if (!left) {
    //     left = 0;
    //   }
    //   if (value > 0) {
    //     // 移动步长为200
    //     if (parseInt(-left - 200) <= 200) {
    //       ul.style.marginLeft = 0 + "px";
    //     } else {
    //       ul.style.marginLeft = "-" + (-left - 200) + "px";
    //     }
    //   }
    // },
    // // 右移动
    // tabsRight() {
    //   let tboxWidth = $(".centerTab").outerWidth(true); //外边盒子距离长度
    //   let ulWidth = $(".tabs-switch-page")[0].offsetWidth; //tab 栏宽度
    //   let ul = document.getElementsByClassName("tabs-switch-page")[0]; //tabs外边盒子长度
    //   let value = ulWidth - tboxWidth; //差值    为负时：不需要移动  为正时：需要移动
    //   let left = parseInt(ul.style.marginLeft.replace("px", ""));
    //   if (!left) {
    //     left = 0;
    //   }
    //   if (value > 0) {
    //     // 移动步长为200
    //     if (parseInt(-left + 200) <= value) {
    //       ul.style.marginLeft = "-" + (-left + 200) + "px";
    //     } else {
    //       ul.style.marginLeft = "-" + (value - 15) + "px";
    //     }
    //   }
    // },
    // cloaseTabsPosition(event) {
    //   setTimeout(function () {
    //     if ($(".tabs-switch-page")[0] != undefined) {
    //       let tboxWidth = $(".centerTab").outerWidth(true); //外边盒子距离长度
    //       let ulWidth = $(".tabs-switch-page")[0].offsetWidth; //tab 栏宽度
    //       let ul = document.getElementsByClassName("tabs-switch-page")[0]; //tabs外边盒子长度
    //       let left = parseInt(ul.style.marginLeft.replace("px", ""));
    //       if (tboxWidth > ulWidth) {
    //         ul.style.marginLeft = 0 + "px";
    //       }
    //       if (!left) {
    //         left = 0;
    //       }
    //     }
    //   });
    // },
    // // 设置tab栏位置
    // setTabsPosition() {
    //   setTimeout(function () {
    //     if ($(".tabs-switch-page")[0] != undefined) {
    //       let tboxWidth = $(".centerTab").outerWidth(true); //外边盒子距离长度
    //       let ulWidth = $(".tabs-switch-page")[0].offsetWidth; //tab 栏宽度
    //       let ul = document.getElementsByClassName("tabs-switch-page")[0]; //tabs外边盒子长度
    //       let left = Number(ul.style.marginLeft.replace("px", ""));
    //       let fistTabWidth = $(".homeArea").outerWidth(true);
    //       let leftButWidth = $(".leftIcon").outerWidth(true);
    //       let asideWidth = $(".asideContent").outerWidth(true);
    //       let offsetVal = 0;
    //       if ($(".el-tag--dark").offset() !== undefined) {
    //         offsetVal =
    //           $(".el-tag--dark").offset().left - fistTabWidth - leftButWidth - asideWidth;
    //       }
    //       var value = ulWidth - tboxWidth;
    //       if (!left) {
    //         left = 0;
    //       }
    //       if (value > 0) {
    //         if (offsetVal > 0) {
    //           // 右
    //           if (parseInt(-left + Math.abs(offsetVal)) <= value) {
    //             ul.style.marginLeft =
    //               "-" + (-left + Math.abs(offsetVal)) + "px";
    //           } else {
    //             ul.style.marginLeft = "-" + value + "px";
    //           }
    //         } else if (offsetVal < 0) {
    //           // 左
    //           if (parseInt(-left + Math.abs(offsetVal)) <= tboxWidth) {
    //             ul.style.marginLeft = 0 + "px";
    //           } else {
    //             ul.style.marginLeft =
    //               "-" + (-left - Math.abs(offsetVal)) + "px";
    //           }
    //         }
    //         var marginLeft = Number(ul.style.marginLeft.replace("px", ""));
    //         sessionStorage.setItem("leftVal", marginLeft);
    //       } else {
    //         sessionStorage.setItem("leftVal", 0);
    //       }
    //     }
    //   });
    // },
    // // 关闭事件
    // tabsCloseItem(item) {
    //   $(".tabs-switch-page").css("marginLeft", "0");
    //   sessionStorage.removeItem("leftVal");
    //   switch (item) {
    //     case "other":
    //       var currentPath = this.$store.state.selectTabPath;
    //       var allList = this.$store.state.tabList;
    //       if (allList.length != 0) {
    //         if (currentPath == "/dashboard") {
    //           this.$store.commit("clearVUEX");
    //           this.$router.push("/dashboard");
    //           // sessionStorage.clear();
    //         } else {
    //           for (let i = 0; i < allList.length; i++) {
    //             if (allList[i].path == currentPath) {
    //               allList = allList[i];
    //             }
    //           }
    //           this.$store.commit("clearVUEX");
    //           this.$store.commit("selectMenu", allList);
    //         }
    //       }
    //       break;
    //     case "all":
    //       this.$store.commit("clearVUEX");
    //       this.$router.push("/dashboard");
    //       // sessionStorage.clear();
    //       break;
    //   }
    // },
    // sizeArea() {
    //   var _this = this;
    //   _this.setTabsPosition();
    //   window.addEventListener("resize", function () {
    //     _this.setTabsPosition();
    //     setTimeout(() => {
    //       _this.moveDistance();
    //     });
    //   });
    // },
    // moveDistance() {
    //   var length = this.$store.state.tabList.length;
    //   if (length > 0) {
    //     var tabsLeft = sessionStorage.getItem("leftVal");
    //     setTimeout(function () {
    //       $(".tabs-switch-page").css("marginLeft", tabsLeft + "px");
    //     });
    //   } else {
    //     sessionStorage.removeItem("leftVal");
    //     setTimeout(function () {
    //       $(".tabs-switch-page").removeAttr("style");
    //     });
    //   }
    // },
  },
  mounted() {
    // this.sizeArea();
    // this.moveDistance();
  },
}
</script>
<style>
.tabContent {
    border-bottom: 2px solid #eee;
    height: 40px;
    line-height: 40px;
  }
  .tabCenter {
    margin-left: 18px;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
  }
  .tabCenter div span::after{
    content: ">";
    margin: 0 10px;
    color: #000
  }
  .tabCenter div span:last-child:after{
    content: "";
    margin: 0;
  }
  .twoText {
    cursor: pointer;
    color: #1890ff;
  }
/* .tabContent {
  height: 40px;
  line-height: 40px;
  position: relative;
}
.tabCenter {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #eee;
} */
/* 左右 */
/* .leftIcon,
.rightIcon,
.tabs-close-box,
.homeArea {
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 12px;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(76, 76, 76, 0.5);
}
.leftIcon,
.rightIcon,
.tabs-close-box {
  padding: 0 2px;
}
.tabs-close-box,
.homeArea {
  background: transparent;
  box-shadow: none;
}
.tabCenter .el-icon-arrow-left,
.tabCenter .el-icon-arrow-right,
.tabs-close-box .el-icon-close {
  font-size: 15px;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  vertical-align: middle;
}
.tabs-close-box .el-icon-close {
  border: 1px solid #000;
  padding: 3px;
  margin: 0 5px;
  border-radius: 4px;
  background: #fff;
}
.el-dropdown-menu {
  padding: 8px 0 !important;
}
.el-dropdown-menu__item {
  font-size: 15px;
  line-height: 1 !important;
  padding: 12px 18px !important;
  margin: 2px 0;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:hover {
  background-color: var(--hover-color) !important;
  color: var(--theme-color) !important;
}
.centerTab {
  flex: 1;
  overflow: hidden;
  position: relative;
}
.tabs-switch-page {
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 5px 0 0;
  width: max-content;
  margin-left: 0;
  line-height: 40px;
}
.el-tag {
  color: #aaaaaa !important;
  cursor: pointer;
  font-size: 15px !important;
  margin-right: 12px;
  padding: 0 12px 0 12px !important;
  background-color: #fff !important;
  border: none !important;
  border: 1px solid #aaaaaa !important;
}
.el-tag.el-tag--medium.el-tag--dark {
  color: var(--theme-color) !important;
  border: 1px solid var(--theme-color) !important;
}
.el-tag.el-tag--medium.el-tag--plain .el-icon-close:before {
  color: #aaaaaa !important;
}
.el-tag.el-tag--medium.el-tag--dark .el-icon-close:before {
  color: var(--theme-color) !important;
}
.el-tag--medium {
  height: 28px !important;
  line-height: 27px !important;
}
.el-tag .el-tag__close {
  color: #b2b2b2 !important;
  font-size: 16px !important;
  transform: scale(1);
  font-weight: bold;
  margin-left: 1px;
}
.el-tag .el-tag__close:hover {
  background: transparent !important;
} */
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .tabContent {
    height: 38px;
    line-height: 38px;
  }
  .tabCenter {
    margin-left: 40px;
    font-size: 15px;
  }
  /* .tabContent {
    height: 38px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 38px;
    line-height: 38px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right,
  .tabs-close-box .el-icon-close {
    font-size: 14px;
  }
  .tabs-close-box .el-icon-close {
    padding: 2px;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 37px;
  } */
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  .tabContent {
    height: 32px;
    line-height: 32px;
  }
  .tabCenter {
    margin-left: 18px;
    font-size: 15px;
  }
  /* .tabContent {
    height: 32px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 32px;
    line-height: 32px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right,
  .tabs-close-box .el-icon-close {
    font-size: 12px;
  }
  .tabs-close-box .el-icon-close {
    padding: 2px;
  }
  .el-dropdown-menu__item {
    padding: 8px 12px !important;
    font-size: 13px !important;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 32px;
  }
  .el-tag {
    font-size: 14px !important;
  }
  .el-tag--medium {
    height: 24px !important;
    line-height: 23px !important;
  }
  .el-tag .el-icon-close {
    top: -2px;
    font-size: 14px !important;
  } */
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .tabContent {
    height: 30px;
    line-height: 30px;
  }
  .tabCenter {
    margin-left: 18px;
    font-size: 14px;
  }
  /* .tabContent {
    height: 30px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 30px;
    line-height: 30px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box {
    padding: 0 2px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right,
  .tabs-close-box .el-icon-close {
    font-size: 9px;
  }
  .tabs-close-box .el-icon-close {
    padding: 2px;
  }
  .el-dropdown-menu__item {
    padding: 8px 10px !important;
    font-size: 13px !important;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 30px;
  }
  .el-tag {
    font-size: 13px !important;
  }
  .el-tag--medium {
    height: 22px !important;
    line-height: 22px !important;
  }
  .el-tag .el-icon-close {
    top: -2px;
    font-size: 13px !important;
  } */
}
@media screen and (max-width: 1024px) {
  .tabContent {
    height: 30px;
    line-height: 30px;
  }
  .tabCenter {
    margin-left: 15px;
    font-size: 14px;
  }
  /* .tabContent {
    height: 30px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 30px;
    line-height: 30px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right,
  .tabs-close-box .el-icon-close {
    font-size: 9px;
  }
  .tabs-close-box .el-icon-close {
    padding: 2px;
  }
  .el-dropdown-menu__item {
    padding: 8px 10px !important;
    font-size: 13px !important;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 30px;
  }
  .el-tag {
    font-size: 13px !important;
  }
  .el-tag--medium {
    height: 23px !important;
    line-height: 22px !important;
  }
  .el-tag .el-icon-close {
    top: -2px;
    font-size: 14px !important;
  } */
}
</style>
