<template>
  <div class="layoutContainer auditContent">
    <div class="tableDetail">
      <el-table
        style="width:100%"
        border
        stripe
        ref="table"
        highlight-current-row
        :max-height="maximumHeight"
        :data="resultList"
        :header-cell-style="{
          'text-align':'center',
          'background-color': 'var(--other-color)',
        }"
        @header-dragend="changeColWidth"
      >
        <el-table-column label="Topic名称" prop="directoryName" min-width="150"></el-table-column>
        <el-table-column label="审核状态" prop="status" min-width="150" align="center">
            <template slot-scope="{row}">
            <span v-if="row.status === 0">未审核</span>
            <span v-if="row.status === 1">审核通过</span>
            <span v-if="row.status === 2">审核驳回</span>
          </template>
        </el-table-column>
        <el-table-column label="驳回原因" prop="reason" min-width="150"></el-table-column>
        <el-table-column label="审核人" prop="auditorName" width="150" align="center"></el-table-column>
        <el-table-column label="审核时间" prop="createdTime" align="center" width="160" :formatter='dateFormat'></el-table-column>
        <el-table-column label="操作" prop="auditorName" fixed="right" width="140" align="center">
          <template slot-scope="{row}">
            <el-button type="text" v-if="hasPerm('menuAsimssCMS3A3B_115')" size="small" @click="historyAudit(row)">历史审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog width="790px !important" title="历史审核"  :visible.sync="dialogFormVisible">
        <el-table
          style="width:100%"
          border
          stripe
          ref="table"
          highlight-current-row
          :data="historyList"
          :header-cell-style="{
            'text-align': 'center',
            'background-color': 'var(--other-color)',
          }"
          @header-dragend="changeColWidth"
        >
          <el-table-column label="审核状态" prop="status" width="110" align="center">
            <template slot-scope="{row}">
              <span v-if="row.status === 0">未审核</span>
              <span v-if="row.status === 1">审核通过</span>
              <span v-if="row.status === 2">审核驳回</span>
            </template>
          </el-table-column>
          <el-table-column label="驳回原因" prop="reason" min-width="350">
            <template slot-scope="{row}">
              <span v-if="row.status === 2">{{row.reason}}</span>
              <span v-if="row.status === 1">无</span>
            </template>
          </el-table-column>
          <el-table-column label="审核人" prop="auditorName" width="120" align="center"></el-table-column>
          <el-table-column label="审核时间" align="center" prop="createdTime" width="160" :formatter='dateFormat'></el-table-column>
        </el-table>
        <pagination v-show="audittotal>0" :total="audittotal" :page.sync="auditcurrentPage" :limit.sync="auditpagesize" @pagination="dataList"/>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, handleAlert, tableHeight, tableMaxHeight } from '@/assets/js/common.js'
import { auditDetailData, auditHistory } from '@/api/cmsmgt.js'
import Pagination from '@/components/Pagination'
import axios from 'axios'
export default {
  name: 'auditDetail',
  components: { Pagination },
  data () {
    return {
      manualId: '',
      manualVersion: '',
      formInline: {
        manualVersion: '',
        queryDirectorName: '',  // Topic名称
        queryAuditStatus: '',   // 审核状态
        beginTime: null,  // 审核开始时间
        endTime: null,   // 审核结束时间
        queryAuditor: ''   // 审核人
      },
      resultList: [],
      versionList: [],
      auditorList: [],
      historyList: [],
      dialogFormVisible:false,
      pagesize: 20,
      currentPage: 1,
      total: 0,
      auditpagesize: 20,
      auditcurrentPage: 1,
      audittotal: 0,
      maximumHeight: 0,
    }
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "auditDetail") {
        _this.manualId = to.params.id
        _this.manualVersion = to.params.version
        _this.dataList()
      }
    }
  },
  methods: {
    // 监听表格列宽变化
    changeColWidth(){
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      })
    },
    // 数据
    dataList () {
      this.$loading.show();
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        manualId: this.manualId,  // 手册ID
        manualVersion: this.manualVersion,    // 手册版本号
      }
      auditDetailData(params).then(res => {
        this.total = res.data.total;
        this.resultList = res.data.data;
        this.tableHeightArea();
        this.$loading.hide();
      })
    },
    historyAudit(row){
      let directoryId=row.directoryId
      var params = {
        page: this.auditcurrentPage,
        limit: this.auditpagesize,
        directoryId: directoryId
      }
      auditHistory(params).then(res => {
        if(res.data.code==100){
          this.audittotal = res.data.total
          this.historyList = res.data.data
          this.dialogFormVisible=true
        }else{
          handleAlert('error','查询历史审核数据失败')
        }
      }).catch(function(error){
        handleAlert('error','查询历史审核数据失败')
      })
    },
    // 时间转换
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        const H = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        const Min = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
        const S = date.getSeconds() < 10 ? '0' + date.getSeconds() + '' : date.getSeconds()
        return Y + M + D + H + Min + S
      }
    },
     maximumArea() {
      setTimeout(() => {
        tableHeight()
        setTimeout(() => {
          this.maximumHeight = tableMaxHeight;
        }, 50)
      }, 50)
    },
    tableHeightArea() {
      var _this = this;
      _this.maximumArea();
      window.addEventListener("resize", function () {
        _this.maximumArea();
      });
    }
  },
  mounted () {
    this.manualId = this.$route.params.id
    this.manualVersion =this.$route.params.version
    this.dataList()
  },
}
</script>
