// import '../../node_modules/jquery/src/jquery'
//热点g标签Id
export let hotLabelId = "Label_Layer";
//热点位置集合索引
export let hotLocationX = 4;
export let hotLocationY = 5;

export let hotReduceY = 3;

//热点圆圈半径
export let hotRadius = "9";

// eslint-disable-next-line no-unused-vars
export function loadSvg(svgPath) {
  $("#mysvg").svg("destroy");
  $("#mysvg").svg({
    loadURL: svgPath, 
    onLoad: function (svg) {
      onloadsvg("mysvg");
    },
  });
}

function onloadsvg(id) {
  //svg放大缩小
  var $parent = $(".carte-svg-multipe");
  var $panzoom = $("#mysvg").panzoom({
    $zoomIn: $parent.find(".zoom-in"),
    $zoomOut: $parent.find(".zoom-out"),
    $reset: $parent.find(".recover"),
  });
  $panzoom.parent().on("mousewheel.focal DOMMouseScroll.focal", function (e) {
    e.preventDefault();
    var delta = -e.detail || e.delta || e.originalEvent.wheelDelta;
    var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
    $panzoom.panzoom("zoom", zoomOut, {
      animate: false,
      focal: e,
    });
  });

  // if (!id) {
  //   id = "mysvg";
  // }
  // // 去除title标签
  // $("#mysvg").find("title").remove();
  // //热点标签text放在id=Hot_Point的g标签下
  // var $child = $("#" + id + "")
  //   .find("#" + hotLabelId)
  //   .children();

  // var isHotPiont = false;
  // if ($child.length > 0) {
  //   isHotPiont = true;
  // } else {
  //   $child = $("text");
  // }

  // //根据id和data-name确认热点元素code,并赋值给其data-code属性和class属性
  // $child.each(function (index) {
  //   var tagName = $(this).get(0).tagName;
  //   if (tagName != "text") {
  //     return true;
  //   }

  //   var text = $(this).text();
  //   var circle = document.createElementNS(
  //     "http://www.w3.org/2000/svg",
  //     "circle"
  //   );
  //   $(this).mouseover(function () {
  //     if (isHotPiont) {
  //       if ($("#" + hotLabelId + " circle").attr("flag") == "none") {
  //         $("#" + hotLabelId + "  circle").remove();
  //       }
  //     } else {
  //       $("circle").remove();
  //     }

  //     $(this).parent("g").append(circle);
  //     //设置位置
  //     var transform = $(this).attr("transform");
  //     var transformType = transform.substring(0, transform.indexOf("("));
  //     if (transformType == "translate") {
  //       hotLocationX = 0;
  //       hotLocationY = 1;
  //     } else {
  //       hotLocationX = 4;
  //       hotLocationY = 5;
  //     }
  //     var transformArray = transform
  //       .substring(transform.indexOf("(") + 1, transform.indexOf(")"))
  //       .split(" ");
  //     var translateX =
  //       parseFloat(transformArray[hotLocationX]) + this.getBBox().width / 2;
  //     var translateY = parseFloat(transformArray[hotLocationY]) - hotReduceY;
  //     $(circle).attr({
  //       cx: translateX,
  //       cy: translateY,
  //       r: hotRadius,
  //       stroke: "#FFDD02",
  //       fill: "none",
  //       "stroke-width": "3",
  //       flag: "none",
  //       fg: "ye",
  //     });
  //   });
  //   $(this).mouseout(function () {
  //     if ($(circle).attr("flag") == "none") {
  //       $(circle).remove();
  //     }
  //   });
  // });

  // $child.css({
  //   cursor: "pointer",
  // });

  // //热点单击回调
  // $child.click(function () {
  //   var tagName = $(this).get(0).tagName;
  //   if (tagName != "text") {
  //     return true;
  //   }
  //   var trIndex = $(this).text();
  //   //删除已存在的圆圈元素
  //   $("#" + hotLabelId + " circle").remove();

  //   //重新遍历父标签g下的text标签,text值相同的添加圆圈元素
  //   $(this)
  //     .parent("g")
  //     .children()
  //     .each(function (index) {
  //       var tagName = $(this).get(0).tagName;
  //       if (tagName != "text") {
  //         return true;
  //       }
  //       var textVal = $(this).text();
  //       if (trIndex != textVal) {
  //         return true;
  //       }

  //       var circle = document.createElementNS(
  //         "http://www.w3.org/2000/svg",
  //         "circle"
  //       );
  //       $(this).parent("g").append(circle);
  //       //设置圆圈位置
  //       var transform = $(this).attr("transform");
  //       var transformType = transform.substring(0, transform.indexOf("("));
  //       if (transformType == "translate") {
  //         hotLocationX = 0;
  //         hotLocationY = 1;
  //       } else {
  //         hotLocationX = 4;
  //         hotLocationY = 5;
  //       }
  //       var transformArray = transform
  //         .substring(transform.indexOf("(") + 1, transform.indexOf(")"))
  //         .split(" ");
  //       var translateX =
  //         parseFloat(transformArray[hotLocationX]) + this.getBBox().width / 2;
  //       var translateY = parseFloat(transformArray[hotLocationY]) - hotReduceY;
  //       $(circle).attr({
  //         cx: translateX,
  //         cy: translateY,
  //         r: hotRadius,
  //         stroke: "#E30A0A",
  //         fill: "none",
  //         "stroke-width": "3",
  //         flag: "selected",
  //       });
  //     });

  //   //修改svg 对应的表格列表样式
  //   $("tr").removeClass("trStyle");
  //   $(".lightUp." + trIndex).addClass("trStyle");
  //   $("tr td:nth-child(2) div").removeClass("relationWhite");
  //   $(".lightUp." + trIndex + " td:nth-child(2) div").addClass("relationWhite");
  //   var trHeight = $(".lightUp." + trIndex).outerHeight(true);
  //   var table = $(".admin-main").height();
  //   var RightField = $(".detailField").height();
  //   var Range =
  //     $(".lightUp." + trIndex).offset().top - $(".admin-main").offset().top;
  //   if (table - RightField <= 0) {
  //     return;
  //   } else if (table - RightField > 0) {
  //     if (Range - RightField <= 0) {
  //       var scrollRange =
  //         $(".detailField").scrollTop() +
  //         ($(".lightUp." + trIndex).offset().top -
  //           $(".detailField").offset().top);
  //       if (scrollRange < table - RightField) {
  //         $(".detailField").scrollTop(0);
  //       } else {
  //         $(".detailField").scrollTop(scrollRange);
  //       }
  //     } else if (Range - RightField > 0) {
  //       $(".detailField").scrollTop(Range - RightField + trHeight);
  //     }
  //   }
  // });
}

/**
 * 根据元素class点亮热点
 * @param svgBoxId: svg外层dom元素id
 * @param className：目标元素class名称
 */
// function lightElement(svgBoxId, className) {
//   // 该目标元素之外的所有热点取消点亮
//   $("#" + svgBoxId + "#" + hotLabelId + "  *")
//     .not("." + className + " *")
//     .removeClass("animate textfill linefill");
//   for (var i = 0; i < $("#" + svgBoxId + " ." + className).length; i++) {
//     // 目标热点

//     var $element = $("#" + svgBoxId + " ." + className).eq(i);

//     var $children = $element.children();
//     var _length = $children.length;
//     // 1、有子元素
//     if (_length > 0) {
//       // 1.1 若已经点亮
//       if ($children.hasClass("animate")) {
//         $children.removeClass("animate textfill linefill");
//         // 1.2 若未点亮
//       } else {
//         if (!$children.is("text")) {
//           $children.addClass("animate linefill");
//         } else {
//           for (var j = 0; j < _length; j++) {
//             if ($children.eq(j).is("text")) {
//               console.log("1");
//             } else {
//               $children.eq(j).addClass("animate textfill");
//             }
//           }
//         }
//       }
//       // 2、无子元素
//     } else {
//       // 2.1 若已经点亮
//       if ($element.hasClass("animate")) {
//         $element.removeClass("animate textfill linefill");
//         // 2.2 若未点亮
//       } else {
//         if ($element.is("polygon")) {
//           $element.addClass("animate linefill");
//         } else {
//           $element.addClass("animate textfill linefill");
//         }
//       }
//     }
//   }
// }

/**
 * 图片加载不出显示默认图
 * @param {Object} obj  图片对象，即$('#id')
 */
// function defaultImage(obj, thisUrl) {
//   /*加载图片失败回调函数;*/
//   obj.bind("error", function () {
//     /*判断是否有传入默认图片地址，有就使用，没有便直接默认*/
//     if (thisUrl) {
//       obj.attr("src", thisUrl);
//     } else {
//       obj.attr("src", "default.jpg");
//     }
//   });
// }
//根据id和data-name确认热点元素code,并赋值给其data-code属性和class属性
//添加水印
// export function watermark(settings) {
//   //debugger;
//   //默认设置
//   var defaultSettings = {
//     watermark_txt: "text",
//     watermark_x: 50, //水印起始位置x轴坐标
//     watermark_y: 160, //水印起始位置Y轴坐标
//     watermark_rows: 5, //水印行数
//     watermark_cols: 8, //水印列数
//     watermark_x_space: 100, //水印x轴间隔
//     watermark_y_space: 50, //水印y轴间隔
//     watermark_color: "#ededed", //水印字体颜色
//     watermark_alpha: 0.35, //水印透明度
//     watermark_fontsize: "19px", //水印字体大小
//     watermark_font: "微软雅黑", //水印字体
//     watermark_width: 210, //水印宽度
//     watermark_height: 120, //水印长度
//     watermark_angle: 330, //水印倾斜度数
//   };
//   //采用配置项替换默认值，作用类似jquery.extend
//   if (arguments.length === 1 && typeof arguments[0] === "object") {
//     var src = arguments[0] || {};
//     console.log(src);
//     // for (num in src) {
//     //   if (src[num] && defaultSettings[num] && src[num] === defaultSettings[num])
//     //     continue;
//     //   else if (src[num]) defaultSettings[num] = src[num];
//     // }
//   }

//   var oTemp = document.createDocumentFragment();

//   //获取页面最大宽度
//   var pages_width = Math.max(
//     $("#mainContent").width(),
//     $("#mainContent").width()
//   );
//   var countX = Math.ceil(pages_width / 315);
//   var cutWidth = pages_width * 0.015;
//   var page_width = pages_width - cutWidth;
//   //获取页面最大高度
//   var page_height = Math.max(
//     $("#mainContent").height() - 100,
//     $("#mainContent").height() - 100
//   );
//   var countY = Math.ceil(page_height / 315);
//   // var page_height = document.body.scrollHeight + document.body.scrollTop;
//   //如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔
//   if (
//     defaultSettings.watermark_cols == 0 ||
//     parseInt(
//       defaultSettings.watermark_x +
//         defaultSettings.watermark_width * defaultSettings.watermark_cols +
//         defaultSettings.watermark_x_space * (defaultSettings.watermark_cols - 1)
//     ) > page_width
//   ) {
//     defaultSettings.watermark_cols = parseInt(
//       (page_width -
//         defaultSettings.watermark_x +
//         defaultSettings.watermark_x_space) /
//         (defaultSettings.watermark_width + defaultSettings.watermark_x_space)
//     );
//     defaultSettings.watermark_x_space = parseInt(
//       (page_width -
//         defaultSettings.watermark_x -
//         defaultSettings.watermark_width * defaultSettings.watermark_cols) /
//         (defaultSettings.watermark_cols - 1)
//     );
//   }
//   // //如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔
//   if (
//     defaultSettings.watermark_rows == 0 ||
//     parseInt(
//       defaultSettings.watermark_y +
//         defaultSettings.watermark_height * defaultSettings.watermark_rows +
//         defaultSettings.watermark_y_space * (defaultSettings.watermark_rows - 1)
//     ) > page_height
//   ) {
//     defaultSettings.watermark_rows = parseInt(
//       (defaultSettings.watermark_y_space +
//         page_height -
//         defaultSettings.watermark_y) /
//         (defaultSettings.watermark_height + defaultSettings.watermark_y_space)
//     );
//     defaultSettings.watermark_y_space = parseInt(
//       (page_height -
//         defaultSettings.watermark_y -
//         defaultSettings.watermark_height * defaultSettings.watermark_rows) /
//         (defaultSettings.watermark_rows - 1)
//     );
//   }
//   var x;
//   var y;
//   for (var i = 0; i < countY; i++) {
//     //水印的纵轴定位是图片的高度
//     y =
//       defaultSettings.watermark_y +
//       (defaultSettings.watermark_y_space + page_height / countY) * i;
//     for (var j = 0; j < countX; j++) {
//       //水印的横轴定位是图片的宽度
//       let countX = Math.ceil(page_width / 300);
//       x =
//         defaultSettings.watermark_x +
//         (page_width / countX + defaultSettings.watermark_x_space) * j;
//       //添加水印的背景图片
//       var mask_div = document.createElement("div");
//       mask_div.id = "mask_div" + i + j;
//       mask_div.className = "mask_div";
//       mask_div.appendChild(
//         document.createTextNode(defaultSettings.watermark_txt)
//       );
//       // //设置水印div倾斜显示
//       mask_div.style.webkitTransform =
//         "rotate(-" + defaultSettings.watermark_angle + "deg)";
//       mask_div.style.MozTransform =
//         "rotate(-" + defaultSettings.watermark_angle + "deg)";
//       mask_div.style.msTransform =
//         "rotate(-" + defaultSettings.watermark_angle + "deg)";
//       mask_div.style.OTransform =
//         "rotate(-" + defaultSettings.watermark_angle + "deg)";
//       mask_div.style.transform =
//         "rotate(-" + defaultSettings.watermark_angle + "deg)";
//       mask_div.style.visibility = "";
//       mask_div.style.position = "absolute";
//       mask_div.style.left = x + "px";
//       mask_div.style.top = y + "px";
//       mask_div.style.overflow = "hidden";
//       mask_div.style.zIndex = "80";
//       mask_div.style.pointerEvents = "none"; //pointer-events:none  让水印不遮挡页面的点击事件
//       //mask_div.style.border="solid #eee 1px";
//       mask_div.style.opacity = defaultSettings.watermark_alpha;
//       mask_div.style.fontSize = defaultSettings.watermark_fontsize;
//       mask_div.style.fontFamily = defaultSettings.watermark_font;
//       mask_div.style.color = defaultSettings.watermark_color;
//       mask_div.style.textAlign = "center";
//       mask_div.style.width = defaultSettings.watermark_width + "px";
//       mask_div.style.height = defaultSettings.watermark_height + "px";
//       mask_div.style.display = "block";
//       oTemp.appendChild(mask_div);
//     }
//   }
//   var name = sessionStorage.getItem("userName");
//   console.log(name);
//   // document.getElementById("mainContent").appendChild(oTemp);
//   document.body.appendChild(oTemp);
//   $(".mask_div").html(
//     "<img src=" +
//       require("../../assets/image/otherIcon/waterMark.png") +
//       " style='margin-bottom:0px'><p>广汽丰田汽车有限公司</p>" +
//       name
//   );
//   $(".mask_div").children("img").css({ width: "50%" });
//   // defaultImage($('body .mask_div img'), '../img/bg.png')
// }

// //判断打开方式
// //  if (window.opener == null) {
// //     closeWindow();
// //     window.location.href = "/login.aspx";
// //     $(document.body).html("");
// // } else if (window.opener == window) {
// //     window.location.href = "/login.aspx";
// //     $(document.body).html("");
// // }
// //监听按键
// // 监听ctrl和F12键禁止粘贴复制
// // document.onkeydown = function (event) {
// //   var e = event || window.event || arguments.callee.caller.arguments[0];
// //   if ((e && e.keyCode == 17) || (e && e.keyCode == 123)) {
// //     // console.log(e + "\t" + e.keyCode);
// // 	  // closeWindow();
// //   }
// // };
