import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    roles: [],
    perms: [],
    // 缓存组件页面
    catchComponents: [],
    selectTabPath: '/dashboard',
    tabList: [
      {path: '/dashboard', label: '首页', name: 'dashboard'}
    ]
  },
  mutations: {
    //清空vuex数据
    clearVUEX(state){
      state.catchComponents = []
      state.selectTabPath = '/dashboard'
      state.tabList = [
        {path: '/dashboard', label: '首页', name: 'dashboard'}
      ]
    },
    // 跳转页面执行
    selectMenu(state, submenu) {
      // 首页就是 wellcome   也就是 home
      if(submenu.name === '/dashboard' || submenu.name === '/home'){
        submenu.name = 'dashboard'
        submenu.path = '/dashboard'
      }
      // 当前选中菜单
      var selectTabPath = submenu.path
      // 历史已选中菜单列表
      var oldTabList = state.tabList
      // console.log(submenu.label.replace(">"+submenu.label.split(">")[submenu.label.split(">").length -1], ""));
      // 将菜单信息添加到tablist - 添加时判断是否已有该标签
      var result = oldTabList.some((item,index) => {
        // if(item.path === selectTabPath){
        //   Vue.set(oldTabList, index, {
        //     path: submenu.path,
        //     name: submenu.name,
        //     label: submenu.label.replace(">"+submenu.label.split(">")[submenu.label.split(">").length -1], ""),
        //   });
        //   return true;
        // }
        if(item.path === selectTabPath){
          if(item.label !== submenu.label) {
            Vue.set(oldTabList, index, {
              path: submenu.path,
              name: submenu.name,
              label: submenu.label,
            })
          } else {
            return true
          }
          return true
        }
      })
      // 如果不包含该对象，则添加
      if(!result){
        oldTabList.push({
          path: submenu.path,
          label: submenu.label,
          name: submenu.name,
        })
      }
      // 重新赋值
      state.selectTabPath = selectTabPath
      state.tabList = oldTabList
    },
    // 添加keepalive缓存
    addKeepAliveCache(state, val){
      // 如果是首页不缓存
      if(val === 'dashboard' || val === 'home'){
        return
      }
      // 添加时判断，如果该组件已存在，则不添加
      if(state.catchComponents.indexOf(val) === -1) {
        // 不存在，缓存页面
        state.catchComponents.push(val)
      }
    },
    // 删除keepalive缓存
    removeKeepAliveCache(state, val){
      let cache = state.catchComponents
      for(let i = 0;i < cache.length;i++){
        if(cache[i] === val){
            cache.splice(i, 1);
        }
      }
      state.catchComponents = cache
    },
    //关闭菜单
    closeTab(state, val) {
      // 重新赋值
      state.selectTabPath = val.selectTabPath
      state.tabList = val.tabList
    },
    // 点击标签选择菜单
    changeMenu(state, val) {
      if (val === '/dashboard' || val === '/home') {
        state.selectTabPath = "/dashboard";
      } else {
        state.selectTabPath = val;
      }
    },
    // 修改token，并将token存入localStorage
    set_token (state, token) {
      state.token = token;
      sessionStorage.token = token;
    },
    setRefreshToken(state, refreshToken){
      state.refreshToken = refreshToken;
      sessionStorage.refreshToken = refreshToken;
    },
    set_userName(state,userName){
      state.userName = userName;
    },
    del_token (state) {
      state.token = '';
      //state.refreshToken="";
      this.commit("clearVUEX");
      sessionStorage.clear();
      // sessionStorage.removeItem('token')
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMS: (state, perms) => {
      state.perms = perms
    }
  },
  actions: {
  },
  modules: {
  }
})
