<template>
<div class="layoutContainer">

    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="车系" prop="carTrainId" :label-width="formLabelWidth">
            <el-select v-model="formInline.carTrainId" placeholder="请选择车系" @change="getTrainYearList" clearable filterable>
                <el-option-group v-for="group in trainList" :key="group.id" :label="group.nameCh">
                    <el-option v-for="item in group.children" :key="item.id" :label="item.nameCh" :value="item.id"></el-option>
                </el-option-group>
            </el-select>
          </el-form-item>
        <el-form-item label="年款" prop="modelYear" :label-width="formLabelWidth">
            <el-select v-model="formInline.modelYear" clearable filterable>
              <el-option v-for="(item,index) in yearList" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item :label-width="formLabelWidth">
          <el-button v-if="hasPerm('menuAsimss5A3B_104')" type="primary" @click="onSubmit" icon="el-icon-search">统计</el-button>
          <el-button v-if="hasPerm('menuAsimss5A3B_104')" plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-row>
        <el-col :span="8">
            <div id="sparePart" class="introduce" style="width: 100%; height: 300px"></div>
        </el-col>

        <el-col :span="8">
            <div id="circuit" class="introduce" style="width: 100%; height: 300px"></div>
        </el-col>

        <el-col :span="8">
            <div id="service" class="introduce" style="width: 100%; height: 300px"></div>
        </el-col>
    </el-row>

    <el-row>
        <div class="introduce">
        <!-- <el-row>
        <el-col :span="10"> -->
            <el-date-picker style="width: 260px;" v-model="valueDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            :clearable="false" value-format='yyyy-MM-dd' @change="selectDate()" :picker-options="pickerOptions"></el-date-picker>
        <!-- </el-col>
        </el-row> -->
        <div id="line"  style="width: 100%; height: 380px"></div>
        </div>

    </el-row>
</div>



</template>

<script>
  import axios from 'axios'
  import { commentStatistics, commentLineChart, commentTreeList, commentTrainYear } from '@/api/material.js'

  export default {
    name: 'commentstatistics',
    data () {
      return {
            // 时间段
            valueDate: [],
            start: '',
            end: '',
            // 定义颜色
            colorList: ['#FF0000', '#FF7D00', '#FFCC00', '#00FF00', '#0000FF', '#00FFFF', '#FF00FF',
                        '#99CC00', '#FF6600', '#996600', '#003333', '#666600', '#333300', '#FF99FF'
                ],
            // 饼图的数据
            partsList: [],  // 配件
            serviceList: [],  // 维修
            circuitList: [],  // 电路
            // 折线图的数据
            linePartses: [],  // 配件
            lineServices: [],  // 维修
            lineCircuits: [],  // 电路
            lineX: [], // 横坐标

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 3600 * 1000 * 24 * 1
                }
            },
            formInline: {
                carTrainId: '',  // 车系
                modelYear: '',  // 年款
            },
            trainList: [],
            yearList: [],
            formLabelWidth: '100px',
      }
    },

    methods: {

         // 查询 年款 - 车型
        getTrainYearList(trainId){
            this.formInline.modelYear = '';
            this.yearList = [];
            if(!trainId || trainId == ''){
                return false;
            }
            var params = {
                tearId: trainId
            }
            commentTrainYear(params).then(res => {
                this.yearList = res.data.data
            })
        },
        // 查询 品牌 - 车系
        getTrainList () {
            commentTreeList().then(res => {
                this.trainList = res.data.data
            })
        },

        dateFormat (date) {
            const Y = date.getFullYear() + '-'
            const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
            const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
            return Y + M + D
        },
        selectDate(){
            this.start = this.valueDate[0];
            this.end = this.valueDate[1];
            this.lineChartData();
            var b = this.start.split("-");
            var a = this.end.split("-");
            this.getLineX(new Date(b[0], b[1]-1, b[2]), new Date(a[0], a[1]-1, a[2]));
        },
        getLineX(date1, date2){
            this.lineX = [];
            while (date1 <= date2){
                this.lineX.push(this.dateFormat(date1));
                date1.setTime(date1.getTime() + 3600 * 1000 * 24 * 1);
            }
            // console.log("-----", this.lineX);
        },

        dateInit(){
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
            this.valueDate = [start, end];

            this.getLineX(new Date(start.getTime()), new Date(end.getTime()));
        },

        // 折线图
        lineChartData(){
            let _this = this
            var params = new URLSearchParams()
            params.append('trainId', this.formInline.carTrainId)
            params.append('modelYear', this.formInline.modelYear)
            params.append('start', this.start)
            params.append('end', this.end)
            commentLineChart(params).then(res => {
                // console.log("===", res.data.data);
                _this.lineChart(res.data.data.circuit, res.data.data.service, res.data.data.sparePart);
            })
        },

        dataList(){
            let _this = this
            var params = new URLSearchParams()
            params.append('trainId', this.formInline.carTrainId)
            params.append('modelYear', this.formInline.modelYear)
            commentStatistics(params).then(res => {

                _this.drawChart('sparePart', res.data.data.sparePart.data, res.data.data.sparePart.avg, '配件');
                _this.drawChart('circuit', res.data.data.circuit.data, res.data.data.circuit.avg, '电路');
                _this.drawChart('service', res.data.data.service.data, res.data.data.service.avg, '维修');
                _this.lineChart(res.data.data.line.circuit, res.data.data.line.service, res.data.data.line.sparePart);
            })
        },

        onSubmit(){
            this.dataList();
            this.dateInit();
        },
        reset(){
            this.formInline.carTrainId = '';
            this.formInline.modelYear = '';
            this.onSubmit();
        },

        // 饼图
        drawChart(id, data, avg, txt) {
            // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
            let myChart = this.$echarts.init(document.getElementById(id));
            // 指定图表的配置项和数据
            let color = this.colorList;
            let option = {
                title:{   //圆环中间内容
                    text: txt,
                    subtext: avg,
                    left:"center",
                    top:"44%",
                    textStyle:{
                        color:"#000",
                        fontSize:17,
                        align:"center"
                    },
                    subtextStyle: {
                        fontSize: 20,
                        fontWeight: '700',
                        align:"center",
                        color:'#000'
                    },
                },
                tooltip: {
                    trigger: 'item'
                },
                color,
                series: [
                    {
                    name: '',
                    type: 'pie',
                    center: ['50%', '50%'],
                    radius: ['40%', '65%'],
                    avoidLabelOverlap: true,
                    label: {
                        show: true,
                        position: 'outside',
                        formatter: '{d}%',
                        rich: {
                            a: {
                                padding: [0,0,-10,0]
                            }
                        }
                    },
                    labelLine: {
                        // show: false
                                normal: {
                            length: 10,
                            length2: 20,
                            lineStyle: {
                                width: 1
                            }
                        }
                    },
                    data,
                }
                ]
            };
            window.addEventListener("resize", function () {
              myChart.resize();
            });
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },
        // 线形图
        lineChart(circuit, service, sparePart){
            let myChart = this.$echarts.init(document.getElementById('line'));
            let x = this.lineX
            let option = {
                title: {
                    textStyle: {
                        rich: {
                            a: {
                                fontSize: 16,
                                fontWeight: 600,
                            },
                        },
                    },
                    top: '2%',
                    left: '2%',
                },
                legend: {
                    data: ['配件', '电路', '维修'],
                    textStyle: {
                        align: 'right',
                    },
                    top: '2%',
                    right: '2%',
                },

                tooltip: {
                    trigger: 'axis',
                },

                grid: {
                    top: '18%',
                    left: '8%',
                    right: '3%',
                    bottom: '10%',
                },
                xAxis: [
                    {
                        type: 'category',
                        axisLine: {
                            show: true,
                        },
                        splitArea: {
                            // show: true,
                            color: '#f00',
                            lineStyle: {
                                color: '#f00',
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            margin: 10,
                        },
                        boundaryGap: false,
                        data: x,
                    },
                ],

                yAxis: [
                    {
                        type: 'value',
                        // 最小 步长为 1
                        minInterval: 1,
                        splitNumber: 5,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#e3e3e3',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            formatter: '{value}',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '配件',
                        type: 'line',
                        smooth: true, //是否平滑
                        showAllSymbol: true,
                        symbol: 'circle',
                        symbolSize: 12,
                        lineStyle: {
                            normal: {
                                color: '#7AA1FF',
                            },
                        },
                        itemStyle: {
                            color: '#7AA1FF',
                            borderWidth: 3,
                            borderColor: '#f3f3f3',
                        },
                        data: sparePart,
                    },
                    {
                        name: '电路',
                        type: 'line',
                        smooth: true, //是否平滑
                        showAllSymbol: true,
                        symbol: 'circle',
                        symbolSize: 12,
                        lineStyle: {
                            normal: {
                                color: '#00ca95',
                            },
                        },
                        itemStyle: {
                            color: '#00ca95',
                            borderColor: '#fff',
                            borderWidth: 3,
                        },
                        data: circuit,
                    },
                    {
                        name: '维修',
                        type: 'line',
                        smooth: true, //是否平滑
                        showAllSymbol: true,
                        symbol: 'circle',
                        symbolSize: 12,
                        lineStyle: {
                            normal: {
                                color: '#C258F6',
                            },
                        },
                        itemStyle: {
                            color: '#C258F6',
                            borderColor: '#fff',
                            borderWidth: 3,
                        },
                        data: service,
                    },
                ],
            };
            window.addEventListener("resize", function () {
              myChart.resize();
            });
            myChart.setOption(option);
        }

    },
    mounted () {
        this.getTrainList();
        this.onSubmit();
    }
  }
  </script>


<style>
  .introduce{
    border: 1px solid #cfd5de;
    box-sizing: border-box;
    margin: 10px 0;
    padding:15px 3%;
    border-radius: 5px;
  }


</style>
