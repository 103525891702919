<template>
  <div class="login">
    <!-- <div class="loginHeader"> -->
      <!-- <div class="logoArea">
        <img src="../../assets/image/logoHead.png"/>
      </div> -->
      <!-- <div class="languageArea">
        <el-select v-model="selectValue" @change="langChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div> -->
    <!-- </div> -->
    <div class="loginDetail">
      <p class="login-title">{{ $t('login.title') }}</p>
      <el-form rel="loginForm" :model="loginform" :rules="loginrules" class="login-form" autocomplete="on" label-position="left">
        <el-form-item prop="username">
          <el-input type="text" autocomplete="off" :placeholder="$t('login.userInput')" v-model="loginform.username">
            <i slot="prefix" class="el-input__icon icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input class="password" type="password" autocomplete="off" :placeholder="$t('login.passInput')" v-model="loginform.password">
            <i slot="prefix" class="el-input__icon icon-password"></i>
          </el-input>
          <img
            class="seeArea"
            @click="noSeeClick"
            src="../../assets/image/visibleIcon.png"
          />
          <img
            class="noSeeArea"
            @click="seeClick"
            src="../../assets/image/notVisible.png"
          />
        </el-form-item>
        <el-form-item class="captCha" prop="captCha">
          <el-input type="text" autocomplete="off" :placeholder="$t('login.codeInput')" v-model="loginform.captCha">
            <i slot="prefix" class="el-input__icon icon-captCha"></i>
          </el-input>
          <img @click="getCaptCha" :src="loginform.src" class="CaptChaImg">
          <input type="hidden" v-model="loginform.id">
        </el-form-item>
        <el-form-item class="loginBut">
          <el-button class="button" @click="login" type="primary">{{ $t('login.button') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {handleAlert, languageType, frontLogin, useCommonLogin} from '@/assets/js/common.js'
import {onLogin, currentUserInfo, captCha, getUserInfo} from '@/api/sysmgt.js'
import axios from 'axios'

export default {
  name: 'login',
  data() {
    return {
      selectValue: '',
      options: [
        {
          value: 'cn',
          label: '中文'
        },
        // {
        //   value: 'en',
        //   label: 'English'
        // }
      ],
      loginform: {
        username: '',
        password: '',
        captCha: '',
        id: null,
        src: ''
      },
      // 表单验证，需要在el-form-item元素中增加prop属性
      loginrules: {
        username: [
          {required: true, message: this.$t('login.userTip'), trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, message: this.$t('login.passTip'), trigger: ['blur', 'change']}
        ],
        captCha: [
          {required: true, message: this.$t('login.codeTip'), trigger: ['blur', 'change']}
        ]
      },
      // 对话框显示和隐藏
      dialogVisible: false
    }
  },
  created() {
    var that = this
    that.selectValue = 'cn'
    // that.selectValue = localStorage.lang === undefined ? 'cn' : localStorage.lang
    // localStorage.lang = that.selectValue
  },
  methods: {
    // 语言切换
    // langChange(e) {
    //   localStorage.setItem('lang', e)
    //   this.$i18n.locale = e
    //   location.reload()
    // },
    // 密码可见
    seeClick() {
      $(".seeArea").show();
      $(".noSeeArea").hide();
      $(".password input").attr("type", "text");
    },
    // 密码不可见
    noSeeClick() {
      this.pswstate();
    },
    pswstate() {
      $(".seeArea").hide();
      $(".noSeeArea").show();
      $(".password input").attr("type", "password");
    },
    getCaptCha() {
      // sessionStorage.clear();
      this.$store.commit('del_token');
      // this.$store.commit("clearVUEX");
      axios.defaults.headers.common['Authorization'] = '';
      captCha().then(res => {
        if (res.data.code === 100) {
          this.loginform.id = res.data.data.id
          this.loginform.src = res.data.data.image
        }
      })
    },
    getUserDetail() {
      currentUserInfo().then(res => {
        if (res.data.code == 100) {
          this.$store.commit('SET_ROLES', res.data.data.roleList)
          this.$store.commit('SET_PERMS', res.data.data.permissionList)
          this.$router.push('/home')
          languageType()
        }
      }).catch(err => {
        if (err != null && err !== '' && err.responseText !== null) {
          handleAlert('error', '系统登录异常')
        }
      })
    },
    login() {
      var _this = this
      // sessionStorage.clear();
      _this.$store.commit('del_token');
      // _this.$store.commit("clearVUEX");
      if (!_this.loginform.username) {
        handleAlert('error', _this.$t('login.userTip'))
        return
      }
      if (!_this.loginform.password) {
        handleAlert('error', _this.$t('login.passTip'))
        return
      }
      if (!_this.loginform.captCha) {
        handleAlert('error', _this.$t('login.codeTip'))
        return
      }
      var params = {
        username: _this.loginform.username,
        password: _this.loginform.password,
        vid: _this.loginform.id,
        verifycode: _this.loginform.captCha,
        type: '1'
      }
      onLogin(params).then(res => {
        if (res.data.code === 100) {
          var tokenVal = res.data.data.tokenHead + res.data.data.token
          this.$store.commit('set_token', tokenVal)
          this.$store.commit("set_userName", params.username);
          this.$store.commit("setRefreshToken",res.data.data.refreshToken);
          sessionStorage.setItem("store", JSON.stringify(this.$store.state));
          this.getUserDetail()
          languageType()
          if(window.bc){
             window.bc.postMessage(JSON.stringify(this.$store.state))
           }
        } else if (res.data.code === 1011) {
          handleAlert('error', res.data.msg)
        } else if (res.data.code === 1012) {
          handleAlert('error', res.data.msg)
        } else if (res.data.code === 400) {
          handleAlert('error', res.data.msg)
        } else if (res.data.code === 101) {
          handleAlert('error', res.data.msg)
        } else {
          handleAlert('error', '系统登录失败')
        }
      }).catch(err => {
        if (err != null && err !== '' && err.responseText !== null) {
          handleAlert('error', '系统登录异常')
        }
      })
    },
    keyDown(e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
  },
  mounted() {
    this.getCaptCha()
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
}
</script>
<style>
.login {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url('../../assets/image/login.png') no-repeat 0.3%;
  background-size: cover;
  position: relative;
}
.loginDetail {
  background: #fff;
  width: 470px;
  position: absolute;
  right: 8%;
  top: 23%;
  border-radius: 5px;
}
.loginDetail .login-title {
  font-size: 28px;
  color: #000;
  text-align: center;
  margin: 9% 0 10%;
}
.loginDetail .login-title::before {
  content: "";
  display: inline-block;
  width: 14%;
  margin-right: 5px;
  border-bottom: 1px transparent solid;
  border-image:linear-gradient(to right,#fff,rgb(225,225,225), rgb(173,173,173)) 1 10;
  vertical-align: middle;
}
.loginDetail .login-title::after {
  content: "";
  display: inline-block;
  width: 14%;
  border-bottom: 1px transparent solid;
  border-image:linear-gradient(to right, rgb(173,173,173), rgb(215,215,215), #fff) 1 10;
  margin-left: 5px;
  vertical-align: middle;
}
.login-form {
  margin: 0 8%;
}
.login-form .el-form-item {
  margin-bottom: 10%;
}
.icon-user::after,
.icon-password::after,
.icon-captCha::after {
  display: inline-block !important;
  width: 20px !important;
  height: 20px !important;
  background: url("../../assets/image/userIcon.png") no-repeat;
  background-size: 100%;
  margin-top: -1px;
  /* margin: 0 10px 0 5px; */
}
.icon-password::after {
  background: url("../../assets/image/passwordIcon.png") no-repeat;
  background-size: 100%;
}
.icon-captCha::after {
  background: url("../../assets/image/captchaIcon.png") no-repeat;
  background-size: 100%;
}
.login .el-form-item__content {
  display: flex;
  line-height: 40px!important;
}
.login-form .el-input__icon {
  line-height: 40px !important;
}
/* 输入框 */
.login-form .el-input__inner {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid rgba(227,227,227,0.6)  !important;
  height: 40px !important;
  line-height: 40px !important;
  color: var(--text-color)!important;
  font-size: 17px;
  border-radius: 0;
  width: 100%!important;
  padding: 0 38px !important;
}
.login-form .el-input__inner::placeholder {
  color: #ccc !important;
}
.login-form .el-input__inner:hover,
.login-form .el-input__inner:focus {
  border-bottom: 1px solid rgba(227,227,227,0.6) !important;
}
/* .login-form .el-input__inner */
.login-form .noSeeArea, .login-form .seeArea {
  width: 20px;
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
  cursor: pointer;
}
.login-form .loginBut {
  padding: 5% 0 6% 0;
}
/* 验证码 */
.captCha .CaptChaImg {
  height: 42px;
  width: 80px;
}
.login-form .loginBut .el-form-item__content {
  border: none !important;
}
.login-form .loginBut .el-button {
  background: #2188f5 !important;
  color: #fff !important;
  width: 100%;
  padding: 0;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
}
.login-form .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.login-form .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: '*';
  color: transparent !important;
  margin-right: 4px;
}
@media screen and (max-width: 1680px) and (min-width: 1600px) {
  .loginDetail .login-title {
    font-size: 25px;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
   /* 登录区域 */
  .loginDetail {
    width: 360px;
    right: 6%;
    top: 22%;
  }
  .loginDetail .login-title {
    font-size: 21px;
  }
  .login-form {
    margin: 0px 7%;
  }
  .login-form .el-form-item {
    /* margin-bottom: 7.5%; */
  }
  .login-form .el-input__icon {
    line-height: 36px !important;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 16px !important;
    height: 16px !important;
  }
  .login-form .el-form-item__content {
    line-height: 35px !important;
  }
  .login-form .el-input .el-input__inner {
    height: 36px !important;
    line-height: 36px !important;
    font-size: 15px;
    padding: 0 28px !important;
  }
  .login-form .seeArea,
  .login-form .noSeeArea {
    width: 16px;
  }
  .login-form .captCha .el-input .el-input__inner {
    padding: 0 8px 0 35px;
  }
  .login-form .CaptChaImg {
    width: 70px;
    height: 34px;
  }
  .login-form .loginBut .el-button {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  /* 登录区域 */
  .loginDetail {
    width: 350px;
    right: 6%;
    top: 22%;
  }
  .loginDetail .login-title {
    font-size: 20px;
  }
  .login-form {
    margin: 0px 7%;
  }
  .login-form .el-input__icon {
    line-height: 34px !important;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 16px !important;
    height: 16px !important;
  }
  .login-form .el-form-item__content {
    line-height: 34px !important;
  }
  .login-form .el-input .el-input__inner {
    height: 34px !important;
    line-height: 34px !important;
    font-size: 15px;
    padding: 0 28px !important;
  }
  .login-form .seeArea,
  .login-form .noSeeArea {
    width: 16px;
  }
  .login-form .captCha .el-input .el-input__inner {
    padding: 0 6px 0 32px;
  }
  .login-form .CaptChaImg {
    width: 70px;
    height: 34px;
  }
  .login-form .loginBut .el-button {
    font-size: 14px;
    height: 35px;
    line-height: 35px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  /* 登录区域 */
  .loginDetail {
    width: 330px;
    right: 6%;
    top: 20%;
  }
  .loginDetail .login-title {
    font-size: 18px;
    margin: 8% 0 7%;
  }
  .loginDetail .login-title::before {
    margin-right: 4px;
  }
  .loginDetail .login-title::after {
    margin-left: 4px;
  }
  .login-form {
    margin: 0px 7%;
  }
  .login-form .el-form-item {
    margin-bottom: 8%;
  }
  .login-form .el-input__icon {
    line-height: 32px !important;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 15px !important;
    height: 15px !important;
  }
  .login-form .el-form-item__content {
    line-height: 32px !important;
  }
  .login-form .el-input .el-input__inner {
    height: 31px !important;
    line-height: 32px !important;
    font-size: 14px;
    padding: 0 28px !important;
  }
  .login-form .seeArea,
  .login-form .noSeeArea {
    width: 16px;
  }
  .login-form .captCha .el-input .el-input__inner {
    padding: 0 5px 0 28px;
  }
  .login-form .CaptChaImg {
    width: 60px;
    height: 32px;
  }
  .login-form .loginBut .el-button {
    font-size: 13px;
    height: 34px;
    line-height: 34px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  /* 登录区域 */
  .loginDetail {
    width: 305px;
    right: 6%;
    top: 20%;
  }
  .loginDetail .login-title {
    font-size: 16px;
    margin: 8% 0 7%;
  }
  .loginDetail .login-title::before {
    margin-right: 4px;
  }
  .loginDetail .login-title::after {
    margin-left: 4px;
  }
  .login-form {
    margin: 0px 7%;
  }
  .login-form .el-form-item {
    margin-bottom: 8%;
  }
  .login-form .el-input__icon {
    line-height: 30px !important;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 14px !important;
    height: 14px !important;
  }
  .login-form .el-form-item__content {
    line-height: 30px;
  }
  .login-form .el-input .el-input__inner {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 13px;
    padding: 0 26px !important;
  }
  .login-form .seeArea,
  .login-form .noSeeArea {
    width: 14px;
  }
  .login-form .captCha .el-input .el-input__inner {
    padding: 0 5px 0 26px;
  }
  .login-form .CaptChaImg {
    width: 56px;
    height: 30px;
  }
  .login-form .loginBut .el-button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }
}
@media screen and (max-width: 1024px) {
  /* 登录区域 */
  .loginDetail {
    width: 310px;
    right: 6%;
    top: 20%;
  }
  .loginDetail .login-title {
    font-size: 16px;
    margin: 8% 0 6%;
  }
  .loginDetail .login-title::before {
    margin-right: 4px;
  }
  .loginDetail .login-title::after {
    margin-left: 4px;
  }
  .login-form {
    margin: 0px 7%;
  }
  .login-form .el-form-item {
    margin-bottom: 7%;
  }
  .login-form .el-input__icon {
    line-height: 30px !important;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 14px !important;
    height: 14px !important;
  }
  .login-form .el-form-item__content {
    line-height: 30px;
  }
  .login-form .el-input .el-input__inner {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 12px;
    padding: 0 26px !important;
  }
  .login-form .seeArea,
  .login-form .noSeeArea {
    width: 14px;
  }
  .login-form .captCha .el-input .el-input__inner {
    padding: 0 5px 0 26px;
  }
  .login-form .CaptChaImg {
    width: 56px;
    height: 30px;
  }
  .login-form .loginBut .el-button {
    font-size: 13px;
    height: 32px;
    line-height: 32px;
  }
}
</style>
