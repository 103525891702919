<template>

<div class="tinymce-editor">

<Editor
    :id="editorId"

    v-model="editorValue"

    :init="editorInit"

    :disabled="disabled"

     @onClick="handleClick"

    />

</div>

</template>

<script>
// 引入组件
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
// 引入富文本编辑器主题的js和css
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/icons/default/icons'

// 扩展插件

import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount' // 字数统计插件
import { cmsServerUrl } from '@/assets/js/common.js'
import axios from 'axios'

export default {
  name: 'TinymceEditor',
  components: { Editor },
   props: {
     id:    {
      type: String,
      default: 'tinymceEditor'
    },
    value: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
       default: false
    },

    plugins: {
     type: [String, Array],
     default: 'link lists image code table wordcount'
    },

    toolbar: {
      type: [String, Array],
      default: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat'
    },

    // 设置为“true”即允许粘贴图像，而将其设置为“false”则不允许粘贴图像。
    paste_data_images: {
      type: Boolean,
      default: true
    },
    //  插入word文档需要该属性
    paste_convert_word_fake_lists: {
      type: Boolean,
      default: true
    },
     // 上传图片地址
    imgUploadUrl: {
      type: String,
      default: ""
    }

   },

  data() {

    return {

        editorInit: {

          // 打包后运行出现 Failed to load language: zh_CN from url /tinymce/langs/zh_CN.js
        language_url: require('../../plugins/zh_CN.js'),

        language: 'zh_CN',

        skin_url: '/tinymce/skins/ui/oxide',
        content_css: '/tinymce/skins/content/default/content.css',

        height: 300,

        plugins: this.plugins,

        toolbar: this.toolbar,

        statusbar: true, // 底部的状态栏

        menubar: true, // 最上方的菜单

        branding: false, // 水印“Powered by TinyMCE”

        images_upload_handler: (blobInfo,success,failure) => {
            // this.$emit('handleImgUpload', blobInfo, success, failure)
            this.handleImgUpload(blobInfo, success, failure);

        },

        init_instance_callback: editor => {
          if (this.value) {
            editor.setContent(this.value);
          }
          this.hasInit = true;

          editor.on("NodeChange Change KeyUp SetContent", () => {
            this.hasChange = true;
            // const val = editor.getContent().replace(/<p><img\s?src="data.*?<\/p>/g, '')
            this.$emit("input", editor.getContent());
          });

          editor.on("paste", evt => {
            // 监听粘贴事件
            console.log("监听粘贴事件");
            this.onPaste(evt);
          });
        },




       },

      editorId: this.id,
     editorValue: this.value

    }

   },

  watch: {

 editorValue(newValue) {

    this.$emit('input', newValue)

     }

   },

  mounted() {

   tinymce.init({
    paste_data_images: this.paste_data_images, // 设置为“true”即允许粘贴图像，而将其设置为“false”则不允许粘贴图像。
    paste_word_valid_elements: "*[*]", //word需要它
    default_link_target: "_blank", //链接打开方式

   })

   },

  methods: {

    onPaste(event, success, failure) {

      // 实现图⽚粘贴上传
      const items = (event.clipboardData || window.clipboardData).items;
      let len = items.length;
      for (let i = 0; i < len; i++) {
        if (items[i].kind == "file" && items[i].type.indexOf("image") != -1) {
          // 判断是否为图⽚类型
          event.preventDefault(); // 如果是图⽚阻⽌⾃动粘贴到富⽂本编辑器
          let file = items[i].getAsFile(); // 获取⽂件数据
          let blob = new Blob([file], { type: file.type });
          console.log("粘贴的是图⽚类型", blob, file);
          const isLt2M = file.size / 1024 /1024 < 2;
          if (!isLt2M) {
            failure("上传失败，图片不可超过2M!");
            return false;
          }
          const formdate = new FormData();
          formdate.append("file", file);
          axios({
            url: this.imgUploadUrl,
            method: "post",
            data: formdate
          }).then(res => {
              let filePath = cmsServerUrl + 'sys/upload/display?filePath=' + res.data.data.fileUrl;
              console.log("----");
              console.log(filePath);
              // 创建对象
              var img = new Image();

              // 改变图片的src
              img.src = filePath;
              img.onload = function(){

                const style = `style="width:${img.width}px;height:${img.height}px;"`;
                tinymce.execCommand(
                  "mceReplaceContent",
                  false,
                  `<img src='${filePath}' ${style} />`
                );
              };
            }).catch(function(err) {
              // 上传错误可⾃⾏给出提⽰
              this.$message.error(err);
            });
        } else {
          // 不是图⽚类型直接粘贴, 跳过oss上传处理
          console.log("粘贴的不是图⽚");
        }
      }
    },

    handleImgUpload(blobInfo, success, failure) {
      //判断图片格式
      const isAccord = blobInfo.blob().type === 'image/jpeg' || blobInfo.blob().type === 'image/png' || blobInfo.blob().type === 'image/GIF' || blobInfo.blob().type === 'image/jpg' || blobInfo.blob().type === 'image/BMP';
      if (blobInfo.blob().size/1024/1024>2) {
        failure("上传失败，图片大小请控制在 2M 以内")
        return;
      } else if (blobInfo.blob().type == isAccord) {
        failure('图片格式错误')
        return;
      } else {
        let formData = new FormData()
        // 服务端接收文件的参数名，文件数据
        formData.append('file', blobInfo.blob())
        axios({
          method: 'POST',
          url: this.imgUploadUrl,
          data: formData,
        }).then((res) => {
       
          // 这里返回的是你图片的地址
          success(cmsServerUrl + 'sys/upload/display?filePath=' + res.data.data.fileUrl)
        }).catch(() => {
          failure('上传失败')
        })
      }
    },

        // https://github.com/tinymce/tinymce-vue => All available events

    handleClick(e) {

    this.$emit('onClick', e, tinymce)

    },

     setValue(value){
        this.editorValue=value
    },

    clear() {
     this.editorValue = ''

    }

   }

}

</script>
