<template>
  <div class="layoutContainer">
    <div class="noticeContent" v-cloak>
      <!-- 标题 -->
      <div class="title-notic" v-if="result != null">{{result.title}}</div>
      <!-- 通告信息 -->
      <div class="datum-notic" v-if="result != null">

        <!-- <span>状态：{{getStatus(this.result.status)}}</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
        <span>更新人员：{{result.username}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <!-- <span>类型：{{getType(this.result.type)}}</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
        <span>更新时间：{{result.updatedTime | conversion("yyyy-MM-dd HH:mm:ss")}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <!-- <span v-if="result.isTop == 1">置顶&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span v-if="result.isRecmd == 1">推荐</span> -->
      </div>
      <!-- 内容 -->
      <div class="content-notic" v-if="result != null">

        <div v-html="result.content"></div>
      </div>
      <div class="datum-notic" v-if="result == null">
        <span>暂无数据</span>
      </div>
    </div>
  </div>
</template>
<script>
import { noticeTypeList, noticeInfo } from '@/api/sysmgt.js'
import axios from 'axios'
export default {
  name: 'noticeDetail',
  data () {
    return {
      id: '',
      result: {},
      typeList:[],
      content: '',
      statusList: [
        { name: '草稿', code: 1 },
        { name: '发布', code: 2 },
        { name: '关闭', code: 3 },
      ],

    }
  },
  methods: {
    // 获取公告明细
    getNoticeInfo(){
      noticeInfo(this.id).then(res=>{
        this.result = res.data.data
      })
    },
    // 公告类型
    getTypeList () {
      noticeTypeList().then(res => {
        if (res !== null && res.data.code === 100) {
          this.typeList = res.data.data
        }
      })
    },

    getType(type){
      for (let i = 0; i < this.typeList.length; i++) {
        if (this.typeList[i].code == type) {
          return this.typeList[i].name;
        }
      }
      return "未知";
    },
    getStatus(status){
      for (let i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].code == status) {
          return this.statusList[i].name;
        }
      }
      return "未知";
    },
  },
  mounted () {
    this.id = this.$route.params.id
    this.getTypeList()
    this.getNoticeInfo();
    window.noticeUnread();
    window.systemReminder();
  }
}
</script>

<style>
  .datum-notic{
    text-align: center;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    color: #666;
    margin: 5px 0;
  }

  .content-notic {
    font-size: 15px;
    line-height: 1.6;
    color: #333;
    height: 700px;
  }

  .title-notic {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    word-break: break-all;
  }

</style>

