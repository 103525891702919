<template>
  <div class="layoutContainer auditContent">
    <div class="infoDetail">
      <el-row>
        <el-col :span="9" class="leftData">
          <div>
            <div class="topButton" v-if="hasPerm('menuAsimssCMS3A3B_116')">
              <el-button type="text" v-if="hasPerm('menuAsimssCMS3A3B_116')" icon="adopt-icon" @click="auditPassClick">审核通过</el-button>
              <el-button type="text" v-if="hasPerm('menuAsimssCMS3A3B_116')" icon="reject-icon" @click="showRejectDlg">审核驳回</el-button>
              <el-button type="text" :disabled="pendingBtnState" @click="openPendingDlgForm">
                待审核
                <el-badge v-if="pendingTaskCount > 0"  :value="pendingTaskCount" class="mark" />
              </el-button>
            </div>
            <div class="scrollClass">
              <el-scrollbar>
                <el-tree ref="tree"
                  :data="listdata"
                  :props="defaultProps"
                  node-key="id"
                  :default-expanded-keys="treeExpandIdList"
                  show-checkbox
                  :render-content="renderContent"
                  @node-click="handleNodeClick"
                  @node-expand="handleNodeExpand"
                  @node-collapse="handleNodeCollapse"
                >
                </el-tree>
              </el-scrollbar>
            </div>
          </div>
        </el-col>
        <el-col :span="15" class="fromRight auditRight">
          <div class="formTitle">
            <div style="padding-left:15px;">
              <span v-if="form.state !== ''">{{form.cnName}} 审核状态：</span>
              <el-button type="text" v-if="form.state == '审核通过'" style="color:green; font-weight: 700">{{form.state}}</el-button>
              <el-button type="text" v-if="form.state == '自检变更'" style="color:green; font-weight: 700">{{form.state}}</el-button>
              <el-button type="text" v-if="form.state == '未审核'" style="color:blue; font-weight: 700">{{form.state}}</el-button>
              <el-button type="text" v-if="form.state == '审核驳回'" style="color:red; font-weight: 700; text-decoration: underline" @click="rejectCause(form.id)">{{form.state}}</el-button>
              <el-button type="text" v-if="form.state == '驳回变更'" style="color:red;font-weight: 700; text-decoration: underline" @click="rejectCause(form.id)">{{form.state}}</el-button>
            </div>
            <div class="rightBut">
              <el-button type="text" v-if="hasPerm('menuAsimssCMS3A3B_116')" icon="opinion-icon" size="small" @click="auditOpinion(form.id)">审核意见</el-button>
            </div>
          </div>
          <div v-if="srcPath == ''" style="margin:15px 20px;text-align:center">{{pathTip}}</div>
          <iframe v-if="srcPath != ''" :src="srcPath" frameborder="0" width="100%" ></iframe>
        </el-col>
      </el-row>
      <el-dialog :width="dialogStatus == 'history' ? '762px !important' : ''" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form :model="dataForm" v-if="this.dialogStatus == 'reject'" :rules="rules" ref='dataForm' label-position="center">
          <el-form-item label="驳回原因:" prop="rejectReason" :label-width="formLabelWidth">
            <el-input type="textarea" rows="4" placeholder="请输入驳回原因"  show-word-limit maxlength="200" v-model.trim="dataForm.rejectReason"></el-input>
          </el-form-item>
          <el-form-item label="附件" prop="rejectAttach" :label-width="formLabelWidth">
            <el-upload
              class="upload-demo"
              style="max-width: 379px;"
              :action="uploadUrl"
              :headers="importHeader"
              :on-success="handleOnSuccess"
              :on-remove="handleOnRemove"
              :before-remove="beforeOnRemove"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleOnExceed"
              multiple
              :limit="1"
              :file-list="fileList"
              accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg,.DOC,.doc,.DOCX,.docx"
            >
              <el-button size="min" icon="el-icon-upload" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>

          <el-form-item  class="submitArea">
            <el-button type="primary" @click="auditRejectClick()" >
              立即提交
            </el-button>
            <el-button @click="dialogFormVisible = false" >
              取消
            </el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="this.dialogStatus == 'history'">
          <el-table
            style="width:100%"
            border
            stripe
            ref="table"
            highlight-current-row
            :data="historyList"
            :header-cell-style="{
              'text-align':'center',
              'background-color': 'var(--other-color)',
            }"
            @header-dragend="changeColWidth"
          >
            <el-table-column label="审核状态" prop="status" width="110">
              <template slot-scope="{row}">
                <span v-if="row.status === 1">审核通过</span>
                <span v-if="row.status === 2">审核驳回</span>
              </template>
            </el-table-column>
            <el-table-column label="驳回原因" prop="reason" width="350"></el-table-column>
            <el-table-column label="审核人" prop="auditorName" width="120"></el-table-column>
            <el-table-column label="审核时间" prop="createdTime" width="140" :formatter='dateFormat'></el-table-column>
          </el-table>
          <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="auditOpinionHistory"/>
        </el-form>
      </el-dialog>
      <el-dialog :title="form.cnName + ' 驳回原因'" :visible.sync="dialogVisible">
        <div style="min-height:200px">
          <p style="margin-bottom:8px">{{cause.reason}} </p>
          <img v-if="cause.path != ''" :src="url + 'cms/manual/image/display?filePath=' + cause.path" style="max-width:50%; margin:10px 0">
        </div>
      </el-dialog>
      <el-dialog width="550px !important" class="pendingList" title="待审核任务"  :visible.sync="pendingDlgVisible">
        <!-- 待处理任务列表 -->
        <el-form>
          <div class="pendingTitle">
            <div>
              <span>主题名称：</span>
              <el-input v-model="themeName" placeholder="请输入主题名称"></el-input>
            </div>
            <el-button type="primary" @click="searchClick">搜索</el-button>
          </div>
          <div class="listTable">
            <el-table
              style="width:100%"
              border
              stripe
              ref="table"
              highlight-current-row
              :data="pendingTaskList"
              :header-cell-style="{
                'text-align':'center',
                'background-color': 'var(--other-color)',
              }"
              @header-dragend="changeColWidth"
            >
              <el-table-column label="序号" type="index" width="60"></el-table-column>
              <el-table-column label="主题名称" prop="cnName" min-width="150"></el-table-column>
              <el-table-column label="项目状态" prop="statusName" align="center" min-width="100"></el-table-column>
              <el-table-column label="操作" fixed="right" align="center">
                <template slot-scope="{row}">
                  <el-button type="text"  size="small" @click="handleSelecTask(row)">【选中】</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { sysServerUrl, cmsServerUrl, contentSize, handleAlert, expandEvents, nodeExpand, nodeCollapse } from '@/assets/js/common.js'
import { auditManualData, auditPass, auditReject, auditHistory,  auditRejectCause,  pendingAuditList } from '@/api/cmsmgt.js'
import { previewURL } from '@/api/releasemgt.js'
import axios from 'axios'
export default {
  name: 'audit',
  components: { Pagination },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'cnName',
      },
      dialogStatus: '',
      textMap: {
        reject: '审核驳回',
        history: '审核意见'
      },
      directoryId: '',
      historyList:[],
      pagesize: 20,
      currentPage: 1,
      total: 0,
      form: {
        id: '',
        pid: '',
        manualId: '',
        manualVersion: '',
        state: '',
        effect: '',
        cnName: ''
      },
      formLabelWidth: '100px',
      manualId: 0,
      manualVersion: '',
      manualType: '',
      treeExpandIdList:[],
      listdata: [],
      dialogFormVisible: false,
      pendingDlgVisible: false,
      pendingTaskList: [],
      pendingTaskCount: 0,
      pendingBtnState: false,
      uploadUrl: '',
      dataForm: {
        rejectReason: '',
      },
      fileList: [],
      srcPath: '',
      pathTip:'',
      dialogVisible: false,
      themeName:'',
      url: '',
      cause: {
        id: '',
        reason: '',  // 原因
        path: '', // 图片路径
      },
      rules: {
        rejectReason: [{ required: true, message: '驳回原因不能为空' , trigger: ['blur', 'change']}],
      },
      searchSattus:false,
      previewId:'',
    }
  },
  computed: {
    importHeader: function () {
      return { Authorization: sessionStorage.token }
    }
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "audit") {
        _this.manualId = to.params.id
        _this.manualVersion = to.params.version
        _this.initialState();
      }
    }
  },
  methods: {
    // 监听表格列宽变化
    changeColWidth(){
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      })
    },
    // 数据
    dataList () {
      var params = new URLSearchParams()
      params.append('id', this.$route.params.id)
      params.append('manualVersion', this.$route.params.version)
      auditManualData(params).then(res=>{
        this.listdata = res.data.data
        this.expandStatus(this.listdata)
      })
    },
    expandStatus(data){
      if(this.previewId !== undefined && this.previewId !== ''){
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(this.previewId);
        });
      }else{
        var expand = expandEvents(data)
        this.treeExpandIdList.push(expand.id)
        this.handleNodeClick(expand)
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(expand.id);
        });
      }
    },
    previewPath(id , isLeafStatus){
      this.previewId = id
      var directoryId = this.previewId + '/0'
      if( isLeafStatus == '1'){
        previewURL(directoryId).then(res => {
          if(res.data.code == '100'){
            if(res.data.data != null){
              this.srcPath = res.data.data;
              this.pathTip = ''
            }else{
              this.srcPath = res.data.data
              this.pathTip = '暂无详情页面'
            }
          }else{
            this.srcPath =''
            this.pathTip = res.data.msg
          }
        })
      }
    },
    renderContent(h, { node, data, store }) {
      let isLeaf = data.isLeaf;
      let vStatus= data.auditStatus==null?0:data.auditStatus;
      let style=''
      if(isLeaf==1){
	  	  if(vStatus==2){
	  	    style='red-category'
	  	  }else if(vStatus==1){
          style='green-category'
	  	  }else if(vStatus==3){
          style='orange-category'
	  	  }else {
          style='blue-category'
        }
  		}else{
        style='black-category'
  		}
      return(<span class={style} style="font-size:16px;cursor:pointer">{node.label}</span>)
    },
    // 审核通过
    auditPassClick() {
      var checkedNodes = this.$refs.tree.getCheckedNodes();
      if(checkedNodes.length==0){
        handleAlert('error','请先勾选目录节点')
        return false
      }
		  var chkIds="";
			for(var i=0;i<checkedNodes.length;i++){
				if(checkedNodes[i].isLeaf==1){
					chkIds+=checkedNodes[i].id + ",";
				}
      }
      var params = new URLSearchParams()
      params.append('directoryIds', chkIds)
      params.append('manualId', this.manualId)
      params.append('manualVersion',this.manualVersion)
      auditPass(params).then(res => {
        if(res.data.code==100){
          handleAlert('success',res.data.msg)
          this.dataList()
          this.getPendingTaskList()
        }else{
          handleAlert('error', res.data.msg)
        }
      }).catch(function(error){
        handleAlert('error','审核提交失败')
      })
    },
    resetTemp(){
      this.dataForm = {
        rejectReason: '',
      }
      this.$nextTick(function() {
        this.$refs.dataForm.clearValidate();
      })
    },
    showRejectDlg(){
      this.dialogStatus = "reject"
      this.fileList=[]
      var checkedNodes = this.$refs.tree.getCheckedNodes();
      if(checkedNodes.length==0){
        handleAlert('error','请先勾选目录节点')
        return false
      }
      this.dialogFormVisible=true
      this.resetTemp()
    },
    // 审核驳回
    auditRejectClick() {
      if(this.dataForm.rejectReason==''){
        handleAlert('error','请输入驳回原因')
        return false
      }
      var checkedNodes = this.$refs.tree.getCheckedNodes();
		  var chkIds="";
			for(var i=0;i<checkedNodes.length;i++){
			 	if(checkedNodes[i].isLeaf==1){
			 	  chkIds+=checkedNodes[i].id + ",";
			 	}
		  }
      var params = new URLSearchParams()
      params.append('directoryIds', chkIds)
      params.append('manualId',this.manualId)
      params.append('reason', this.dataForm.rejectReason)
      params.append('manualVersion',this.manualVersion)
      let fileName=''
      let filePath=''
      if(this.fileList!=null&&this.fileList.length>0){
        fileName =this.fileList[0].name
        filePath =this.fileList[0].url
      }
      params.append('fileName', fileName)
      params.append('path',filePath)
      auditReject(params).then(res => {
        if(res.data.code==100){
          handleAlert('success','审核驳回提交成功')
          this.dataList()
          this.getPendingTaskList()
          this.dialogFormVisible=false
        }else{
          handleAlert('error',res.data.msg)
        }
      }).catch(function(error){
        handleAlert('error','审核驳回提交失败')
      })
    },
    handleOnSuccess (res, obj) {
      var file = {name: res.data.fileName, url: res.data.fileUrl}
      this.fileList.push(file)
    },
    beforeOnRemove(file, fileList) {
      return this.$confirm(`确定移除选择文件？`, '删除',{type: 'warning'});
    },
    handleOnRemove(file,fileList){
      if(fileList.length == '0'){
        this.fileList = []
      }
    },
    beforeAvatarUpload (file) {
      var fileName = file.name.substring(file.name.lastIndexOf(".")+1).toLowerCase()
      const extension = fileName === 'png'
      const extension2 = fileName === 'jpg'
      const extension3 = fileName === 'jpeg'
      const extension4 = fileName === 'gif'
      const extension5 = fileName === 'doc'
      const extension6 = fileName === 'docx'
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!extension && !extension2 && !extension3 && !extension4 && !extension5 && !extension6) {
        handleAlert('warning','上传模板只能是 png、jpg、jpeg、gif、doc、docx格式!')
      }
      if (!isLt2M) {
        handleAlert('warning','上传模板大小不能超过 5MB!')
      }
    },
    handleOnExceed (files, fileList) {
      handleAlert('warning',`当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // 历史审核意见
    auditOpinion(id){
      if(id == ''){
        handleAlert("error", "请选择节点信息")
      }else {
        this.dialogStatus="history"
        this.dialogFormVisible=true
        this.directoryId = id
        this.auditOpinionHistory()
      }
    },
    auditOpinionHistory(){
      var params = {
          page: this.currentPage,
          limit: this.pagesize,
          directoryId: this.directoryId
        }
        auditHistory(params).then(res => {
          this.total = res.data.total
          this.historyList = res.data.data
        })

    },
    //查询待审核任务列表
    pendingTaskData(){
      var params = new URLSearchParams()
      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)
      params.append('cnName',this.themeName)
      pendingAuditList(params).then(res => {
        if(this.searchSattus == false){
          this.pendingTaskCount = res.data.total    // 总条数
        }
        if(this.pendingTaskCount>0){
          this.pendingBtnState=false;
        }else{
          this.pendingBtnState=true;
        }
        this.pendingTaskList = res.data.data   // 数据
      })
    },
    getPendingTaskList () {
      this.searchSattus = false
      this.pendingTaskData()
    },
    // 搜索
    searchClick(){
      this.searchSattus = true
      this.pendingTaskData()
    },
    //打开待审核任务对话框
    openPendingDlgForm(){
      this.pendingDlgVisible=true;
    },
    //选中待审核任务列表项
    handleSelecTask(row){
      //关闭弹出框
      this.pendingDlgVisible=false;
      //设置展开节点
      var idArr = new Array();
      idArr.push(row.id)
      this.treeExpandIdList= idArr;
      //设置选中
      this.$refs.tree.setCheckedKeys([row.id])
      //触发选中节点事件
      const node = this.$refs.tree.getNode(row.id)
      this.handleNodeClick(node.data)
      // 滚动定位
      setTimeout(() => {
        var allHeight = $(".scrollClass").height()
        var currentHeight = $(".scrollClass .el-tree-node.is-current").offset().top
        if(currentHeight > allHeight){
          setTimeout(() =>{
            var val = currentHeight - allHeight
            document.querySelector('.el-scrollbar__wrap').scrollTop = val
          },200)
        }else{
          setTimeout(() => {
            document.querySelector('.el-scrollbar__wrap').scrollTop = 0
          },200)
        }
      })
    },
    // 显示详情
    handleNodeClick (data) {
      this.previewPath(data.id, String(data.isLeaf))
      if(data.isLeaf == 0){
        return false;
      }
      if (data.children != null && data.children.length>0) {
        return false;
      }
      this.form.id = data.id
      this.form.pid = data.pid
      this.form.manualId = data.manualId
      this.form.manualVersion = this.manualVersion
      this.form.cnName =  data.cnName
      if (data.isLeaf === 1) {
        let code = data.code
        if (data.auditStatus === 1) {
          this.form.state = '审核通过'
        } else if (data.auditStatus === 2) {
          this.form.state = '审核驳回'
        }else if (data.auditStatus === 3) {
          this.form.state = '驳回变更'
        }else if (data.auditStatus === 4) {
          this.form.state = '自检变更'
        } else {
          this.form.state = '未审核'
        }
      } else {
        this.form.state = ''
      }
    },
    // 数节点展开
    handleNodeExpand(data){
      var list = nodeExpand(this.treeExpandIdList, data)
      this.treeExpandIdList = list
    },
    // 数节点关闭
    handleNodeCollapse(data){
      var list = nodeCollapse(this.treeExpandIdList,data)
      this.treeExpandIdList = list
    },
    rejectCause(id){
      var params = new URLSearchParams()
      params.append('id', id)
      auditRejectCause(params).then(res=>{
        if(res.data.code == 100){
          this.cause.id = res.data.data.id
          this.cause.reason = res.data.data.reason  // 原因
          this.cause.path = res.data.data.path // 图片路径
        }
      })
      this.dialogVisible = true
    },
    // 时间转换
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    },
    initialState() {
      var _this = this;
      _this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=ManualReason'
      _this.url = cmsServerUrl
      _this.dataList()
      _this.getPendingTaskList();
      contentSize()
    },
  },
  mounted () {
    this.manualId = this.$route.params.id
    this.manualVersion=this.$route.params.version
    this.initialState()
  },
}
</script>
<style>
  .red-category,
  .green-category,
  .orange-category,
  .blue-category,
  .black-category{
    color:red;
    font-size: 14px !important;
    margin-left: 1px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .orange-category{
    color:orange;
  }
  .green-category{
    color:green;
  }
  .blue-category{
    color:blue;
  }
  .black-category{
    color:#333;
  }
  .infoDetail .fromRight.auditRight .formTitle{
    background: #fafafa;
    border-bottom: 1px solid #cfd5de;
    box-sizing: border-box;
    font-weight: bold;
    box-sizing: border-box;
    height: 42px;
    line-height: 42px;
    text-align: left;
  }
  .infoDetail .auditRight .formTitle div{
    display: inline-block;
  }
  .infoDetail .auditRight .formTitle .rightBut{
    float: right;
  }
  .auditContent .el-pagination{
    padding: 3px 20px !important;
  }
  .auditContent .el-dialog .el-select .el-input.el-input--suffix{
    width: 100px !important;
  }
  .auditContent .el-textarea{
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
  .auditContent .el-textarea .el-input__count{
    line-height: 1;
    bottom: 0;
    right: 5px;
  }
  .auditContent .el-textarea .el-textarea__inner{
    border: none;
    margin-bottom: 12px;
  }
</style>
