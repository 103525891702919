import {connectMessageServer} from "@/api/websocket.js";
import router from '@/router'
import store from '@/store/index'
import { handleAlert } from '@/assets/js/common.js'
const enableWebSocket = false;

export function closeRealMessage(){
  setTimeout(function(){
    //关闭socket
    if(window.socketFd && window.socketFd !=null){
        window.socketFd.close();
      }
    },100)
}

function sendheartbeat(){
    clearTimeout(sendheartbeat)
    //无token，则关闭
    if(sessionStorage.token == undefined ||  sessionStorage.token == null || sessionStorage.token == ""){
      if(window.socketFd && window.socketFd.readyState == window.socketFd.OPEN){
        window.socketFd.close()
      }
      return
    }
    if(window.socketFd && window.socketFd.readyState == window.socketFd.OPEN){
     // console.log("send beat")
        window.socketFd.send("heartbeat")
    }
    if(window.socketFd && !(window.socketFd.readyState == window.socketFd.CLOSED)){
      setTimeout(sendheartbeat,10000)
      //console.log("继续循环 send beat")
    }
   // console.log(window.socketFd)
  }

  export function initWebSocket(messageCallBack){
    if(!enableWebSocket){
      return;
    }
    //如果已经连接,先断开，不能保留，因为可能token与当前登录的用户不是同一个用户，保险起见，关闭，重新连接，会使用新token
    if(window.socketFd && window.socketFd.readyState == window.socketFd.OPEN){
        window.socketFd.close()
        window.socketFd = null;
        //关闭后会自动重新连接，不要继续执行
        return
    }
    window.socketFd = connectMessageServer(msg=>{
      //console.log(msg);
       var dataObj =  JSON.parse(msg.data)
       if(dataObj.type == 0){
        //被其他用户挤下线
        handleAlert("error",dataObj.data)
        store.commit('del_token');
        setTimeout(() => {
          //由于线上是前后台不是同一个项目，跳转会导致前面的弹窗失效，
          //所以延时1s再跳转
          router.push("/");
      }, 1000);
        return;
       }
       messageCallBack(dataObj)
    },error=>{
      //重连接
      console.log(error)
      //报错后会调用关闭事件，不要在这里重新连接
    },(socket)=>{
      //ready
      setTimeout(sendheartbeat,10000)
    },(socket)=>{
      //onclose
      setTimeout(initWebSocket,1000)
    })
}
