import { post,  get,down, download } from "../plugins/request";
import { sysServerUrl, cmsServerUrl } from '../assets/js/common'


// 技术通告
export const bulletinData = (params) => get(cmsServerUrl + 'cms/manual/bulletin/list', params); // 技术通告-内容数据
export const bulletinAdd = (params) => post(cmsServerUrl + 'cms/manual/bulletin/add', params); // 技术通告-新增
export const bulletinEdit = (params) => post(cmsServerUrl + 'cms/manual/bulletin/edit', params); // 技术通告-编辑
export const bulletinContent = (params) => post(cmsServerUrl + 'cms/manual/bulletin/content/' + params); // 技术通告-内容
export const bulletinStatusEdit = (params) => post(cmsServerUrl + 'cms/manual/bulletin/statusEdit', params); // 技术通告-状态
export const bulletinDelete = (params) => post(cmsServerUrl + 'cms/manual/bulletin/del/' + params); // 技术通告-删除
export const bulletinInfo = (params) => post(cmsServerUrl + 'cms/manual/bulletin/details/' + params); // 技术通告-明细
export const bulletinLanguageType = (params) => get(sysServerUrl + 'release/api/language', params); // 语种分类
export const userCountryData = (params) => get(sysServerUrl + 'sys/region/api/getCountry'); // 获取国家
export const bulletinTypeData = (params) => get(sysServerUrl + 'cms/manual/bulletin/type', params); // 获取类型
// 用户管理-获取全部的国家信息包含大洲
export const getCountryAll = (params) => post(sysServerUrl + 'sys/region/api/country/all'); 


// ========== 内容管理 ========== //
// 大纲管理
export const syllabusNodeAdd = (params) => post(cmsServerUrl + 'cms/manual/outline/insertNode', params); // 大纲管理-只添加节点
export const syllabusTreeList = (params) => get(cmsServerUrl + 'cms/manual/outline/getManualOutlineTreeList', params); //大纲管理-手册内容数据
export const syllabusCatalog = (params) => get(cmsServerUrl + 'cms/manual/outline/getOutlineTree/'+ params); //大纲管理-手册目录
export const syllabusManualAdd = (params) => post(cmsServerUrl + 'cms/manual/outline/insertManual', params); //大纲管理-添加手册
export const syllabusManualDel = (params) => post(cmsServerUrl + 'cms/manual/outline/del/'+ params); //大纲管理-删除手册
export const syllabusUpdate = (params) =>post(cmsServerUrl + 'cms/manual/outline/update', params); //大纲管理-修改节点
export const syllabusNodeDel = (params) => post(cmsServerUrl +"cms/manual/outline/delete/" + params); // 大纲管理-删除节点
export const syllabusType = (params) => post(sysServerUrl + 'front/message/manual', params); // 大纲管理 - 主题类型
export const syllabusFirm = (params) => get(sysServerUrl + 'sys/car/train/list', params); // 大纲管理-公司列表
export const syllabusFile = (params, uploadProgress) => post(sysServerUrl + 'cms/manual/outline/importOutline', params, uploadProgress); // 大纲管理-批量上传
export const batchExport = (params) => down(cmsServerUrl + "cms/manual/directory/batchExport" + params);//大纲管理-下载
export const syllabusexportExcel = (params) => download(cmsServerUrl + 'cms/manual/outline/download', params); // 大纲管理-导出excel

// 项目管理
export const projectData = (params) => get(cmsServerUrl + 'cms/project/list', params);//项目管理-内容数据
export const trainInfo = (params) => get(sysServerUrl + 'sys/car/train/list', params); //项目管理-车系信息
export const yearInfo = (params) => post(sysServerUrl + 'sys/car/model/find/' + params);//项目管理-年款信息
export const projectUser = (params) => get(cmsServerUrl + 'cms/project/projectManager', params);// 项目管理-查询项目负责人
export const projectFirm = (params) => get(sysServerUrl + 'sys/car/train/list', params); //项目管理-公司
export const projectAdd = (params) => post(cmsServerUrl + 'cms/project/add', params); // 项目管理-增加
export const projectEdit = (params) => post(cmsServerUrl + 'cms/project/edit', params);// 项目管理-编辑
export const projectDel = (params) => get(cmsServerUrl + 'cms/project/del/' + params);//项目管理-删除
export const projectMember = (params) => get(cmsServerUrl + 'cms/manual/projectUser/member/' + params); // 项目成员
export const memberUpdate = (params) => post(cmsServerUrl + 'cms/manual/projectUser/insert', params); // 权限控制-添加

// 手册管理
export const manualCatalogue = (params) => get(cmsServerUrl + 'cms/project/manualTree', params); // 手册管理-左侧目录
export const catalogueList = (params) => get(cmsServerUrl + 'cms/project/findProjectTree', params); // 手册管理-目录
export const manualContent = (params) => get(cmsServerUrl + 'cms/project/manualList', params); // 手册管理-内容数据
export const manualUser = (params) => get(sysServerUrl + 'cms/manual/manualManager', params); // 手册管理-查询手册负责人
export const projectList = (params) => get(cmsServerUrl + 'cms/project/list/all', params);  // 手册管理-项目列表
export const manualAdd = (params) => post(cmsServerUrl + 'cms/manual/add', params); //手册管理-增加
export const manualEdit = (params) => post(cmsServerUrl + 'cms/manual/edit', params); // 手册管理-编辑
export const manualDel = (params) => get(cmsServerUrl + 'cms/manual/del/' + params); //手册管理-删除
export const manualMember = (params) => get(cmsServerUrl + 'cms/manual/accredit/member/' + params) ;  //手册管理-查询成员
export const manualMemberProject = (params) => get(cmsServerUrl + 'cms/manual/accredit/memberProjectId/' + params) ;  //手册管理-查询成员
export const dictTypeData = (params) => get(sysServerUrl + 'sys/dict/query?dictType='+ params); //手册管理-增加
export const dictTypeOutput = () => get(sysServerUrl + 'cms/manual/style/output/type'); 
export const manualAddCover = (params) => post(cmsServerUrl + 'cms/manual/addCover', params); //手册管理-封面封底

export const manualTypeListQuery = (params) => get(cmsServerUrl + 'cms/manual/getManualType/'+ params); //手册类型列表
export const copyTreeData = (params) => get(cmsServerUrl + 'cms/manual/directory/entiretyTree/'+params); // 手册管理-手册目录-复制获取目录树
export const defineCopy = (params) => post(cmsServerUrl + 'cms/manual/directory/entiretyCopy', params); // 手册管理-手册目录-确定复制
// 手册管理-版本
export const versionData = (params) => get(cmsServerUrl + 'cms/manual/version/list' + params); // 手册管理-手册目录-内容数据
export const versionAdd = (params) => post(cmsServerUrl + 'cms/manual/version/edit', params); // 手册管理-手册目录-增加
export const versionDefault = (params) =>post(cmsServerUrl + 'cms/manual/version/setDefault', params); // 手册管理-手册目录-默认版本
export const versionDel = (params) => get(cmsServerUrl + 'cms/manual/version/del/' + params); // 手册管理-手册目录-删除

// 手册管理-手册目录
export const catalogData = (params) => post(cmsServerUrl + 'cms/manual/directory/getChildrenList', params); //手册管理-手册目录-内容列表
// export const catalogFile = (params) => post(sysServerUrl+"sys/upload/procFile", params); // 手册管理-手册目录-批量上传
// export const catalogCheck = (params) => post(sysServerUrl+"sys/upload/checkProgress", params); // 手册管理-手册目录-批量上传分片
export const catalogImport = (params) => get(cmsServerUrl +"cms/manual/directory/batchImport", params); // 手册管理-手册目录-批量上传(成功)
export const catalogEdit = (params) => post(cmsServerUrl +  "cms/manual/directory/edit", params); // 手册管理-手册目录-节点保存修改
export const catalogAdd = (params) => post(cmsServerUrl +  "cms/manual/directory/add", params); // 手册管理-手册目录-节点添加
export const catalogDel = (params) => get(cmsServerUrl + "cms/manual/directory/del/" + params); // 手册管理-手册目录-删除
export const catalogUpload = (params, uploadProgress) => post(cmsServerUrl + "cms/manual/directory/importDirectory", params,  uploadProgress) //手册管理-手册目录-导入excel
export const catalogFirmUpload = (params) => post(cmsServerUrl + "cms/manual/directory/importFirmDirectory", params) //手册管理-手册目录-导入主机厂默认目录
export const catalogexportExcel = (params) => download(cmsServerUrl + 'cms/manual/directory/download', params); // 手册管理-手册目录-导出excel
export const catalogTypeList = (params) => get(cmsServerUrl + "cms/manual/directory/type/" + params);   // 章节类型
export const catalogimportPackage = (params) => post(cmsServerUrl + 'cms/manual/directory/finishUpload', params); // 手册管理-手册目录-上传数据包
export const catalogdowPackage = (params) => download(cmsServerUrl + 'cms/manual/directory/exportPackage', params); // 手册管理-手册目录-导出数据包
export const catalogdelPackage = (params) => post(cmsServerUrl + 'cms/manual/directory/delUploadFile', params); // 手册管理-手册目录-删除导入的数据包
export const catalogparsePackage = (params) => post(cmsServerUrl + 'cms/manual/directory/importPackage', params); // 手册管理-手册目录-解析导入的数据包


// 手册管理-手册内容
export const contentNodeDetail = (params) => get(cmsServerUrl + 'cms/manual/topicContent/getTopicContent/' + params); // 手册管理-手册内容-根据目录ID获取内容

export const contentData = (params) => post(cmsServerUrl + 'cms/manual/directory/getChildrenList', params); // 手册管理-手册内容-内容数据
export const contentInsert = (params) => post(cmsServerUrl + 'cms/manual/topicContent/editSave', params); // 手册管理-手册内容-保存
export const contentSaveAs = (params) => post(cmsServerUrl + 'cms/manual/topicContent/add', params); // 手册管理-手册内容-另存为
export const contentRefresh = (params) => post(cmsServerUrl + 'cms/manual/topicContent/editRefresh', params); // 手册管理-手册内容-刷新
export const contentImmigration = (params) => post(cmsServerUrl + 'cms/manual/topicContent/immigration', params); // 手册管理-手册内容-迁入
export const contentEmigration = (params) => post(cmsServerUrl + 'cms/manual/topicContent/emigration/' + params); // 手册管理-手册内容-迁出
export const contentAuthority = (params) => post(cmsServerUrl + 'cms/manual/topicContent/authority/' + params); // 手册管理-手册内容-权限编辑
export const pendingTaskList = (params) => post(cmsServerUrl + "cms/manual/directory/awaitWrite" ,  params); // 手册管理-手册内容-获取待处理任务列表
// 图片管理-图片明细
export const detailData = (params) => get(cmsServerUrl + 'cms/manual/image/list', params); // 图片管理-图片明细-内容数据
export const detailImport = (params, uploadProgress) => post(cmsServerUrl + 'cms/manual/image/batchImport', params,uploadProgress); // 图片管理-图片明细-批量导入
export const detailUpdate = (params) => post(cmsServerUrl +  'cms/manual/image/update', params); // 图片管理-图片明细-编辑
export const detailNotes = (params) => post(cmsServerUrl +  'cms/manual/image/edit', params); // 图片管理-图片明细-批注
export const detailDelete = (params) => post(cmsServerUrl +  'cms/manual/image/del/' + params); // 图片管理-图片明细-删除
// 任务分配
export const taskCatalogue = (params) => get(cmsServerUrl + 'cms/project/getDirectoryTree', params);//任务分配-左侧目录
export const taskManualData = (params) => get(cmsServerUrl +  'cms/manual/topicPermission/getDirectoryByManualId', params); // 任务分配-手册目录
export const assignTasks = (params) => post(cmsServerUrl +  'cms/manual/topicPermission/add', params); // 任务分配-分配任务
export const tasksUser = (params) => get(cmsServerUrl + 'cms/manual/topicPermission/getUserTree/' + params); //分配人员查询
// 审核管理
export const auditCatalogue = (params) => get(cmsServerUrl + 'cms/project/auditTree', params); //审核管理-左侧目录
export const auditManualList = (params) => get(cmsServerUrl + 'cms/project/auditManualList', params); // 审核管理-内容数据
export const auditHistory = (params) => get(cmsServerUrl + 'cms/manual/audit/history', params); //审核管理-历史审核意见
// 审核管理-手册审核
export const auditManualData = (params) => post(cmsServerUrl + 'cms/manual/directory/getChildrenList', params); // 审核管理-手册审核-数据内容
export const auditPass = (params) => post(cmsServerUrl + 'cms/manual/audit/pass', params); // 审核管理-手册审核-审核通过
export const auditReject = (params) => post(cmsServerUrl + 'cms/manual/audit/refuse', params); // 审核管理-手册审核-审核驳回
export const auditRejectCause = (params) => post(cmsServerUrl + 'cms/manual/audit/refuseCause', params); // 审核管理-手册审核-审核驳回
export const pendingAuditList = (params) => post(cmsServerUrl + "cms/manual/directory/awaitAudit" ,  params); // 审核管理-手册审核-获取待审核任务列表
export const auditResultState = (params) => post(cmsServerUrl + "cms/manual/directory/directoryAuditStatus/" +params); // 手册管理-手册内容-审核状态

// 审核管理-审核明细
export const auditDetailData = (params) => get(cmsServerUrl + 'cms/manual/audit/list', params); // 审核管理-审核明细-数据内容
