<template>
  <div class="layoutContainer">
    <div class="bulletinContainer">
      <div class="bulletinContent">
        <div v-for="(item, index) of noticeList" :key="index">
          <div class="bulletinDetail" @click="detailClick(item)">
            <div class="bulletinTitle">
              <p class="readState" style="color:#666" v-if="item.isRead == true">[已读]</p>
              <p class="readState" style="color:#c30000" v-if="item.isRead == false">[未读]</p>
              <p class="header">{{item.title}}</p>
            </div>
            <div class="upladeTime">{{ item.time | conversion("yyyy-MM-dd") }}</div>
          </div>
        </div>
      </div>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="noticeData"/>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { addTabs } from '@/assets/js/common.js'
import { systemBulletinList, dictData } from '@/api/sysmgt.js'
import $ from 'jquery'
export default {
  name:"systemBulletin",
  components: { Pagination },
  data () {
    return {
      noticeList:[],
      pagesize: 15,
      currentPage: 1,
      total: 0,
    }
  },
  methods: {
    noticeData(){
      var params = {
        page: this.currentPage,
        limit: this.pagesize
      }
      systemBulletinList(params).then(res => {
        if(res.data.code == '100'){
          this.total = res.data.total
          this.noticeList = res.data.data
        }
      })
    },
    detailClick(item){
      this.$router.push({ name: 'noticeDetail', params:{id: item.id}})
      addTabs(this.$route.path, item.title);
      if (item.isRead == false) {
        window.noticeUnread()
      }
    },
    heightArea() {
      setTimeout(() => {
        var allHeigth = $(".bulletinContainer").height();
        var pageHeight = $(".pagination-container").outerHeight(true);
        var val = allHeigth - pageHeight - 5;
        $(".bulletinContent").css("height", val);
      })
    },
    areaSize() {
      var _this = this
      _this.heightArea()
      window.addEventListener('resize', function () {
        _this.heightArea()
      })
    },
  },
  mounted () {
    var _this = this
    _this.noticeData()
    _this.areaSize()

  },
  watch: {
    $route(to,from){
      if (to.name == 'systemBulletin') {
        this.noticeData();
      }
    }
  },
}
</script>
<style>
.bulletinContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
  font-size: 16px;
}
.bulletinContainer .pagination-container {
  border: none;
  position: absolute;
  bottom: 0;
  background: transparent;
  /* background: #fff; */
}
.bulletinContainer .bulletinContent{
  /* height: calc(100% - 45px); */
  overflow-x: hidden;
  overflow-y: auto;
}
.bulletinContent .bulletinDetail{
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;
}
.bulletinContent .bulletinDetail .bulletinTitle{
  display: flex;
  flex: 1;
  overflow: hidden;
}
.bulletinContent .bulletinDetail .bulletinTitle .header{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.bulletinDetail .upladeTime,
.bulletinTitle .readState {
  white-space: nowrap;
  margin-right: 10px;
}
.bulletinTitle .readState {
  font-size: 12px;
}
.noticeTitle.read{
  color: #999;
}

</style>
