<template>
  <div class="layoutContainer">
    <div class="infoDetail manualContainer contentManual">
      <el-row>
        <el-col :span="7" class="leftData">
             <div class="manualMenu">
              <div class="scrollClass">
                <div class="topOptArea">
                  <el-button :disabled="pendingBtnState"   @click="openPendingDlgForm" type="text">
                    待处理
                    <el-badge v-if="pendingTaskCount > 0"  :value="pendingTaskCount" class="mark" />
                  </el-button>
                </div>
                <el-scrollbar style="height: calc(100% - 42px) !important;">
                  <el-tree
                    ref="tree"
                    id="filterTree"
                    :data="listdata"
                    node-key="id"
                    :default-expanded-keys="treeExpandIdList"
                    :props="defaultProps"
                    @node-click="handleNodeClick"
                  ></el-tree>
                </el-scrollbar>
            </div>
            <div class="foldBut" @click="foldClick()">
              <i class="el-icon-d-arrow-left"></i>
            </div>
          </div>
          <div class="openBut" @click="openClick()">
            <i class="el-icon-d-arrow-right"></i>
          </div>
        </el-col>
        <el-col :span="17" class="fromRight manualCenter" v-if="isNotSvg">
          <div v-if="isEmigration"
            class="formTitle"
            style="
              text-align: left;
              padding: 0 15px;
              color: #000;
              height: 42px;
              line-height: 42px;
            "
          >
            <!-- <el-button type="primary"  :disabled="showViewBtn" >预览效果</el-button> -->
            <!-- <el-button type="text" icon="preview-icon" @click="preview('预览效果', '/preview')">预览效果</el-button> -->
            <el-button type="text" icon="preserve-icon" @click="updateTopic">保存</el-button>
            <!-- 2023-02-08 先禁用，因为版本管理暂时没该功能 -->
            <el-button v-if="false" type="text" icon="save-icon" @click="showAddVersionDlg">另存为</el-button>
            <el-button type="text" icon="el-icon-refresh" @click="showAddRefresh">刷新</el-button>
            <el-button type="text" icon="checkIn-icon" @click="immigration">签入</el-button>
            <el-button v-show="auditCauseBtnVisible" style="float:right" type="text" @click="openAuditDetailDlgForm()">
              <span v-if="curState == 1" style="color:green; ">{{nodeStatusName}}</span>
              <span v-else-if="curState == 2" style="color:red; text-decoration: underline">{{nodeStatusName}}</span>
              <span v-else>{{nodeStatusName}}</span>
            </el-button>
          </div>
          <div v-else
            class="formTitle"
            style="
              text-align: left;
              padding: 0 15px;
              color: #000;
              height: 42px;
              line-height: 42px;
            "
          >
            <el-button type="text" v-if="isLeaf === 1" icon="checkOut-icon" @click="emigration" :disabled="isAuthority">签出</el-button>
            <el-button v-show="auditCauseBtnVisible" type="text" style="float:right" @click="openAuditDetailDlgForm()">
              <span v-if="curState == 1" style="color:green; ">{{nodeStatusName}}</span>
              <span v-else-if="curState == 2" style="color:red; text-decoration: underline">{{nodeStatusName}}</span>
              <span v-else>{{nodeStatusName}}</span>
            </el-button>
          </div>
          <!-- <textarea ref="textarea" v-if="isSvg" style="height:100%"></textarea> -->
          <div id="header">
            <!--iframe引入   style="pointer-events: none;"-->
            <iframe
              disabled="true"
              id="editFrame"
              marginwidth="0"
              marginheight="0"
              width="100%"
              height="100%"
              src="static/editor/lib/index.html"
              frameborder="no"
            ></iframe>
          </div>
        </el-col>
        <el-col :span="17" class="fromRight" v-else>
          <div class="fileImg">
            <div id="mysvg" style="height: 100%"></div>
          </div>
        </el-col>
      </el-row>
      <el-dialog title="确认生成新版本" :visible.sync="dialogFormVisible">
        <el-form ref="dataForm" label-position="center" :label-width="formLabelWidth">
          <el-form-item
            label="版本备注:"
            prop="versionRemark"
          >
            <el-input
              type="textarea"
              rows="5"
              placeholder="请输入版本备注"
              v-model.trim="versionRemark"
              aria-required="true"
            ></el-input>
          </el-form-item>
          <el-form-item class="submitArea">
            <el-button @click="dialogFormVisible = false"> 取消 </el-button>
            <el-button type="primary" @click="addTopic"> 确认 </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog width="500px !important" class="pendingList"  title="待处理任务"  :visible.sync="pendingDlgVisible">
        <!-- 待处理任务列表 -->
        <el-form>
          <div class="pendingTitle">
            <div>
              <span>主题名称：</span>
              <el-input v-model="themeName" placeholder="请输入主题名称"></el-input>
            </div>
            <el-button type="primary"  @click="searchClick">搜索</el-button>
          </div>
          <div class="listTable">
            <el-table style="width:100%; margin-bottom:10px;" border :data="pendingTaskList" :header-cell-style="{'text-align':'center'}">
              <el-table-column label="序号" type="index" width="60"></el-table-column>
              <el-table-column label="主题名称" prop="cnName" min-width="150">
                <template slot-scope="{row}">
                  <span :title="row.cnName">{{row.cnName}}</span>
                </template>
              </el-table-column>
              <el-table-column label="项目状态" prop="statusName" min-width="100"></el-table-column>
              <el-table-column label="操作" fixed="right">
                <template slot-scope="{row}">
                  <el-button type="text"  size="small" @click="handleSelecTask(row)">【选中】</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
      </el-dialog>
      <el-dialog :title="cnName + ' 审核驳回'" :visible.sync="auditCauseDlgVisible" >
        <div style="min-height:200px">
          <p style="margin-bottom:8px">{{cause.reason}}</p>
          <img v-if="cause.path != ''" :src="url + 'cms/manual/image/display?filePath=' + cause.path" style="max-width:50%; margin:10px 0">
        </div>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="auditCauseDlgVisible = false">确 定</el-button>
        </span> -->
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { cmsServerUrl, contentSize, addTabs, handleAlert } from "@/assets/js/common.js";
import {
  contentData,
  contentNodeDetail,
  contentSaveAs,
  contentInsert,
  contentRefresh,
  contentImmigration,
  contentEmigration,
  contentAuthority,
  pendingTaskList,
  auditResultState
} from "@/api/cmsmgt.js";
import $ from "jquery";
import { loadSvg } from "@/plugins/mysvg.js";
export default {
  name: "manualContent",
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "cnName",
      },
      formLabelWidth: "100px",
      listdata: [],
      treeExpandIdList: [],
      manualId: 0,
      manualVersion: "",
      projectName: "",
      cnName: "",
      directoryId: 0,
      versionRemark: "",
      dialogFormVisible: false,
      auditCauseDlgVisible: false,
      auditCauseBtnVisible: false,
      pendingDlgVisible: false,
      pendingTaskList: [],
      pendingTaskCount: 0,
      pendingBtnState: false,
      cause: {
        id: '',
        reason: '',  // 原因
        path: '', // 图片路径
      },
      url:'',
      // showViewBtn: true,
      isNotSvg: true,
      isSvg: true,
      isAuthority: true,   // 编辑权限
      isEmigration: false,   // 迁出
      childWindow: null, // 子窗体（编辑器）的window对象
      isLeaf: '',    // 叶子节点
      isHaveContent: true,
      htmlContent: '',
      nodeStatusName:'',
      curState:'',
      themeName:'',
      searchSattus:false,
      worker: undefined, // worker线程
    };
  },
  watch: {
    // 利用watch方法检测路由变化
    $route(to) {
      var _this = this;
      if (to.name !== "manualContent") {
        _this.url = cmsServerUrl;
        _this.manualId = to.params.id;
        _this.manualVersion = to.params.manualVersion;
        _this.dataList();
        _this.isNotSvg = true;
      }
    },
  },
  methods: {
    dataList() {
      var params = new URLSearchParams();
      params.append("id", this.$route.params.id);
      params.append("manualVersion", this.$route.params.manualVersion);
      contentData(params).then((res) => {
        this.listdata = res.data.data;
        this.treeExpandIdList.push(this.listdata[0].id);
      });
    },
    foldClick(){
      $(".leftData .manualMenu").hide()
      $(".openBut").show()
      $(".manualContainer .leftData").css("width", "30px")
      $(".manualContainer .fromRight").css("width", "calc(100% - 30px)")
    },
    openClick(){
      $(".leftData .manualMenu").show()
      $(".openBut").hide()
      $(".manualContainer .leftData,.manualContainer .fromRight").removeAttr("style")
    },
    //查询待处理任务列表
    pendingTaskData(){
      var params = new URLSearchParams()
      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)
      params.append('cnName',this.themeName)
      pendingTaskList(params).then(res => {
        if(this.searchSattus == false){
          this.pendingTaskCount = res.data.total    // 总条数
        }
        if(this.pendingTaskCount>0){
          this.pendingBtnState=false;
        }else{
          this.pendingBtnState=true;
        }
        this.pendingTaskList = res.data.data   // 数据
      })
    },
    getPendingTaskList () {
      this.searchSattus = false
      this.pendingTaskData()
    },
    //打开待处理任务对话框
    openPendingDlgForm(){
      this.pendingDlgVisible=true;
    },
    // 搜索
    searchClick(){
      this.searchSattus = true
      this.pendingTaskData()
    },
    //打开审核意见内容对话框
    openAuditDetailDlgForm(){
      if(this.curState==2){
        this.auditCauseDlgVisible=true;
      }else{
        this.auditCauseDlgVisible=false;
      }
    },
    //查询topic内容最近一次的审核结果
    getAuditResult(directoryId){
      this.auditCauseBtnVisible=true;
      auditResultState(directoryId).then(res=>{
        if(res.data.code == 100){
          this.nodeStatusName = res.data.data.statusName
          this.curState= res.data.data.status
          if(this.curState==2){
            this.cause.id = res.data.data.id
            this.cause.reason = res.data.data.reason  // 原因
            this.cause.path = res.data.data.path // 图片路径
          }
        }
      })
    },
    //选中待处理任务列表项
    handleSelecTask(row){
      //关闭弹出框
      this.pendingDlgVisible=false;
      //设置展开节点
      var idArr = new Array();
      idArr.push(row.id)
      this.treeExpandIdList= idArr;
      //设置选中
      this.$refs.tree.setCurrentKey(row.id)
      //触发选中节点事件
      const node = this.$refs.tree.getNode(row.id)
      this.handleNodeClick(node.data)
      // 滚动定位
      setTimeout(() => {
        var allHeight = $(".scrollClass").height()
        var currentHeight = $(".scrollClass .el-tree-node.is-current").offset().top
        if(currentHeight > allHeight){
          setTimeout(() =>{
            var val = currentHeight - allHeight
            document.querySelector('.el-scrollbar__wrap').scrollTop = val
          },200)
        }else{
          setTimeout(() => {
            document.querySelector('.el-scrollbar__wrap').scrollTop = 0
          },200)
        }
      })
    },
    handleNodeClick(treeNode) {
      // 切换Topic停止计时
      this.stoptimeWorker();
      this.isEmigration = false;
      this.isAuthority = true;
      let directoryId = treeNode.id;
      this.isLeaf = treeNode.isLeaf;
      // 禁用编辑器
      this.childWindow.disabledEditor()
      this.isHaveContent = true;
      if (this.isLeaf == 1) {
        this.directoryId = directoryId;
        this.getAuditResult(this.directoryId)
        // this.childWindow.setContent("<br>")
        // 获取编辑器内容
        contentNodeDetail(directoryId).then((res) => {
          let topicContent = res.data.data
          if (topicContent.id != null) {
            this.childWindow.setContent(topicContent.content, true);
            this.isHaveContent = false;
          } else {
            this.childWindow.setContent("<br>", true);
            this.isHaveContent = true;
            this.htmlContent = `<tag tagname="title" tagtype="    level=&quot;1&quot;" id="tag_1" style="font-weight: bold;font-size:1.9em;" level="1" layout="default">${topicContent.directoryName}</tag>
<tag tagname="abstract" id="tag_2">‌<tag tagname="indexterm" id="tag_3">‌<tag tagname="index-sort-as" id="tag_4">${topicContent.indexterm}</tag>​</tag>​</tag>
<tag tagname="body" id="tag_5">‌<tag tagname="section" id="tag_6" layout="default">‌<br></tag>‌</tag><br>`;
          }
          // 有没有权限
          contentAuthority(directoryId).then(res => {
            // this.childWindow.setContent("<br>");
            if (res.data.data == "true") {
              this.isAuthority = false;
            }else if(res.data.data == "emigration"){
              // 启用 编辑器
              this.childWindow.removeEditor();
              if (this.isHaveContent) {
                this.childWindow.setContent(this.htmlContent, true);
              }else{
                // 获取缓存中的编辑器源码，如果缓存中存在就赋值
                // let h = localStorage.getItem(directoryId)
                // if (h) {
                //   this.childWindow.setContent(h, true);
                // }
              }

              this.isEmigration = true;
              this.timeWorker();
              // handleAlert('warning', "已经有人在编辑了")
            }else{
              this.isAuthority = true;
              // handleAlert('warning', "您还没有权限哦")
            }
          })
        });
      }else{
        this.auditCauseBtnVisible=false;
      }
    },
    // 保存当前版本
    updateTopic() {
      var formData = new FormData();
      formData.append("directoryId", this.directoryId); // 节点的id
      formData.append("content", this.childWindow.getContent()); // 节点内容
      contentInsert(formData)
        .then((res) => {
          if (res.data.code === 100) {
            handleAlert('success', "保存成功")
          } else {
            handleAlert('error', res.data.msg)
          }
        }).catch(function (err) {
          if (err !== null && err !== '' && err.responseText !== null) {
            handleAlert('error', "保存取消")
          }
        });
    },
    showAddVersionDlg() {
      this.dialogFormVisible = true;
    },
    // 添加新版本, 另存为
    addTopic() {
      if (this.versionRemark === "" || this.versionRemark.length === 0) {
        handleAlert('error','请输入版本备注')
        return false;
      }
      if (this.versionRemark.length > 200) {
        handleAlert('error','版本备注最多只能输入200个字符')
        return false;
      }
      var formData = new FormData();
      formData.append("directoryId", this.directoryId);
      formData.append("content", this.childWindow.getContent());
      formData.append("remark", this.versionRemark);

      contentSaveAs(formData)
        .then((res) => {
          if (res.data.code === 100) {
            handleAlert('success','保存新版本成功')
            this.dialogFormVisible = false;
          } else {
            handleAlert('error',res.data.msg)
          }
        }).catch(function (err) {
          if (err !== null && err !== '' && err.responseText !== null) {
            handleAlert('error',"保存新版本失败")
          }
        });
    },
    // 刷新
    showAddRefresh(){
      var formData = new FormData();
      formData.append("directoryId", this.directoryId); // 节点的id
      formData.append("content", this.childWindow.getContent()); // 节点内容
      contentRefresh(formData).then((res) => {
        if (res.data.code === 100) {
          handleAlert('success',"刷新成功")
        } else {
          handleAlert('error',res.data.msg)
        }
        if (res.data.data != null) {
          this.childWindow.setContent(res.data.data, true);
        } else {
          this.childWindow.setContent("<br>", true);
        }
      });
    },
    // 签入
    immigration(){
      var formData = new FormData();
      formData.append("directoryId", this.directoryId); // 节点的id
      formData.append("content", this.childWindow.getContent()); // 节点内容
      contentImmigration(formData).then(res => {
        if (res.data.code == 100) {
          handleAlert('success',"操作成功")
          this.isAuthority = true;
          this.isEmigration = false;
          // 禁用编辑器
          this.childWindow.disabledEditor()
          // 刷新待办清单
          this.pendingTaskData();
          // 刷新状态
          this.getAuditResult(this.directoryId)
          // 停止计时
          this.stoptimeWorker();
          // 清空缓存
          localStorage.removeItem(this.directoryId)
        }else {
          handleAlert('error',res.data.msg)
        }
      }).catch(err => {
        if (err !== null && err !== '' && err.responseText !== null) {
          handleAlert('error',"操作失败")
        }
      })
    },
    // 签出
    emigration(){
      contentEmigration(this.directoryId).then(res => {
        if (res.data.data) {
          this.isEmigration = true;
          // 启用 编辑器
          this.childWindow.removeEditor()
          if (this.isHaveContent) {
            this.childWindow.setContent(this.htmlContent, true);
            // 刷新状态
            this.getAuditResult(this.directoryId)
            var formData = new FormData();
            formData.append("directoryId", this.directoryId); // 节点的id
            formData.append("content", this.htmlContent); // 节点内容
            contentRefresh(formData).then((res) => {
            });
          }
          // 计时
          this.timeWorker()
        }else{
          this.isEmigration = false;
        }
      }).catch(err => {
        if (err !== null && err !== '' && err.responseText !== null) {
        }
        this.isEmigration = false;
      })
    },
    // 计时
    timeWorker(){
      var workCode = `
        var fun =  function(){
          self.postMessage(null);
          setTimeout(fun,3000);
        };
        setTimeout(fun,3000)
      `;
      var _this = this;
      var workBlob = new Blob([workCode]);
      // 创建方法
      const url = URL.createObjectURL(workBlob);
      if (_this.worker == undefined) {
        // 开启 worker 线程
        _this.worker = new Worker(url);
      }
      let count = 0;
      _this.worker.onmessage = function (data) {
        // 三秒钟，保存一次缓存
        localStorage.setItem(_this.directoryId, _this.childWindow.getContent())
        count++;
        // console.log("第" + count + "次");
        if (count == 100) {
          count = 0;
          // 5 分钟，保存一次服务器，并清空缓存
          // var formData = new FormData();
          // formData.append("directoryId", _this.directoryId); // 节点的id
          // formData.append("content", _this.childWindow.getContent()); // 节点内容
          // contentRefresh(formData).then((res) => {
          // });
          // localStorage.removeItem(_this.directoryId)
        }
      }
    },
    // 停止计时
    stoptimeWorker(){
      if (this.worker != undefined) {
        localStorage.setItem(this.directoryId, this.childWindow.getContent())
        this.worker.terminate();
        this.worker = undefined;
      }
    },
    // 预览效果
    // preview(name, url) {
    //   this.projectName = JSON.parse(window.localStorage.getItem("projectName"));
    //   this.cnName = JSON.parse(window.localStorage.getItem("cnName"));
    //   let title =
    //     name +
    //     "-" +
    //     this.projectName +
    //     "-" +
    //     this.cnName +
    //     "-" +
    //     "-" +
    //     this.$route.path;
    //   let curl = url + "/" + this.manualId + "/" + this.manualVersion;
    //   this.$router.push({
    //     name: "preview",
    //     params: { id: this.manualId, manualVersion: this.manualVersion },
    //   });
    //   addTabs(this.$route.path, title);
    // },
  },
  mounted() {
    this.url = cmsServerUrl;
    this.manualId = this.$route.params.id;
    this.manualVersion = this.$route.params.manualVersion;
    // this.childWindow = document.getElementById("editFrame").contentWindow; //获取子窗体的window对象.
    // this.isNotSvg = true
    // 初始化
    this.dataList();
    this.getPendingTaskList();
    contentSize();
    this.isSvg = false;
    const iframe = document.getElementById("editFrame");
    //注意： 如果本身iframe嵌套的盒子有延迟 则下面判断最好也加延迟
    var that = this;
    // 处理兼容行问题
    if (iframe.attachEvent) {
      iframe.attachEvent("onload", function () {
        that.childWindow = document.getElementById("editFrame").contentWindow;
        that.childWindow.paramUrl(cmsServerUrl, that.manualId);
        that.childWindow.setToken(sessionStorage.getItem('token'))
        that.childWindow.disabledEditor()
      });
    } else {
      iframe.onload = function () {
        that.childWindow = document.getElementById("editFrame").contentWindow;
        that.childWindow.paramUrl(cmsServerUrl, that.manualId);
        that.childWindow.setToken(sessionStorage.getItem('token'))
        that.childWindow.disabledEditor()
      };
    }
  },
  // 切换选项卡前（实例销毁之前调用）
  beforeDestroy(){
    this.stoptimeWorker();
  }
};
</script>
<style>
.pendingList .el-dialog{
  height: 76%;
}
.manualCenter #header {
  width: 100%;
  height: calc(100% - 41px)
}
.el-dialog .pendingTitle{
  margin: -10px 0 10px;
  display: flex
}
.el-dialog .pendingTitle .el-input{
 width: 220px !important
}
.el-dialog .pendingTitle .el-button{
  padding: 9px 15px;
  margin: 0 10px;
}
.pendingList .el-form{
  height: 100%;
}
.pendingList .listTable{
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 2px 20px 2px 0;
  margin-right: -18px;
}
</style>

