<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="发布设置" prop="queryReleaseName">
          <el-input v-model.trim="formInline.queryReleaseName" placeholder="请输入发布名称"></el-input>
        </el-form-item>
        <el-form-item label="发布状态" prop="queryStatus">
          <el-select v-model="formInline.queryStatus" clearable filterable>
            <el-option value="1" label="未开始"></el-option>
            <el-option value="2" label="发布中"></el-option>
            <el-option value="3" label="发布完成"></el-option>
            <el-option value="4" label="发布失败"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布类型" prop="queryReleaseType">
          <el-select v-model="formInline.queryReleaseType" clearable filterable>
            <el-option value="1" label="预览"></el-option>
            <el-option value="2" label="发布"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="发布人" prop="queryIssuer">
          <el-select v-model="formInline.queryIssuer" clearable filterable>
            <el-option v-for="(item, index) in issuerList" :key="index" :value="item.id" :label="item.realName"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="发布时间">
          <el-date-picker align="center" type="date" value-format="yyyy-MM-dd" placeholder="选择开始日期" v-model="formInline.begintime" :picker-options="pickerBeginTime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker align="center" type="date" value-format="yyyy-MM-dd" placeholder="选择结束日期" v-model="formInline.endtime" :picker-options="pickerEndTime"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <div class="tableHandle" v-if="hasPerm('menuAsimssCMS4A2B_101')">
        <el-button type="text" icon="el-icon-plus" @click="addData()" :disabled="isAddFlag">新增</el-button>
      </div>
      <el-table
        style="width:100%"
        border
        stripe
        ref="table"
        highlight-current-row
        :max-height="maximumHeight"
        :data="resultList"
        :header-cell-style="{
          'text-align':'center',
          'background-color': 'var(--other-color)',
        }"
        @header-dragend="changeColWidth"
      >
        <el-table-column label="发布设置" prop="releaseName" min-width="150"></el-table-column>
        <el-table-column label="输出类型" prop="outputTypeName" width="125"></el-table-column>
        <el-table-column label="发布类型" prop="releaseType" width="100">
          <template slot-scope="{row}">
            <span v-if="row.releaseType === 1">预览</span>
            <span v-if="row.releaseType === 2">发布</span>
          </template>
        </el-table-column>
        <el-table-column label="有效状态" prop="useFlag" width="100">
          <template slot-scope="{row}">
            <span v-if="row.useFlag === 1" class="successColor">启用</span>
            <span v-if="row.useFlag !== 1" class="errorColor">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="发布状态" prop="status" width="100">
          <template slot-scope="{row}">
            <span v-if="row.status === 1">未开始</span>
            <span v-if="row.status === 2">发布中</span>
            <span v-if="row.status === 3" class="successColor">发布完成</span>
            <span v-if="row.status === 4" class="errorColor">发布失败</span>
          </template>
        </el-table-column>
        <el-table-column label="发布人" prop="updatedUserName" min-width="150"></el-table-column>
        <el-table-column label="发布时间" prop="updatedTime" :formatter="dateFormat" width="140"></el-table-column>
        <el-table-column label="操作" fixed="right" width="260">
          <template slot-scope="{row}">
            <el-button type="text" v-if="hasPerm('menuAsimssCMS4A2B_104')"  @click="headleDetail(row)">查看</el-button>
            <el-button type="text" v-if="row.outputType != 'pdf' && row.htmlStatus && row.status === 3 && hasPerm('menuAsimssCMS4A2B_112')" @click="headelPushSet(row)">推送</el-button>
            <el-button type="text" v-if="row.pdfStatus && hasPerm('menuAsimssCMS4A2B_108')" @click="download('pdf', row)">下载</el-button>
            <el-button type="text" v-if="row.htmlStatus && hasPerm('menuAsimssCMS4A2B_108')" @click="download('html', row)">下载</el-button>
            <el-dropdown style="margin-left: 10px;" v-if="hasPerm('menuAsimssCMS4A2B_119') || hasPerm('menuAsimssCMS4A2B_102')">
              <el-button type="text" size="small">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="row.useFlag === 1 && row.status === 1 && hasPerm('menuAsimssCMS4A2B_119')" @click.native="headleEnable(row,0)">禁用</el-dropdown-item>
                <el-dropdown-item v-if="row.useFlag !== 1 && hasPerm('menuAsimssCMS4A2B_119')" @click.native="headleEnable(row,1)">启用</el-dropdown-item>
                <el-dropdown-item v-if="hasPerm('menuAsimssCMS4A2B_102')" type="text" size="small" @click.native="headelDelete(row)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit'" :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="发布设置" prop="configId"  :label-width="formLabelWidth">
            <el-select v-model="temp.configId">
              <el-option v-for="(item, index) in releaseConfigList" :key="index" :label="item.releaseName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
         <el-form-item label="发布类型" prop="releaseType" :label-width="formLabelWidth">
            <el-select v-model="temp.releaseType" clearable filterable>
              <el-option v-for="(item, index) in releaseTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model.trim="temp.remark" placeholder="请输入备注"  show-word-limit maxlength="100"></el-input>
          </el-form-item>
          <el-form-item class="submitArea">
            <el-button type="primary" @click="addClick('temp')">立即提交</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'detail'" :model="temp" ref="temp" label-position="center" >
          <el-form-item label="版本号" prop="versionNumber" :label-width="formLabelWidth">
            <el-input v-model="temp.versionNumber" placeholder="请输入版本号" readonly></el-input>
          </el-form-item>
          <el-form-item label="发布结果" prop="issueResult" :label-width="formLabelWidth">
            <el-input type="textarea" rows="5" style="width: 85% !important;" v-model="temp.issueResult" placeholder="请输入发布结果" readonly></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model="temp.remark" placeholder="请输入备注" readonly></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { addTabs, handleAlert, tableHeight, tableMaxHeight } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { releaseData, releaseUser, releaseConfig, releaseAdd, releaseEnable, releaseDel, releaseDownload, releaseIsCan } from '@/api/releasemgt.js'
import axios from 'axios'
export default {
  name: 'release',
  components: { Pagination },
  data () {
    return {
      formInline: {
        queryReleaseName: '',
        queryStatus: '',
        queryReleaseType:'',
        begintime: '',
        endtime: '',
        queryIssuer: ''
      },
      temp: {
        id: '',
        configId: '',
        releaseName: '',
        versionNumber: '',
        outputType: '',
        releaseType: 2,
        status:'',
        useFlag: '',
        remark: ''
      },
      releaseTypeList: [
        { name: '发布', code: 2 },
        { name: '预览', code: 1 },
      ],
      useFlagList: [
        { name: '启用', code: 1 },
        { name: '禁用', code: 0 }
      ],
      issuerList: [],
      releaseConfigList: [],
      projectId: '',
      isShowFlag: true,
      isAddFlag: false,
      basicId: '',
      manualOutputType: '',
      // manualVersion: '',
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        edit: '编辑发布',
        detail: '发布明细',
        add: '新增发布'
      },
      maximumHeight: 0,
      resultList: [],
      pagesize: 20,
      currentPage: 1,
      total: 0,
      formLabelWidth: '100px',
      rules: {
        configId: [{ required: true, message: '请选择发布名称',  trigger: ['blur', 'change']  }],
        releaseType: [{ required: true, message: '请选择发布类型', trigger: ['blur', 'change'] }],
      },
      pickerBeginTime: {
        disabledDate: (time) => {
          return this.formInline.endtime ? time.getTime() > new Date(this.formInline.endtime) : false //只能选结束日期之前的日期
          //返回---结束时间是否有值？   可选时间小于结束时间   ：  任意时间都可选
        }
      },
      pickerEndTime: {
        disabledDate: (time) => {
          return this.formInline.begintime ? time.getTime() < new Date(this.formInline.begintime) : false //只能选开始时间之后的日期
          //返回---开始时间是否有值？   可选时间大于开始时间   ：  任意时间都可选
        }
      }
    }
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "release") {
        _this.projectId = to.params.projectId
        _this.basicId = to.params.id
        _this.manualOutputType = to.params.outputType
        _this.dataList()
        _this.headelAdd()
      }
    }
  },
  methods: {
    // 监听表格列宽变化
    changeColWidth(){
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      })
    },
    // 数据
    dataList () {
      this.$loading.show();
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        basicId:this.basicId,
        releaseName: this.formInline.queryReleaseName,  // 发布名称
        status: this.formInline.queryStatus,  // 发布状态 basicId
        releaseType: this.formInline.queryReleaseType, // 发布类型
        beginTime: this.formInline.begintime,
        endTime: this.formInline.endtime,
        createdUser: this.formInline.queryIssuer   // 发布人
      }
      releaseData(params).then(res => {
        if(res.data.code == 100) {
          this.total = res.data.total
          this.resultList = res.data.data
        }
        this.tableHeightArea();
        this.$loading.hide();
      })
    },
    // 搜索
    onSubmit () {
      this.currentPage = 1
      this.dataList()
    },
    // 重置
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.formInline.begintime = ""
      this.formInline.endtime = ""
      this.currentPage = 1
      this.dataList()
    },
    // 获取用户列表
    // getUserList () {
    //   releaseUser().then(res => {
    //     if (res.data.code === 100) {
    //       this.issuerList = res.data.data
    //     }
    //   })
    // },
    //获取发布配置列表
    getReleaseConfigList(){
      releaseConfig(this.basicId).then(res => {
        if (res.data.code === 100) {
          this.releaseConfigList= res.data.data
          if(this.releaseConfigList.length > 0){
            this.temp.configId = this.releaseConfigList[0].id
          }
        }
      })
    },
    resetTemp () {
      this.temp = {
        id: '',
        configId:'',
        releaseName: '',
        versionNumber: '',
        outputType: '',
        releaseType: 2,
        useFlag: '',
        remark: ''
      }
      this.$nextTick(function() {
        this.$refs.temp.clearValidate();
      })
    },
    // 新增
    addData () {
      this.temp.releaseType=2
      this.temp.useFlag=1
      this.getReleaseConfigList()
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
      this.resetTemp()
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          this.$loading.show();
          var params = new URLSearchParams()
          params.append('releaseBasicId', this.basicId)
          params.append('configId', this.temp.configId)
          params.append('releaseType', this.temp.releaseType)
          params.append('remark', this.temp.remark)
          releaseAdd(params).then(res => {
            if (res.data.code === 100) {
              handleAlert('success',res.data.msg)
              this.dataList()
              this.dialogFormVisible = false
            } else {
              handleAlert('error',res.data.msg)
            }
            this.$loading.hide();
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              handleAlert('error','提交失败,请重试')
            }
            this.$loading.hide();
          })
        } else {
          handleAlert('error','请完善表单信息');
          this.$loading.hide();
        }
      })
    },
    // 查看
    headleDetail (row) {
      this.temp = Object.assign({}, row)
      this.dialogStatus = 'detail'
      this.dialogFormVisible = true
    },
    headleEnable(row,flag){
      var params = new URLSearchParams()
      params.append('id', row.id)
      params.append('useFlag', flag)
      releaseEnable(params).then(res => {
        let msg= res.data.msg
        msg='操作成功'
        if (res.data.code === 100) {
          handleAlert('success',msg)
          this.dataList()
        } else {
          msg='操作失败'
          handleAlert('error',msg)
        }
      }).catch(err => {
        if (err !== null && err !== '' && err.responseText !== null) {
          handleAlert('error','操作失败,请重试')
        }
      })
    },
    // 推送
    headelPushSet(row){
      var listName = sessionStorage.getItem("issuePush")
      var one = listName.split(">")[1] + '&' + listName.split(">")[3] + '&' + row.releaseName
      var two = listName.split(">")[2]
      let title = "推送>" + one + ">" + two + ">" + this.$route.path
      // let title = "推送>" + one + ">" + two;
      this.$router.push({ name: 'pushList', params: { id: row.id, projectId:row.projectId, manualId:row.manualId, manualVersion: row.manualVersion }})
      addTabs(this.$route.path, title);
    },
    // 删除
    headelDelete (row) {
      this.$confirm('确定删除当前的发布记录信息?', '删除发布记录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        releaseDel(row.id).then(res => {
          if (res.data.code === 100) {
            handleAlert('success','删除成功')
            if(this.total > 1){
              if(this.resultList!=null&&this.resultList.length == 1){
                this.currentPage =this.currentPage-1
              }
            }
            this.dataList()
          }else{
            handleAlert('error','删除失败')
          }
        })
      }).catch((error) => {})
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        const H = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        const Min = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
        const S = date.getSeconds() < 10 ? '0' + date.getSeconds() + '' : date.getSeconds()
        return Y + M + D + H + Min + S
      }
    },
    download(type, row){
      var params = new URLSearchParams()
      params.append('id', row.id)
      params.append('type', type)
      releaseDownload(params).then(res => {
        this.$message.closeAll();
        if(!res.data){
          return
        }
        var name = decodeURI(res.headers['content-type']);
        var blob = new Blob([res.data]);
        var url = window.URL.createObjectURL(blob);
        var aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = url;
        aLink.setAttribute("download", name);
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink); //下载完成移除元素
        window.URL.revokeObjectURL(url); //释放掉blob对象
      })
    },

    // 2023-02-24 判断能不能点击新增发布按钮
    headelAdd(){
      var params = new URLSearchParams()
      params.append('basicId', this.basicId)
      releaseIsCan(params).then(res => {
        if (res.data.code === 100 && res.data.data>0) {
          this.isAddFlag = true
        } else {
          this.isAddFlag = false
        }
      }).catch(err => {
        this.isAddFlag = false
      })
    },
    maximumArea() {
      setTimeout(() => {
        tableHeight()
        setTimeout(() => {
          this.maximumHeight = tableMaxHeight;
        }, 50)
      },50)
    },
    tableHeightArea() {
      var _this = this;
      _this.maximumArea();
      window.addEventListener("resize", function () {
        _this.maximumArea();
      });
    },

  },
  mounted () {
    this.projectId = this.$route.params.projectId
    this.basicId = this.$route.params.id
    this.manualOutputType = this.$route.params.outputType
    this.dataList()
    this.headelAdd()
  }
}
</script>
