<template>
  <div class="latestNewsPage">
    <div class="latestNewsCenter">
      <div class="dateNavTab">
        <div v-for="(item, index) of dataList" :key="index" :class="item.type == type ? 'active' : ''">
          <p @click="dateClick(item)">{{item.name}}</p>
        </div>
      </div>
      <div class="latestNewsDetail">
        <div class="accessRecordsInfo" v-if="recordsList != null">
          <div v-for="(item, index) of recordsList" :key="index">
            <p class="dateTitle" >
              <span>{{ item.day }}</span>
              <span>{{ item.week }}</span>
            </p>
            <div class="recordsDetail">
              <div class="recordsList" v-for="(itm, idx) of item.data" :key="idx">
                <div class="accessRecords">
                  <p>
                    <span>{{ itm.operateTime | conversion("HH:mm") }}</span>
                    <span class="circle"></span>
                    <span class="line"></span>
                  </p>
                  <p v-if="itm.type == 'access'" style="color: #808080;">访问了<span style="color: #409EFF;">【 <span style="text-decoration:underline;cursor: pointer;" @click="jumpMenu(itm)">{{itm.action}} </span>】</span></p>
                  <p v-else style="color: #808080;">{{ itm.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-else class="noData">暂无最新动态</p>
      </div>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="menuData"/>
    </div>
  </div>
</template>
<script>
import { addTabs } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { getRecordsData } from '@/api/sysmgt.js'
import $ from 'jquery'
export default {
  name: "latestNews",
  components: { Pagination },
  data() {
    return {
      dataList: [
        {
          "name": "所有",
          "type": "7",
        },
        {
          "name": "今天",
          "type": "1",
        },
        {
          "name": "昨天",
          "type": "2",
        },
        {
          "name": "本周",
          "type": "3",
        },
        {
          "name": "上周",
          "type": "4",
        },
        {
          "name": "本月",
          "type": "5",
        },
        {
          "name": "上月",
          "type": "6",
        }
      ],
      recordsList: [],
      pagesize: 15,
      currentPage: 1,
      total: 0,
      type: 1,
    };
  },
  watch: {
    $route(to,from){
      if (to.name == 'latestNews') {
        this.menuData();
      }
    }
  },
  methods: {
    // 数据
    menuData() {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        type: this.type,
      };
      getRecordsData(params).then((res) => {
        if (res.data.code == 100) {
          this.recordsList = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    // tab切换
    dateClick(item) {
      this.type = item.type;
      this.currentPage = 1;
      this.menuData();
    },
    jumpMenu(itm) {
      this.$router.push(itm.targetUrl);
      setTimeout(() => {
        addTabs(itm.targetUrl, itm.action);
      })
    },
    heightArea() {
      setTimeout(() => {
        var allHeight = $(".latestNewsCenter").height();
        var tabHeight = $(".dateNavTab").outerHeight(true);
        var pageHeight = $(".pagination-container").height();
        var val = allHeight - tabHeight - pageHeight - 10;
        $(".latestNewsDetail").css("height", val);
      });
    },
    areaSize() {
      var _this = this
      _this.heightArea()
      window.addEventListener('resize', function () {
        _this.heightArea()
      })
    },
  },
  mounted() {
    this.menuData();
    this.areaSize();
  },
}
</script>
<style>
  .latestNewsCenter {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  /* tab */
  .dateNavTab {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(239, 239, 239);
  }
  .dateNavTab div {
    font-size: 16px;
    margin: 0 15px;
    padding: 1px 3px;
    border-bottom: 2px solid transparent;
  }
  .dateNavTab .active {
    font-weight: bold;
    color: #1890ff;
    border-bottom: 2px solid #1890ff;
  }
  /* 内容 */
  .latestNewsDetail {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .latestNewsDetail .accessRecordsInfo > div {
    margin: 0 30px;
    padding: 0px 0 20px;
    border-bottom: 1px solid rgb(244, 244, 244);
  }
  .latestNewsDetail .accessRecordsInfo > div:last-child {
    border-bottom: none;
  }
  .latestNewsDetail .noData {
    font-size: 16px;
    margin: 20px;
  }
  .latestNewsDetail .dateTitle {
    font-size: 22px;
    font-weight: bold;
    margin: 15px 0;
  }
  .latestNewsDetail .dateTitle span {
    margin-right: 15px;
  }
  .accessRecordsInfo .recordsDetail .accessRecords {
    font-size: 16px;
    line-height: 25px;
    display: flex;
    margin: 8px 0;
    align-items: center;
  }
  .accessRecordsInfo .recordsList:last-child .accessRecords{
    margin: 0;
  }
  .recordsDetail .accessRecords p {
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
  }
  .recordsDetail .accessRecords p:first-child span:first-child {
    display: inline-block;
    width:45px;
  }
  .recordsDetail .accessRecords .circle {
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #c2c2c2;
    border-radius: 50%;
    margin: 0 10px;
  }
  .recordsDetail .accessRecords .line {
    border-right:  1px dashed #c2c2c2;
    height: 28px;
    position: absolute;
    right: 13px;
    top: 18px;
  }
  .recordsDetail .recordsList:last-child .accessRecords .line {
    display: none;
  }
  .recordsDetail .accessRecords p:nth-child(2) {
    color: #1890ff;
    position: relative;
  }
  .recordsDetail .accessRecords p:last-child{
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0
  }
  /* 分页 */
  .latestNewsCenter .pagination-container {
    border: none;
    position: absolute;
    bottom: 0;
    background: transparent;
  }
  @media screen and (max-width: 1600px) and (min-width: 1440px) {
    /* tab */
    .dateNavTab {
      padding-bottom: 16px;
    }
    .dateNavTab div {
      font-size: 15px;
    }
    /* 内容 */
    .latestNewsDetail .accessRecordsInfo > div {
      margin: 0 25px;
    }
    .latestNewsDetail .noData {
      font-size: 15px;
    }
    .latestNewsDetail .dateTitle {
      font-size: 20px;
    }
    .accessRecordsInfo .recordsDetail .accessRecords {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 1366px) and (min-width: 1280px) {
    /* tab */
    .dateNavTab {
      padding-bottom: 12px;
    }
    .dateNavTab div {
      font-size: 14px;
      margin: 0 12px;
      padding: 0px 3px;
    }
    /* 内容 */
    .latestNewsDetail .accessRecordsInfo > div {
      margin: 0 20px;
      padding: 0px 0 10px;
    }
    .latestNewsDetail .noData {
      font-size: 14px;
    }
    .latestNewsDetail .dateTitle {
      font-size: 17px;
      margin: 12px 0;
    }
    .latestNewsDetail .dateTitle span {
      margin-right: 10px;
    }
    .accessRecordsInfo .recordsDetail .accessRecords {
      font-size: 14px;
      line-height: 22px;
      margin: 8px 0;
    }
    .recordsDetail .accessRecords p:first-child span:first-child {
      width:38px;
    }
    .recordsDetail .accessRecords .circle {
      width: 6px;
      height: 6px;
    }
    .recordsDetail .accessRecords .line {
      height: 28px;
      right: 12.5px;
      top: 13px;
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 1024px) {
    /* tab */
    .dateNavTab {
      padding-bottom: 10px;
    }
    .dateNavTab div {
      font-size: 13px;
      margin: 0 12px;
      padding: 0px 3px;
    }
    /* 内容 */
    .latestNewsDetail .accessRecordsInfo > div {
      margin: 0 20px;
      padding: 0px 0 10px;
    }
    .latestNewsDetail .noData {
      font-size: 13px;
      margin: 20px;
    }
    .latestNewsDetail .dateTitle {
      font-size: 16px;
      margin: 10px 0;
    }
    .latestNewsDetail .dateTitle span {
      margin-right: 10px;
    }
    .accessRecordsInfo .recordsDetail .accessRecords {
      font-size: 14px;
      line-height: 20px;
      margin: 8px 0;
    }
    .recordsDetail .accessRecords p:first-child span:first-child {
      width:35px;
    }
    .recordsDetail .accessRecords .circle {
      width: 5px;
      height: 5px;
    }
    .recordsDetail .accessRecords .line {
      height: 28px;
      right: 12px;
      top: 13px;
    }
  }
  @media screen and (max-width: 1024px) {
    /* tab */
    .dateNavTab {
      padding-bottom: 10px;
    }
    .dateNavTab div {
      font-size: 13px;
      margin: 0 12px;
      padding: 0px 3px;
    }
    /* 内容 */
    .latestNewsDetail .accessRecordsInfo > div {
      margin: 0 20px;
      padding: 0px 0 10px;
    }
    .latestNewsDetail .noData {
      font-size: 13px;
      margin: 20px;
    }
    .latestNewsDetail .dateTitle {
      font-size: 16px;
      margin: 10px 0;
    }
    .latestNewsDetail .dateTitle span {
      margin-right: 10px;
    }
    .accessRecordsInfo .recordsDetail .accessRecords {
      font-size: 14px;
      line-height: 20px;
      margin: 8px 0;
    }
    .recordsDetail .accessRecords p:first-child span:first-child {
      width:35px;
    }
    .recordsDetail .accessRecords .circle {
      width: 5px;
      height: 5px;
    }
    .recordsDetail .accessRecords .line {
      height: 28px;
      right: 12px;
      top: 13px;
    }
  }
</style>
