const maintenanceRouter = [
    {
        path: '/maintenance/data/list',
        component: () => import('@/views/maintenance/data/list'),
        name: 'maintenance_data_list'
    },
    {
        path: '/maintenance/history/list',
        component: () => import('@/views/maintenance/history/list'),
        name: 'maintenance_i18n_list'
    },
]
export default maintenanceRouter;
