<template>
  <div class="layoutContainer addBulleinContent">
    <el-form :model="temp" ref="temp" label-position="center" :label-width="formLabelWidth" :rules="rules">
      <el-row>
        <el-col :span="20">
          <el-form-item label="通告标题" prop="title">
            <el-input v-model.trim="temp.title" placeholder="请输入通告标题" show-word-limit maxlength="100"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="文档概述" prop="summary">
            <el-input type="textarea" rows="3" placeholder="请输入文档概述" v-model.trim="temp.summary" show-word-limit maxlength="200"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="20" :sm="15" :md="10" :lg="7" :xl="5">
          <el-form-item label="状态" prop="status">
            <el-select v-model="temp.status" clearable filterable>
              <el-option v-for="(item, index) of statusList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="20" :sm="15" :md="10" :lg="7" :xl="5">
          <el-form-item label="置顶" prop="isTop">
            <el-select v-model="temp.isTop" clearable filterable>
              <el-option v-for="(item, index) of topList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="20" :sm="15" :md="10" :lg="7" :xl="5">
        <el-form-item label="通告类别" prop="type">
            <el-select v-model="temp.type" clearable filterable>
              <el-option v-for="(item, index) of bulletinTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="20" :sm="15" :md="10" :lg="7" :xl="5">
          <el-form-item label="排序" prop="sort">
            <el-input type="number" :min="1" :max="9999" @input="e => temp.sort=parserNumber(e,1,9999)" v-model.trim="temp.sort" placeholder="请输入排序"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
            <el-col :span="20">
              <el-form-item label="适用国家" prop="country">
                <template slot="label">
                <span>适用国家
                  <el-tooltip class="item" effect="dark" placement="right">
                    <i class="el-icon-question" style="font-size: 16px; vertical-align: middle;"></i>
                    <div slot="content">
                      <p>不选择视为适用于所有国家</p>
                    </div>
                  </el-tooltip>
                </span>
              </template>
              <el-button type="primary" @click="suitTarget()">{{ targetName }}</el-button>
              </el-form-item>
            </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="备注" prop="remark">
            <el-input v-model.trim="temp.remark" placeholder="请输入备注" show-word-limit maxlength="100"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="文档类型" prop="docType">
            <el-radio-group v-model="temp.docType" class="noticType" @change="switchType">
              <el-radio :label="1">富文本</el-radio>
              <el-radio :label="2">pdf</el-radio>
              <el-radio :label="3">图片</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item v-if="temp.docType == 1" label="内容" prop="docType">
            <TinymceEditor ref="bulletinEditor" :imgUploadUrl="uploadUrl" v-model="temp.content"/>
          </el-form-item>
          <el-form-item v-else label="上传文件内容" prop="docPath">
            <el-upload
              class="upload-demo"
              style="max-width: 379px;"
              :action="uploadUrl"
              :headers="importHeader"
              :on-success="handleOnSuccess"
              :on-remove="handleOnRemove"
              :before-remove="beforeOnRemove"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleOnExceed"
              multiple
              :limit="1"
              :file-list="fileList"
               accept="pdf,PDF"
            >
              <el-button size="min" icon="el-icon-upload" type="primary">点击上传</el-button>
            </el-upload>
            <img v-if="getShow()" :src="fileList[0].url" style="max-width: 200px;max-height: 200px;">
          </el-form-item>
        </el-col>
      </el-row>
      <div class="submitArea">
        <el-button type="primary" @click="addClick('temp')">保存</el-button>
        <el-button @click="resetForm('temp')">重置</el-button>
      </div>
    </el-form>
    <el-dialog v-dialogDrag lock-scroll width="995px !important" class="assignCountryArea" title="适用国家" :visible.sync="dialogCountryVisible" v-if="dialogCountryVisible" :close-on-click-modal="false">
        <div class="secondFloat">
            <el-form :inline="true" ref="countryData" :model="countryInfo" class="demo-form-inline">
              <el-form-item label="大洲" prop="continent">
                <el-select v-model="countryInfo.continent" filterable clearable>
                    <el-option v-for="(item, index) of continentList" :key="index" :label="item.continentName" :value="item.continentCode"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="国家" prop="country">
                <el-input v-model.trim="countryInfo.country" placeholder="请输入国家"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button type="primary" @click="searchApply" icon="el-icon-search">搜索</el-button>
                <el-button plain @click="resetApply()">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        <!-- 适用车型 -->
          <el-row>
            <el-col :span="17" class="nationalInfo">
              <el-table
                :row-key="selRowKey"
                ref="applytable"
                style="width:100%;"
                max-height="550px"
                border
                stripe
                highlight-current-row
                :data="applyCountrylLists"
                :header-cell-style="{
                  'text-align': 'center',
                  'background-color': 'var(--other-color)',
                }"
                @selection-change="handleSelectionApply"
              >
                <el-table-column type="selection" width="40" fixed="left" align="center" reserve-selection></el-table-column>
                <el-table-column label="序号" type="index" width="60"  align="center"></el-table-column>
                <el-table-column label="大洲" prop="continentName" min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="国家" prop="countryName" min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="代码" prop="countryCode" min-width="100" show-overflow-tooltip></el-table-column>
              </el-table>
              <pagination v-show="totalApply>0" :total="totalApply" :page.sync="currentPageApply" :limit.sync="pagesizeApply" @pagination="selectCountry"/>
            </el-col>
            <el-col :span="7" class="nationalSelect">
              <el-table
                style="width:100%"
                :height="accreditTableHeight"
                border
                highlight-current-row
                :data="determineModelList"
                :header-cell-style="{
                  'background-color': '#fafafa',
                }"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellLeaveEnter"
              >
                <el-table-column prop="title">
                  <template slot="header">
                    <div class="authorityTitle">
                      <div>
                        <span>已选(<b> {{ selectNum }} </b>)</span>
                      </div>
                      <div>
                        <span class="warrantAction" @click="authorizeAll">选择全部</span>
                        <span class="clearAction" @click="emptyCountry">清空</span>
                      </div>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div :class="'nationalList ' + '_'+scope.row.countryId">
                      <span>{{ scope.row.countryName }}</span>
                      <i class="el-icon-close" @click="deleteEmpower(scope.row)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

            </el-col>
          </el-row>

          <el-button type="primary" style="display: block;margin: 15px auto 10px;"  @click="selectapply">确定</el-button>
      </el-dialog>

  </div>
</template>
<script>
import { sysServerUrl, handleAlert, removeTabs, addTabs } from '@/assets/js/common.js'
import TinymceEditor from "@/components/TinymceEditor/TinymceEditor.vue"
import Pagination from '@/components/Pagination'
import {
  bulletinInfo,
  bulletinAdd,
  bulletinEdit,
  bulletinContent,
  getCountryAll,
  bulletinTypeData
} from '@/api/cmsmgt.js'
export default {
  name: 'addBulletin',
  components: {TinymceEditor, Pagination},
  data() {
    return {
      init: {
        // 组件
        plugins: ' lists image colorpicker textcolor wordcount contextmenu autoresize',
        // 工具栏
        toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent  | undo redo | link unlink image  | removeformat ',
        branding: false,
        min_height: 300
      },
      temp: {
        id: '',
        title: '',
        summary: '',
        status: 1,
        isTop: 0,
        docType: 1,
        docPath: '',
        docName: '',
        language: '',  // 语种
        sort: 1, // 排序
        content: '', // 富文本内容
        remark: '',
        updatedTime: '',
        visitTimes: '',
        country: [],
        type: 'annunciate'
      },
      statusList: [
        {name: '草稿', code: 1},
        {name: '发布', code: 2},
        // {name: '关闭', code: 3},
      ],
      topList: [
        {name: '是', code: 1},
        {name: '否', code: 0},
        // {name: '关闭', code: 3},
      ],
      fileList: [], // 文件列表
      fileObj: {},
      uploadUrl: sysServerUrl + 'sys/upload/attach?flag=manualBulletin', // 文件上传地址
      docName: '',
      docType: '',
      isFlag: true,
      formLabelWidth: '100px',
      rules: {
        title: [{required: true, message: '公告标题不能为空', tigger: ['blur', 'change']}],
      },
      bulletinId: "",
      userCountryList: [],
      // 语种
      languageList:[],
      bulletinTypeList: [],

      // 适用国家
      dialogCountryVisible: false,
      targetName: '未选择',
      totalApply: 0,
      currentPageApply: 1,
      pagesizeApply: 10,
      // 选中的对象
      determineModelList:[],
      // 选中的数量
      selectNum: 0,
      // 列表中的
      applyCountrylLists: [],
      // 所有的
      applyCountrylList:[],

      accreditTableHeight: 0,
      continentList: [],

      countryInfo: {
        country: '',
        continent: '',
      },
    }
  },
  computed: {
    // 设置请求上传的头部
    importHeader: function () {
      return { Authorization: sessionStorage.token };
    }
  },
  methods: {
    getBulletinInfo() {
      bulletinInfo(this.bulletinId).then(res=>{
        if (res.data.code == 100) {
          var data = res.data.data
          this.temp.id = data.id
          this.temp.title = data.title
          this.temp.isTop = data.isTop
          this.temp.summary = data.summary
          this.temp.content = data.content

          this.temp.status = data.status
          this.temp.docType = data.docType
          this.docType = data.docType
          this.temp.docPath = data.docPath
          this.temp.docName = data.docName
          this.temp.sort = data.sort
          this.temp.type = data.type
          this.temp.remark = data.remark

          if(data.countryList && data.countryList.length > 0){
            this.temp.country = data.countryList
            this.targetName = "已选择";
          }else{
            this.temp.country = []
            this.targetName = "未选择";
          }
          if(data.docType != 1){
            this.fileList = [];
            this.fileObj = {
                name: data.docName,
                url: data.path
              }
            this.fileList.push(this.fileObj);
            var fileName = data.docName;
            setTimeout(() => {
              var length = $(".el-upload-list").children("li").length;
              for (var i = 0; i < length; i++) {
                var type = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
                $(".el-upload-list__item.is-success:nth-child(" + (i + 1) + ")")
                  .children("a")
                  .children("i")
                  .addClass(type);
              }
            }, 150);
          }else{
            this.$refs.bulletinEditor.setValue(data.content)
          }
        }else{
          handleAlert('error','内容已删除')
          removeTabs(this.$route.path);
          this.$router.push('/cmsmgt/bulletin/list')
          setTimeout(() => {
            addTabs(this.$route.path, "技术通告");
          })
        }

      })

    },
    switchType(){
      this.fileList = [];
      if(this.temp.docType == this.docType){
        this.fileList.push(this.fileObj);
        var fileName = this.fileObj.name;
        setTimeout(() => {
          var length = $(".el-upload-list").children("li").length;
          for (var i = 0; i < length; i++) {
            var type = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
            $(".el-upload-list__item.is-success:nth-child(" + (i + 1) + ")")
              .children("a")
              .children("i")
              .addClass(type);
          }
        }, 150);
      }
    },
    getShow(){
      return this.temp.docType === 3 && this.fileList.length >0 && this.fileList[0].url.startsWith(sysServerUrl)
    },
    // 获取公告内容
    getContent() {
      var _this = this
      bulletinContent(_this.bulletinId).then(res => {
        if (res.data.code == 100) {
          _this.$refs.bulletinEditor.setValue(res.data.data.content)
          this.temp.content = res.data.data.content
        }
      })
    },

    // 获取国家
    getUserCountryList(){
      getCountryAll().then(res=>{
        this.applyCountrylList = res.data.data
      })
    },

    // 获取类型
    getBulletinTypeList(){
        bulletinTypeData().then(res=>{
        this.bulletinTypeList = res.data.data
      })
    },

    // 获取文件
    getFileList(row) {
      this.fileList = []
      let fileName = row.docPath
      let index = fileName.lastIndexOf('\\')
      if (index != -1) {
        fileName = fileName.substring(index + 1)
      }
      var file = {name: fileName, url: row.docPath}
      this.fileList.push(file)
    },
    addClick(temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('title', this.temp.title)
          params.append('summary', this.temp.summary)
          params.append('status', this.temp.status)

          if (this.temp.country && this.temp.country.length>0) {
            params.append("sltCountry", this.temp.country.toString())
          }
          params.append('isTop', this.temp.isTop)
          params.append('type', this.temp.type)
          params.append('language', this.temp.language)
          params.append('docType', this.temp.docType)
          if (this.temp.docType != 1) {
            if (this.fileList.length == 0) {
              handleAlert('error','技术通告内容为空')
              return false;
            }
            params.append('docPath', this.temp.docPath)
            params.append('docName', this.temp.docName)
          } else {
            let activeEditor = tinymce.activeEditor;
            let editBody = activeEditor.getBody();
            activeEditor.selection.select(editBody);
            let text = activeEditor.selection.getContent({'format': 'text'});
            if (text.trim() == '' && (this.temp.content).indexOf("img") == -1) {
              handleAlert('error','技术通告内容为空')
              return false;
            }
            params.append('content', this.temp.content)
          }
          params.append('sort', this.temp.sort)
          params.append('remark', this.temp.remark)
          this.$loading.show()
          if (this.bulletinId == "add") {
            bulletinAdd(params).then(res => {
              if (res.data.code === 100) {
                handleAlert('success',res.data.msg);
                removeTabs(this.$route.path);
                this.$router.push('/cmsmgt/bulletin/list')
                setTimeout(() => {
                  addTabs(this.$route.path, "技术通告");
                })
              } else {
                handleAlert('error',res.data.msg);
              }
              this.$loading.hide()
            }).catch(err => {
               this.$loading.hide()
              if (err !== null && err !== '' && err.responseText !== null) {
                handleAlert('error','提交失败,请重试');
              }
            })
          } else {
            params.append('id', this.temp.id);
            bulletinEdit(params).then(res => {
              if (res.data.code === 100) {
                handleAlert('success',res.data.msg);
                removeTabs(this.$route.path);
                this.$router.push('/cmsmgt/bulletin/list')
                setTimeout(() => {
                  addTabs(this.$route.path, "技术通告");
                })
              } else {
                handleAlert('error',res.data.msg);
              }
              this.$loading.hide()
            }).catch(err => {
              this.$loading.hide()
              if (err !== null && err !== '' && err.responseText !== null) {
                handleAlert('error','提交失败,请重试');
              }
            })
          }
        } else {
          this.$loading.hide()
          handleAlert('error','请完善信息');
        }
      })
    },
    // 重置
    resetTemp() {
      this.temp = {
        id: '',
        title: '',
        summary: '',
        status: 1,
        isTop: 0,
        docType: 1,
        language: '',
        docPath: '',
        sort: 1, // 排序
        content: '', // 富文本内容
        remark: '',
        country: [],
        type:'annunciate',
      }
      this.$nextTick(function() {
        this.$refs.temp.clearValidate();
        this.$refs.bulletinEditor.setValue("")
      })
    },
    resetForm(temp) {
      this.resetTemp()
    },
    // 上传成功时的钩子
    handleOnSuccess(res, obj) {
      this.temp.docName = this.docName
      this.temp.docPath = res.data.fileUrl
      this.fileList = []
      var file = {name: res.data.fileName, url: res.data.fileUrl}
      this.fileList.push(file)
      this.isFlag = true;
      var fileName = file.name
      setTimeout(() => {
        var length = $(".el-upload-list").children("li").length;
        for (var i = 0; i < length; i++) {
          var type = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
          $(".el-upload-list__item.is-success:nth-child(" + (i + 1) + ")")
            .children("a")
            .children("i")
            .addClass(type);
        }
      }, 150);
    },
    // 文件移除前的钩子
    beforeOnRemove(file, fileList) {
      if (this.isFlag) {
        return this.$confirm(`确定移除选择文件？`, '删除',{type: 'warning'});
      }
    },
    // 文件移除时的钩子
    handleOnRemove(file, fileList) {
      this.fileList = []
      this.isFlag = true
    },
    // 文件上传前的钩子
    beforeAvatarUpload(file) {
      this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=manualBulletin'
      var fileName = file.name.substring(file.name.lastIndexOf(".")+1).toLowerCase()
      this.docName = file.name
      if (this.temp.docType == 2) {
        const extension = fileName === 'pdf'
        const extension2 = fileName === 'PDF'
        const isLt2M = file.size / 1024 / 1024 < 100
        if (!extension && !extension2) {
          handleAlert('warning','上传文件只能是 pdf 格式!')
          this.isFlag = false;
          return false;
        }
        if (!isLt2M) {
          handleAlert('warning','上传文件内大小不能超过 100MB!')
          this.isFlag = false;
          return false;
        }
      } else {
        const extension = fileName === 'png'
        const extension2 = fileName === 'jpg'
        const extension3 = fileName === 'jpeg'
        const extension4 = fileName === 'gif'
        const isLt2M = file.size / 1024 / 1024 < 5
        if (!extension && !extension2 && !extension3 && !extension4) {
          handleAlert('warning','上传图片只能是 png、jpg、jpeg、gif格式!')
          this.isFlag = false;
          return false;
        }
        if (!isLt2M) {
          handleAlert('warning','上传图片大小不能超过 5MB!')
          this.isFlag = false;
          return false;
        }
      }
    },
    // 超过文件数量限制时的钩子
    handleOnExceed(files, fileList) {
      handleAlert('warning',`当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },


    selRowKey(row){
      return row.countryCode
    },
    // 适用对象
    suitTarget(){
      this.dialogCountryVisible = true
      let countrys = this.temp.country
      let _this = this
      let list = []
      setTimeout(() => {
        _this.applyCountrylList.forEach(itm => {
          if(countrys.includes(itm.countryCode)){
            list.push(itm)
            _this.$refs.applytable.toggleRowSelection(itm, true)
          }
        })
        this.handleSelectionApply(list)
        this.resetApply();
      })

    },
    handleSelectionApply(val){
      // console.log("选中的车型：", val);
      this.determineModelList = val
      this.selectNum = this.determineModelList.length
    },
    // 获取全部
    authorizeAll(){
      let list = []
      let _this = this
      _this.emptyCountry()
      _this.applyCountrylList.forEach(row => {
        list.push(row)
        _this.$refs.applytable.toggleRowSelection(row, true)
      })
      this.handleSelectionApply(list)
    },
    // 清空
    emptyCountry(){
      this.handleSelectionApply([])
      this.$refs.applytable.clearSelection();
    },
     // 删除授权
    deleteEmpower(row){
      let _this = this
      _this.determineModelList.forEach(itm => {
        if(itm.countryCode === row.countryCode){
          _this.$refs.applytable.toggleRowSelection(row, false)
        }
      })
    },

    // 搜索
    searchApply(){
      this.currentPageApply = 1
      this.selectCountry();
    },
    // 重置
    resetApply(){
      this.countryInfo.country = '';
      this.countryInfo.continent = '';
      this.currentPageApply = 1;
      this.pagesizeApply = 10;
      this.selectCountry();
    },

    // 获取数据
    selectCountry(){
      this.applyCountrylLists = []
      let country = this.countryInfo.country
      let continent = this.countryInfo.continent
      let list = []
      if (!country && !continent) {
        list = this.applyCountrylList
      }else{
        for (let i = 0; i < this.applyCountrylList.length; i++) {
          let row = this.applyCountrylList[i]
          if (continent && continent.length > 0) {
            if (country && country.length > 0) {
              if (continent == row.continentCode && row.countryName.indexOf(country) !== -1) {
                list.push(row)
              }
            }else{
              if (continent == row.continentCode) {
                list.push(row)
              }
            }
          }else if(country && country.length > 0){
            if (row.countryName.indexOf(country) !== -1) {
                list.push(row)
              }
          }else{
            list.push(row)
          }
        }
      }
      if (list.length <= 0) {
        this.totalApply=0
        this.applyCountrylLists = []
        return;
      }
      // 分页
      this.totalApply = list.length
      let i = (this.currentPageApply - 1) * this.pagesizeApply
      let j = i + this.pagesizeApply
      this.applyCountrylLists = list.slice(i, j)
      this.nationalArea()
    },


    // 确定
    selectapply() {
      this.dialogCountryVisible = false
      let list = []
      if(this.determineModelList.length > 0){
        this.determineModelList.forEach(row => {
          list.push(row.countryCode)
        })
      }
      this.temp.country = list

      this.targetName = list.length > 0 ? "已选择" : "未选择"
    },

    // 分配国家 权限选择
    cellMouseEnter(row){
      $(".nationalList._" + row.countryId + " .el-icon-close").show()
    },
    cellLeaveEnter(){
      $(".nationalList .el-icon-close").hide()
    },
    // 分配国家 表格高度
    nationalArea() {
      var _this = this;
      setTimeout(() => {
        var allTable = $(".nationalInfo").outerHeight(true);
        _this.accreditTableHeight = allTable;
      }, 80)
      window.addEventListener("resize", function () {
        setTimeout(() => {
          var tableArea = $(".nationalInfo").outerHeight(true);
          _this.accreditTableHeight = tableArea;
        }, 80)
      })
    },
    initialState() {
      this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=manualBulletin'
      this.getUserCountryList();
      this.getBulletinTypeList();
      if (this.bulletinId != 'add') {
        this.getBulletinInfo();
      } else {
        this.resetTemp();
      }
    },
  },
  mounted () {
    this.bulletinId = this.$route.params.id;
    this.initialState();
  },
  watch: {
    $route(to) {
      if (to.name == 'addBulletin') {
        this.bulletinId = to.params.id;
        this.initialState();
      }
    }
  },
}
</script>
<style>
.layoutContainer.addBulleinContent {
  overflow-y: auto;
}
.tox-pop--top {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.noticType .el-radio{
  line-height: 40px;
}
.addBulleinContent .el-select{
  width: 100%
}
.el-textarea .el-input__count {
  line-height: 1 !important;
}

</style>
