<template>
  <div class="layoutContainer" :class="result && result.docType == 2 ? 'iframDiv': ''">
    <div class="noticeContent" v-cloak>
      <div class="datum-bull" v-if="result == null">
        <span>暂无数据</span>
      </div>
      <!-- 标题 -->
      <div class="title-bull" v-if="result != null">{{result.title}}</div>
      <!-- 通告信息 -->
      <div class="datum-bull" v-if="result != null">
        <!-- <span>状态：{{getStatus(this.result.status)}}</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
        <span>更新人员：{{ result.username}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <!-- <span>类型：{{getType(this.result.docType)}}</span>&nbsp;&nbsp;&nbsp;&nbsp; -->
        <span>更新时间：{{ result.updatedTime | conversion("yyyy-MM-dd HH:mm:ss")}}</span><br>
        <!-- <span>备注：{{(result.remark == "" || result.remark == null) ? "无": result.remark}}</span> -->
      </div>
      <!-- 通告概述 -->
      <div class="subhead" v-if="result != null">{{result.summary}}</div>
      <!-- 内容 -->
      <div class="content-bull" v-if="result != null">
        <div v-if="result.docType == 1" v-html="result.content"></div>
        <div v-else-if="result.docType == 3">
          <img :src="result.path" width="100%" />
        </div>
        <iframe v-else
          id="ifram"
          :src="url"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl } from '@/assets/js/common.js'
import { bulletinInfo } from '@/api/cmsmgt.js'
import axios from 'axios'

export default {
  name: 'bulletinDetails',
  data () {
    return {
      id: '',
      result: {},
      content: '',
      url:'',
      statusList: [
        { name: '草稿', code: 1 },
        { name: '发布', code: 2 },
        { name: '关闭', code: 3 },
      ],
      typeList: [
        { name: '富文本', code: 1 },
        { name: 'pdf', code: 2 },
        { name: '图片', code: 3 },
      ],
    }
  },
  watch: {
    $route(to) {
      if(to.name == "bulletinDetails") {
        this.id = this.$route.params.id
        this.getBulletinInfo();
      }
    }
  },
  methods: {
    // 获取通告明细
    getBulletinInfo(){
      bulletinInfo(this.id).then(res=>{
        this.result = res.data.data
        if (this.result != null) {
          this.url = this.result.docType == 2 ? "static/pdf/web/viewer.html?file=" + this.result.path : '';
          this.bulletinSize();
        }
      }).catch(e => {
        this.result = null;
      })
    },
    getType(type){
      for (let i = 0; i < this.typeList.length; i++) {
        if (this.typeList[i].code == type) {
          return this.typeList[i].name;
        }
      }
      return "未知";
    },
    getStatus(status){
      for (let i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].code == status) {
          return this.statusList[i].name;
        }
      }
      return "未知";
    },
    bulletinHeight() {
      setTimeout(() => {
        var allHeight = $(".noticeContent").height();
        var title = $(".title-bull").outerHeight(true);
        var datum = $(".datum-bull").outerHeight(true);
        var val = allHeight - title - datum - 15;
        $(".content-bull").css("height", val)
      })
    },
    bulletinSize(){
      var _this = this;
      _this.bulletinHeight();
      window.addEventListener("resize", function () {
        _this.bulletinHeight();
      });
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.getBulletinInfo();
    window.systemReminder();
  }
}
</script>

<style>
  .iframDiv{
    height: 100%;
    line-height: 1.6;
  }
  .noticeContent .title {
    text-align: center;
    font-size: 20px;
    font-weight:bold;
    line-height: 1.6;
    word-break: break-all;
  }
  .noticeContent .datum {
    text-align: center;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    color: #666;
    margin: 5px 0;
  }
  .noticeContent .subhead{
    font-size: 16px;
    line-height: 1.6;
    word-break: break-all;
  }
  .noticeContent .content{
    font-size: 15px;
    line-height: 1.6;
    color: #333;
  }
  .datum-bull{
    text-align: center;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    color: #666;
    margin: 5px 0;
  }

  .content-bull {
    font-size: 15px;
    line-height: 1.6;
    color: #333;
    /* height: 700px; */
  }

  .title-bull {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    word-break: break-all;
  }

</style>
