const carmgtRouter = [
  {
    path: '/carmgt/train/list',
    component: () => import('@/views/carmgt/train/list'),
    name: 'carmgttrainlist'
  },
  {
    path: '/carmgt/model/list',
    component: () => import('@/views/carmgt/model/list'),
    name: 'carmgtmodellist'
  },
]
export default carmgtRouter;
