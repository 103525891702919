import log from '@/assets/js/log_config'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './plugins/element.js'
import qs from 'qs'
import axios from 'axios'
import './assets/icons/index'
import './assets/css/common.css'
import './assets/css/style.css'
import 'vue-easytable/libs/theme-default/index.css'
import './api/cmsmgt'
import './api/sysmgt'
import Router from 'vue-router'
import './plugins/codemirror'
import './plugins/directives.js'
import i18n from './i18n/i18n'
import {VeTable} from "vue-easytable";
import {conversion} from "@/store/filters";
import {handleAlert} from "@/assets/js/common.js"
import echarts from "echarts";
import {autoRefreshToken } from '@/assets/js/refresh_token.js'
import loading from './plugins/loading/loading.js'


Vue.prototype.$echarts = echarts;
Vue.use(Router)
Vue.use(axios)
Vue.use(loading)
Vue.use(VeTable)
Vue.filter("conversion", conversion);
// 解决with new navigation
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
Vue.prototype.$qs = qs
Vue.config.productionTip = false

Vue.prototype.validForbid = function (value) {
  //value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\·~！@#￥%……&*（）——\-+={}|\\《》？：“”【】\[\]、；‘’，。、]/g, '').replace(/\s/g, "")
  //.replace(/\s/g, "") 去掉空格
  //.replace(/^\s+|\s+$/g,'') 禁止前后输入空格，不禁止中间输入空格
  value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\·~！@#￥%……&*（）——\-+={}|\\《》？：“”【】\[\]、；‘’，。、]/g, '').replace(/^\s+|\s+$/g, '')
  return value
}
Vue.prototype.hasPerm = function (value) {
  let perms = store.state.perms
  if (perms && perms instanceof Array && perms.length > 0) {
    if (perms.indexOf(value) > -1)
      return true
  } else {
    return false
  }
}


Vue.prototype.hasRole = function (value) {
  let roles = store.state.roles
  if (roles && roles instanceof Array && roles.length > 0) {
    if (roles.indexOf(value) > -1)
      return true
  } else {
    return false
  }
}


/** 权限指令,对按钮权限的控制 **/
const hasPermission = Vue.directive("hasPermission", {
  inserted: function (el, binding) {
    if (!Vue.prototype.$_hasPermission(binding.value)) {
      el.parentNode.removeChild(el);
    }
  },
});

Vue.prototype.$_hasPermission = function (value) {
  let perms = store.state.perms
  if (perms && perms instanceof Array && perms.length > 0) {
    if (perms.indexOf(value) > -1)
      return true
  } else {
    return false
  }
}
export {hasPermission};

Vue.prototype.parserNumber = function (value, min, max) {
  if (value != null && value.length > 0) {
    if (!isNaN(value)) {
      let v = parseInt(value)
      if (v > max) {
        value = max
      } else if (v < min) {
        value = min
      } else {
        value = v
      }
    } else {
      value = 1
    }
  } else {
    value = 1
  }

  return value
}
// console.log(store);
// axios.defaults.headers.common['Authorization'] = sessionStorage.token
// Vue.http.options.root = 'http://vue.studyit.io/';
// Vue.prototype.axios = axios.create({
//   baseURL : "http://192.168.1.180:8080/cmsmgt/",
//   transformRequest: [
//     data => qs.stringify(data)
//   ]
// })
// axios.defaults.baseURL = "http://192.168.1.180:8080/cmsmgt/"
// 添加请求拦截器
axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么

  if (sessionStorage.token) {
    config.headers.common['Authorization'] = sessionStorage.token
  } else {
    router.push({name: 'login'});
  }
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

const broadChannel = new BroadcastChannel('tokenSync');
window.bc = broadChannel;
bc.onmessage = function(e) {
 let data =  JSON.parse(e.data)
  if(store.state.userName && store.state.userName == data.userName){
    if(sessionStorage.token && sessionStorage.token!='' && sessionStorage.refreshToken && sessionStorage.refreshToken != ''){
      store.commit('set_token', data.token)
      //刷新token不加Bearer
      store.commit("setRefreshToken",data.refreshToken);
     sessionStorage.setItem("store", JSON.stringify(store.state));
     console.log("sync tokenKey")
    }
  }

};

bc.onmessageerror = function(e) {
  console.warn('error:', e);
}

function toLoginPage(){
  clearTimeout(toLoginPage)
  window.isKidOffLineShow = false
  router.push('/')
}

// http response 拦截器
axios.interceptors.response.use(async (response) => {
      try{
        if(response.data == "" || response.data == null){
          handleAlert("error", "系统繁忙...")
          return new Promise((resove) => {});
        }
        if(response.headers['content-type'].toLowerCase().indexOf('json') >=0){
            if(response.data.code == 555){
              //isRefreshToken 基本用不上,但为了保险起见，加上
              if(sessionStorage.getItem("isRefreshToken") == "1"){
                return autoRefreshToken(response, axios)
              }
              if((!window.isKidOffLineShow)){
              window.isKidOffLineShow = true
              handleAlert("error",response.data.msg)
                 setTimeout(toLoginPage,1000)
                }
               return new Promise(resove=>{

               });
            }
        }
      }catch{
        //不处理
      }



    // 兼容blob下载出错json提示
    if (response.request.responseType === 'blob' && response.data instanceof Blob && response.data.type && response.data.type.toLowerCase().indexOf('json') !== -1) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          response.data = JSON.parse(reader.result)
          resolve(Promise.reject(response.data.msg))
        }
        reader.onerror = () => {
          reject(response.data.msg)
        }
        reader.readAsText(response.data)
      })
    }else {
      return response;
    }
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          return autoRefreshToken(error.response, axios)
          //store.commit("del_token");
          // store.commit("clearVUEX");
          // sessionStorage.clear();
          // router.push({name: 'login'});
          // handleAlert("error", "请登录")
      }
    }
    handleAlert("error", "系统繁忙...")
    return Promise.reject(error.response.data)
  }
);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
