<template>
  <div class="layoutContainer manualCatalog">
    <div class="secondFloat">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-input placeholder="在结构中查找" v-model="searchVal"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset()">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-dropdown>
            <el-button v-if="hasPerm('menuAsimssCMS3A2B_107')" style="font-size: 14px;">
              导入导出<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" style="margin-top: -3px">
              <el-dropdown-item v-if="hasPerm('menuAsimssCMS3A2B_107')" @click.native="importCatalogClick()">导入默认目录</el-dropdown-item>
              <el-dropdown-item >
                <el-upload
                  class="upload-demo inline-block"
                  ref="batchUpload"
                  action="#"
                  :show-file-list="false"
                  multiple
                  :before-upload="onBeforeUpload"
                  :http-request="uploadFile"
                  accept="xls、xlsx"
                >
                  <span v-if="hasPerm('menuAsimssCMS3A2B_107')">导入excel</span>
                </el-upload>
              </el-dropdown-item>
              <el-dropdown-item v-if="hasPerm('menuAsimssCMS3A2B_107')" @click.native="exportExcelClick()">导出excel</el-dropdown-item>
              <el-dropdown-item v-if="hasPerm('menuAsimssCMS3A2B_107')" @click.native="importPackageClick()">导入数据包</el-dropdown-item>
              <el-dropdown-item v-if="hasPerm('menuAsimssCMS3A2B_107')" @click.native="exportPackageClick()">导出数据包</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <div class="tableHandle">
        <el-button type="text" :disabled="revokeState == false ? false : true" icon="revoke-icon"  @click="revokeClick()">撤销</el-button>
        <el-button type="text" :disabled="recoveryState == false ? false : true" icon="recovery-icon"  @click="recoveryClick()">恢复</el-button>
        <el-button type="text" :disabled="buttonState == false ? false : true" icon="shear-icon"  @click="shearClick()">剪切</el-button>
        <el-button type="text" :disabled="buttonState == false ? false : true" icon="copy-icon"  @click="copyClick()">复制</el-button>
        <el-button type="text" :disabled="buttonState == false ? false : true" icon="paste-icon"  @click="pasteClick()">粘贴</el-button>
      </div>
      <el-table
        class="catalogTable"
        style="width:100%"
        border
        ref="table"
        highlight-current-row
        :max-height="maximumHeight"
        :data="listdata"
        default-expand-all
        row-key="id"
        @header-dragend="changeColWidth"
        :header-cell-style="{
          'text-align': 'center',
          'background-color': 'var(--other-color)',
        }"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :row-style="rowStyle"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="大纲结构" prop="cnName" min-width="240">
          <template slot-scope="{row}" class="kdad">
            <span style="cursor: pointer;" @contextmenu.prevent="openMenu(row, $event)" class="synopsisText">
              {{row.cnName}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="编码" prop="code" min-width="130"></el-table-column>
        <el-table-column label="节点类型" width="80" prop="isLeaf" align="center">
          <template slot-scope="{row}">
            <span v-if="row.isLeaf == 1">章节</span>
            <span v-if="row.isLeaf == 0">目录</span>
            <!-- <div v-for="(item, index) in chapterTypeList" :key="index">
              <span v-if="item.code == row.chapterType">{{item.name}}</span>
            </div> -->
          </template>
        </el-table-column>
        <el-table-column label="目录类型" width="80" prop="chapterType" align="center" >
          <template slot-scope="{row}">
            <div v-for="(item, index) in chapterTypeList" :key="index">
              <span v-if="item.code == row.chapterType">{{item.name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="主题类型" min-width="100" prop="topicType" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.children.length !== 0" :class="'noEdit ' + scope.$index + '_6'">
              <i class="noEdit-icon"></i>
            </div>
            <div v-else class="rowEditShow" @click="cellClick(scope, 'topicType')">
              <el-input
                v-if="
                rowEditIndex == scope.$index &&
                colimnEditIndex == scope.column.id"
                ref="tableRowInputRef"
                v-model="scope.row.topicType"
                @blur="onInputTableBlur(scope)"
              >
              </el-input>
              <div
                v-else
                :class="'textShow ' + scope.$index + '_6 ' + scope.row.id + ' topicType'"
                @contextmenu.prevent="contextmenuClick(scope, $event, 'topicType')"
                @dblclick="dbClickCell(scope)"
              >
                {{ scope.row.topicType }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="筛选属性" min-width="220" prop="topicFilter">
          <template slot-scope="scope">
            <div v-if="scope.row.children.length !== 0" :class="'noEdit ' + scope.$index + '_7'">
              <i class="noEdit-icon"></i>
            </div>
            <div v-else class="rowEditShow" @click="cellClick(scope, 'topicFilter')">
              <el-input
                v-if="
                rowEditIndex == scope.$index &&
                colimnEditIndex == scope.column.id"
                ref="tableRowInputRef"
                v-model="scope.row.topicFilter"
                @blur="onInputTableBlur(scope)"
              >
              </el-input>
              <div
                v-else
                :class="'textShow ' + scope.$index + '_7 ' + scope.row.id + ' topicFilter'"
                @contextmenu.prevent="contextmenuClick(scope, $event, 'topicFilter')"
                @dblclick="dbClickCell(scope)"
              >
                {{ scope.row.topicFilter }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="适用配置" min-width="180" prop="config">
           <template slot-scope="scope">
            <div v-if="scope.row.children.length !== 0" :class="'noEdit ' + scope.$index + '_8'">
              <i class="noEdit-icon"></i>
            </div>
            <div v-else class="rowEditShow" @click="cellClick(scope, 'config')">
              <el-input
                v-if="
                rowEditIndex == scope.$index &&
                colimnEditIndex == scope.column.id"
                ref="tableRowInputRef"
                v-model="scope.row.config"
                @blur="onInputTableBlur(scope)"
              >
              </el-input>
              <div
                v-else
                :class="'textShow ' + scope.$index + '_8 ' + scope.row.id + ' config'"
                @contextmenu.prevent="contextmenuClick(scope, $event, 'config')"
                @dblclick="dbClickCell(scope)"
              >
                {{ scope.row.config }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="排序" min-width="50" prop="sort" align="center"></el-table-column>
        <el-table-column label="备注" min-width="100" prop="remark"></el-table-column>
        <el-table-column label="附加信息" width="80" prop="additionInfoList" align="center">
          <template slot-scope="{row}">
            <el-button type="text" size="small" @click="additionalClick(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="contextmenuArea">
      <div v-if="hasPerm('menuAsimssCMS3A2B_101')" @click="addNodeClick">
        <i class="el-icon-plus"></i>
        <span>新增</span>
      </div>
      <div v-if="hasPerm('menuAsimssCMS3A2B_103')" @click="editNodeClick">
        <i class="compile-icon"></i>
        <span>编辑</span>
      </div>
      <div @click="renameClick" v-if="hasPerm('menuAsimssCMS3A2B_103')">
        <i class="rename-icon"></i>
        <span>重命名</span>
      </div>
      <div v-if="hasPerm('menuAsimssCMS3A2B_102')" @click="delNode">
        <i class="el-icon-delete"></i>
        <span>删除</span>
      </div>
    </div>
    <div class="contextmenuContent">
      <div @click="shearClick()">
        <i class="shear-icon"></i>
        <span>剪切</span>
      </div>
      <div @click="copyClick()">
        <i class="copy-icon"></i>
        <span>复制</span>
      </div>
      <div @click="pasteClick()">
        <i class="paste-icon"></i>
        <span>粘贴</span>
      </div>
      <el-divider></el-divider>
      <div @click="clearClick()">
        <i class="clear-icon"></i>
        <span>清空单元格</span>
      </div>
    </div>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit'" :label-width="formLabelWidth" :model="form" ref="form" :rules="newForm">
        <el-form-item label="编码" prop="code">
          <el-input v-model="form.code"  placeholder="请输入目录编码" show-word-limit maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="cnName">
          <el-input v-model="form.cnName" placeholder="请输入名称" show-word-limit maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="节点类型" prop="isLeaf">
          <el-radio-group v-model="form.isLeaf" :disabled="butType!=='addNodeBut'">
            <el-radio :label="0">目录</el-radio>
            <el-radio :label="1">章节</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="目录类型" prop="chapterType">
          <el-select v-model="form.chapterType" clearable filterable :disabled="manualTypeId != form.pid">
            <el-option v-for="(item, index) in chapterTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.isLeaf == '1'" label="主题类型" prop="topicType">
          <el-select v-model="form.topicType" clearable filterable>
            <el-option-group v-for="group in topicTypeList" :key="group.code" :label="group.name">
              <el-option
                v-for="item in group.children"
                :key="item.code"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.isLeaf == '1'" label="筛选属性" prop="topicFilter">
          <el-input type="textarea"  :autosize="{ minRows: 4, maxRows: 6}" v-model.trim="form.topicFilter" placeholder="请输入筛选属性" show-word-limit maxlength="100"></el-input>
        </el-form-item>
        <el-form-item v-if="form.isLeaf == '1'" label="适用配置" prop="config">
          <el-input v-model.trim="form.config" placeholder="请输入适用配置" show-word-limit maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input type="number" min="0" oninput="value=value.replace(/[^\d]/g, '')" v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" show-word-limit maxlength="100"></el-input>
        </el-form-item>
        <el-form-item class="submitArea">
          <el-button type="primary" @click="dialogStatus=='add' ? nodeConfirm('form') : updateNode()">确定</el-button>
          <el-button plain @click="resetForm()">取消</el-button>
        </el-form-item>
      </el-form>
      <el-form v-if="dialogStatus === 'rename'" :label-width="formLabelWidth" :model="form" ref="form" :rules="newForm">
        <el-form-item label="名称" prop="cnName" >
          <el-input v-model="form.cnName" placeholder="请输入目录编码" show-word-limit maxlength="50"></el-input>
        </el-form-item>
             <el-form-item class="submitArea">
          <el-button type="primary" @click="renameConfirm()">确定</el-button>
          <el-button plain @click="resetForm()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog width="450px !important" title="导入数据包" :visible.sync="dialogImportPackageVisible">
      <el-form label-position="center">
        <el-form-item >
          <el-upload class="upload-demo inline-block margin-left-10 margin-right-10"
             ref="elUpload"
             action="#"
             :show-file-list="false"
             multiple
             :limit="1"
             :before-upload="onBeforeUploadPackage">
                <!-- before-upload：上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。 -->
              <el-button size="min" icon="el-icon-upload" type="primary" style="">批量上传</el-button>
              <el-progress style="width:350px;margin-top:50px;margin-left:10px"  v-show="showUploadProcess" color="green" type="line"  :text-inside="true" :stroke-width="20" :percentage="percentage"></el-progress>
          </el-upload>
          <el-upload ref="elUploadResult"
            action="#"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :before-remove="handleBeforeRemove"
            :on-remove="handleRemove" >
          </el-upload>
        </el-form-item>
        <el-form-item class="submitArea">
          <el-button type="primary" @click="importPackage()">
            立即提交
          </el-button>
          <el-button @click="resetImport()">
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog width="550px !important" title="添加附加信息" :visible.sync="dialogAdditionalVisible" >
      <!-- 动态增加项目 -->
      <!-- 不止一个项目，用div包裹起来 -->
      <el-form :model="formAdditional" >
        <div v-for="(item, index) in formAdditional.dynamicItem" :key="index">
          <el-row>
            <el-col :span="11">
              <el-form-item label="属性名" :prop="'dynamicItem.' + index + '.key'">
                <el-input v-model.trim="item.key"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="属性值" :prop="'dynamicItem.' + index + '.value'" >
                  <el-input v-model.trim="item.value"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-form-item style="margin-top:35px">
                <i class="el-icon-delete" @click="deleteItem(item, index)" style="cursor: pointer;"></i>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-button type="text" style="padding: 0!important;margin: 0!important;" icon="el-icon-plus" @click="addDomain()">新增</el-button>
        <el-form-item class="submitArea">
          <el-button type="primary" @click="confirmAdditional()">保存</el-button>
          <!-- <el-button @click="addDomain()">新增</el-button> -->
          <el-button @click="dialogAdditionalVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, tableHeight, tableMaxHeight, handleAlert, expandTree } from '@/assets/js/common.js'
import { procSplitFile, checkUploadProgress } from '@/api/sysmgt.js'
import { catalogData, catalogImport, catalogUpload, catalogAdd, catalogEdit, catalogTypeList,
  catalogDel, copyTreeData, defineCopy, catalogFirmUpload, catalogexportExcel,
  catalogimportPackage, catalogdowPackage, catalogdelPackage, catalogparsePackage } from '@/api/cmsmgt.js'
import axios from 'axios'
import $ from 'jquery'
import { version } from 'vue'
import SparkMD5 from 'spark-md5'
/** 计算文件md5值
 * @param file 文件
 * @param chunkSize 分片大小
 * @returns Promise
 */
function getmd5(file, chunkSize) {
  return new Promise((resolve, reject) => {
    let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    let chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let spark = new SparkMD5.ArrayBuffer();
    let fileReader = new FileReader();
    fileReader.onload = function(e) {
      spark.append(e.target.result);
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        let md5 = spark.end();
        resolve(md5);
        //  console.log(md5);
      }
    };
    fileReader.onerror = function(e) {
      reject(e);
    };
    function loadNext() {
      let start = currentChunk * chunkSize;
      let end = start + chunkSize;
      if (end > file.size){
        end = file.size;
      }
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    loadNext();
  });
}
export default {
  name: 'manualCatalog',
  data () {
    return {
      butType: "",
      searchVal: "", // 搜索
      menuData: [],
      listdata: [], // 数据内容
      manualId: "",
      manualTypeId: "",
      manualVersion: "",
      dialogImportPackageVisible: false,// 导入数据包
      percentage: 0,
      showUploadProcess:false,
      maximumHeight: 0,
      chapterTypeList: [], // 目录类型
      topicTypeList: [], // 主题类型
      fileList: [],
      //切片文件
      fileShard:{},
      //当前文件
      curFile:{},
        //文件分割的开始位置
      start: 0,
      //文件分割的结束位置
      end: 0,
      //文件大小
      fileSize: 0,
      fileKey: '',
      fileShardIndex: 0,
      fileShardTotal: 0,
      fileShardSize: 0,
      // 附加信息弹窗
      dialogAdditionalVisible: false,
      formAdditional: {
        dynamicItem: []
      },
      // additionInfoList: [],
      dialogStatus: "",
      textMap: {
        edit: "编辑节点信息",
        add: "新增节点信息",
        rename: "重命名",
      },
      // 新增
      catalogueLevel:"",
      formLabelWidth: '100px',
      dialogFormVisible: false,
      childrenList: [],
      form: {
        id: 0,
        pid: 0,
        type: '',
        code: '',
        cnName: '',
        isLeaf: '',
        sort: 1,
        remark: '',
        level: '',
        chapterType: '', // 章节类型
        topicType: '', // 主题类型
        topicFilter: '',  // 筛选属性
        additionInfoList:[],
        versionNumber: '',
        topicContentBaseSyncVersion: '',
        treeModelBaseSyncVersion: '',
        structSyncVersion: "",
        config: '',  // 适用配置
      },
      newForm:{
        code: [{ required: true, message: '目录编码不能为空', trigger: ['blur', 'change'] }],
        cnName: [{ required: true, message: '中文名称不能为空', trigger: ['blur', 'change'] }],
        isLeaf: [{ required: true, message: '请选择Topic节点', trigger: ['blur', 'change'] }],
      },
      structSyncVersion: "",
      // 复制粘贴
      buttonState: false,
      revokeState: false,
      recoveryState: false,
      copyVal: "",
      rowEditIndex: "",
      colimnEditIndex: "",
      cellId: "",
      rowIndex: "",
      colIndex: "",
      cellType: "",
      cellState: false,
      uploadStatue: false,
      storageStack: [], // 原数据
      undoStack: [], // 撤销操作的栈
      redoStack: [], // 恢复操作的栈
      returnStack: [], // 回复后的原数据
      stackIndex: "",
      copyNode: "",
      uplopadVersion: "",
      lastChildList: [],
    }
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "manualCatalog") {
        _this.manualId = to.params.id
        _this.manualVersion = to.params.manualVersion
        _this.initialDisplay();
      }
    }
  },
  methods: {
    // 表格样式
    rowStyle({ row, rowIndex }) {
      let styleJson = {
        "background": "var(--cell-bgColor)",
      }
      if(row.children.length == 0 || row.children == null) {
        return styleJson;
      } else {
        return {};
      }
    },
    // 监听表格列宽变化
    changeColWidth() {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      })
    },
    // 搜索
    onSubmit(){
      var val = this.searchVal
        .replace(/(^\s*)|(\s*$)/g, "")
        .replace(/[\r\n]/g, "");
      var data = this.menuData;
      var bigArr = [];
      data.forEach((itemOne)=> {
        if(itemOne.children.length == 0){
          if (itemOne.cnName.indexOf(val) !== -1) {
            var firstList = itemOne;
            bigArr.push(firstList)
          }
        } else {
          var firstList = {
              pid: itemOne.pid,
              id:itemOne.id,
              type: itemOne.type,
              cnName: itemOne.cnName,
              code: itemOne.code,
              level:itemOne.level,
              isLeaf: itemOne.isLeaf,
              chapterType: itemOne.chapterType,
              topicType: itemOne.topicType,
              topicFilter: itemOne.topicFilter,
              config: itemOne.config,
              sort: itemOne.sort,
              remark: itemOne.remark,
              additionInfoList: itemOne.additionInfoList,
              versionNumber: itemOne.versionNumber,
              treeModelBaseSyncVersion: itemOne.versionNumber,
              structSyncVersion: itemOne.structSyncVersion,
              topicContentBaseSyncVersion: itemOne.topicContentBaseSyncVersion,
              children: []
          }
          itemOne.children.forEach((itemTwo) => {
            if(itemTwo.children.length == 0){
              if (itemTwo.cnName.indexOf(val) !== -1) {
                var secondList = itemTwo;
                firstList.children.push(secondList)
              }
            } else {
              var secondList = {
                pid: itemTwo.pid,
                id: itemTwo.id,
                type: itemTwo.type,
                cnName: itemTwo.cnName,
                code: itemTwo.code,
                level: itemTwo.level,
                isLeaf: itemTwo.isLeaf,
                chapterType: itemTwo.chapterType,
                topicType: itemTwo.topicType,
                topicFilter: itemTwo.topicFilter,
                config: itemTwo.config,
                sort: itemTwo.sort,
                remark: itemTwo.remark,
                additionInfoList: itemTwo.additionInfoList,
                versionNumber: itemTwo.versionNumber,
                treeModelBaseSyncVersion: itemTwo.versionNumber,
                structSyncVersion: itemTwo.structSyncVersion,
                topicContentBaseSyncVersion: itemTwo.topicContentBaseSyncVersion,
                children: []
              }
              itemTwo.children.forEach((itemThree) => {
                if(itemThree.children.length == 0){
                  if (itemThree.cnName.indexOf(val) !== -1) {
                    var thirdList = itemThree
                    secondList.children.push(thirdList)
                  }
                } else {
                  var thirdList = {
                    pid: itemThree.pid,
                    id: itemThree.id,
                    type: itemThree.type,
                    cnName: itemThree.cnName,
                    code: itemThree.code,
                    level: itemThree.level,
                    isLeaf: itemThree.isLeaf,
                    chapterType: itemThree.chapterType,
                    topicType: itemThree.topicType,
                    topicFilter: itemThree.topicFilter,
                    config: itemThree.config,
                    sort: itemThree.sort,
                    remark: itemThree.remark,
                    additionInfoList: itemThree.additionInfoList,
                    versionNumber: itemThree.versionNumber,
                    treeModelBaseSyncVersion: itemThree.versionNumber,
                    structSyncVersion: itemThree.structSyncVersion,
                    topicContentBaseSyncVersion: itemThree.topicContentBaseSyncVersion,
                    children:[]
                  }
                  itemThree.children.forEach((itemFour) => {
                    if(itemFour.children.length == 0){
                      if (itemFour.cnName.indexOf(val) !== -1) {
                        var fourthList = itemFour;
                        thirdList.children.push(fourthList)
                      }
                    } else{
                      var fourthList = {
                          pid: itemFour.pid,
                          id: itemFour.id,
                          type: itemFour.type,
                          cnName: itemFour.cnName,
                          code: itemFour.code,
                          level: itemFour.level,
                          isLeaf: itemFour.isLeaf,
                          chapterType: itemFour.chapterType,
                          topicType: itemFour.topicType,
                          topicFilter: itemFour.topicFilter,
                          config: itemFour.config,
                          sort: itemFour.sort,
                          remark: itemFour.remark,
                          additionInfoList: itemFour.additionInfoList,
                          versionNumber: itemFour.versionNumber,
                          treeModelBaseSyncVersion: itemFour.versionNumber,
                          structSyncVersion: itemFour.structSyncVersion,
                          topicContentBaseSyncVersion: itemFour.topicContentBaseSyncVersion,
                          children: [],
                        }
                      itemFour.children.forEach((itemFive) => {
                        if(itemFive.children.length == 0){
                          if (itemFive.cnName.indexOf(val) !== -1) {
                            var fifthList = itemFive;
                            fourthList.children.push(fifthList)
                          }
                        } else {
                          var fifthList = {
                            pid: itemFive.pid,
                            id: itemFive.id,
                            type: itemFive.type,
                            cnName: itemFive.cnName,
                            code: itemFive.code,
                            level: itemFive.level,
                            isLeaf: itemFive.isLeaf,
                            chapterType: itemFive.chapterType,
                            topicType: itemFive.topicType,
                            topicFilter: itemFive.topicFilter,
                            config: itemFive.config,
                            sort: itemFive.sort,
                            remark: itemFive.remark,
                            additionInfoList: itemFive.additionInfoList,
                            versionNumber: itemFive.versionNumber,
                            treeModelBaseSyncVersion: itemFive.versionNumber,
                            structSyncVersion: itemFive.structSyncVersion,
                            topicContentBaseSyncVersion: itemFive.topicContentBaseSyncVersion,
                            children: [],
                          }
                          itemFive.children.forEach((itemSix) => {
                            if(itemSix.children.length == 0){
                              if(itemSix.cnName.indexOf(val) !== -1){
                                var sixthList = itemSix;
                                fifthList.children.push(sixthList)
                              }
                            } else {
                              var sixthList = {
                                pid: itemSix.pid,
                                id: itemSix.id,
                                type: itemSix.type,
                                cnName: itemSix.cnName,
                                code: itemSix.code,
                                level: itemSix.level,
                                isLeaf: itemSix.isLeaf,
                                chapterType: itemSix.chapterType,
                                topicType: itemSix.topicType,
                                topicFilter: itemSix.topicFilter,
                                config: itemSix.config,
                                sort: itemSix.sort,
                                remark: itemSix.remark,
                                additionInfoList: itemSix.additionInfoList,
                                versionNumber: itemSix.versionNumber,
                                treeModelBaseSyncVersion: itemSix.versionNumber,
                                structSyncVersion: itemSix.structSyncVersion,
                                topicContentBaseSyncVersion: itemSix.topicContentBaseSyncVersion,
                                children: [],
                              }
                              itemSix.children.forEach((itemSeven) => {
                                if(itemSeven.children.length == 0){
                                  if(itemSeven.cnName.indexOf(val) !== -1){
                                    var seventhList = itemSeven;
                                    sixthList.children.push(seventhList)
                                  }
                                }
                              })
                              if(sixthList.children.length !== 0){
				                        fifthList.children.push(sixthList)
				                      }
                            }
                          })
                          if(fifthList.children.length !== 0){
				                    fourthList.children.push(fifthList)
				                  }
                        }
                      })
                      if(fourthList.children.length !== 0){
				                thirdList.children.push(fourthList)
				              }
                    }
                  })
                  if(thirdList.children.length !== 0){
				            secondList.children.push(thirdList)
				          }
                }
              })
              if(secondList.children.length !== 0){
				        firstList.children.push(secondList)
				      }
            }
          })
        }
        if(firstList.children.length !== 0){
			    bigArr.push(firstList)
			  }
      })
      this.listdata = bigArr
    },
    keyDown(e) {
      if (e.keyCode === 13) {
        this.onSubmit();
      }
    },
    // 重置
    reset(){
      this.searchVal = "";
      this.dataList();
    },
    dataList () {
      this.$loading.show()
      var params = new URLSearchParams()
      params.append('id', this.$route.params.id)
      params.append('manualVersion', this.$route.params.manualVersion)
      catalogData(params).then(res => {
        if(res.data.code==100){
          this.listdata = res.data.data
          this.menuData = res.data.data;
          this.manualTypeId = res.data.data[0].id;
          this.structSyncVersion = res.data.data[0].versionNumber
          this.tableHeightArea();
          this.lastChildList = [];
          this.treeMenu(this.menuData)
        }
        this.versionStatus();
        this.$loading.hide()
      })
    },
    getChapterTypeList(){
      catalogTypeList('2').then(res => {
        this.chapterTypeList = res.data.data
      }).catch(e => {
        this.chapterTypeList = []
      })
    },
    getTopicTypeList(){
      catalogTypeList('1').then(res => {
        this.topicTypeList = res.data.data
      }).catch(e => {
        this.topicTypeList = []
      })
    },
    versionStatus(){
      var _this = this;
      if(_this.undoStack.length > 0){
        _this.revokeState = false
      } else {
        _this.revokeState = true
      }
      if(_this.redoStack.length > 0){
        _this.recoveryState = false
      } else {
        _this.recoveryState = true
      }
    },
    // 撤销
    revokeClick(){
      var _this = this
      _this.uploadStatue = true
      var lastItem = _this.undoStack.pop();
      if (lastItem !== undefined) {
        if(_this.storageStack.length >0){
          var list = _this.storageStack.pop();
          _this.stackOperation(list)
          _this.returnStack.push(list)
          _this.redoStack.push(lastItem);
        }
      }
    },
    stackOperation(lastAction){
      var list = lastAction
      for(var i = 0; i < list.length; i++){
        for(var j = 0; j < list[i].length; j++) {
          var id = JSON.parse(list[i][j]).id
          for (var k = 0; k < this.lastChildList.length; k++) {
            if(this.lastChildList[k].id == id){
              this.uplopadVersion = this.lastChildList[k].versionNumber
              var data = JSON.parse(list[i])
              this.uploadAssignment(data)
            }
          }
        }
      }
    },
    treeMenu(data){
      for(var i = 0;  i< data.length; i++){
        if(data[i].children.length !== 0){
          this.treeMenu(data[i].children)
        } else{
          this.lastChildList.push(data[i]);
        }
      }
    },
    // 恢复
    recoveryClick(){
      var _this = this
      _this.uploadStatue = true
      const lastItem = this.redoStack.pop();
      if (lastItem !== undefined) {
        _this.storageStack.push(_this.returnStack.pop());
        _this.undoStack.push(lastItem);
        _this.stackOperation(lastItem)
      }
    },
    // 表格双击编辑
    dbClickCell(scope){
      var _this = this
      _this.rowEditIndex = scope.$index
      _this.colimnEditIndex = scope.column.id
      _this.$nextTick(() => {
        _this.$refs.tableRowInputRef.focus();
      });
      _this.cellsContent(scope, scope.column.property)
      _this.uploadStatue = false
      _this.buttonState = true
      _this.revokeState = true
      _this.recoveryState = true
      _this.editStackData(scope)
    },
    getStackDetail(list){
      var _this = this
      _this.undoStack.push(list);
      _this.redoStack = [];
    },
    editStackData(scope) {
      var _this = this
      _this.uplopadVersion = "";
      var list = new Array
      var listOne = []
      listOne.push(JSON.stringify(scope.row))
      list.push(listOne)
      _this.storageStack.push(list);
    },
    inputBlurText(attr, scope){
      var _this = this
      if(attr !== _this.copyVal){
        var list = new Array
        var listOne = []
        listOne.push(JSON.stringify(scope.row))
        list.push(listOne)
        _this.getStackDetail(list)
        _this.uploadAssignment(scope.row)
      } else {
        _this.storageStack.pop()
      }
    },
    onInputTableBlur(scope) {
      var _this = this
      var type = scope.column.property;
      var id = scope.row.id
      _this.rowEditIndex = ""
      _this.colimnEditIndex = ""
      _this.buttonState = false
      _this.uploadStatue = true
      if (type == "topicType") {
        _this.inputBlurText(scope.row.topicType, scope)
      }
      if (type == "topicFilter") {
        _this.inputBlurText(scope.row.topicFilter, scope)
      }
      if (type=="config") {
        _this.inputBlurText(scope.row.config, scope)
      }
    },

    uploadAssignment(data){
      var _this = this
        let list = []
        if (data.additionInfoList) {
          var type = typeof data.additionInfoList
          if (type == "string") {
            let obj = JSON.parse(data.additionInfoList)
            Object.keys(obj).forEach(key=>{
              let param = {
                key:key,
                value:obj[key]
              }
              list.push(param);
            })
          } else {
            list = data.additionInfoList
          }
        }
        _this.form.id = data.id
        _this.form.pid = data.pid
        _this.form.type = data.type
        _this.form.code = data.code
        _this.form.cnName = data.cnName
        _this.form.level = data.level
        _this.form.isLeaf = data.isLeaf
        _this.form.sort = data.sort
        _this.form.remark = data.remark
        _this.form.chapterType = data.chapterType ? data.chapterType : ""
        _this.form.topicType = data.topicType ? data.topicType : ""
        _this.form.topicFilter = data.topicFilter ? data.topicFilter : ""
        _this.form.config = data.config ? data.config : ""
        if(this.uplopadVersion != ""){
          _this.form.treeModelBaseSyncVersion = this.uplopadVersion ? this.uplopadVersion : ""
        } else {
          _this.form.treeModelBaseSyncVersion = data.versionNumber ? data.versionNumber : ""
        }
        _this.form.structSyncVersion = this.structSyncVersion ? this.structSyncVersion: ""
        _this.form.additionInfoList = list
        if(_this.uploadStatue == true){
          _this.additionalUpdate();
        }
    },
    additionalUpdate() {
      var _this = this
      this.$loading.show();
      var params = new URLSearchParams()
      params.append('manualId', _this.manualId)
      params.append('manualVersion', _this.manualVersion)
      params.append('id', _this.form.id)
      params.append('pid', _this.form.pid)
      params.append('treeModelBaseSyncVersion', _this.form.treeModelBaseSyncVersion)
      params.append('structSyncVersion', _this.form.structSyncVersion)
      params.append('code', _this.form.code)
      params.append('cnName', _this.form.cnName)
      params.append('level', _this.form.level)
      params.append('isLeaf', _this.form.isLeaf)
      params.append('sort', _this.form.sort)
      if (_this.form.remark) {
        params.append('remark', _this.form.remark)
      }
      const map =new Map()
      _this.form.additionInfoList.forEach(function (item) {
        map.set(item.key, item.value);
      })
      let jsonStr =  JSON.stringify(Object.fromEntries(map))
      params.append('topicType', _this.form.topicType)
      params.append('chapterType', _this.form.chapterType)
      params.append('topicFilter', _this.form.topicFilter)
      params.append('config', _this.form.config)
      params.append('additionInfoList', jsonStr)
      catalogEdit(params).then(res => {
        if (res.data.code === 100) {
          handleAlert('success',res.data.msg)
          _this.dataList()
        } else {
          handleAlert('error',res.data.msg)
        }
        this.$loading.hide();
      })
    },
    // 主题类型，筛选属性 ，适用配置右键菜单
    contextmenuClick(scope, event, type) {
      this.cellsContent(scope, type)
      var allHeight = $(".el-container").height();
      var allWidth = $(".el-container").width();
      var menuHeight = $(".contextmenuContent").height();
      var menuWidth = $(".contextmenuContent").width();
      $(".contextmenuContent").show()
      $(".contextmenuArea").hide()
      if(event.clientY + menuHeight > allHeight) {
        $(".contextmenuContent").css('top', event.clientY - menuHeight)
      } else {
        $(".contextmenuContent").css('top', event.clientY)
      }
      if(event.clientX + menuWidth > allWidth) {
        $(".contextmenuContent").css('left', event.clientX - menuWidth)
      } else {
        $(".contextmenuContent").css('left', event.clientX)
      }
    },
    // 剪切
    shearClick() {
      var id = this.rowIndex + "_" + this.colIndex + "." + this.cellId + "." + this.cellType;
      $(".textShow." + id).html("");
      this.copyClick();
    },
    // 复制
    copyClick(){
      var input = document.createElement('input')
      input.value = this.copyVal
      document.body.appendChild(input)
      input.select()
      document.execCommand("copy")
      setTimeout(() =>{
        input.remove()
      },100)
    },
    // 粘贴
    pasteClick(){
      Notification.requestPermission().then(function(permission){
        if(permission == "granted"){
          this.pasteOperate();
        }else{
          alert("剪切板被禁用，请先开启浏览器的剪切板使用权限。")
        }
      })
    },
    // 点击单元格
    cellClick(scope, type) {
      var _this = this
      _this.cellsContent(scope, type)
    },
    // 单元格信息
    cellsContent(scope, type) {
      this.copyVal = "";
      this.cellId = scope.row.id;
      this.rowIndex = scope.$index;
      if(type == "topicType") {
        this.copyVal = scope.row.topicType ? scope.row.topicType : "";
        this.colIndex = 6;
        this.cellType = "topicType";
      }
      if(type == "topicFilter") {
        this.copyVal = scope.row.topicFilter ? scope.row.topicFilter : "";
        this.colIndex = 7;
        this.cellType = "topicFilter";
      }
      if(type == "config"){
        this.copyVal = scope.row.config ? scope.row.config : "" ;
        this.colIndex = 8;
        this.cellType = "config";
      }
    },
    // ctrl + c
    copyControl() {
      var _this = this
      _this.copyClick();
    },
    // ctrl + v
    pasteControls(){
      var _this = this;
      if(_this.copyVal !== ''){
        window.cellStatus = false;
        _this.pasteOperate();
        _this.$confirm('确定是否覆盖原有数据信息?', "复制提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.cellStatus = true;
          _this.getPasteResult(window.copyNode)
        }).catch((error)=>{
          window.cellStatus = false;
        })
      } else {
        window.cellStatus = true;
        _this.pasteOperate();
      }
    },
    // 粘贴操作
    pasteOperate() {
      var _this = this
      var items = (event.clipboardData || window.clipboardData).items;
      var len = items.length;
      let div = document.createElement('html')
      if(len == 1){
        var id = this.rowIndex + "_" + this.colIndex + "." + this.cellId + "." + this.cellType;
        items[i].getAsString(function(str) {
          $(".textShow." + id).html(str);
        })
      } else {
        for (let i = 0; i < len; i++) {
          if(items[i].type.indexOf("html") !== -1) {
            items[i].getAsString(function(str) {
              // 获取复制的值
              let body = document.createElement('body')
              body.innerHTML = str.replace("\u200c", "").replace("\u200b", "")
              div.appendChild(body)
              _this.getAnalysisHtml(div)
            })
          }
        }
      }
    },
    getAnalysisHtml(node) {
      var _this = this;
      let body = node.getElementsByTagName('body')[0]
      let newBody = document.createElement('body')
      let nodes = body.children
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].nodeName == "TABLE") {
          newBody.appendChild(nodes[i]);
          i--
        }
        if (newBody.childNodes.length > 0) {
          if(window.cellStatus == true){
            _this.getPasteResult(newBody)
          } else {
            window.copyNode = newBody
          }
        }
      }
    },
    getPasteResult(newNode) {
      var _this = this
      var pasteState = newNode.getElementsByTagName('table')[0]
      if(pasteState){
        let currentRow = _this.rowIndex; // 当前行数
        let currentCow = _this.colIndex; // 当前列数
        let copyRowNum = ""; //行数
        let copyColNum = ""; // 列数
        copyRowNum = newNode.getElementsByTagName('tr').length
        copyColNum = newNode.getElementsByTagName('tr')[0].getElementsByTagName('td').length
        // 行列复制
        var originaList = new Array
        var uploadList = new Array
        let originalData = []
        let uploadData = []
        setTimeout(() => {
          for (var row = 0; row < copyRowNum; row++) {
            for(var col = 0 ; col < copyColNum; col++) {
              var text = newNode.getElementsByTagName('tr')[row].getElementsByTagName('td')[col].innerHTML
              var rowCol = (currentRow + row) + "_" + (currentCow + col)
              if ($(".textShow." + rowCol).length > 0) {
                var oldText = $(".textShow." + rowCol).text();
                var id = $(".textShow." + rowCol).attr('class').split(" ")[2]
                var type = $(".textShow." + rowCol).attr('class').split(" ")[3]
                for (var i = 0; i < this.lastChildList.length; i++) {
                  _this.uploadStatue = false
                  if (this.lastChildList[i].id == id) {
                    originalData.push(JSON.stringify(this.lastChildList[i]))
                  }
                }
                for (var i = 0; i < this.lastChildList.length; i++) {
                  _this.uploadStatue = true
                  if (this.lastChildList[i].id == id) {
                    if(type == "topicType"){
                      this.lastChildList[i].topicType = text
                    }
                    if(type == "topicFilter") {
                      this.lastChildList[i].topicFilter = text
                    }
                    if(type == "config"){
                      this.lastChildList[i].config = text
                    }
                    uploadData.push(JSON.stringify(this.lastChildList[i]))
                    _this.uploadAssignment(this.lastChildList[i])
                  }
                }
              } else {
                if($(".noEdit." + rowCol).length > 0) {
                  return
                }
              }
            }
          }

        }, 10)
        originaList.push(originalData)
        uploadList.push(uploadData)
        _this.storageStack.push(originaList);
        _this.getStackDetail(uploadList);
      }
    },
    // 清空
    clearClick() {
      var id = this.rowIndex + "_" + this.colIndex + "." + this.cellId + "." + this.cellType;
      $(".textShow." + id).html("");
    },
    // 大纲管理右键菜单
    openMenu(row, event) {
      var allHeight = $(".el-container").height();
      var menuHeight = $(".contextmenuArea").height();
      $(".contextmenuArea").show()
      $(".contextmenuContent").hide()
      if(event.clientY + menuHeight > allHeight) {
        $(".contextmenuArea").css({'top': event.clientY - menuHeight, 'left': event.clientX})
      } else {
        $(".contextmenuArea").css({'top': event.clientY, 'left': event.clientX})
      }
      this.formAdditional = {
        dynamicItem: []
      }
      this.assignment(row)
    },
    assignment(data){
      var _this = this
      let list = []
      if (data.additionInfoList) {
        let obj = JSON.parse(data.additionInfoList)
        Object.keys(obj).forEach(key=>{
          let param = {
            key:key,
            value:obj[key]
          }
          list.push(param);
        })
      }
      _this.butType = ''
      _this.childrenList = data.children
      _this.form.id = data.id
      _this.form.pid = data.pid
      _this.form.type = data.type
      _this.form.code = data.code
      _this.form.cnName = data.cnName
      _this.catalogueLevel = data.level
      _this.form.isLeaf = data.isLeaf
      _this.form.sort = data.sort
      _this.form.remark = data.remark
      _this.form.chapterType = data.chapterType ? data.chapterType : ""
      _this.form.topicType = data.topicType ? data.topicType : ""
      _this.form.topicFilter = data.topicFilter ? data.topicFilter : ""
      _this.form.config = data.config ? data.config : ""
      _this.form.treeModelBaseSyncVersion = data.versionNumber ? data.versionNumber: ""
      _this.form.structSyncVersion = this.structSyncVersion ? this.structSyncVersion: ""
      _this.form.additionInfoList = list
      if (data.children != null) {
        _this.form.length = data.children.length
      }
    },
    // 新增
    formClear(){
      this.form= {
        id: 0,
        pid: 0,
        type: '',
        code: '',
        cnName: '',
        isLeaf: '',
        sort: 1,
        remark: '',
      }
      this.$nextTick(function() {
        this.$refs.form.clearValidate();
      })
    },
    addNodeClick(){
      this.dialogStatus = "add";
      this.dialogFormVisible = true;
      this.butType='addNodeBut';
      this.catalogueLevel = this.catalogueLevel + 1
      let sotr = this.form.length;
      let chapterTypeTemp = this.form.chapterType
      let pid = this.form.pid
      let id = this.form.id
      this.formClear()
      if(pid == '0'){
        this.form.pid = this.manualTypeId
        this.form.chapterType = ''
      } else {
        this.form.pid = id
        this.form.chapterType = chapterTypeTemp
      }
      this.form.additionInfoList =[];
      this.form.sort = sotr + 1;
    },
    // 新增确定
    nodeConfirm(form){
      if(!this.form.pid || this.form.pid <= 0){
        handleAlert('error', "操作频繁，请稍后再试")
        return;
      }
      this.$nextTick(function() {
        this.$refs[form].validate((valid) => {
          if (valid) {
            const map = new Map()
            this.form.additionInfoList.forEach(function(item) {
              map.set(item.key, item.value);
            })
            let jsonStr = JSON.stringify(Object.fromEntries(map));
            this.$loading.show();
            var params = new URLSearchParams()
            params.append('pid', this.form.pid)
            params.append('manualId', this.manualId)
            params.append('manualVersion', this.manualVersion)
            params.append('code', this.form.code)
            params.append('cnName', this.form.cnName)
            params.append('level', this.catalogueLevel)
            params.append('isLeaf', this.form.isLeaf)
            params.append('chapterType', this.form.chapterType ? this.form.chapterType : "")
            params.append('topicType', this.form.topicType ? this.form.topicType : "")
            params.append('topicFilter', this.form.topicFilter ? this.form.topicFilter : "")
            params.append('config', this.form.config ? this.form.config : "")
            params.append('additionInfoList', jsonStr)
            params.append('structSyncVersion', this.structSyncVersion)
            params.append('sort', this.form.sort)
            params.append('remark', this.form.remark)
            catalogAdd(params).then(res => {
              if (res.data.code === 100) {
                handleAlert('success',res.data.msg)
                this.dataList()
                // this.nodeKeyList.push(this.form.pid)
                this.butType=''
                // this.editTitle = "当前目录节点信息"
                this.formClear()
                this.dialogFormVisible = false;
              } else {
                handleAlert('error',res.data.msg)
              }
              this.$loading.hide();
            })
          }else{
            handleAlert('error','请完善信息');
            this.$loading.hide();
          }
        })
      })
    },
    // 新增取消
    resetForm(){
      this.formClear()
      this.dialogFormVisible = false;
    },
    // 编辑
    editNodeClick() {
      this.dialogStatus = "edit";
      this.dialogFormVisible = true;
    },
    updateNode() {
      this.$loading.show();
      var params = new URLSearchParams()
      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)
      params.append('id', this.form.id)
      params.append('pid', this.form.pid)
      params.append('treeModelBaseSyncVersion', this.form.treeModelBaseSyncVersion)
      params.append('structSyncVersion', this.form.structSyncVersion)
      params.append('code', this.form.code)
      params.append('cnName', this.form.cnName)
      params.append('level', this.catalogueLevel)
      params.append('isLeaf', this.form.isLeaf)
      params.append('sort', this.form.sort)
      if (this.form.remark) {
        params.append('remark', this.form.remark)
      }
      const map =new Map()
      this.form.additionInfoList.forEach(function (item) {
        map.set(item.key, item.value);
      })
      let jsonStr =  JSON.stringify(Object.fromEntries(map))
      params.append('topicType', this.form.topicType)
      params.append('chapterType', this.form.chapterType)
      params.append('topicFilter', this.form.topicFilter)
      params.append('config', this.form.config)
      params.append('additionInfoList', jsonStr)
      catalogEdit(params).then(res => {
        if (res.data.code === 100) {
          handleAlert('success',res.data.msg)
          this.dataList()
          this.dialogFormVisible = false;
        } else {
          handleAlert('error',res.data.msg)
        }
        this.$loading.hide();
      })
    },
    // 重命名
    renameClick() {
      this.dialogStatus = "rename";
      this.dialogFormVisible = true;
    },
    renameConfirm() {
      this.$loading.show();
      var params = new URLSearchParams()
      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)
      params.append('treeModelBaseSyncVersion', this.form.treeModelBaseSyncVersion)
      params.append('structSyncVersion', this.form.structSyncVersion)
      params.append('id', this.form.id)
      params.append('pid', this.form.pid)
      params.append('code', this.form.code)
      params.append('cnName', this.form.cnName)
      params.append('level', this.catalogueLevel)
      params.append('isLeaf', this.form.isLeaf)
      params.append('sort', this.form.sort)
      if (this.form.remark) {
        params.append('remark', this.form.remark)
      }
      const map =new Map()
      this.form.additionInfoList.forEach(function (item) {
        map.set(item.key, item.value);
      })
      let jsonStr =  JSON.stringify(Object.fromEntries(map))
      params.append('topicType', this.form.topicType)
      params.append('chapterType', this.form.chapterType)
      params.append('topicFilter', this.form.topicFilter)
      params.append('config', this.form.config)
      params.append('additionInfoList', jsonStr)
      catalogEdit(params).then(res => {
        if (res.data.code === 100) {
          handleAlert('success',res.data.msg)
          this.dataList()
          this.dialogFormVisible = false;
          // this.nodeKeyList.push(this.form.id)
        } else {
          handleAlert('error',res.data.msg)
        }
        this.$loading.hide();
      })
    },
    // 删除
    delNode () {
      let curId = this.form.id
      let nodeName = this.form.cnName
      if(this.form.pid == 0) {
        handleAlert('error','目录根节点无法删除')
        return
      }
      if(this.childrenList.length > 0){
        handleAlert('error','有子节点无法删除')
        return false;
      }
      if(curId==0){
        handleAlert('error','请选中需要删除的目录节点')
        return false;
      }
      this.$confirm('确定删除【'+ nodeName+ '】的目录节点信息?', '删除目录节点', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        catalogDel(curId).then(res => {
          if(res.data.code==100){
            handleAlert('success','删除成功')
            this.dataList()
            this.nodeKeyList.push(this.form.pid)
          }else{
            handleAlert('error',res.data.msg)
          }
        })
      }).catch((error)=>{})
    },
    // 导入主机厂默认目录
    importCatalogClick(){
      this.$confirm('确定要导入主机厂默认的目录信息?', '导入主机厂默认目录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var params = new URLSearchParams()
        params.append('manualId', this.manualId)
        params.append('manualVersion', this.manualVersion)
        catalogFirmUpload(params).then(res => {
          if(res.data.code==100){
            this.dataList()
          }else{
            handleAlert('error',res.data.msg)
          }
        })
      }).catch((error)=>{})
    },
    //导入EXCEl
    // 文件上传之前
    onBeforeUpload(file){
      // 获取文件后缀
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();
      // 文件后缀
      const docExt = fileExt === 'xls'
      const docxExt = fileExt === 'xlsx'
      // 文件大小不能超过1G
      const isLimit = file.size / 1024 / 1024 < 1024
      if(!docExt && !docxExt) {
        handleAlert('warning',"上传的文件只能是 xls、xlsx格式!")
        return false;
      }
      if (!isLimit) {
        handleAlert('warning',"上传文件大小不能超过 1GB!")
        return false;
      }
    },
    initialState(){
      this.percentage = 0
      this.showUploadProcess= false
    },
    // 批量上传
    uploadFile (file) {
      var _this = this
      _this.initialState()
      var formData = new FormData();
      formData.append('manualId', _this.manualId);
      formData.append('manualVersion', _this.manualVersion);
      formData.append('file', file.file);
      const uploadProgress = progressEvent => {
        _this.showUploadProcess = true
        _this.percentage = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      }
      catalogUpload(formData, uploadProgress).then(res => {
        if(res.data.code == 100){
          handleAlert('success','上传已完成')
          _this.dataList()
          _this.initialState()
        }else{
          handleAlert('error','导入失败')
          _this.initialState()
        }
      }).catch(function(error){
        handleAlert('error','上传出现异常，稍后重试')
        _this.initialState()
      })
    },
    batchImport(fileId){
      var params = {
        manualId: this.manualId,
        fileId: fileId
      }
      catalogImport(params).then(res => {
        if(res.data.code==100){
          handleAlert('success','上传已完成,系统正在解析处理数据，请稍等...')
          this.dataList()
        }
      })
    },
    // 导出excel
    exportExcelClick(){
      var params = new URLSearchParams()
      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)
      catalogexportExcel(params).then(res => {
        if (!res.data) {
          return
        }
        let name = decodeURI(res.headers["content-type"].split("charset=")[1]);
        var blob = new Blob([res.data]);
        var url = window.URL.createObjectURL(blob);
        var aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = url;
        aLink.setAttribute("download", name);
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink); //下载完成移除元素
        window.URL.revokeObjectURL(url); //释放掉blob对象
      })
    },
    // 导入数据包
    importPackageClick(){
      this.dialogImportPackageVisible = true;
      this.fileList=[];
    },
    // 文件上传之前
    onBeforeUploadPackage(file){
      let text=""
      // 获取文件后缀
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();
      // 文件后缀是否是 zip
      const zipExt = fileExt === 'zip'
      // 文件大小不能超过1G
      const isLimit = file.size / 1024 / 1024 < 2048
      if(!zipExt) {
        text="上传文件只能是 zip格式!";
        this.$message.error(text)
        return false;
      }
      if (!isLimit) {
        text="上传文件大小不能超过 2GB!";
        this.$message.error(text)
        return false;
      }
      this.flagType = 'manual/' + this.manualId
      this.fileShardSize = 1*1024 * 1024; //每片文件大小
      this.showUploadProcess=false;
      //点击后隐藏上传按钮 ，防止重复点击
      this.showUploadBtn=false
      this.showUploadProcess=true
      this.percentage=1
      var _this=this
      getmd5(file,_this.fileShardSize).then(e =>{
        _this.switchC=false;
        _this.fileShardIndex=1;//分片索引
        _this.curFile=file;
        _this.fileKey=e;
        _this.fileSize=file.size;
        _this.fileShardTotal=Math.ceil(file.size/_this.fileShardSize);//分片总数
        var fileFullName=file.name;
        _this.fileSuffix = fileFullName.substr(fileFullName.lastIndexOf('.') + 1);
        _this.fileName = fileFullName.substr(0, fileFullName.lastIndexOf('.'));
        //上传参数
        var params =  new FormData()
        params.append('fileName', _this.fileName)
        params.append('fileShardTotal', _this.fileShardTotal)
        params.append('fileKey', _this.fileKey)
        params.append('fileSuffix', _this.fileSuffix)
        params.append('fileShardSize', _this.fileShardSize)
        params.append('fileSize', _this.fileSize)
        params.append('fileFlag', _this.flagType)
        _this.updateProgress(file,params)
      })
    },
    // 批量上传
    uploadPackage (formData) {
      var _this=this
      // 上传
      procSplitFile(formData).then(res => {
        if(res.data.code==200){
          //上传分片完成
          if(res.data.shardIndex<_this.fileShardTotal){
            _this.fileShardIndex=_this.fileShardIndex+1;
            _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
            _this.end = Math.min(_this.curFile.size,_this.start+_this.fileShardSize);
            _this.fileSize=_this.curFile.size;
            var params =  new FormData()
            params.append('fileName', _this.fileName)
            params.append('fileShardTotal', _this.fileShardTotal)
            params.append('fileKey', _this.fileKey)
            params.append('fileSuffix', _this.fileSuffix)
            params.append('fileShardSize', _this.fileShardSize)
            params.append('fileSize', _this.fileSize)
            params.append('fileFlag', _this.flagType)
            params.append('fileShardIndex', _this.fileShardIndex)
            var fileShardtem=_this.curFile.slice(_this.start,_this.end);//从文件中获取当前分片数据
            let fileReader = new FileReader();
            //异步读取本地文件分片数据并转化为base64字符串
            fileReader.readAsDataURL(fileShardtem);
            //本地异步读取成功后，进行上传
            fileReader.onload = function (e) {
              let  base64str = e.target.result;
              params.append('base64', base64str)
              _this.uploadPackage(params)
            }
            let perentNum=Math.ceil(this.fileShardIndex * 100 / this.fileShardTotal)
            if(perentNum>100){
              this.percentage=100
            }else{
              this.percentage=perentNum
            }
          }
        }else if(res.data.code==100){
          var fileId= res.data.id
          //上传完成
          _this.percentage=100
          _this.switchC=true

          _this.finishUpload(fileId)
        }
      }).catch((error)=>{
        if(error.response){
        console.log(error.response.data)
        }else{
          console.log(error.message)
        }
      })
    },
    updateProgress(file,params){
      var _this= this
      var param = new URLSearchParams()
      param.append('shardKey', _this.fileKey)
      // 批量上传
      checkUploadProgress(param).then(res => {
        if(res.data.code==200){
          //新文件
          _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
          _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
          _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        }else if(res.data.code==220){
          _this.fileShardIndex=res.data.ShardIndex;
          //有上传未完成的
          _this.start=(_this.fileShardIndex-1)*_this.fileShardSize;
          _this.end =Math.min(file.size,_this.start+_this.fileShardSize);
          _this.fileShard=file.slice(_this.start,_this.end);//从文件中获取当前分片数据
        }else if (res.data.code==240){
          //急速上传
          var fileId= res.data.id
          _this.percentage=100
          _this.switchC=true
          _this.finishUpload(fileId)
          return false;
        }
        //读取base64str
        let fileReader = new FileReader();
        //异步读取本地文件分片并转化为base64字符串
        fileReader.readAsDataURL(_this.fileShard);
        //本地异步读取成功，进行上传
        fileReader.onload=function (e){
          let  base64str=e.target.result;
          params.append('base64', base64str)
          params.append('fileShardIndex', _this.fileShardIndex)
          if(_this.switchC==false){
            _this.uploadPackage(params)
          }
        }
      }).catch((error)=>{
        this.$message.error('上传错误')
      })
    },
    finishUpload(fileId){
      var _this=this
      //进行保存提醒
      _this.uploadMsg = _this.$message({
        duration:0,
        message: "请稍等，正在保存...",
        type: "warning"
      });
      var param = new URLSearchParams()
      param.append('fileId', fileId)
      catalogimportPackage(param).then(res => {
        if(res.data.code==100){
          let uploadFileName= res.data.data.fileName
          let uploadFilePath= res.data.data.filePath
          _this.fileList = []
          if(uploadFileName != null && uploadFileName.length > 0){
            var fileObj = { name: uploadFileName , filePath:uploadFilePath}
            _this.fileList.push(fileObj)
          }
          //关闭消息提醒
          _this.uploadMsg.close()
          //上传完成提示
          _this.$message({
            duration:2000,
            message: '上传已完成',
            type: 'success'
          })
          _this.showUploadProcess=false
          _this.showUploadBtn=true
        }
      })
    },
    handleBeforeRemove(){
      if(this.fileList!=null&&this.fileList.length>0){
        var filePath= this.fileList[0].filePath;
        var _this=this
        this.$confirm('确认删除文件？', '删除',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var param = new URLSearchParams()
          param.append('filePath', filePath)
          catalogdelPackage(param).then(res => {
            _this.fileList=[];
            return true;
          })
        }).catch((error)=>{
          return false;
        })
      }
      return false;
    },
    handleRemove(){
      this.fileList = [];
    },
    // 提交导入数据包
    importPackage(){
      if (this.fileList.length<=0) {
        text="文件上传失败!";
        this.$message.error(text)
        return false;
      }
      this.$loading.show();
      var params =  new FormData()
      params.append('filePath', this.fileList[0].filePath)
      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)
      catalogparsePackage(params).then(res =>{
        if (res.data.code == 100) {
          this.dialogImportPackageVisible = false;
          this.dataList()
        }else{
          this.$message.error(res.data.msg)
        }
        this.$loading.hide();
      })
    },
    resetImport(){
      this.dialogImportPackageVisible = false;
    },
    // 导出数据包
    exportPackageClick(){
      this.$loading.show();
      var params =  new FormData()
      params.append('manualId', this.manualId)
      params.append('manualVersion', this.manualVersion)
      catalogdowPackage(params).then(res => {
        this.$message.closeAll();
        if(!res.data){
          return
        }
        let name = decodeURI(res.headers["content-type"].split("charset=")[1]);
        if(!name || name == 'undefined'){
          handleAlert('error', '暂无数据，不能导出');
          return
        }
        var blob = new Blob([res.data]);
        var url = window.URL.createObjectURL(blob);
        var aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = url;
        aLink.setAttribute("download", name);
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink); //下载完成移除元素
        window.URL.revokeObjectURL(url); //释放掉blob对象
        this.$loading.hide();
      })
    },
    // 添加附加信息
    additionalClick(row){
      // 打开弹窗
      let _this = this
      this.assignment(row)
      setTimeout(() => {
        _this.formAdditional.dynamicItem = []
        _this.form.additionInfoList.forEach(function (item) {
          _this.formAdditional.dynamicItem.push(item)
        })
        _this.dialogAdditionalVisible = true
        if(_this.formAdditional.dynamicItem.length <= 0){
          _this.addDomain()
        }
      })
    },
    addDomain () {
      this.formAdditional.dynamicItem.push({
        key: '',
        value: ''
      })
    },
    deleteItem (item, index) {
      this.formAdditional.dynamicItem.splice(index, 1)
    },
    confirmAdditional(){
      // 遍历获取所有有效的键值对
      let list = []
      let len = this.formAdditional.dynamicItem.length;
      let b = false;
      for (let i = 0; i < len; i++) {
        let item = this.formAdditional.dynamicItem[i];
        if (item.key.length>0 && item.value.length>0) {
          let param = {
            key: item.key,
            value: item.value
          }
          list.push(param)
        }else{
          b = true;
        }
      }
      if (b) {
        handleAlert('warning',"提交时不会保存空属性")
      }
      this.form.additionInfoList = list
      this.updateNode();
      this.dialogAdditionalVisible = false
    },
    maximumArea() {
      setTimeout(() => {
        tableHeight()
        setTimeout(() => {
          this.maximumHeight = tableMaxHeight;
        }, 50)
      }, 50)
    },
    tableHeightArea() {
      var _this = this;
      _this.maximumArea();
      window.addEventListener("resize", function () {
        _this.maximumArea();
      });
    },
    initialDisplay() {
      this.dataList()
      // this.versionStatus();
      // this.getcopyData()
      this.getChapterTypeList()
      this.getTopicTypeList()
    },
  },
  mounted() {
    this.manualId = this.$route.params.id
    this.manualVersion = this.$route.params.manualVersion
    this.initialDisplay();
    window.addEventListener("copy", this.copyControl);
    window.addEventListener("paste", this.pasteControls);
    document.oncontextmenu = function (e) {
      return false
    }
    document.onclick = function (e) {
      $(".contextmenuArea").hide()
      $(".contextmenuContent").hide()
    }
    document.onmousewheel = function(e) {
      $(".contextmenuArea").hide()
      $(".contextmenuContent").hide()
    }
    window.addEventListener('keydown', this.keyDown)
    window.cellStatus = false;
    document.addEventListener('copy', (event) => {
    })
    document.addEventListener('paste', (event) => {
      // console.log(event)
      // console.log(event.clipboardData || window.clipboardData)
    })
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
}
</script>
<style>
  .manualCatalog .synopsisText{
    display: inline-block;
    cursor: pointer;
    flex: 1;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .manualCatalog  .catalogTable tr td:nth-child(2) .cell {
    display: flex;
    align-items: center;
  }
  .manualCatalog .tableHandle .el-button.is-disabled {
    filter: grayscale(100%) brightness(0);
    opacity: 0.3;
  }
  .manualCatalog .contextmenuArea,
  .manualCatalog .contextmenuContent {
    position: absolute;
    /* top: 0; */
    display: none;
    background: #fff;
    border-radius: 3px;
    margin: 2px 0;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    z-index: 10;
  }
  .manualCatalog .contextmenuArea > div,
  .manualCatalog .contextmenuContent > div {
    padding: 5px 15px;
    margin: 3px 0;
    cursor: pointer;
    font-size: 14px;
  }
  .manualCatalog .contextmenuArea > div i,
  .manualCatalog .contextmenuContent > div i {
    width: 20px;
    margin: 0 3px;
    color: #000;
    filter: grayscale(100%) brightness(0);
  }
  .manualCatalog .contextmenuArea > div:hover,
  .manualCatalog .contextmenuContent > div:hover {
    background: var(--hover-color);
    color: var(--theme-color);
    filter: grayscale(0%) brightness(1);
  }
  .manualCatalog .contextmenuArea > div:hover i,
  .manualCatalog .contextmenuContent > div:hover i {
    color: var(--theme-color);
    filter: grayscale(0%) brightness(1);
  }
  .manualCatalog .contextmenuContent .el-divider--horizontal {
    padding: 0;
  }
  .manualCatalog .contextmenuArea > div:last-child:hover{
    color: #F56C6C
  }
  .manualCatalog .contextmenuArea > div:last-child:hover i {
    color: #F56C6C;
    filter: grayscale(0%);
  }
  .manualCatalog .el-table__expand-icon .el-icon-arrow-right::before,
  .manualCatalog .el-table__expand-icon .el-icon-arrow-right::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url("../../../assets/image/Icon/expandIcon.png") no-repeat center;
    background-size: 100% 100%;
    margin-right: 3px;
    vertical-align: middle;
  }
  .manualCatalog .el-table__expand-icon .el-icon-arrow-right::after {
    width: 15px;
    height: 15px;
    background: url("../../../assets/image/Icon/treeFold.png") no-repeat center;
    background-size: 100% 100%;
    margin-right: 0px;
    /* vertical-align: middle; */
  }
  .manualCatalog .el-table__expand-icon--expanded {
    transform: none !important;
  }
  .manualCatalog .el-table__expand-icon.el-table__expand-icon--expanded .el-icon-arrow-right::before,
  .manualCatalog .el-table__expand-icon.el-table__expand-icon--expanded .el-icon-arrow-right::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url("../../../assets/image/Icon/collapseIcon.png") no-repeat center;
    background-size: 100% 100%;
    margin-right: 3px;
    vertical-align: middle;
  }
  .manualCatalog .el-table__expand-icon.el-table__expand-icon--expanded .el-icon-arrow-right::after {
    width: 15px;
    height: 15px;
    background: url("../../../assets/image/Icon/treeOpen.png") no-repeat center;
    background-size: 100% 100%;
    margin-right: 0px;
  }
  .manualCatalog  .el-table__placeholder {
    margin-left: 20px;
  }
  .manualCatalog .el-table__placeholder::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url("../../../assets/image/Icon/chapterIcon.png") no-repeat center;
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -3px;
  }
  .manualCatalog .el-table .el-table__cell {
    padding: 0;
  }
  .manualCatalog .el-table .cell {
    height: 30px;
    line-height: 30px;
  }
  .manualCatalog .rowEditShow  {
    height: 100%;
    width: calc(100% + 10px);
    margin-left: -5px;
    cursor: pointer;
  }
  .manualCatalog .rowEditShow > div {
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .manualCatalog .rowEditShow .textShow {
    padding: 0 5px;
  }
  .manualCatalog .rowEditShow .el-input .el-input__inner{
    border-radius: 0;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    border: 1px solid transparent;
  }
  @media screen and (max-width: 1366px) and (min-width: 1280px) {
    .excelContainer .el-table .cell,
    .excelContainer .rowEditShow .el-input .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
  @media screen and (max-width: 1280px) and (min-width: 1024px) {
    .excelContainer .el-table .cell,
    .excelContainer .rowEditShow .el-input .el-input__inner {
      height: 26px;
      line-height: 26px;
    }
  }
  @media screen and (max-width: 1024px) {
    .excelContainer .el-table .cell,
    .excelContainer .rowEditShow .el-input .el-input__inner {
      height: 25px;
      line-height: 25px;
    }
  }
</style>
