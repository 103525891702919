
import addBulletin from '@/views/cmsmgt/bulletin/addBulletin.vue'
import bulletinDetails from '@/views/cmsmgt/bulletin/bulletinDetails.vue'

import version from '@/views/cmsmgt/manual/version.vue'
import manualCatalog from '@/views/cmsmgt/manual/manualCatalog.vue'
import manualContent from '@/views/cmsmgt/manual/manualContent.vue'
import manualImage from '@/views/cmsmgt/manual/manualImage.vue'
import audit from '@/views/cmsmgt/audit/audit.vue'
import auditDetail from '@/views/cmsmgt/audit/detail.vue'
import manageCatalog from '@/views/cmsmgt/syllabus/manageCatalog.vue'

const cmsmgtRouter = [

  {
    path: '/addBulletin/:id',
    name: 'addBulletin',
    component: addBulletin
  },
  {
    path: '/bulletinDetails/:id',
    name: 'bulletinDetails',
    component: bulletinDetails
  },

  {
    path: '/cmsmgt/bulletin/list',
    component: () => import('@/views/cmsmgt/bulletin/list'),
    name: 'cmsmgtbulletinlist'
  },

  // cms
  {
    path: '/version/:id',
    name: 'version',
    component: version
  },
  {
    path: '/manualCatalog/:id/:manualVersion',
    name: 'manualCatalog',
    component: manualCatalog
  },
  {
    path: '/manualContent/:id/:manualVersion',
    name: 'manualContent',
    component: manualContent
  },
  {
    path: '/manualImage/:id/:manualVersion',
    name: 'manualImage',
    component: manualImage
  },
  {
    path: '/audit/:id/:version',
    name: 'audit',
    component: audit
  },
  {
    path: '/auditDetail/:id/:version',
    name: 'auditDetail',
    component: auditDetail
  },
  {
    path: '/cmsmgt/syllabus/list',
    component: () => import('@/views/cmsmgt/syllabus/list.vue'),
    name: 'cmsmgtsyllabuslist'
  },
  {
    path: "/manageCatalog",
    component: manageCatalog,
    name: "manageCatalog",
  },
  {
    path: '/cmsmgt/project/list',
    component: () => import('@/views/cmsmgt/project/list'),
    name: 'cmsmgtprojectlist'
  },
  {
    path: '/cmsmgt/manual/list',
    component: () => import('@/views/cmsmgt/manual/list'),
    name: 'cmsmgtmanuallist'
  },
  {
    path: '/cmsmgt/task/list',
    component: () => import('@/views/cmsmgt/task/list'),
    name: 'cmsmgttasklist'
  },
  {
    path: '/cmsmgt/audit/list',
    component: () => import('@/views/cmsmgt/audit/list'),
    name: 'cmsmgtauditlist'
  },

]
export default cmsmgtRouter;
