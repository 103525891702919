var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layoutContainer auditContent"},[_c('div',{staticClass:"tableDetail"},[_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"border":"","stripe":"","highlight-current-row":"","max-height":_vm.maximumHeight,"data":_vm.resultList,"header-cell-style":{
        'text-align':'center',
        'background-color': 'var(--other-color)',
      }},on:{"header-dragend":_vm.changeColWidth}},[_c('el-table-column',{attrs:{"label":"Topic名称","prop":"directoryName","min-width":"150"}}),_c('el-table-column',{attrs:{"label":"审核状态","prop":"status","min-width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [(row.status === 0)?_c('span',[_vm._v("未审核")]):_vm._e(),(row.status === 1)?_c('span',[_vm._v("审核通过")]):_vm._e(),(row.status === 2)?_c('span',[_vm._v("审核驳回")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"驳回原因","prop":"reason","min-width":"150"}}),_c('el-table-column',{attrs:{"label":"审核人","prop":"auditorName","width":"150","align":"center"}}),_c('el-table-column',{attrs:{"label":"审核时间","prop":"createdTime","align":"center","width":"160","formatter":_vm.dateFormat}}),_c('el-table-column',{attrs:{"label":"操作","prop":"auditorName","fixed":"right","width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [(_vm.hasPerm('menuAsimssCMS3A3B_115'))?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.historyAudit(row)}}},[_vm._v("历史审核")]):_vm._e()]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total>0),expression:"total>0"}],attrs:{"total":_vm.total,"page":_vm.currentPage,"limit":_vm.pagesize},on:{"update:page":function($event){_vm.currentPage=$event},"update:limit":function($event){_vm.pagesize=$event},"pagination":_vm.dataList}}),_c('el-dialog',{attrs:{"width":"790px !important","title":"历史审核","visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"border":"","stripe":"","highlight-current-row":"","data":_vm.historyList,"header-cell-style":{
          'text-align': 'center',
          'background-color': 'var(--other-color)',
        }},on:{"header-dragend":_vm.changeColWidth}},[_c('el-table-column',{attrs:{"label":"审核状态","prop":"status","width":"110","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [(row.status === 0)?_c('span',[_vm._v("未审核")]):_vm._e(),(row.status === 1)?_c('span',[_vm._v("审核通过")]):_vm._e(),(row.status === 2)?_c('span',[_vm._v("审核驳回")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"驳回原因","prop":"reason","min-width":"350"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [(row.status === 2)?_c('span',[_vm._v(_vm._s(row.reason))]):_vm._e(),(row.status === 1)?_c('span',[_vm._v("无")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"审核人","prop":"auditorName","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"label":"审核时间","align":"center","prop":"createdTime","width":"160","formatter":_vm.dateFormat}})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.audittotal>0),expression:"audittotal>0"}],attrs:{"total":_vm.audittotal,"page":_vm.auditcurrentPage,"limit":_vm.auditpagesize},on:{"update:page":function($event){_vm.auditcurrentPage=$event},"update:limit":function($event){_vm.auditpagesize=$event},"pagination":_vm.dataList}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }