<template>
  <div class="layoutContainer addNoticeContent">
    <el-form :model="temp" ref="temp" :label-width="formLabelWidth" label-position="center" :rules="rules" >
      <el-row>
        <el-col :span="20">
          <el-form-item label="标题" prop="title">
            <el-input v-model.trim="temp.title" placeholder="请输入公告标题" show-word-limit maxlength="100"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="20" :sm="15" :md="10" :lg="7" :xl="5">
          <el-form-item label="状态" prop="status">
            <el-select v-model="temp.status" clearable filterable>
              <el-option v-for="(item, index) of statusList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="20" :sm="15" :md="10" :lg="7" :xl="5">
          <el-form-item label="类型" prop="tpye">
            <el-select v-model="temp.type" clearable filterable>
              <el-option v-for="(item, index) of typeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="20" :sm="15" :md="10" :lg="7" :xl="5">
          <el-form-item label="是否置顶" prop="isTop">
            <el-select v-model="temp.isTop" clearable filterable>
              <el-option v-for="(item, index) of topRecmd" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="适用对象" prop="target">
            <template slot="label">
              <span>适用对象
                <el-tooltip class="item" effect="dark" placement="right">
                  <i class="el-icon-question" style="font-size: 16px; vertical-align: middle;"></i>
                  <div slot="content">
                    <p>不选择视为适用于国家下的所有服务店</p>
                  </div>
                </el-tooltip>
              </span>
            </template>
            <el-button type="primary" @click="suitTarget()">{{ targetName }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-form-item label="内容" prop="content">
            <TinymceEditor ref="noticeEditor" :imgUploadUrl="uploadUrl" v-model="temp.content"/>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="submitArea">
        <el-button type="primary" @click="addClick('temp')">保存</el-button>
        <el-button v-if="this.noticeId == 'add'" @click="resetForm('temp')">重置</el-button>
      </div>
    </el-form>

      <el-dialog v-dialogDrag lock-scroll width="995px !important" class="assignCountryArea" title="适用对象" :visible.sync="dialogeditTargetVisible" v-if="dialogeditTargetVisible" :close-on-click-modal="false">
        <div class="secondFloat">
            <el-form :inline="true" ref="countryData" :model="countryInfo" class="demo-form-inline">
              <el-form-item label="国家" prop="country">
                <el-select v-model="countryInfo.country" filterable clearable>
                    <el-option v-for="(item, index) of continentList" :key="index" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="类型" prop="type">
                <el-select v-model="countryInfo.type" filterable clearable>
                    <el-option v-for="(item, index) of userTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账户" prop="username">
                <el-input v-model.trim="countryInfo.username" placeholder="请输入账户"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button type="primary" @click="searchApply" icon="el-icon-search">搜索</el-button>
                <el-button plain @click="resetApply()">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        <!-- 适用对象 -->
          <el-row>
            <el-col :span="17" class="nationalInfo">
              <el-table
                :row-key="selRowKey"
                ref="applytable"
                style="width:100%;"
                max-height="550px"
                border
                stripe
                highlight-current-row
                :data="applyCountrylLists"
                :header-cell-style="{
                  'text-align': 'center',
                  'background-color': 'var(--other-color)',
                }"
                @selection-change="handleSelectionApply"
              >
                <el-table-column type="selection" width="40" fixed="left" align="center" reserve-selection></el-table-column>
                <el-table-column label="序号" type="index" width="60"  align="center"></el-table-column>
                <el-table-column label="类型" prop="userTypeName" min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="国家" prop="defaultCountryName" min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="账户" prop="username" min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="名称" prop="realName" min-width="100" show-overflow-tooltip></el-table-column>
              </el-table>
              <pagination v-show="totalApply>0" :total="totalApply" :page.sync="currentPageApply" :limit.sync="pagesizeApply" @pagination="selectCountry"/>
            </el-col>

            <el-col :span="7" class="nationalSelect">
              <el-table
                style="width:100%"
                :height="accreditTableHeight"
                border
                highlight-current-row
                :data="determineModelList"
                :header-cell-style="{
                  'background-color': '#fafafa',
                }"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellLeaveEnter"
              >
                <el-table-column prop="title">
                  <template slot="header">
                    <div class="authorityTitle">
                      <div>
                        <span>已选(<b> {{ selectNum }} </b>)</span>
                      </div>
                      <div>
                        <span class="warrantAction" @click="authorizeAll">选择全部</span>
                        <span class="clearAction" @click="emptyCountry">清空</span>
                      </div>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div :class="'nationalList ' + '_'+scope.row.id">
                      <span>{{ scope.row.realName + '('+scope.row.username+')' }}</span>
                      <i class="el-icon-close" @click="deleteEmpower(scope.row)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

            </el-col>
          </el-row>
          <el-button type="primary" style="display: block;margin: 15px auto 10px;"  @click="getCheckedKeys">确定</el-button>
      </el-dialog>

  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, addTabs, removeTabs, handleAlert } from '@/assets/js/common.js'
import TinymceEditor from  "@/components/TinymceEditor/TinymceEditor.vue"
import {  noticeTypeList, noticeAdd, noticeEdit, userCountryData, noticeInfo, noticTarget, noticEmpower } from '@/api/sysmgt.js'
import Pagination from '@/components/Pagination'
import axios from 'axios'
export default {
  name: 'sysmgtnoticeaddNotice',
  components: { TinymceEditor, Pagination },
  data () {
    return {
      init: {
        // 组件
        plugins: ' lists image colorpicker textcolor wordcount contextmenu autoresize',
        // 工具栏
        toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent  | undo redo | link unlink image  | removeformat ',
        branding: false,
        max_width: 800,
        min_height: 300
      },
      temp: {
        id: '',
        title: '',
        content: '',
        status: 1,
        type: 'notice',
        isTop: 0,
        isRecmd: 0,
        country: ['cn'],
        targetIdList: [],
      },
      userCountryList: [],
      statusList: [
        { name: '草稿', code: 1 },
        { name: '发布', code: 2 },
        // { name: '关闭', code: 3 }
      ],
      topRecmd: [
        {name: '是', code: true},
        {name: '否', code: false},
      ],
      formLabelWidth: '100px',
      uploadUrl: '', // 文件上传地址
      rules: {
        title: [{ required: true, message: '公告标题不能为空', tigger: ['blur', 'change'] }]
      },
      typeList: [],
      noticeId: "",
      targetName: '未选择',

      filterText: '',
      // 可选的
      targetList: [],
      // 已选的
      checkedKeys: [],
      checkedTarget: [],
      dialogeditTargetVisible: false,
      defaultTargetProps:{
        children: 'children',
        label: 'name'
      },

      // === 适用对象 ===
      totalApply: 0,
      currentPageApply: 1,
      pagesizeApply: 10,
      // 选中的对象
      determineModelList:[],
      // 选中的数量
      selectNum: 0,
      // 列表中的
      applyCountrylLists: [],
      // 所有的
      applyCountrylList:[],

      accreditTableHeight: 0,
      continentList: [],

      countryInfo: {
        country: '',
        username: '',
        type: ''
      },
      userTypeList: [
        {name: '系统用户', code: 1},
        {name: '服务店', code: 2},
      ]


    }
  },
  watch: {
    // 利用watch方法检测路由变化
    $route(to) {
      if (to.name == "addNotice") {
        this.getTypeList()
        this.getTarget()
        // this.getUserCountryList()
        this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=sysNotice'
        this.noticeId = to.params.id;
        if (this.noticeId != "add") {
          this.getNoticeInfo()
        } else {
          this.resetTemp()
          this.checkedKeys = [];
          this.checkedTarget = [];
        }
      }
    }
  },
  methods: {
    // 公告类型
    getTypeList () {
      noticeTypeList().then(res => {
        if (res !== null && res.data.code === 100) {
          this.typeList = res.data.data
        }
      })
    },
    // 获取国家
    // getUserCountryList(){
    //   userCountryData().then(res=>{
    //     this.userCountryList = res.data.data
    //   })
    // },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          let activeEditor = tinymce.activeEditor;
          let editBody = activeEditor.getBody();
          activeEditor.selection.select(editBody);
          let text = activeEditor.selection.getContent( { 'format' : 'text' } );
          if (text.trim() == '' && (this.temp.content).indexOf("img") ==-1) {
            handleAlert('error','系统公告内容为空')
            return false;
          }
          this.$loading.show()
          var params = new URLSearchParams()
          params.append('title', this.temp.title)
          params.append('content', this.temp.content)
          params.append('status', this.temp.status)
          if (this.temp.country && this.temp.country.length>0) {
            params.append('sltCountry', this.temp.country.toString())
          }
          if (this.checkedTarget && this.checkedTarget.length>0) {
            params.append('targetStr', this.checkedTarget.toString())
          }
          params.append('type', this.temp.type)
          params.append('isTop', this.temp.isTop)
          params.append('isRecmd', this.temp.isRecmd)
          if (this.noticeId == "add") {
            noticeAdd(params).then(res => {
              if (res.data.code === 100) {
                handleAlert('success',res.data.msg)
                removeTabs(this.$route.path);
                this.$router.push('/sysmgt/notice/list');
                setTimeout(() => {
                  addTabs(this.$route.path, "系统公告");
                })
              } else {
                handleAlert('error',res.data.msg)
              }
              this.$loading.hide()
            }).catch(err => {
              this.$loading.hide()
              if (err !== null && err !== '' && err.responseText !== null) {
                handleAlert('error','提交失败,请重试')
              }
            })
          }else{
            params.append('id', this.temp.id)
            noticeEdit(params).then(res => {
              if (res.data.code === 100) {
                handleAlert('success',res.data.msg)
                removeTabs(this.$route.path);
                this.$router.push('/sysmgt/notice/list')
                setTimeout(() => {
                  addTabs(this.$route.path, "系统公告");
                })
              } else {
                handleAlert('error',res.data.msg)
              }
              this.$loading.hide()
            }).catch(err => {
              this.$loading.hide()
              if (err !== null && err !== '' && err.responseText !== null) {
                handleAlert('error','提交失败,请重试')
              }
            })
          }
          if(this.temp.status == "2") {
            window.noticeUnread();
          }
        } else {
          this.$loading.hide()
          handleAlert('error','请完善信息')
        }
      })
    },
    getNoticeInfo () {
      noticeInfo(this.noticeId).then(res=>{
        if(res.data.code == 100){
          var data = res.data.data;
          this.temp.id = data.id
          this.temp.title = data.title
          this.temp.content = data.content
          this.temp.status = data.status
          this.temp.type = data.type
          this.temp.isTop = data.isTop
          this.temp.isRecmd = data.isRecmd
          this.temp.country = data.countryList
          this.temp.targetIdList = data.targetIdList
          this.checkedTarget = data.targetIdList
          this.$refs.noticeEditor.setValue(data.content)
          if(data.targetIdList && data.targetIdList.length > 0){
            this.targetName = '已选择';
          }
        }else{
          handleAlert('error', '内容已删除')
          removeTabs(this.$route.path);
          this.$router.push('/sysmgt/notice/list')
          setTimeout(() => {
            addTabs(this.$route.path, "系统公告");
          })
        }

      })
    },
    resetTemp(){
      this.temp= {
        id: '',
        title: '',
        content: '',
        status: 1,
        type: 'notice',
        isTop: 0,
        isRecmd: 0,
        country: ['cn'],
        targetIdList: [],
      },
      this.checkedTarget = []
      this.targetName = '未选择'
      this.$nextTick(function() {
        this.$refs.temp.clearValidate();
        this.$refs.noticeEditor.setValue("")
      })
    },
    // 重置
    resetForm (temp) {
      this.resetTemp()
    },

    selRowKey(row){
      return row.id
    },
    // 适用对象
    suitTarget(){
      this.filterText = ''
      this.dialogeditTargetVisible = true
      let ids = this.checkedTarget
      let _this = this
      let list = []
      setTimeout(() => {
        _this.applyCountrylList.forEach(itm => {
        if(ids.includes(itm.id)){
          list.push(itm)
          _this.$refs.applytable.toggleRowSelection(itm, true)
        }
      })
      this.handleSelectionApply(list)

      this.resetApply();
      })
      
    },
    handleSelectionApply(val){
      // console.log("选中的车型：", val);
      this.determineModelList = val
      this.selectNum = this.determineModelList.length
    },
    // 获取全部
    authorizeAll(){
      let list = []
      let _this = this
      _this.emptyCountry()
      _this.applyCountrylList.forEach(row => {
        list.push(row)
        _this.$refs.applytable.toggleRowSelection(row, true)
      })
      this.handleSelectionApply(list)
    },
    // 清空
    emptyCountry(){
      this.handleSelectionApply([])
      this.$refs.applytable.clearSelection();
    },
     // 删除授权
    deleteEmpower(row){
      let _this = this
      _this.determineModelList.forEach(itm => {
        if(itm.id === row.id){
          _this.$refs.applytable.toggleRowSelection(row, false)
        }
      })
    },

    // 搜索
    searchApply(){
      this.currentPageApply = 1
      this.selectCountry();
    },
    // 重置
    resetApply(){
      this.currentPageApply = 1
      this.countryInfo.country = ''
      this.countryInfo.username = ''
      this.countryInfo.type = ''
      this.selectCountry();
    },

    // 获取数据
    selectCountry(){
      this.applyCountrylLists = []
      let country = this.countryInfo.country
      let username = this.countryInfo.username
      let type = this.countryInfo.type
      let list = []
      this.applyCountrylLists = []
      if (!country && !username && !type) {
        list = this.applyCountrylList
      }else{
        let l = this.applyCountrylList.length
        let row;
        for (let i = 0; i < l; i++) {
          row = this.applyCountrylList[i]
          if (country && country != row.defaultCountryCode) {
            continue
          }
          if(type && type != row.userType){
            continue
          }
          if (username && !row.username.includes(username)) {
            continue
          }
          list.push(row)
        }
      }

      if (list.length <= 0) {
        this.totalApply=0
        this.applyCountrylLists = []
        return;
      }
      // 分页
      this.totalApply = list.length
      let i = (this.currentPageApply - 1) * this.pagesizeApply
      let j = i + this.pagesizeApply
      this.applyCountrylLists = list.slice(i, j)
      this.nationalArea()
    },


    // 确定
    getCheckedKeys() {
      let list = this.determineModelList
      this.dialogeditTargetVisible = false
      if(list && list.length > 0){
        this.targetName = '已选择'
      }else{
        this.targetName = '未选择'
      }
      let lst = []
      let cList = []
      list.forEach(item => {
            lst.push(item.id)
            if (item.defaultCountryCode) {
              cList.push(item.defaultCountryCode)
            }
        })
      this.checkedTarget = lst
      this.temp.country = Array.from(new Set(cList))
    },

    // 分配国家 权限选择
    cellMouseEnter(row){
      $(".nationalList._" + row.countryId + " .el-icon-close").show()
    },
    cellLeaveEnter(){
      $(".nationalList .el-icon-close").hide()
    },
    // 分配国家 表格高度
    nationalArea() {
      var _this = this;
      setTimeout(() => {
        var allTable = $(".nationalInfo").outerHeight(true);
        _this.accreditTableHeight = allTable;
      }, 80)
      window.addEventListener("resize", function () {
        setTimeout(() => {
          var tableArea = $(".nationalInfo").outerHeight(true);
          _this.accreditTableHeight = tableArea;
        }, 80)
      })
    },

    // 获取适用对象
    getTarget(){
      this.continentList = []
      let _this = this
      let list = []
      noticTarget().then(res => {
        _this.applyCountrylList = res.data.data
        _this.applyCountrylList.forEach(itm => {
          if (itm.defaultCountryCode && !list.includes(itm.defaultCountryCode)) {
            list.push(itm.defaultCountryCode)
            _this.continentList.push({code: itm.defaultCountryCode, name: itm.defaultCountryName})
          }
          
        })
      }).catch(e => {
        this.applyCountrylList = []
        this.continentList = []
      })
    },

  },
  mounted () {
    this.getTypeList()
    // this.getUserCountryList()
    this.getTarget()
    this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=sysNotice'
    this.noticeId = this.$route.params.id;
    if (this.noticeId != "add") {
      this.getNoticeInfo()
    } else {
      this.resetTemp()
    }
  },
}
</script>
<style>
  .layoutContainer.addNoticeContent {
    overflow-y: auto;
  }
  .tox-pop--top {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .addNoticeContent .el-select {
    width: 100%
  }


  /* 对象 */
  /* 分页 */
  .assignCountryArea .el-pagination {
    padding: 3px 15px !important;
  }
  .assignCountryArea .el-pagination .el-pagination__sizes .el-select {
    width: 85px;
  }
  .assignCountryArea .el-pagination .el-input__icon {
    line-height: 25px;
  }
  .assignCountryArea .el-dialog .el-form-item {
    margin-bottom: 10px;
    margin-top: 0px
  }
  .assignCountryArea .el-dialog .el-pagination .el-pagination__jump {
    margin-left: 10px;
  }
  .assignCountryArea .el-dialog .el-pagination .el-pagination__jump .el-input {
    width: 50px !important;
  }
  /*右侧 */
  .assignCountryArea .nationalSelect .el-table {
    border-left: 0px solid transparent;
  }
  .assignCountryArea .nationalSelect .el-table td.el-table__cell {
    border-bottom: 1px solid transparent !important;
  }
  .assignCountryArea .nationalSelect .el-table .el-table__body tr.el-table__row td.el-table__cell,
  .assignCountryArea .nationalSelect .el-table {
    background: var(--dialog-color);
  }
  .nationalSelect .authorityTitle,
  .nationalSelect .nationalList {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nationalSelect .authorityTitle  span {
    margin: 0 5px;

  }
  .nationalSelect .authorityTitle  span b {
    color: var(--theme-color) ;
  }
  .nationalSelect .authorityTitle .warrantAction {
    color: #1890ff ;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
  }
  .nationalSelect .authorityTitle .clearAction {
    color: #d91c1c;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
  }
  .nationalSelect .nationalList span{
    display: inline-block;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .nationalSelect i {
    cursor: pointer;
    display: none;
    font-size: 14px;
    font-weight: bold;
  }
</style>
