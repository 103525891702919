<template>
  <div class="reminderContent" id="reminderContainer">
    <div @mousemove="Hotline($event)" v-if="false">
      <div class="infoIconArea">
        <el-badge :hidden="infoTotal > 0 ? false : true" :value="infoTotal" class="item" :max="99">
          <div class="reminderTips">
            <img @click.stop="infoClick" src="../../assets/image/noticeIcon/messageIcon.png" alt="" />
          </div>
        </el-badge>
      </div>
      <div class="systemReminder" >
        <div>
          <div class="reminderTitle">
            <p>
              <img class="titleIcon" src="../../assets/image/noticeIcon/noticeIcon.png" alt="" />
              <span class="titleName">系统提醒</span>
            </p>
            <p @click.stop="reminderClick"><i class="el-icon-minus"></i></p>
          </div>
          <div class="reminderArea">
            <div v-for="(item, index) of reminderList" :key="index" @click.stop="menuClick(item)">
              <p>
                <img :src="require('../../' + imgSrc + iconList.get(item.name))" alt="" />
                {{item.name}}
              </p>
              <p>{{item.count}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="messageReminder">
        <div>
          <div class="reminderTitle">
            <p>
              <img class="titleIcon" src="../../assets/image/noticeIcon/infoIcon.png" alt="" />
              <span class="titleName">消息提醒</span>
            </p>
            <p @click.stop="messageClose"><i class="el-icon-close"></i></p>
          </div>
          <div class="messageArea">
            <p @click.stop="detailClick()">{{ messageTitle }}</p>
            <p @click.stop="detailClick()">查看详情<i class="el-icon-arrow-right"></i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { messageData } from "@/api/sysmgt.js"
import { addTabs } from '@/assets/js/common.js'
import { initWebSocket } from '@/assets/js/real_message.js'
export default {
  name: "reminder",
  data() {
    return {
      imgSrc: "assets/image/noticeIcon/",
      iconList: [],
      infoTotal: 0,
      messageDetail: [],
      messageTitle: [],
      reminderList: [],
    }
  },
  watch: {
    // $route(to) {
    //   setTimeout(() => {
    //     this.systemInfo()
    //   })
    // }
  },
  methods: {
    widthSize() {
      setTimeout(() => {
        var container = document.getElementById("reminderContainer");
        var dispalyVal = $(".infoIconArea").css("display");
        var infoWidth = $(".infoIconArea .el-badge").width();
        var widthVal = $(".systemReminder").width();
        var height = "";
        if (dispalyVal == "block") {
          $("#reminderContainer").css("width", infoWidth);
          height = $(".infoIconArea .el-badge")[0].offsetHeight;
        } else {
          $("#reminderContainer").css("width", widthVal);
          if ($(".systemReminder").css("display") == "block") {
            height = $(".systemReminder")[0].offsetHeight;
          } else {
            height = $(".messageReminder")[0].offsetHeight;
          }
        }
        if (container.style.top == "") {
          var allHeight = document.body.offsetHeight;
          var footer = $(".footerArea").height();
          var val = allHeight - footer - height - 5;
          $(".reminderContent").css("top", val);
        } else {
          if (dispalyVal == "block") {
            if (sessionStorage.getItem("location") != null) {
              var coordinate = JSON.parse(sessionStorage.getItem("location"));
              $(".reminderContent").css("top", coordinate.top);
              $(".reminderContent").css("left", coordinate.left);
            } else {
              $(".reminderContent").css("top", container.style.top);
            }
          }
          $(".reminderContent").css("top", container.style.top);
        }
        // this.moveState();
      }, 50);
    },
    widthArea() {
      var _this = this;
      _this.widthSize();
      _this.Hotline(event);
      window.addEventListener("resize", function () {
        _this.widthSize();
        _this.Hotline(event);
      });
    },
    Hotline(event) {
      var container = document.getElementById("reminderContainer");
      var allWidth = document.body.offsetWidth;
      var allHeight = document.body.offsetHeight;
      container.onmousedown = function (ev) {
        var oevent = ev || event;
        var distanceX = oevent.clientX - container.offsetLeft;
        var distanceY = oevent.clientY - container.offsetTop;
        oevent.preventDefault();
        if ($(event.target).attr("class") == undefined) {
          return;
        }
        document.onmousemove = function (ev) {
          var oevent = ev || event;
          oevent.preventDefault();
          if (oevent.clientX - distanceX < 0) {
            container.style.left = 0 + "px";
          } else if (
            oevent.clientX - distanceX >
            allWidth - container.offsetWidth
          ) {
            container.style.left = allWidth - container.offsetWidth + "px";
          } else {
            container.style.left = oevent.clientX - distanceX + "px";
          }
          if (oevent.clientY - distanceY < 0) {
            container.style.top = 0 + "px";
          } else if (
            oevent.clientY - distanceY >
            allHeight - container.offsetHeight
          ) {
            container.style.top = allHeight - container.offsetHeight + "px";
          } else {
            container.style.top = oevent.clientY - distanceY + "px";
          }
        };
        document.onmouseup = function () {
          oevent.preventDefault();
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    },
    moveState() {
      var container = document.getElementById("reminderContainer");
      var widthMax =
        document.body.offsetWidth - $(".systemReminder")[0].offsetWidth;
      var heightMax =
        document.body.offsetHeight - $(".systemReminder")[0].offsetHeight;
      var location = {
        left: container.style.left,
        top: container.style.top
      }
      sessionStorage.setItem("location", JSON.stringify(location))
      if (container.offsetLeft > widthMax) {
        container.style.left = widthMax + "px";
      }
      if (container.offsetTop > heightMax) {
        container.style.top = heightMax + "px";
      }
    },
    infoClick() {
      var _this = this;
      event.stopPropagation();
      event.preventDefault();
      $(".systemReminder").show();
      $(".systemReminder").css({ animation: "infoShow 0.4s ease-in-out" });
      setTimeout(() => {
        $(".systemReminder").removeAttr("style");
        $(".infoIconArea,.messageReminder").hide();
        _this.widthArea();
        _this.moveState();
      }, 100)
    },
    reminderClick() {
      var _this = this;
      $(".infoIconArea").show();
      $(".systemReminder,.messageReminder").css({ animation: "infoHide 0.4s ease-in-out" });
      setTimeout(() => {
        $(".systemReminder,.messageReminder").removeAttr("style");
        $(".systemReminder,.messageReminder").hide();
        _this.widthArea();
      }, 100)
    },
    // 数量
    systemInfo() {
      var _this = this;
      _this.iconList = new Map([
        ["待办任务", "pendIcon.png"],
        ["反馈记录", "repliedIcon.png"],
        ["技术通告", "technicalCircular.png"],
        ["系统公告", "systemBulletin.png"],
      ])
      messageData().then(res => {
        if (res.data.code == 100) {
          _this.infoTotal = res.data.total;
          _this.reminderList = res.data.data;
          if (_this.infoTotal == 0) {
            _this.reminderClick();
          }
          _this.widthArea();
        }
      })
    },
    menuClick(item) {
      event.stopPropagation();
      event.preventDefault();
      this.$router.push(item.url);
      setTimeout(() => {
        addTabs(item.url, item.name)
      });
      this.reminderClick();
    },
    // 消息提醒
    detailnfo(message) {
      $(".messageReminder").show();
      $(".systemReminder,.infoIconArea").hide();
      setTimeout(() => {
        $(".messageReminder").css({ animation: "infoHide 0.4s ease-in-out" });
        $(".infoIconArea").show();
        setTimeout(() => {
          $(".messageReminder").removeAttr("style");
          $(".systemReminder,.messageReminder").hide();
        }, 300)
      }, 1000*60);
      this.messageDetail = message;
      this.systemInfo();
    },
    messageClose() {
      var _this = this;
      _this.reminderClick();
      _this.widthArea();
    },
    // 查看详情
    detailClick() {
      event.stopPropagation();
      event.preventDefault();
      var type = this.messageDetail.type;
      if (type == 1) {
        this.$router.push('/material/feedback/list')
        setTimeout(() => {
          addTabs(this.$route.path, "在线反馈");
        })
      } else if (type == 3) {
        var bulletinId = this.messageDetail.data.id;
        var bulletinTitle = this.messageDetail.data.title;
        this.$router.push({ name: 'bulletinDetails', params: { id: bulletinId }});
        addTabs(this.$route.path, bulletinTitle);
      } else if (type == 4) {
        var noticeId = this.messageDetail.data.id;
        var noticeTitle = this.messageDetail.data.title;
        this.$router.push({ name: 'noticeDetail', params: { id: noticeId }});
        addTabs(this.$route.path, noticeTitle);
      }
      this.reminderClick();
    }
  },
  mounted() {
    // this.systemInfo();
    // window.systemReminder = () => {
    //   setTimeout(() => {
    //     this.systemInfo();
    //   })
    // }
    // initWebSocket(message => {
    //   switch(message.type) {
    //     case 1:
    //       // 在线反馈
    //       this.detailnfo(message)
    //       this.messageTitle = message.data.problemTheme;
    //       // console.log("在线反馈主题", message.data.problemTheme);
    //       break;
    //     case 3:
    //       // 技术通告
    //       this.detailnfo(message)
    //       this.messageTitle = message.data.title;
    //       // console.log("技术通告标题", message.data.title)
    //       break;
    //     case 4:
    //       // 系统公告
    //       this.detailnfo(message)
    //       this.messageTitle = message.data.title;
    //       // console.log("系统公告标题", message.data.title);
    //       break;
    //     default:
    //       break;
    //   }
    // })
  },
}
</script>
<style>
  .reminderContent {
    position: fixed;
    right: 13px;
    z-index: 100;
    cursor: move;
  }
  @keyframes infoShow {
    0%{
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes infoHide {
    0%{
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  /* 提示按钮 */
  .reminderContent .el-badge__content.is-fixed {
    right: 20px;
  }
  .reminderTips {
    background: var(--theme-color);
    border-radius: 5px;
    padding: 8px 10px;
    cursor: move;
  }
  .reminderTips img {
    width: 28px;
    vertical-align: middle;
    cursor: pointer;
  }
  /* 系统提醒  */
  .systemReminder,
  .messageReminder {
    width: 240px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #e5e5e5;
  }
  .infoIconArea,
  .messageReminder {
    display: none;
  }
  .reminderTitle,
  .reminderTitle > p:first-child,
  .reminderArea > div,
  .reminderArea > div p:first-child {
    display: flex;
    align-items: center;
  }
  .reminderTitle,
  .reminderArea > div {
    justify-content: space-between;
  }
  .reminderTitle {
    font-size: 14px;
    color:#fff;
    background: var(--theme-color);
    padding: 5px 15px 5px 10px;
    height: 26px;
    border-radius: 5px 5px 0 0;
  }
  .reminderTitle p:last-child {
    cursor: pointer;
  }
  .reminderTitle p img,
  .reminderArea div p img {
    margin-right: 5px;
  }
  .reminderTitle p img{
    width: 14px;
  }
  .reminderTitle p i {
    font-size: 15px;
    font-weight: bold;
    color: #fff !important;
    margin-top: 2px;
  }
  .reminderArea div p img {
    width: 18px;
  }
  .reminderArea,
  .messageArea {
    padding: 5px 0;
  }
  .reminderArea > div,
  .messageArea > p {
    font-size: 12px;
    color: #333;
    padding: 5px 20px 5px 10px;
    cursor: pointer;
  }
  .reminderArea > div {
    height: 30px;
    line-height: 30px;
  }
  .reminderArea > div p:last-child {
    font-size: 18px;
    color: #333;
  }
  /*消息提醒 */
  .messageReminder .reminderTitle p img {
    vertical-align: middle;
    margin-top: 3px;
  }
  .messageArea > p:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .messageArea > p:last-child {
    font-size: 12px;
    display: flex;
    align-items:center;
    justify-content: end;
    color: #189fff;
  }
  .messageArea > p {
    font-size: 13px;
    height: 24px;
    line-height: 24px;
  }
  .messageArea > p:last-child i {
    font-size: 14px;
    vertical-align: middle;
    margin-top: 2px;
  }
</style>
