const icmsmgtRouter = [
    {
        path: '/icmsmgt/data/list',
        component: () => import('@/views/icmsmgt/data/list'),
        name: 'icmsmgt_data_list'
    },
    {
        path: '/icmsmgt/history/list',
        component: () => import('@/views/icmsmgt/history/list'),
        name: 'icmsmgt_i18n_list'
    },
]
export default icmsmgtRouter;
