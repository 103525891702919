<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="推送环境" prop="pushEnv">
          <el-select v-model="formInline.pushEnv" clearable filterable>
            <el-option v-for="(item, index) in pushEvnList" :key="index" :value="item.code" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送方式" prop="pushMethod">
          <el-select v-model="formInline.pushMethod" clearable filterable>
            <el-option value="1" label="本机复制"></el-option>
            <el-option value="2" label="ftp上传"></el-option>
            <el-option value="3" label="http上传"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送状态" prop="pushStatus">
          <el-select v-model="formInline.pushStatus" clearable filterable>
            <el-option value="0" label="未推送"></el-option>
            <el-option value="1" label="推送中"></el-option>
            <el-option value="2" label="推送完成"></el-option>
            <el-option value="3" label="推送失败"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送人" prop="updatedUser">
          <el-select v-model="formInline.updatedUser" clearable filterable>
            <el-option v-for="(item, index) in userList" :key="index" :value="item.id" :label="item.real_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送时间">
          <el-date-picker align="center" type="date" value-format="yyyy-MM-dd" placeholder="选择开始日期" v-model="formInline.beginTime" :picker-options="pickerBeginTime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker align="center" type="date" value-format="yyyy-MM-dd" placeholder="选择结束日期" v-model="formInline.endTime" :picker-options="pickerEndTime"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <div class="tableHandle" v-if="hasPerm('menuAsimssCMS4A2B_101')">
        <el-button type="text" icon="el-icon-plus" @click="addData()">新增</el-button>
      </div>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}">
        <el-table-column label="推送环境" prop="pushEnvName"></el-table-column>
        <el-table-column label="推送方式" prop="pushMethod">
          <template slot-scope="{row}">
            <span v-if="row.pushMethod == '1'">本机复制</span>
            <span v-if="row.pushMethod == '2'">ftp上传</span>
            <span v-if="row.pushMethod == '3'">http上传</span>
          </template>
        </el-table-column>
        <el-table-column label="推送状态" prop="pushStatus">
          <template slot-scope="{row}">
            <span v-if="row.pushStatus === 0">未开始</span>
            <span v-if="row.pushStatus === 1">推送中</span>
            <span v-if="row.pushStatus === 2" class="successColor">推送成功</span>
            <span v-if="row.pushStatus === 3" class="errorColor">推送失败</span>
          </template>
        </el-table-column>
        <el-table-column label="推送人" prop="updatedUserName"></el-table-column>
        <el-table-column label="推送时间" prop="updatedTime" :formatter="dateFormat"></el-table-column>
        <el-table-column label="操作" fixed="right" width="140">
          <template slot-scope="{row}">
            <!-- 2023-02-08 查看表示打开推送的链接地址 -->
            <el-link type="primary" :underline="false" v-if="hasPerm('menuAsimssCMS4A2B_104')" size="mini" :href="row.displayUrl" target="_blank">查看</el-link>
            <!-- <el-button type="text" v-if="hasPerm('menuAsimssCMS4A2B_104')" size="mini" @click="headleDetail(row)">查看</el-button> -->
            <el-button type="text" v-if="hasPerm('menuAsimssCMS4A2B_102')" size="mini" @click="headelDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
        <el-form v-if="dialogStatus === 'add'" :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="推送环境" prop="pushEnv" :label-width="formLabelWidth">
            <el-select v-model="temp.pushEnv" clearable filterable>
              <el-option v-for="(item, index) in pushEvnList" :key="index" :value="item.code" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推送方式" prop="pushMethod" :label-width="formLabelWidth">
            <el-select v-model="temp.pushMethod" clearable filterable>
            <el-option value="1" label="本机复制"></el-option>
            <el-option value="2" label="ftp上传"></el-option>
            <el-option value="3" label="http上传"></el-option>
          </el-select>
          </el-form-item>
          <el-form-item class="submitArea">
            <el-button type="primary" @click="addClick('temp')">立即提交</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'detail'" :model="temp" ref="temp" label-position="center" >
          <el-form-item label="推送环境" prop="pushEnvName" :label-width="formLabelWidth">
            <el-input v-model="temp.pushEnvName" readonly></el-input>
          </el-form-item>
          <el-form-item label="推送方式" prop="pushMethod"  :label-width="formLabelWidth">
            <el-input v-if="temp.pushMethod == '1'" value="本机复制" readonly></el-input>
            <el-input v-if="temp.pushMethod == '2'" value="ftp上传" readonly></el-input>
            <el-input v-if="temp.pushMethod == '3'" value="http上传" readonly></el-input>
          </el-form-item>
          <el-form-item label="推送状态" prop="pushStatus" :label-width="formLabelWidth">
            <el-input v-if="temp.pushStatus === 0" value="未开始" readonly></el-input>
            <el-input v-if="temp.pushStatus === 1" value="推送中" readonly></el-input>
            <el-input v-if="temp.pushStatus === 2" value="推送成功" readonly></el-input>
            <el-input v-if="temp.pushStatus === 3" value="推送失败" readonly></el-input>
          </el-form-item>
          <el-form-item label="推送结果" prop="pushResult" :label-width="formLabelWidth">
            <el-input type="textarea" rows="5" style="width: 85% !important;" v-model="temp.pushResult" readonly></el-input>
          </el-form-item>
          <el-form-item label="推送人" prop="updatedUserName" :label-width="formLabelWidth">
            <el-input v-model="temp.updatedUserName" placeholder="请输入备注" readonly></el-input>
          </el-form-item>
          <el-form-item label="推送时间" prop="updatedTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.updatedTime" readonly></el-date-picker>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, handleAlert } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
// import { dictTypeData } from "@/api/cmsmgt.js";
import { releasePushData, dictTypePush, pushUserList, pushSetData, releasePushAdd, releasePushDel } from '@/api/releasemgt.js'
import axios from 'axios'
export default {
  name: 'release',
  components: { Pagination },
  data () {
    return {
      formInline: {
        pushEnv: '',
        pushMethod: '',
        pushStatus:'',
        updatedUser:'',
        beginTime: null,
        endTime: null,
      },
      temp: {
        pushEnv:"",
        pushEnvName: "",
        pushMethod: "",
        pushResult: "",
        pushStatus: "",
        releaseId: "",
        updatedTime: "",
        updatedUser: "",
        updatedUserName: ""
      },
      pushEvnList: [],
      userList:[],
      pushSetData:[],
      projectId: '',
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        detail: '推送明细',
        add: '新增推送'
      },
      resultList: [],
      pagesize: 20,
      currentPage: 1,
      total: 0,
      formLabelWidth: '100px',
      rules: {
        pushEnv: [{ required: true, message: '推送环境不能为空',  trigger: ['blur', 'change']  }],
        pushMethod: [{ required: true, message: '推送方式不能为空',  trigger: ['blur', 'change']  }],
      },
      pickerBeginTime: {
        disabledDate: (time) => {
          return this.formInline.endTime != null ? time.getTime() > new Date(this.formInline.endTime) : false //只能选结束日期之前的日期
          //返回---结束时间是否有值？   可选时间小于结束时间   ：  任意时间都可选
        }
      },
      pickerEndTime: {
        disabledDate: (time) => {
          return this.formInline.beginTime != null ? time.getTime() < new Date(this.formInline.beginTime) : false //只能选开始时间之后的日期
          //返回---开始时间是否有值？   可选时间大于开始时间   ：  任意时间都可选
        }
      },
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        releaseId:this.releaseId,
        limit:this.pagesize,
        page:this.currentPage,
        pushEnv: this.formInline.pushEnv,
        updatedUser:this.formInline.updatedUser,
        pushStatus: this.formInline.pushStatus,
        pushMethod: this.formInline.pushMethod,
        beginTime: this.formInline.beginTime,
        endTime: this.formInline.endTime,
      }
      releasePushData(params).then(res =>{
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 搜索
    onSubmit () {
      this.currentPage = 1
      this.dataList()
    },
    // 重置
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.formInline.beginTime = ""
      this.formInline.endTime = ""
      this.currentPage = 1
      this.dataList()
    },
    // 获取推送设置
    getPushSetList() {
      var params = {
        projectId: this.projectId
      }
      pushSetData(params).then(res => {
        if(res.data.code === 100){
          this.pushSetData = res.data.data;
        }
      })
    },
    // 获取推送环境
    getPushEvnList () {
      dictTypePush("pushEvn").then(res => {
        if(res.data.code === 100){
          this.pushEvnList = res.data.data;
        }
      })
    },
    // 获取推送人
    getPushUser(){
      var params = {
        releaseId: this.releaseId
      }
      pushUserList(params).then(res => {
        if(res.data.code === 100){
          this.userList = res.data.data
        }
      })
    },
    resetTemp () {
      this.temp = {
        configId:"",
        pushEnvName: "",
        pushMethod: "",
        pushResult: "",
        pushStatus: "",
        releaseId: "",
        updatedTime: "",
        updatedUser: "",
        updatedUserName: ""
      }
      this.$nextTick(function() {
        this.$refs.temp.clearValidate();
      })
    },
    // 新增
    addData () {
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
      this.resetTemp()
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('projectId', this.projectId)
          params.append('releaseId', this.releaseId)
          params.append('pushEnv', this.temp.pushEnv)
          params.append('pushMethod', this.temp.pushMethod)
          releasePushAdd(params).then(res => {
            if (res.data.code === 100) {
              handleAlert('success','提交成功')
              this.dataList()
              this.dialogFormVisible = false
            } else {
              handleAlert('error','新增失败')
            }
          }).catch((err) => {
            if (err !== null && err !== "" && err.responseText !== null) {
              handleAlert('error',"新增失败,请重试")
            }
          });
        } else {
          handleAlert('error',"请完善信息")
        }
      })
    },
    resetForm(temp){
      this.resetTemp()
    },
    // 查看
    headleDetail (row) {
      this.temp = Object.assign({}, row)
      this.dialogStatus = 'detail'
      this.dialogFormVisible = true
    },
    // 删除
    headelDelete (row) {
      this.$confirm('确定删除当前的推送记录信息?', '删除推送记录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        releasePushDel(row.id).then(res => {
          if (res.data.code === 100) {
            handleAlert('success','删除成功')
            if(this.total > 1){
              if(this.resultList!=null&&this.resultList.length == 1){
                this.currentPage =this.currentPage-1
              }
            }
            this.dataList()
          }else {
            handleAlert('error','删除失败')
          }
        })
      }).catch((error) => {})
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        const H = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
        const Min = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
        const S = date.getSeconds() < 10 ? '0' + date.getSeconds() + '' : date.getSeconds()
        return Y + M + D + H + Min + S
      }
    },
  },
  mounted () {
    this.releaseId = this.$route.params.id
    this.projectId = this.$route.params.projectId
    this.dataList()
    this.getPushSetList()
    this.getPushEvnList()
    this.getPushUser()
  },
}
</script>
<style>
  .el-link{
    font-size: 12px;
    line-height: 1;
    margin-right: 14px;
  }
</style>
