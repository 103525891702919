import serviceDetail from '@/views/statistics/service/detail'

const statisticsRouter = [
    {
        path: '/statistics/number/list',
        component: () => import('@/views/statistics/number/list'),
        name: 'statistics_number_list'
    },
    {
        path: '/statistics/people/list',
        component: () => import('@/views/statistics/people/list'),
        name: 'statistics_people_list'
    },
    {
        path: '/statistics/feedback/list',
        component: () => import('@/views/statistics/feedback/list'),
        name: 'statistics_feedback_list'
    },
    {
        path: '/statistics/comment/list',
        component: () => import('@/views/statistics/comment/list'),
        name: 'statistics_comment_list'
    },
    {
        path: '/statistics/service/list',
        component: () => import('@/views/statistics/service/list'),
        name: 'statistics_service_list'
    },
    {
        path: '/serviceDetail',
        component: serviceDetail,
        name: 'statistics_service_detail'
    },
]
export default statisticsRouter;

