<template>
  <div class="workbench">
    <div class="mineContainer">
      <div class="deviceStatistics">
        <p>设备状态统计</p>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="7">
            <div class="deviceNumArea">
              <div class="deviceInfo">
                <div class="infoNum">
                  <p>设备数量</p>
                  <p>63</p>
                </div>
                <div>
                  <img src="../../assets/image/deviceCount.png" alt="">
                </div>
              </div>
              <div class="deviceTips">
                <p>今日新增 <span class="successColor">2</span></p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="9">
            <div class="lineStateArea">
              <div class="onlineArea">
                <el-progress type="circle" :stroke-width="10" :percentage="88" :format="formatOne" color="#1db314"></el-progress>
                <p>在线设备</p>
                <p class="successColor">88%</p>
              </div>
              <div class="offlineArea">
                <el-progress type="circle" :stroke-width="10" :percentage="7" :format="formatTwo"  color="#ff2c2e"></el-progress>
                <p>离线设备</p>
                <p class="errorColor">7%</p>
              </div>
              <div class="indiciaArea">
                <p>在线设备：<span>56</span></p>
                <p>离线设备：<span>7</span></p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="reportDataStatistics">
        <p>上报数据量统计</p>
        <div class="secondFloat">
          <el-form :inline="true" ref="formInline" label-width="80px" :model="formInline" class="demo-form-inline">
            <el-form-item label="服务店名称" prop="name">
              <el-input v-model="formInline.name" placeholder="请输入服务店名称"></el-input>
            </el-form-item>
            <el-form-item label="检测时间" >
              <el-date-picker align="center" prop="startDate" value-format="yyyy-MM-dd" type="date" placeholder="选择开始日期"
                v-model="valueDate.startDate" :picker-options="pickerBeginTime"></el-date-picker>
              <span class="line">至</span>
              <el-date-picker align="center" prop="endDate" value-format="yyyy-MM-dd" type="date" placeholder="选择结束日期"
                  v-model="valueDate.endDate" :picker-options="pickerEndTime"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search">搜索</el-button>
              <el-button plain >重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="7">
            <div class="reportDataArea">
              <div class="reportDataInfo">
                <div class="infoNum">
                  <p>上报数据量</p>
                  <p>1450</p>
                </div>
                <div>
                  <img src="../../assets/image/reportedIcon.png" alt="">
                </div>
              </div>
              <div class="deviceTips">
                <p>今日新增 <span class="successColor">289</span></p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="14" :lg="16" :xl="17">
            <div class="statisticsArea">
              <div id="myChart" style="width:100%;height:280px"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { addTabs,  handleAlert } from '@/assets/js/common.js'
import $ from 'jquery'
export default {
  name: 'dashboard',
  data () {
    return {
      lineSX: [], // 横坐标 时间
      lineSY1: [], // 纵坐标 订单
      lineSY2: [], // 纵坐标 访问
      formInline: {
        name:"",
        startDate:"",
        endDate:"",
      },
      // 时间段
      valueDate: {
        startDate: '',
        endDate: '',
      },
      pickerBeginTime: {
        disabledDate: (time) => {
          return this.valueDate.endDate != null ? time.getTime() > new Date(this.valueDate.endDate) : false //只能选结束日期之前的日期
          //返回---结束时间是否有值？   可选时间小于结束时间   ：  任意时间都可选
        }
      },
      pickerEndTime: {
        disabledDate: (time) => {
          return this.valueDate.startDate != null ? time.getTime() < new Date(this.valueDate.startDate) : false //只能选开始时间之后的日期
          //返回---开始时间是否有值？   可选时间大于开始时间   ：  任意时间都可选
        }
      },
    }
  },
  methods: {
    formatOne(percentage) {
      return "56个";
    },
    formatTwo(percentage) {
      return "7个";
    },
    getStatistics(){
      this.drawLine();
    },
    drawLine() {
      var myChart = this.$echarts.init(document.getElementById('myChart'));
      // 指定图表的配置项和数据
        var option = {
        backgroundColor: "#fff",
        grid:{
          top:'10%',
          right:'5%',
          bottom:'7%',
          left:'4%'
        },
          tooltip: {},
          legend: {
            data:['上报数据量'],
            right:"4%"
          },
          xAxis: {
            name:"时间",
            data: ["06-1","06-2","06-3","06-4","06-5","06-6","06-7","06-8"]
          },
          yAxis: {
            name: "数据量",
          },
          series: [
            {
              name: '上报数据量',
              type: 'bar',
              data: [16, 13, 24, 17, 3, 8, 20, 7],
              itemStyle: {
                color: "rgb(35, 134, 238)",
              }
            }
          ]
      };
      // 使用刚指定的配置项和数据显示图表。
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      myChart.setOption(option);
    },
    // 获取当前时间的近一年区间
    intervalTime(){
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      this.valueDate = {
        startDate: start,
        endDate: end,
      }
    }
  },
  mounted () {
    this.getStatistics();
    this.intervalTime();
  },
  created() {
  },
  watch: {
    $route(to,from){
      if (to.name == 'dashboard') {
      }
    }
  },
}
</script>
<style>
/* header */
.workbench {
  /* background: var(--work-bgColor); */
  padding: 15px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid var(--table-color);
  box-sizing: border-box;
}
.deviceStatistics,
.reportDataStatistics{
  background: var(--dialog-color);
  margin: 10px 0;
  padding: 15px 25px;
  border: 1px solid var(--table-color);
}
.deviceStatistics {
  margin-top: 0;
}
.deviceStatistics > p,
.reportDataStatistics > p {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1%;
}
.deviceStatistics .el-col ,.reportDataStatistics .el-col {
  margin: 10px 0;
}
/* 设备数量 */
.deviceNumArea,
.lineStateArea,
.reportDataArea,
.statisticsArea {
  height: 280px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}
.statisticsArea {
  border: none
}
.deviceInfo,
.reportDataInfo {
  height: 230px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
}
.deviceInfo .infoNum,
.reportDataInfo .infoNum {
  margin-top: 8%;
  margin-left: 5%;
  font-size: 18px;
  white-space: nowrap;
}
.deviceInfo .infoNum p:last-child,
.reportDataInfo .infoNum p:last-child {
  margin-top: 20px;
  font-size: 50px;
}
.deviceInfo div:last-child,
.reportDataInfo div:last-child {
  flex: 1;
  margin-right: -2px;
}
.deviceInfo div img,
.reportDataInfo div img {
  margin-top: -1px;
  margin-right: -2px;
  width: 100%;
}
.deviceTips {
  height: 50px;
  line-height: 50px;
  padding: 0 5%;
  border-top: 1px solid var(--border-color);
  color: #666666
}
.deviceTips span {
  margin-left: 3%;
  font-weight: bold;
}
/*  */
.lineStateArea {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 4%;
}
.indiciaArea {
  align-items: flex-start;
}
.indiciaArea p {
  margin: 10px;
  font-size: 14px;
  color: rgb(102,102,102);
}
.indiciaArea p span{
  color: #000;
  font-weight: bold;
}
.indiciaArea p::before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  border-radius: 2px;
  background: #1db314;
  vertical-align: middle;
  margin-top: -2px;
}
.indiciaArea p:last-child:before {
  background: #ff2c2e
}
.onlineArea,
.offlineArea{
  text-align: center;
  margin-top: 5%;
}
.onlineArea p,
.offlineArea p {
  margin: 10px;
}
.onlineArea p:last-child,
.offlineArea p:last-child {
  font-weight: bold;
}
.reportDataStatistics .secondFloat{
  text-align: right;
  margin-top: -3%;
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .deviceStatistics,
  .reportDataStatistics{
    margin: 10px 0;
    padding: 12px 20px;
  }
  .deviceStatistics > p,
  .reportDataStatistics > p {
    font-size: 15px;
    margin-bottom: 12px;
  }
  /* 设备数量 */
  .deviceNumArea,
  .lineStateArea,
  .reportDataArea,
  .statisticsArea {
    height: 240px;
  }
  .deviceInfo,
  .reportDataInfo {
    height: 205px;
  }
  .deviceInfo .infoNum,
  .reportDataInfo .infoNum {
    font-size: 16px;
  }
  .deviceInfo .infoNum p:last-child,
  .reportDataInfo .infoNum p:last-child {
    margin-top: 15px;
    font-size: 30px;
  }
  .deviceTips {
    font-size: 14px;
    height: 35px;
    line-height: 35px;
  }
  .indiciaArea p {
    margin: 8px;
    font-size: 12px;
  }

  .indiciaArea p::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -2px;
  }

  .onlineArea,
  .offlineArea{
    text-align: center;
    margin-top: 5%;
  }
  .onlineArea .el-progress-circle,
  .offlineArea .el-progress-circle {
    width: 100px !important;
    height: 100px !important;
  }
  .onlineArea p,
  .offlineArea p {
    margin: 8px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  /* header */
  .workbench {
    padding: 10px;
    height: calc(100% - 10px);
  }
  .deviceStatistics,
  .reportDataStatistics{
    margin: 10px 0;
    padding: 10px 15px;
  }
  .deviceStatistics > p,
  .reportDataStatistics > p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  /* 设备数量 */
  .deviceNumArea,
  .lineStateArea,
  .reportDataArea,
  .statisticsArea {
    height: 220px;
  }
  .deviceInfo,
  .reportDataInfo {
    height: 190px;
  }
  .deviceInfo .infoNum,
  .reportDataInfo .infoNum {
    font-size: 15px;
  }
  .deviceInfo .infoNum p:last-child,
  .reportDataInfo .infoNum p:last-child {
    margin-top: 10px;
    font-size: 25px;
  }
  .deviceTips {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
  }
  .indiciaArea p {
    margin: 6px;
    font-size: 12px;
  }

  .indiciaArea p::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -2px;
  }

  .onlineArea,
  .offlineArea{
    text-align: center;
    margin-top: 5%;
  }
  .onlineArea .el-progress-circle,
  .offlineArea .el-progress-circle {
    width: 90px !important;
    height: 90px !important;
  }
  .onlineArea p,
  .offlineArea p {
    margin: 6px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  /* header */
  .workbench {
    padding: 10px;
    height: calc(100% - 10px);
  }
  .deviceStatistics,
  .reportDataStatistics{
    margin: 10px 0;
    padding: 10px 15px;
  }
  .deviceStatistics > p,
  .reportDataStatistics > p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  /* 设备数量 */
  .deviceNumArea,
  .lineStateArea,
  .reportDataArea,
  .statisticsArea {
    height: 220px;
  }
  .deviceInfo,
  .reportDataInfo {
    height: 190px;
  }
  .deviceInfo .infoNum,
  .reportDataInfo .infoNum {
    font-size: 15px;
  }
  .deviceInfo .infoNum p:last-child,
  .reportDataInfo .infoNum p:last-child {
    margin-top: 10px;
    font-size: 25px;
  }
  .deviceTips {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
  }
  .indiciaArea p {
    margin: 6px;
    font-size: 12px;
  }

  .indiciaArea p::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -2px;
  }

  .onlineArea,
  .offlineArea{
    text-align: center;
    margin-top: 5%;
  }
  .onlineArea .el-progress-circle,
  .offlineArea .el-progress-circle {
    width: 90px !important;
    height: 90px !important;
  }
  .onlineArea p,
  .offlineArea p {
    margin: 6px;
    font-size: 14px;
  }
}
</style>
