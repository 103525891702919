import store from '@/store/index';
import router from '@/router'
import {requestTokenRefresh} from "@/api/sysmgt.js"
import { handleAlert } from './common';
let wasUpdateTokenFlag = false,
  requestQueue = []

/**
 * 刷新token
 * @param response
 * @param axiosInstance
 * @returns
 */
export const autoRefreshToken = async (
  response,
  axiosInstance
) => {
  //刷新token不存在，直接退出
  if(sessionStorage.refreshToken == undefined || sessionStorage.refreshToken == null || sessionStorage.refreshToken == ""){
      return response;
  }
  const config = response.config
  if (!wasUpdateTokenFlag) {
    sessionStorage.setItem("isRefreshToken","1")
    console.log('refresh token')
    wasUpdateTokenFlag = true
    const config = response.config
      //刷新token
      try {
      let params =   new URLSearchParams();
      params.append("refresh_token",sessionStorage.refreshToken)
      const res =  await requestTokenRefresh(params)
          if (res.data.code === 100) {
            //设置token
            var tokenVal = res.data.data.tokenHead + res.data.data.token
              store.commit('set_token', tokenVal)
               //刷新token不加Bearer
               store.commit("setRefreshToken",res.data.data.refreshToken);
              //向其他tab发送消息，同步token
              if(window.bc){
                window.bc.postMessage(JSON.stringify(store.state))
                }
            // 执行完更新操作,重新执行未执行请求
            requestQueue.forEach((execute) => execute())
            // 置空队列数组
            requestQueue = []
            config.headers['Authorization'] = tokenVal
            console.log('token刷新成功')
            return axiosInstance(config)
          }else if(res.data.code == 555){
            if((!window.isKidOffLineShow)){
            window.isKidOffLineShow = true
              handleAlert("error",res.data.msg)
              setTimeout(()=>{
                clearTimeout()
                window.isKidOffLineShow = false
                router.push('/')
              },1000)
            }
             return;
          }  else {
            console.log('token刷新失败')
            requestQueue = []
            clearTokenAndGoLogin()
          }
          // 这里是第一次执行进入更新token的接口继续往下执行
          } catch (e){
          console.log(e)
          clearTokenAndGoLogin()
        } finally {
          wasUpdateTokenFlag = false
        sessionStorage.removeItem("isRefreshToken")
        }
  } else {
    return new Promise((resolve) => {
      requestQueue.push(() => {
        config.headers['Authorization'] = sessionStorage.token
         resolve(axiosInstance(config))
      })
    })
  }
  return response
}



function clearTokenAndGoLogin(){
  if(window.onClearToken){
    return
  }
  window.onClearToken = true
  store.commit('del_token');
  handleAlert("error","对不起，当前登录已过期，请重新登录！")
  setTimeout(()=>{
    clearTimeout()
    window.onClearToken = false
    router.push('/')
  },1000)

}
